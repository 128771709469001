import { memo } from "react"

const FormSkeleton = () => (
    <div className="grid grid-cols-2 gap-4">
        <div>
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>

        <div>
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>

        <div className="col-span-2">
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>

        <div>
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>

        <div>
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>

        <div className="col-span-2">
            <div className="skeleton-box h-10 rounded-full">
                <span />
            </div>
        </div>
    </div>
)

export default memo(FormSkeleton);