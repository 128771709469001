import Searchbox from "./searchbox";
import Breadcrumb from "components/shared/breadcrumb";
import { memo, useEffect, useMemo, useState } from "react";
import Checkbox from "components/shared/inputs/checkbox";
import Pagination from "components/shared/pagination";
import { Link, useLocation } from "react-router-dom";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import { generateIconLink, getPagesCount, toMoneyFormat } from "utils";
import convertUtils from "utils/convert-utils";
import PriorityModal from "components/shared/priority-modal";
import UseFetch from "hooks/useFetch";
import Skeleton from "components/skeleton/table-2";
import { paymentStatuses } from "constants/statuses";
import AutoRequestBtn from "../auto-request-btn";
import useAutoRequest from "hooks/useAutoRequest";
import { CSSTransition } from "react-transition-group";

const List = props => {
    const {
        pageData,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [fetchUrl, setFetchUrl] = useState(null);
    const [urlParamsData, setUrlParamsData] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const [priorityData, setPriorityData] = useState({ show: false });

    const [autoRequestStatus, setAutoRequestStatus] = useState(false);
    useAutoRequest(autoRequestStatus, fetchUrl, () => mutate(false));

    useEffect(() => {
        if (!pageData)
            return;

        let newFetchUrl = pageData.index_url;
        let newUrlParams = [];
        let newUrlParamsData = {};

        pageData.search_valid_params.forEach(param => {
            const paramValue = urlParams.get(param);
            if (paramValue) {
                newUrlParamsData[param] = paramValue;
                newUrlParams.push(`${param}=${paramValue}`);
            }
        })

        if (Object.keys(newUrlParamsData).length) {
            newFetchUrl += `?${newUrlParams.join("&")}`;
            setUrlParamsData(newUrlParamsData);
        } else
            setUrlParamsData({});

        setFetchUrl(newFetchUrl);
        // eslint-disable-next-line
    }, [pageData, location.search])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);

        // eslint-disable-next-line
    }, [data])

    const selectRowHandler = id => {
        setSelectedRows(prev => {
            if (selectedRows.indexOf(id) !== -1) {
                const newState = prev.filter(rowId => rowId !== id);
                return newState;
            }

            return [...prev, id];
        })
    }

    const createOrEditLink = id => {
        let url = location.pathname;

        if (id)
            url += `/${id}`;

        const validParams = [];
        if (pageData && pageData.add_valid_params) {
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })
        }

        if (validParams.length)
            url += `?${validParams.join("&")}`;

        return url;
    }

    const localStatuses = useMemo(() => {
        const output = {};
        paymentStatuses.forEach(el => output[`${el.id}`] = el.title);
        return output;
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    {
                        <CSSTransition in={!autoRequestStatus} timeout={200} unmountOnExit={true} appear={true}>
                            <button className="anim-c4 w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                                <i className="fa-regular fa-arrows-retweet"></i>
                            </button>
                        </CSSTransition>
                    }

                    <AutoRequestBtn
                        active={autoRequestStatus}
                        clickHandler={() => setAutoRequestStatus(prev => !prev)}
                    />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="flex justify-between items-start">
                    <Searchbox fields={pageData.search_setting} />
                </div>

                {
                    !isLoading ? (
                        !error ? (
                            data.data?.length ? (
                                <div className="table-c1 mt-10 mb-4">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="w-5"></th>
                                                <th className="w-5"></th>
                                                <th className="w-5"></th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                data.data.map((item, i) => {
                                                    const hasIcon = pageData.index_setting.icon?.length > 0;
                                                    const labels = [];
                                                    ["label_1", "label_2", "label_3"].forEach(label => {
                                                        const labelObj = pageData.index_setting[label];

                                                        if (labelObj) {
                                                            const intendedKey = labelObj.key;
                                                            let value = item[intendedKey] || "-";

                                                            if (labelObj.convert_function)
                                                                value = convertUtils(labelObj.convert_function, value);

                                                            labels.push(`${labelObj.label}: ${value}`);
                                                        }
                                                    })

                                                    labels.push(`مبلغ فاکتور: ${toMoneyFormat(item.factor.price)}`);

                                                    return (
                                                        <tr key={`table-row-${i}`}>
                                                            <td>
                                                                <Checkbox
                                                                    value={selectedRows.indexOf(item.id) !== -1}
                                                                    changeHandler={() => selectRowHandler(item.id)}
                                                                />
                                                            </td>

                                                            <td>
                                                                {
                                                                    item.status_id === 1 && <i className="fa-solid fa-comments-dollar status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 2 && <i className="fa-regular fa-microchip status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 3 && <i className="fa-regular fa-person-digging status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 4 && <i className="fa-regular fa-motorcycle status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 5 && <i className="fa-regular fa-person-walking-arrow-loop-left status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 6 && <i className="fa-regular fa-person-circle-minus status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 7 && <i className="fa-regular fa-person-circle-xmark status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 8 && <i className="fa-regular fa-money-bill-wave status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 9 && <i className="fa-regular fa-person-snowmobiling status deactive" title={localStatuses[item.status_id]}></i>
                                                                }

                                                                {
                                                                    item.status_id === 10 && <i className="fa-regular fa-person-snowmobiling status deactive" title={localStatuses[item.status_id]}></i>
                                                                }
                                                            </td>

                                                            <td>
                                                                <Link className="edit" to={createOrEditLink(item.id)}>
                                                                    <i className="fa-regular fa-eye"></i>
                                                                </Link>
                                                            </td>

                                                            <td className={`desc-col${hasIcon ? " has-icn" : ""}`}>
                                                                {
                                                                    hasIcon && (
                                                                        <div className="icn-holder-c1">
                                                                            {
                                                                                pageData.index_setting.icon.map((icon, i) => {
                                                                                    const className = {};

                                                                                    if (icon.class)
                                                                                        className.className = icon.class;

                                                                                    return (
                                                                                        <Link
                                                                                            key={`${i}-icon-${i}`}
                                                                                            to={generateIconLink(item, icon.url)}
                                                                                            title={icon.title}
                                                                                            {...className}
                                                                                        >
                                                                                            <i className={icon.font_icon} />
                                                                                        </Link>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className={`content${hasIcon ? " grow" : ""}`}>
                                                                    <p className="tp-25" data-fn={pageData.index_setting.title.key}>
                                                                        {item[pageData.index_setting.title.key]}
                                                                    </p>

                                                                    {
                                                                        item.items?.length > 0 && (
                                                                            <ul className="flex my-1">
                                                                                {
                                                                                    item.items.map(order => {
                                                                                        return (
                                                                                            <li className="text-xs text-slate-600 dark:text-slate-200 tiny-order">
                                                                                                {order.title}
                                                                                                <span className="rounded-md inline-block mr-1 bg-slate-200 dark:bg-slate-700 dark:text-slate-300 text-slate-500 px-2">
                                                                                                    {order.quantity}
                                                                                                </span>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        )
                                                                    }

                                                                    <h6 className="tp-26">
                                                                        {
                                                                            labels.join(" | ")
                                                                        }
                                                                    </h6>

                                                                    {
                                                                        pageData.index_setting.recursive && <Link to={`?parent_id=${item.id}`} className="pos-cover"></Link>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>

                                    <div className="flex justify-between items-center mt-4">
                                        <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                            {getPagesCount(data.count, urlParams.limit)}
                                        </span>

                                        <Pagination
                                            count={data.count}
                                            limit={urlParamsData.limit}
                                            offset={urlParamsData.offset}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p className="text-sm text-red-600 dark:text-red-400 flex items-center justify-center py-4">
                                    <i className="fa-regular fa-clipboard-list ml-2 text-lg relative top-px"></i>
                                    موردی برای نمایش یافت نشد
                                </p>
                            )
                        ) : (
                            <p className="text-sm text-red-500 text-center py-4">خطا در دریافت اطلاعات</p>
                        )
                    ) : (
                        <Skeleton />
                    )
                }
            </div>

            <PriorityModal
                data={priorityData}
                setData={setPriorityData}
                submitUrl={pageData.set_url}
                reload={mutate}
            />
        </>
    )
}

export default memo(List);