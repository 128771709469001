import Breadcrumb from "components/shared/breadcrumb";
import Loading from "components/shared/loading";
import UseFetch from "hooks/useFetch";
import { memo } from "react";
import { useParams, Link } from "react-router-dom";
import { fileUsageType, formatBytes, getProductImage, unixToShamsi } from "utils";
import useExtendData from "./use-extend-data";
import userKinds from "constants/user-kinds";
import { GetUserTypeLink } from "constants/financial-user-type";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";
import { fileIdentityContentType,  GetUsageTypeLink } from "constants/file-identities";
import EditName from "./actions/editName";

const View = props => {
    const { pageData } = props;
    const { id } = useParams();
    const { isLoading, error, data, mutate } = UseFetch(pageData.get_url.replace(":id", id));
    const extendData = useExtendData(isLoading, error, data);

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">مدیریت فایلهای آپلودی</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>

                <div className="flex">

                    <Link to={pageData.breadcrumb[1].link} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    <EditName data={data.data} mutate={mutate}/>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="border-b border-gray-200 dark:border-slate-700 pb-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>شناسه</span>
                                            <b>{data.data.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نام</span>
                                            <b>{data.data.name}</b>
                                        </li>

                                        <li className="inline">
                                            <span>اپلیکیشن</span>
                                            <b>
                                                {data.data.app_user_kind === 1 && "سایت اصلی"}
                                                {data.data.app_user_kind === 2 && "پنل تامین کنندگان"}
                                                {data.data.app_user_kind === 3 && "پنل مدیران"}
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ ایجاد</span>
                                            <b>{unixToShamsi(data.data.created_at)}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع کاربر</span>
                                            <b>{userKinds[data.data.user_kind].title}</b>
                                        </li>

                                        {
                                            !!data.data.user_id && data.data.user_kind !== 4 && (
                                                <li className="inline">
                                                    <span>کاربر</span>
                                                    <b>
                                                        <Link to={GetUserTypeLink(data.data.user_kind, data.data.user_id).link} className="link-c1">
                                                            <ShowExtendUserLabel value={extendData.userData} />
                                                        </Link>
                                                    </b>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع</span>
                                            <b>{fileIdentityContentType[data.data.content_type_enum]}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>فرمت</span>
                                            <b>{data.data.content_type}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>پسوند</span>
                                            <b className="dir-ltr">{data.data.extension}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>حجم</span>
                                            <b className="dir-ltr">{formatBytes(data.data.file_size)}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>حجم کل</span>
                                            <b className="dir-ltr">{formatBytes(data.data.total_size)}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع اتصال</span>
                                            <b>{fileUsageType(data.data?.usage)}</b>
                                        </li>
                                        {
                                            data.data?.usage?.lable ? (
                                                <li className="inline">
                                                    <span>متصل شده به</span>
                                                    <b>
                                                        <Link to={GetUsageTypeLink(data.data?.usage) || ""} className="link-c1">
                                                            {data.data?.usage?.lable}
                                                        </Link>
                                                    </b>
                                                </li>
                                            ) : <></>
                                        }
                                    </ul>
                                </div>

                                <div className="pt-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>پوشه</span>
                                            <b>{data.data.folder}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>هش</span>
                                            <b>{data.data.hash_number}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>نام و مسیر فایل اصلی</span>
                                            <b className="dir-ltr">
                                                <a
                                                    href={getProductImage(data.data.original)}
                                                    className="link-c1"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {data.data.original}
                                                </a>
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    data.data.list?.length > 0 && (
                                        <div className="pt-4">
                                            <div className="border-t border-gray-200 dark:border-slate-700">
                                                <b className="font-medium block my-3 text-slate-800 dark:text-slate-200 text-sm">سایر فایلها:</b>

                                                <div className="overflow-auto">
                                                    <table className="filesTbl w-full">
                                                        <thead>
                                                            <tr>
                                                                <th>عنوان</th>
                                                                <th>حجم</th>
                                                                <th>مسیر</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                data.data.list.map((fileEl, fileI) => (
                                                                    <tr key={`file-item-${fileI}`}>
                                                                        <td className="text-center">
                                                                            <b>{data.data.list_label[fileI]}</b>
                                                                        </td>

                                                                        <td className="text-center dir-ltr">
                                                                            <b>{formatBytes(data.data.list_sizes[fileI])}</b>
                                                                        </td>

                                                                        <td className="dir-ltr">
                                                                            <a
                                                                                href={getProductImage(fileEl)}
                                                                                className="link-c1"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {fileEl}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        ) : <div className="global-error text-cnter">خطا در دریافت اطلاعات !</div>
                    ) : <div className="fit-center"><Loading className="loading-c5" /></div>
                }
            </div >
        </>
    )
}

export default memo(View);