import React, { memo } from "react";

const InputNumberSkeleton = () => (
    <div className="skeleton-box w-full max-w-[10rem] h-8 rounded-md mx-auto">
        <span />
    </div>
)

const PriceList = () => (
    <div className="my-5 overflow-x-auto">
        <div className="price-table" style={{ minWidth: 760 }}>
            <table className="main-tbl border tp-32">
                <colgroup>
                    <col className="w-1/12" />
                    <col className="w-1/12" />
                    <col className="w-2/12" />
                    <col className="w-2/12" />
                    <col className="w-2/12" />
                    <col className="w-2/12" />
                    <col className="w-2/12" />
                </colgroup>

                <thead>
                    <tr className="tp-32">
                        <th></th>

                        <th>
                            <div className="skeleton-box w-7 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>

                        <th>
                            <div className="skeleton-box w-16 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>

                        <th>
                            <div className="skeleton-box w-16 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>

                        <th>
                            <div className="skeleton-box w-16 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>

                        <th>
                            <div className="skeleton-box w-16 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>

                        <th>
                            <div className="skeleton-box w-16 h-6 rounded-full mx-auto">
                                <span />
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        Object.keys([...Array(5)]).map(i => (
                            <React.Fragment key={`product-skeleton-${i}`}>
                                <tr className={`bb-0 tp-32${i % 2 !== 0 ? " has-bg" : ""}`}>
                                    <td rowSpan={2}>
                                        <div className="skeleton-box w-16 h-16 rounded-md">
                                            <span />
                                        </div>
                                    </td>

                                    <td colSpan={6}>
                                        <div className="skeleton-box w-full h-9 rounded-full">
                                            <span />
                                        </div>
                                    </td>
                                </tr>

                                <tr className={`tp-32${i % 2 !== 0 ? " has-bg" : ""}`}>
                                    <td>
                                        <div className="skeleton-box w-7 h-6 rounded-full mx-auto">
                                            <span />
                                        </div>
                                    </td>

                                    <td>
                                        <InputNumberSkeleton />
                                    </td>

                                    <td>
                                        <InputNumberSkeleton />
                                    </td>

                                    <td>
                                        <InputNumberSkeleton />
                                    </td>

                                    <td>
                                        <InputNumberSkeleton />
                                    </td>

                                    <td>
                                        <InputNumberSkeleton />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
)

export default memo(PriceList);