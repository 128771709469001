import "assets/scss/app.scss";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import ReduxProvider from "providers/redux";
import Routes from "providers/routes";
import { BrowserRouter as Router } from "react-router-dom";
import Theme from "providers/theme";
import ToastifyProvider from "providers/toastify";
import ErrorBoundary from "providers/errorBoundary";
import ThemeSetting from "providers/theme-setting";
import AuthController from "providers/auth-controller";
import Initialize from "providers/initialize/initialize";
import GetProfile from "providers/get-profile";
import ToTop from "providers/to-top";
import { Helmet } from "react-helmet";
import { siteTitle } from "constants/config";
import ReloadTokenController from "providers/reload-token-controller";

const App = () => {
    return (
        <>
            <Helmet>
                <title>{siteTitle} - پنل ادمین</title>
            </Helmet>

            <ReduxProvider>
                <ErrorBoundary>
                    <Theme />
                    <ThemeSetting />
                    <ToastifyProvider />
                    <AuthController />
                    <GetProfile />

                    <Router>
                        <ToTop />
                        <ReloadTokenController />

                        <Initialize>
                            <Routes />
                        </Initialize>
                    </Router>
                </ErrorBoundary>
            </ReduxProvider>
        </>
    )
}

export default App;