import Breadcrumb from "components/shared/breadcrumb";
import { memo, useState, useCallback, Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import templateFetcherHook from "service/template-fetcher-hook";
import FormGenerator from "components/form-generator/main/main";
import UseFetch from "hooks/useFetch";
import FormSkeleton from "components/skeleton/form";

const EditSetting = props => {
    const {
        template
    } = props;

    const { data: pageData } = templateFetcherHook(template);
    const { isLoading, error, data } = UseFetch(pageData.get_url);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);

    const setParentLoading = useCallback(value => {
        setSubmitLoading(value);
        // eslint-disable-next-line
    }, [])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>

                <div className="flex">
                    <button className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 overflow-hidden relative" onClick={submitHandler}>
                        <i className="fa-regular fa-arrow-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="py-2">
                    {
                        !isLoading ? (
                            !error ? (
                                <>
                                    <div>
                                        {
                                            pageData.password_fields?.length > 0 && (
                                                pageData.password_fields.map((passwordField, i) => {
                                                    const key = `passwordField-${i}`;

                                                    if (data.data[passwordField.key])
                                                        return (
                                                            <div className="bg-yellow-50 text-green-800 p-3 mb-2 last:mb-6 rounded-md text-sm flex items-center dark:bg-slate-700 dark:text-slate-200" key={key}>
                                                                <i className="fa-duotone fa-lock ml-2 text-green-600 dark:text-slate-400"></i>
                                                                {passwordField.label} از قبل تنظیم شده است
                                                            </div>
                                                        )

                                                    return <Fragment key={key} />
                                                })
                                            )
                                        }
                                    </div>

                                    <FormGenerator
                                        fields={pageData.fields}
                                        submitUrl={pageData.edit_url}
                                        goSubmit={goSubmit}
                                        setParentLoading={setParentLoading}
                                        payloadType={3}
                                        defaultValues={data.data}
                                        submitMethod="PUT"
                                    />
                                </>
                            ) : <span className="text-sm text-red-600">خطا در دریافت اطلاعات !</span>
                        ) : <FormSkeleton />
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(EditSetting);