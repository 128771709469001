import { memo, useMemo } from "react";
import TickSkeleton from "components/skeleton/ticket";
import ticketStatuses from "constants/ticket-statuses";
import PieChart from "components/shared/charts/pie";
import GoToAll from "components/shared/go-to-all";
import { useParams } from "react-router-dom";

const Ticket = props => {
    const { loading, data, userType } = props;
    const { id } = useParams();

    const chartData = useMemo(() => {
        const output = {
            series: [],
            labels: [],
            useful: {
                useful: 0,
                not_useful: 0
            }
        };

        if (!loading && !data.error) {
            ticketStatuses.forEach((status, i) => {
                output.labels.push(status.title);
                output.series.push(data.data.ticket.status[i] || 0);
                output.useful = {
                    useful: data.data.ticket.useful.useful || 0,
                    not_useful: data.data.ticket.useful.not_useful || 0
                };
            })
        }

        return output;
    }, [data, loading]);

    return (
        <div className="wrap-box tp-22 page-transition">
            {
                !loading ? (
                    <>
                        <div className="flex justify-between items-center pb-4">
                            <h2 className="tp-34 text-sm font-bold">تیکت {userType === "user" ? "کاربر" : "تامین کننده"}</h2>
                            <GoToAll link={`/panel/${userType === "user" ? "userTicket" : "providerTicket"}?user_id=${id}`} />
                        </div>

                        {
                            !data.error ? (
                                <>
                                    <div className="max-w-[25rem] mx-auto overflow-hidden">
                                        <PieChart series={chartData.series} labels={chartData.labels} />
                                    </div>

                                    <div className="grid grid-cols-2 gap-2 mt-3 text-xs">
                                        <div className="bg-blue-50 dark:bg-slate-800 rounded-sm flex justify-between items-center py-3 px-4">
                                            <span className="text-slate-800 dark:text-slate-300">تیکت های مفید: <b className="text-base">{chartData.useful.useful}</b></span>
                                            <i className="fa-light fa-thumbs-up text-blue-300 dark:text-slate-600 text-3xl"></i>
                                        </div>

                                        <div className="bg-blue-50 dark:bg-slate-800 rounded-sm flex justify-between items-center py-3 px-4">
                                            <span className="text-slate-800 dark:text-slate-300">تیکت های غیر مفید: <b className="text-base">{chartData.useful.useful}</b></span>
                                            <i className="fa-light fa-thumbs-down text-blue-300 dark:text-slate-600 text-3xl"></i>
                                        </div>
                                    </div>
                                </>
                            ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                        }
                    </>
                ) : <TickSkeleton />
            }
        </div>
    )
}

export default memo(Ticket);