import { useEffect } from "react";

const UseOutsideClick = (wrapperRef, close) => {
    useEffect(() => {
        document.addEventListener("mousedown", e => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) 
                close();
        })

        return () => {
            document.removeEventListener("mousedown", () => { })
        }

        // eslint-disable-next-line
    }, [])
}

export default UseOutsideClick;