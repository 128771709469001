import { useEffect, useState } from "react";
import MasterAPI from "service/master";

const useScrolledFetch = urlData => {
    const { url, mainLoading } = urlData;

    const [isLoading, setIsLoading] = useState(true);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [inititalFetch, setInitialFetch] = useState(true);
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        fetcher();
        // eslint-disable-next-line
    }, [url])

    const fetcher = async () => {
        if (!url?.length)
            return;

        if (inititalFetch || mainLoading)
            setIsLoading(true);
        else
            setLoadMoreLoading(true);

        try {
            const res = await MasterAPI({
                method: "GET",
                url,
                callback: () => {
                    setIsLoading(false);
                    setInitialFetch(false);
                    setLoadMoreLoading(false);
                }
            });

            setError(false);
            setData(res);
        } catch (e) {
            setError(e);
        }
    }

    return {
        isLoading,
        loadMoreLoading,
        data,
        error,
        mutate: fetcher
    };
}

export default useScrolledFetch;