import GetFinancialUserType from "constants/financial-user-type";
import signinMethods from "constants/signin-methods";
import moment from "jalali-moment";
import { fileContentTypeEnum, fileUsageType, formatBytes, toMoneyFormat } from "utils";

export const unixToShamsi = unix => {
    if (unix)
        return moment.unix(unix).format("hh:mm:ss jYYYY/jMM/jDD");

    throw Object.assign(
        new Error("no value"),
        { code: 402 }
    );
}

const messageStatus = value => value && value !== "-" ? "خوانده شده" : "خوانده نشده";

const signinMethod = value => signinMethods[value] || "نامشخص";

const convertUtils = (fn, value) => {
    try {
        switch (fn) {
            case "unixToShamsi":
                return unixToShamsi(value);
            case "toMoneyFormat":
                return toMoneyFormat(value);
            case "getFinancialUserType":
                return GetFinancialUserType(value);
            case "messageStatus":
                return messageStatus(value);
            case "signinMethod":
                return signinMethod(value);
            case "formatBytes":
                return formatBytes(value);
            case "fileContentTypeEnum":
                return fileContentTypeEnum(value);
            case "fileUsageType":
                return fileUsageType(value);
            default:
                return "---";
        }
    } catch (e) {
        return "---";
    }
}


export default convertUtils;