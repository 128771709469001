import { memo } from "react";
import { Link, useLocation } from "react-router-dom";


const PriceLink = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return (
        <Link
            className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden ml-3"
            to={`/panel/price?${params.toString()}`}
        >
            <i className="fa-light fa-dollar-sign ml-2"></i>
            لیست قیمت
        </Link>
    )
}

export default memo(PriceLink);