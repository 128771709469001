import moment from 'jalali-moment';
import { MEDIA } from 'service/routes';
import contentStatuses from "constants/content-statuses";
import commentStatuses from 'constants/comment-statuses';
import { fileIdentityContentType, fileIdentityUsageType } from 'constants/file-identities';

export const themeIdToStr = id => {
    if (id === 1)
        return "light";
    else if (id === 2)
        return "dark";
    else if (id === 3)
        return "primary-c";

    return null;
}

export const calculatePagination = (totalPages, currentPage) => {
    let startPoint = currentPage - 3;
    let endPoint = currentPage + 2;

    if (startPoint < 0)
        endPoint += startPoint * -1;

    let finalStartPoint = startPoint < 0 ? 1 : startPoint + 1;

    if (endPoint > totalPages) {
        const largerThanFromTotalPages = endPoint - totalPages;
        endPoint -= largerThanFromTotalPages;

        const tempFinalStartPoint = finalStartPoint - largerThanFromTotalPages;
        if (tempFinalStartPoint >= 1)
            finalStartPoint = tempFinalStartPoint;
    }

    return {
        start: finalStartPoint,
        end: endPoint
    }
}

export const addZeroToNum = num => {
    return num < 10 ? `0${num}` : `${num}`;
}

export const urlEncoder = url => {
    return encodeURIComponent(url);
}

export const urlDecoder = url => {
    return decodeURIComponent(url);
}

export const getFilename = url => {
    const filename = url.split('/').pop();
    return filename;
}

export const generateIconLink = (rowData, url) => {
    const results = url.match(/([:])\w+/g);

    if (results && results.length) {
        let newUrl = url;

        results.forEach(el => {
            const withoutDot = el.replace(":", "");
            const value = rowData[withoutDot];

            if (value)
                newUrl = newUrl.replace(el, value);
        })

        return newUrl;
    }

    return url;
}

export const isJson = value => {
    try {
        if (value) {
            JSON.parse(value);
            return true;
        }

        throw Object.assign(
            new Error(),
            { code: 402 }
        );
    } catch (e) {
        return false;
    }
}

export const toMoneyFormat = (number, isLTR = false) => {
    if (typeof number === 'number' || typeof number === 'bigint') {
        const isNegative = number < 0;
        const newNum = isNegative ? number * -1 : number;

        let output = (Math.round(newNum * 100) / 100).toLocaleString();
        if (isNegative)
            return isLTR ? `-${output}` : `${output}-`;

        return output;
    }

    return '';
}

export const faNumToEng = num => {
    let output = num.replaceAll('۰', '0');
    output = output.replaceAll('۱', '1');
    output = output.replaceAll('۲', '2');
    output = output.replaceAll('۳', '3');
    output = output.replaceAll('۴', '4');
    output = output.replaceAll('۵', '5');
    output = output.replaceAll('۶', '6');
    output = output.replaceAll('۷', '7');
    output = output.replaceAll('۸', '8');
    output = output.replaceAll('۹', '9');

    return output;
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const isObject = value => {
    return value !== null && typeof value === "object" && !Array.isArray(value)
}

export const convertShamsiToUnix = (datetime, time = false) => {
    try {
        let jalaiFormat, miladiFormat;

        if (time) {
            jalaiFormat = 'jYYYY/jM/jD HH:mm:ss';
            miladiFormat = 'YYYY/M/D HH:mm:ss';
        } else {
            jalaiFormat = 'jYYYY/jM/jD';
            miladiFormat = 'YYYY/M/D';
        }

        const m = moment(datetime, jalaiFormat);
        const date = new Date(m.format(miladiFormat));
        return Math.floor(date.getTime() / 1000);
    } catch {
        return null;
    }
}

export const unixToShamsi = (unix, showTime = true) => {
    try {
        if (!unix || isNaN(unix))
            throw new Error("invalid Unix");

        const monthes = [
            "فروردین",
            "اردیبهشت",
            "خرداد",
            "تیر",
            "مرداد",
            "شهریور",
            "مهر",
            "آبان",
            "آذر",
            "دی",
            "بهمن",
            "اسفند"
        ];

        const m = moment.unix(unix);
        let output = `${m.format("jD")} ${monthes[parseInt(m.format("jM")) - 1]} ${m.format("jYYYY")}`;

        if (showTime)
            output += ` - ساعت ${m.format("hh:mm")}`;

        return output;
    } catch {
        return "---";
    }
}

export const getContentCreatorLable = (creator) => {
    return {
        lable: creator.label,
        link: creator.user_kind === 1 ? `/panel/user-detail/${creator.id}` : creator.user_kind === 2 ? `/panel/provider-detail/${creator.id}` : creator.user_kind === 3 ? `/panel/identity_admin/${creator.id}` : null
    }
}

export const getProductImage = (url, cropSize = "org") => {
    try {
        if (url?.length) {
            let output = url.split(";")[0];
            output = output.replaceAll("org", cropSize);
            output = `${MEDIA}${output.replace("/storage", "")}`;

            return output;
        }

        throw Object.assign(new Error());
    } catch {
        return "/assets/image/no-image.png";
    }
}

export const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    if (discountedPrice > originalPrice)
        return 0;

    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return Math.ceil(discountPercentage)
}

export const contentStatusId = status => {
    return contentStatuses[status];
}

export const commentStatus = status => {
    return commentStatuses[status];
}

export const numberRegex = /^[0-9۰۱۲۳۴۵۶۷۸۹]+$/;

export const checkPermission = (list, permissions) => {
    try {
        if (permissions.all_access)
            return true;

        const isIgnored = list.some(routeEl => !permissions.routes_map[routeEl]);
        return !isIgnored;
    } catch {
        return false;
    }
}

export const generateContentTitle = (formId, dataFieds, formsEnum) => {
    let title = "بدون عنوان";

    const intendedForm = formsEnum.find(form => form.id === formId);
    if (intendedForm && intendedForm.label) {
        const labels = intendedForm.label.split(",").map(label => {
            if (label.indexOf('"') !== -1)
                return label.replaceAll('"', "");
            else
                return dataFieds[label] || "";
        });

        title = labels.join("");
    }

    return title;
}

export const select2Styles = darkMode => (
    {
        control: styles => ({
            ...styles,
            borderRadius: "100rem",
            padding: ".2rem 0",
            backgroundColor: darkMode ? "#0E0E23" : "#fff",
            borderColor: darkMode ? "#31314d" : "#e7e7e7",
            fontSize: ".875rem",
            ':hover': {
                borderColor: darkMode ? "#31314d" : "#e7e7e7"
            }
        }),
        menu: styles => ({
            ...styles,
            backgroundColor: darkMode ? "#24243E" : "#fff",
            zIndex: 2
        }),
        option: styles => ({
            ...styles,
            backgroundColor: darkMode ? "#24243E" : "#fff",
            color: darkMode ? "#eee" : "#555",
            ':hover': {
                ...styles[':hover'],
                backgroundColor: darkMode ? "#0e0e18" : "#eee",
            },
            ':active': {
                ...styles[':active'],
                backgroundColor: darkMode ? "red" : "blue"
            }
        }),
        input: (styles) => ({ ...styles, color: darkMode ? "#eee" : "#555" }),
        placeholder: (styles) => ({ ...styles, color: darkMode ? "#eee" : "#555" }),
    }
)

export const getStartOfDayUnix = unix => {
    const timeObj = moment.unix(unix);
    const hourse = parseInt(timeObj.format("H"));
    const minute = parseInt(timeObj.format("m"));
    const second = parseInt(timeObj.format("s"));

    return unix - (hourse * 3600) - (minute * 60) - second;
}

export const getUserLabel = data => {
    const { name, last_name, mobile } = data;

    if (name?.length && last_name?.length)
        return `${name} ${last_name}`;

    if (name?.length)
        return name;

    if (last_name?.length)
        return last_name;

    if (mobile?.length)
        return mobile;

    return "تنطیم نشده";
}

export const nowTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}

export const getPathFromUrl = url => {
    return url.split(/[?#]/)[0];
}

export const getPagesCount = (count, limit) => {
    if (count)
        return `${count} مورد - ${Math.ceil(count / (limit || 10))} صفحه`;

    return "";
}

export const resolveAndDownloadBlob = (response, filename) => {
    let finalFilename = `${filename}.xlsx`;
    finalFilename = decodeURI(finalFilename);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', finalFilename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
}

export const secondToMin = second => {
    if (isNaN(second))
        return "00:00";

    const mins = parseInt(second / 60);
    const seconds = second % 60;

    return `${addZeroToNum(mins)}:${addZeroToNum(seconds)}`;
}

export const copyToClipboard = textToCopy => {
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
        });
    }
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const fileContentTypeEnum = (enumType) => {
    return fileIdentityContentType[enumType]
}

export const fileUsageType = (usageObject) => {
    if (usageObject?.usage_type !== undefined) {
        return fileIdentityUsageType[usageObject.usage_type]
    }
    return "فاقد اتصال"
}

export const getValueFromObjByKey = (intendedObj, key) => {
    const splitedKey = key.split(".");

    try {
        if (splitedKey.length > 1) {
            let output = intendedObj;

            splitedKey.forEach(keyEl => {
                output = output[keyEl];
            })

            return output;
        } else
            return intendedObj[key];
    } catch {
        return undefined;
    }
}

export const readNum = num => {
    const parsedNum = parseInt(num || "");

    if (!isNaN(parsedNum))
        return parsedNum;

    return null;
}