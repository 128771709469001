import { createSearchParams, useSearchParams } from "react-router-dom";
import { readNum } from "utils";
import Selectbox from "./inputs/selectbox";

const SelectPageSize = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const value = readNum(searchParams.get("limit"));

    const goLink = value => {
        const newParams = createSearchParams(searchParams);
        if (value)
            newParams.set("limit", `${value}`);
        else
            newParams.delete("limit");

        setSearchParams(newParams.toString());
    }

    const options = [
        {
            title: "پیشفرض",
            clickHandler: () => goLink()
        },
        {
            title: 10,
            clickHandler: () => goLink()
        },
        {
            title: 20,
            clickHandler: () => goLink(20)
        },
        {
            title: 50,
            clickHandler: () => goLink(50)
        },
        {
            title: 100,
            clickHandler: () => goLink(100)
        }
    ]

    return (
        <Selectbox
            className="c1 tp-24 mr-1"
            placeholder={value ? `${value} مورد` : "تعداد نتیجه"}
            options={options}
        />
    )
}

export default SelectPageSize;