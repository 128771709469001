const EditBtn = (props) => {
    const {
        editHandler
    } = props;

    const clickHandler = () => {
        if (editHandler)
            editHandler()
    }

    return (
        <button
            className="text-gray-500 transition-colors"
            onClick={clickHandler}
            type="button"
        >
            <i className="fa-solid fa-pen-to-square"></i>
        </button>
    )
}

export default EditBtn;