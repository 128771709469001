import Input from "./input";
import { memo } from "react";

const Searchbox = () => {
    return (
        <div className="grow hidden lg:block">
            <div className="searchbox flex relative ">
                {/* <Cats setCat={setSelectedCatCallback} /> */}
                <Input />
            </div>
        </div>
    )
}

export default memo(Searchbox);