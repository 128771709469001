import { useContext, useEffect } from "react";
import { ReducerContext } from "../reducerProvider";
import getPointInfoController from "../controllers/getPointInfo";

const PointInfo = () => {
    const { state, dispatch } = useContext(ReducerContext);

    useEffect(() => {
        getPointInfoController(state, dispatch, state.centerPoint);
        // eslint-disable-next-line
    }, [state.centerPoint])

    return (
        <div className="flex items-center text-xs leading-[1.5em] h-[3em] overflow-hidden">
            {
                !state.pointInfo.pending ? (
                    !state.pointInfo.error ? (
                        <>
                            <i className="fa-solid fa-location-dot text-primary-100 ml-2" />
                            <p className="grow min-w-0 text-gray-600 dark:text-gray-100">
                                {state.pointInfo.data.data.formatted_address}
                            </p>
                        </>
                    ) : <p className="text-sm text-red-500 tracking-tighter font-bold">خطا در دریافت اطلاعات نقطه انتخاب شده !</p>
                ) : <i className="fa-regular fa-spinner-third spin"></i>
            }
        </div>
    )
}

export default PointInfo;