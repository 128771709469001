import { memo } from "react";

const ControlBoxSkeleton = () => (
    // eslint-disable-next-line
    Object.keys([...Array(3)]).map((a, i) => (
        <div className="control-item" key={`control-item-skeleton-${i}`}>
            <div className="inner">
                <span className="grow sp-1">
                    <div className="skeleton-box rounded-full w-full h-6">
                        <span />
                    </div>
                </span>

                <div className="skeleton-box rounded-full w-8 h-8 ml-2">
                    <span />
                </div>

                <div className="skeleton-box rounded-full w-8 h-8">
                    <span />
                </div>
            </div>
        </div>
    ))
)

export default memo(ControlBoxSkeleton);