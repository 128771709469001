import Radio from "components/shared/inputs/radio";
import { memo, useEffect } from "react"
import { createSearchParams, useSearchParams } from "react-router-dom";

const Radio1 = props => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const newSearchParams = createSearchParams(searchParams);

        if (props.setting?.add_value_to_url) {
            if (props.value?.length)
                newSearchParams.set(props.setting.add_value_to_url, props.value[0]);
            else
                newSearchParams.delete(props.setting.add_value_to_url);

            setSearchParams(newSearchParams);
        }

        // eslint-disable-next-line
    }, [])

    const localChangeHandler = newValue => {
        const newVal = newValue == props.value ? [] : [parseInt(newValue)]; // eslint-disable-line
        props.changeHandler(newVal);

        if (props.setting.add_value_to_url) {
            const newSearchParams = createSearchParams(searchParams);
            if (newVal.length === 0)
                newSearchParams.delete(props.setting.add_value_to_url);
            else
                newSearchParams.set(props.setting.add_value_to_url, newVal[0]);

            setSearchParams(newSearchParams);
        }
    }

    return (
        <div className="cat-holder">
            <span className="label tp-27">{props.label}</span>

            {
                props.options.map((radio, i) => (
                    <Radio
                        key={`radio-${radio.value}-${i}`}
                        label={radio.title}
                        name={props.name}
                        value={props.value ? props.value.indexOf(radio.value) !== -1 : false}
                        clickHandler={() => localChangeHandler(radio.value)}
                        // eslint-disable-next-line
                        checked={radio.value == props.value}
                    />
                ))
            }
        </div>
    )
}

export default memo(Radio1); 