import UseOutsideClick from "hooks/useOutsideClick";
import { memo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const Loading = () => {
    return (
        <label className="loading-c1 pos-cover fit-center">
            <i className="fa-solid fa-spinner spin"></i>
        </label>
    )
}

const Selectbox = props => {
    const {
        placeholder,
        className,
        loading,
        options = []
    } = props;

    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null);
    UseOutsideClick(wrapperRef, () => setShowDropdown(false));

    return (
        <div className={`selectbox${className ? ` ${className}` : ""}`} ref={wrapperRef}>
            <div className={`box noselect${showDropdown ? " opn" : ""}`} onClick={() => setShowDropdown(prev => !prev)}>
                {loading && <Loading />}
                {placeholder}
            </div>

            <CSSTransition in={showDropdown} unmountOnExit={true} timeout={{ enter: 200, exit: 0 }}>
                <div className="toggle-box anim-c1 noselect">
                    <ul>
                        {
                            options.map((option, i) => {
                                const localClickHandler = () => {
                                    if (option.clickHandler)
                                        option.clickHandler();

                                    setShowDropdown(false);
                                }

                                return (
                                    <li key={`option-${i}`} onClick={localClickHandler}>
                                        {
                                            option.link ? (
                                                <Link to={option.link}>{option.title}</Link>
                                            ) : (
                                                <span>{option.title}</span>
                                            )
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </CSSTransition>
        </div>
    )
}

export default memo(Selectbox);