import { memo } from "react"
import Skeleton from "./skeleton";
import GoToAll from "components/shared/go-to-all";

const UserData = props => {
    const { loading, data, showScore = true, userType } = props;

    if (loading)
        return <Skeleton />;

    return (
        <div className="wrap-box tp-22 page-transition">
            <div className="h-full flex flex-col">
                <div className="flex justify-between items-center pb-4">
                    <h2 className="tp-34 text-sm font-bold">اطلاعات {userType === "user" ? "کاربر" : "تامین کننده"}</h2>

                    {
                        !data.error && <GoToAll label="جزئیات بیشتر" link={`/panel/identity_${userType}/${data.data.id}`} />
                    }
                </div>

                <div className="grow">
                    {
                        !data.error ? (
                            <ul className="user-info">
                                <li>
                                    <span className="col-t">شناسه :</span>
                                    <b className="col-v">{data.data.id}</b>
                                </li>

                                <li>
                                    <span className="col-t">نام :</span>
                                    <b className="col-v">{data.data.name || "تنظیم نشده است"}</b>
                                </li>

                                <li>
                                    <span className="col-t">نام خانوادگی :</span>
                                    <b className="col-v">{data.data.last_name || "تنظیم نشده است"}</b>
                                </li>

                                <li>
                                    <span className="col-t">شماره همراه :</span>
                                    <b className="col-v">{data.data.mobile || "تنظیم نشده است"}</b>
                                </li>

                                <li>
                                    <span className="col-t">ایمیل :</span>
                                    <b className="col-v">{data.data.email || "تنظیم نشده است"}</b>
                                </li>
                            </ul>
                        ) : <p className="global-error">خطا در دریافت اطلاعات کاربر !</p>
                    }
                </div>

                {
                    !data.error && showScore && (
                        <div className="bg-blue-50 dark:bg-slate-800 rounded-xs overflow-hidden flex mt-4">
                            <div className="bg-blue-100 dark:bg-slate-700 w-14 fit-center">
                                <i className="fa-duotone fa-star text-slate-700 dark:text-slate-300"></i>
                            </div>

                            <div className="grow flex justify-between items-center text-slate-800 dark:text-slate-300 p-4">
                                <span className="text-sm">امتیاز کاربر:</span>
                                <b>{data.data.score}</b>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default memo(UserData);