import { memo } from "react"

const InputText = props => {
    const {
        value,
        id,
        changeHandler,
        label,
        disabled = false
    } = props;

    let className = "text-field";
    if (value?.toString()?.length)
        className += " has-val";

    if (props.masterClass?.length)
        className += ` ${props.masterClass}`;

    if (disabled)
        className += " disabled";

    return (
        <div className={className}>
            <label htmlFor={id}>{label}</label>

            <input
                type="text"
                id={id}
                value={value}
                onChange={changeHandler}
                disabled={disabled}
            />
        </div>
    )
}

export default memo(InputText);