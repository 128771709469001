import { useEffect, useState } from "react";
import extendDataFetcher from "service/extend-data-fetcher";

const generateUserRoute = serviceData => {
    switch (serviceData.financial_user_type_id) {
        case 1:
            return {
                url: `/api/identity/user/get?id=${serviceData.user_id}`
            }
        case 2:
            return {
                url: `/api/identity/provider/get?id=${serviceData.user_id}`
            }
        case 3:
            return {
                url: `/api/financial/system_type/get?id=${serviceData.user_id}`
            }
        case 4:
            return {
                url: `/api/identity/admin/get?id=${serviceData.user_id}`
            }
        default:
            return {};
    }
}

const useExtendData = (isLoading, error, data) => {
    const [localData, setLocalData] = useState({});

    useEffect(() => {
        if (!isLoading && !error)
            fetcher(data.data);
    }, [isLoading, error, data])

    const fetcher = async serviceData => {
        const routes = [
            {
                identifier: "user",
                ...generateUserRoute(serviceData)
            }
        ];

        if (serviceData.admin_id)
            routes.push({
                identifier: "admin",
                url: `/api/identity/admin/get?id=${serviceData.admin_id}`
            });

        const newData = await extendDataFetcher(routes);
        setLocalData(newData);
    }

    return localData;
}

export default useExtendData;