import { memo, useState } from "react";
import Grid from "../grid/grid";
import Breadcrumb from "components/shared/breadcrumb";
import { CSSTransition } from "react-transition-group";
import { Link, useParams } from "react-router-dom";

const Edit = props => {
    const { mode, pageData } = props;
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const { id } = useParams();

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">افزودن نقش</h1>
                    <Breadcrumb data={[
                        ...pageData.breadcrumb,
                        { title: "ویرایش" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to={`/panel/permission/${mode}`} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden"
                        onClick={() => setGoSubmit(prev => prev + 1)}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ویرایش

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <Grid
                mode={mode}
                submitLoading={submitLoading}
                setSubmitLoading={setSubmitLoading}
                goSubmit={goSubmit}
                id={id}
                pageData={pageData}
            />
        </>
    )
}

export default memo(Edit);