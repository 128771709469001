export const paymentStatuses = [
    {
        id: 1,
        title: "در انتظار پرداخت",
        icon: "fa-solid fa-circle-check",
        style: "st-1"
    },
    {
        id: 2,
        title: "در انتظار پردازش",
        icon: "fa-solid fa-circle-check",
        style: "st-1"
    },
    {
        id: 3,
        title: "درحال پردازش",
        icon: "fa-solid fa-circle-check",
        style: "st-1"
    },
    {
        id: 4,
        title: "درانتظار ارسال",
        icon: "fa-solid fa-circle-check",
        style: "st-1"
    },
    {
        id: 5,
        title: "درحال ارسال",
        icon: "fa-solid fa-circle-xmark",
        style: "st-1"
    },
    {
        id: 6,
        title: "تحویل داده شده",
        icon: "fa-solid fa-circle-xmark",
        style: "st-1"
    },
    {
        id: 7,
        title: "لغو شده",
        icon: "fa-solid fa-circle-xmark",
        style: "st-1"
    }
]