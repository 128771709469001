import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { dracula } from '@uiw/codemirror-theme-dracula';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import UseFetch from "hooks/useFetch";
import { isObject } from "utils";
import moment from "jalali-moment";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const darkMode = useSelector(state => state.theme.darkMode);
    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const LogMapper = props => {
        const { fieldKey, value } = props;

        if (isObject(value))
            return (
                <div className="cm-holder">
                    <CodeMirror
                        value={JSON.stringify(value, null, "\t")}
                        height="300px"
                        extensions={[json()]}
                        theme={darkMode ? dracula : "light"}
                        readOnly={true}
                    />
                </div>
            )
        else if (fieldKey === "execution_time")
            return (
                <span className="flex">
                    <span className="ml-1">ms</span>
                    {value / 1_000_000}
                </span>
            )
        else if (fieldKey === "ts")
            return <span className="block">{moment.unix(value).format("HH:mm - jYYYY/jMM/jDD")}</span>
        else if (fieldKey === "error" || fieldKey === "stack")
            return <pre className="pre-c1 dir-ltr">{value}</pre>
        else if (fieldKey === "url")
            return <span className="block dir-ltr">{value}</span>
        else if (fieldKey === "user_log_id")
            return <Link to={`/panel/${pageData.link_type}Logs/${value}`} className="block link-c1">{value}</Link>

        return <p>{value}</p>
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <div className="text-gray-800 dark:text-slate-200 text-sm">
                                {
                                    Object.keys(data.data).map((fieldKey, i) => {
                                        const value = data.data[fieldKey];
                                        return (
                                            <div className="py-4 border-b border-gray-100 dark:border-slate-700 last:border-0" key={`user-logs-${i}`}>
                                                <b className="mb-1 font-medium">{fieldKey}</b>
                                                <LogMapper fieldKey={fieldKey} value={value} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >
        </>
    )
}

export default memo(Edit);