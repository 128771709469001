import Breadcrumb from "components/shared/breadcrumb";
import InputText from "components/shared/inputs/input-text";
import Loading from "components/shared/loading";
import UseFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const GeneralKey = () => {
    const { isLoading, error, data } = UseFetch("/api/setting/general_keys/get");

    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [rows, setRows] = useState([]);
    const [failedKeys, setFailedKeys] = useState([]);

    useEffect(() => {
        if (!isLoading && !error) {
            const currentKeys = data.data?.keys || {};
            const newRows = [];

            Object.keys(currentKeys).forEach(currentKey => {
                newRows.push({ key: currentKey, value: currentKeys[currentKey] })
            })

            setRows(newRows);
        }
    }, [isLoading, error, data])

    const addHandler = () => {
        setRows(prev => [...prev, {
            key: "",
            value: ""
        }])
    }

    const removeHandler = index => {
        setRows(prev => prev.filter((_, i) => i !== index));
        setSubmitEnabled(true);
    }

    const inputChangeHandler = (rowIndex, key, e) => {
        setFailedKeys(prev => prev.filter(failedKeyIndex => failedKeyIndex !== rowIndex))

        setRows(prev => {
            const newState = [...prev];
            newState[rowIndex][key] = e.target.value;
            return newState;
        });

        setSubmitEnabled(true);
    }

    const submitHandler = async () => {
        if (submitLoading || error || !submitEnabled)
            return;

        const newFailedKeys = [];
        rows.forEach((row, i) => {
            if (row.key === "")
                newFailedKeys.push(i);
        })
        setFailedKeys(newFailedKeys);

        if (newFailedKeys.length === 0) {
            const mapData = {};
            rows.forEach(row => mapData[row.key] = row.value);

            try {
                setSubmitLoading(true);

                const res = await MasterAPI({
                    url: "/api/setting/general_keys/edit",
                    method: "PUT",
                    payloadType: 3,
                    data: {
                        keys: mapData
                    },
                    callback: () => setSubmitLoading(false),
                });

                toast.success(res.message);
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">کلیدهای عمومی</h1>

                    <Breadcrumb data={[
                        { title: "تنظیمات فروشگاه" },
                        { title: "تنظیمات سرور", link: "/panel/server-settings" },
                        { title: "کلیدهای عمومی" }
                    ]} />
                </div>

                <div className="flex">
                    <button
                        className="w-10 h-10 text-white bg-primary-100 hover:bg-primary-200 rounded-md fit-center"
                        title="افزودن سایز"
                        onClick={addHandler}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>

                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 mr-3 relative overflow-hidden ${!submitEnabled ? "opacity-50 cursor-default" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition unmountOnExit={true} in={submitLoading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            {
                !isLoading ? (
                    rows.length ? (
                        rows.map((row, i) => {
                            const errorExists = failedKeys.indexOf(i) !== -1;

                            return (
                                <div
                                    className={`wrap-box tp-22 page-transition flex flex-col items-stretch md:flex-row md:items-start mb-3 crop-size-block ${errorExists ? "has-error" : ""}`}
                                    key={`key-${i}`}
                                >
                                    <div className="md:ml-3 grow mb-4 md:mb-0">
                                        <InputText
                                            label="کلید"
                                            masterClass="w-full"
                                            id={`key-${i}-key`}
                                            value={row.key}
                                            changeHandler={e => inputChangeHandler(i, "key", e)}
                                        />

                                        {
                                            errorExists ? <p className="local-error">کلید معتبر</p> : <></>
                                        }
                                    </div>

                                    <InputText
                                        label="مقدار"
                                        masterClass="grow md:ml-3 mb-2 md:mb-0"
                                        id={`key-${i}-value`}
                                        value={row.value}
                                        changeHandler={e => inputChangeHandler(i, "value", e)}
                                    />

                                    <button
                                        className="bg-red-600 hover:bg-red-700 transition-colors rounded-md text-white text-sm px-3 py-1 mt-2"
                                        onClick={() => removeHandler(i)}
                                    >
                                        حذف
                                    </button>
                                </div>
                            )
                        })
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="text-sm text-red-600 dark:text-red-400">
                                <i className="fa-light fa-clipboard-list ml-2 relative top-px"></i>
                                موردی برای نمایش یافت نشد
                            </p>
                        </div>
                    )
                ) : (
                    <div className="wrap-box tp-22">
                        <div className="flex items-center justify-center"><Loading className="loading-c5" /></div>
                    </div>
                )
            }
        </div >
    )
}

export default GeneralKey;