import { Link } from "react-router-dom";
import { calculatePagination } from "utils";
import { memo } from "react";
import { useLocation } from "react-router-dom";

const Pagination = props => {
    const {
        count,
        limit: orgLimit,
        offset: orgOffset
    } = props;

    const limit = orgLimit && !isNaN(orgLimit) ? orgLimit : 10;
    const offset = orgOffset && !isNaN(orgOffset) ? orgOffset : 0;
    const { pathname, search } = useLocation();

    const pagesCount = Math.ceil(count / limit);
    let currentPage = Math.ceil(offset / limit) + 1;
    currentPage = currentPage <= pagesCount ? currentPage : pagesCount;

    const { start, end } = calculatePagination(pagesCount, currentPage);

    const pagesNums = [];
    for (let i = start; i <= end; i++)
        pagesNums.push(i);

    const preAttrs = {};
    if (currentPage === 1)
        preAttrs.className = "deactive";

    const nextAttrs = {};
    if (currentPage === pagesCount)
        nextAttrs.className = "deactive";

    const urlGenerator = page => {
        const newOffset = (page - 1) * limit;
        const urlParams = new URLSearchParams(search);

        if (newOffset > 0)
            urlParams.set("offset", newOffset);
        else
            urlParams.delete("offset");

        const stringParams = urlParams.toString();
        if (stringParams.length)
            return `${pathname}?${stringParams}`;

        return pathname;
    }

    return (
        <ul className="pagination-c1">
            <li {...preAttrs}>
                {
                    currentPage === 1 ? (
                        <span className="in-sec">قبلی</span>
                    ) : (
                        <Link to={urlGenerator(currentPage - 1)} className="in-sec">قبلی</Link>
                    )
                }
            </li>

            {
                pagesNums.map(page => {
                    const attrs = {};
                    if (page === currentPage)
                        attrs.className = "active";

                    return (
                        <li key={`page-${page}`} {...attrs}>
                            {
                                page === currentPage ? (
                                    <span className="in-sec">{page}</span>
                                ) : (
                                    <Link to={urlGenerator(page)} className="in-sec">{page}</Link>
                                )
                            }
                        </li>
                    )
                })
            }

            <li {...nextAttrs}>
                {
                    currentPage === pagesCount ? (
                        <span className="in-sec">بعدی</span>
                    ) : (
                        <Link to={urlGenerator(currentPage + 1)} className="in-sec">بعدی</Link>
                    )
                }
            </li>
        </ul>
    )
}

export default memo(Pagination);