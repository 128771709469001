import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { dracula } from '@uiw/codemirror-theme-dracula';

const HtmlEditor = props => {
    const {
        value,
        label,
        changeHandler
    } = props;

    const darkMode = useSelector(state => state.theme.darkMode);

    return (
        <label className="textarea-c1 has-val editor-box">
            <label className="title">{label}</label>

            <div className="cm-holder">
                <CodeMirror
                    value={typeof value === "string" ? value : ""}
                    height="300px"
                    extensions={[html()]}
                    onChange={changeHandler}
                    theme={darkMode ? dracula : "light"}
                />
            </div>
        </label>

    );
}

export default memo(HtmlEditor);