import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const LineChart = props => {
    const { series, xaxis } = props;
    const darkMode = useSelector(state => state.theme.darkMode);

    useEffect(() => {
        setOptions(prev => ({
            ...prev.options,
            theme: {
                mode: darkMode ? "dark" : "light"
            }
        }));
    }, [darkMode])

    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'area',
            fontFamily: 'IRANSans'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories: xaxis
        }
    });

    useEffect(() => {
        setOptions(prev => ({
            ...prev,
            xaxis: {
                type: 'category',
                categories: xaxis
            }
        }));
    }, [xaxis])

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
        />
    )
}

export default memo(LineChart);