import { toMoneyFormat } from "utils";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useMemo } from "react";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import { useLocation, Link, useSearchParams } from "react-router-dom";
import UseFetch from 'hooks/useFetch';

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);
    const [searchParams] = useSearchParams();

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    useEffect(() => {
        if (pageData) {
            let newFetchUrl = pageData.get_url.replace(":user_id", id);
            newFetchUrl = newFetchUrl.replace(":user_type", searchParams.get("user_type"));

            setFetchUrl(newFetchUrl);
        }
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const serviceData = useMemo(() => {
        if (!isLoading && !error && data)
            return data.data;

        return {};
    }, [data, isLoading, error])

    const userType = parseInt(searchParams.get("user_type"));
    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">ویرایش</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                                    <p className="text-sm text-green-800 dark:text-slate-300">
                                        <b className="inline-block ml-2">موجودی</b>
                                        <span>{toMoneyFormat(serviceData.balance)}</span>

                                        <span className="mr-5">
                                            <b className="inline-block ml-2">موجودی قابل برداشت</b>
                                            <span>{toMoneyFormat(serviceData.withdrawble_balance)}</span>
                                        </span>
                                    </p>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            {
                                                userType === 1 && (
                                                    <Link to={`/panel/identity_user?id=${serviceData.user_id}`} className="ml-2 dark:text-slate-300">
                                                        <i className="fa-regular fa-user"></i>
                                                    </Link>
                                                )
                                            }

                                            {
                                                userType === 2 && (
                                                    <Link to={`/panel/identity_provider?id=${serviceData.user_id}`} className="ml-2 dark:text-slate-300">
                                                        <i className="fa-regular fa-user"></i>
                                                    </Link>
                                                )
                                            }

                                            {
                                                userType === 4 && (
                                                    <Link to={`/panel/identity_admin?id=${serviceData.user_id}`} className="ml-2 dark:text-slate-300">
                                                        <i className="fa-regular fa-user"></i>
                                                    </Link>
                                                )
                                            }

                                            <span>نام</span>
                                            <b>{serviceData.name}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نام خانوادگی</span>
                                            <b>{serviceData.last_name}</b>
                                        </li>

                                        <li className="inline">
                                            <span>موبایل</span>
                                            <b>{serviceData.mobile}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>وضعیت کاربر</span>
                                            <b>
                                                {
                                                    serviceData.user_status_id === 0 && "غیر فعال"
                                                }

                                                {
                                                    serviceData.user_status_id === 1 && "فعال"
                                                }

                                                {
                                                    serviceData.user_status_id === 2 && "پاک شده"
                                                }
                                            </b>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >
        </>
    )
}

export default memo(Edit);