import { memo } from "react"
import { useParams } from "react-router-dom";
import View from "./view";
import List from "./list";

const Basket = () => {
    const { id } = useParams();

    if (id)
        return <View />;

    return <List />;
}

export default memo(Basket);