import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { memo } from "react";
import { addZeroToNum } from "utils";
import { useSelector } from "react-redux";

const CustomComponent = props => {
    const {
        value,
        openCalendar,
        label,
        changeHandler,
        id
    } = props;

    return (
        <div className={`text-field relative${value?.length ? " has-val" : ""}`}>
            <label htmlFor={id}>{label}</label>

            <input
                id={id}
                type="text"
                className="block w-full"
                onClick={openCalendar}
                value={value}
                onFocus={openCalendar}
                onChange={() => { }}
            />

            {
                !!value?.length && (
                    <button type="button" className="unselect-c1" onClick={() => changeHandler(null)} />
                )
            }
        </div>
    )
}

const DatePickerComponent = props => {
    const darkMode = useSelector(state => state.theme.darkMode);

    const {
        pickTime = true,
        name,
        changeHandler = () => { },
        value: defaultValue,
        id,
        openInModal = false
    } = props;

    const attrs = {
        calendar: persian,
        value: defaultValue,
        locale: persian_fa,
        weekDays: ["ش", "ی", "د", "س", "چ", "پ", "ج"],
        containerClassName: "datepicker-c",
        format: pickTime ? "YYYY/MM/DD HH:mm:ss" : "YYYY/MM/DD",
        onChange: e => {
            let value = `${e.year}/${addZeroToNum(e.month)}/${addZeroToNum(e.day)}`;
            if (pickTime)
                value += ` ${addZeroToNum(e.hour)}:${addZeroToNum(e.minute)}:${addZeroToNum(e.second)}`;

            changeHandler(value);
        },
        render: (value, openCalendar) => (
            <CustomComponent
                openCalendar={openCalendar}
                value={value}
                label={props.label}
                name={name}
                changeHandler={changeHandler}
                id={id}
            />
        ),
    }

    let className = "";
    if (darkMode)
        className += "bg-dark ";

    if (openInModal)
        className += "rmdp-mobile ";

    if (className.length)
        attrs.className = className;

    if (pickTime)
        attrs.plugins = [<TimePicker position="bottom" />];

    return (
        <DatePicker {...attrs} />
    )
}

export default memo(DatePickerComponent);