import { useState, useEffect, useCallback } from "react";
import { memo } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Breadcrumb from "components/shared/breadcrumb";
import { CSSTransition } from "react-transition-group";
import FormGenerator from "components/form-generator/main/main";
import FormSkeleton from "components/skeleton/form";
import UseFetch from "hooks/useFetch";

const Edit = props => {
    const { pageData } = props;

    const { pageId, id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [fetchUrl, setFetchUrl] = useState(null);
    const [goSubmit, setGoSubmit] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { isLoading, data, error } = UseFetch(fetchUrl);

    useEffect(() => {
        if (typeof id === "undefined" || isNaN(id))
            navigate(getPreviousRoute(), { replace: true });

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (pageData) {
            let newFetchUrl = pageData.get_url;
            newFetchUrl = newFetchUrl.replace(":page_id", pageId);
            newFetchUrl = newFetchUrl.replace(":control_id", id);

            setFetchUrl(newFetchUrl);
        }

        // eslint-disable-next-line
    }, [])

    const getPreviousRoute = () => {
        const splitedCurrentRoute = location.pathname.split("/");
        if (splitedCurrentRoute[splitedCurrentRoute.length - 1] === "")
            splitedCurrentRoute.pop();

        if (typeof id === "undefined")
            splitedCurrentRoute.splice(-1);
        else
            splitedCurrentRoute.splice(-2);

        return `${splitedCurrentRoute.join("/")}/list`;
    }

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const doneCallback = useCallback(() => {
        navigate(getPreviousRoute(), { replace: true });
        // eslint-disable-next-line
    }, [])

    const setParentLoading = useCallback(val => {
        setSubmitLoading(val);
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">ویرایش کنترل</h1>
                    {
                        <Breadcrumb data={pageData.breadcrumb} />
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <FormGenerator
                                mode="edit"
                                submitUrl={pageData.edit_url}
                                fields={pageData.edit_setting}
                                goSubmit={goSubmit}
                                setParentLoading={setParentLoading}
                                doneCallback={doneCallback}
                                preData={{
                                    page_id: parseInt(pageId),
                                    control_id: parseInt(id)
                                }}
                                defaultValues={data?.data}
                                submitMethod="PUT"
                            />
                        ) : (
                            <p className="font-medium text-sm text-center text-slate-800 dark:text-gray-300 py-4">
                                خطا در دریافت اطلاعات فرم !
                            </p>
                        )
                    ) : (
                        <FormSkeleton />
                    )
                }
            </div>
        </>
    )
}

export default memo(Edit);