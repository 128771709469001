import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="p-5 bg-red-400 flex items-center h-screen justify-center">
                    <i className="fa-solid fa-bug ml-6 text-7xl text-red-600"></i>
                    <span className="text-sm text-white">خطای سیستم! لطفا لحظاتی بعد مجددا امتحان نمایید</span>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;