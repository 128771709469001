import { memo, useState } from "react";
import { toMoneyFormat } from "utils";

const Wallet = props => {
    const { data } = props;
    const [activeSystemWallet, setActiveSystemWallet] = useState(0);

    return (
        <div className="relative h-full">
            <div className="relative xl:absolute top-0 right-0 w-full h-full flex flex-col">
                <div className="wrap-box tp-22 page-transition mb-4">
                    <h2 className="tp-34 text-sm font-bold pb-4">کیف پول بانک</h2>

                    <div className="grow">
                        <div className="rounded-lg p-3 border border-gray-200 dark:border-slate-800 flex items-center">
                            <div className="grow pl-6 overflow-hidden">
                                <ul className="financial-list">
                                    <li className="pb-3 mb-3">
                                        <span>موجودی</span>
                                        <b>{toMoneyFormat(data.bank.balance, true)}</b>
                                        <span className="text-xs">تومان</span>
                                    </li>

                                    <li>
                                        <span>موجودی قابل برداشت</span>
                                        <b>{toMoneyFormat(data.bank.withdrawble_balance, true)}</b>
                                        <span className="text-xs">تومان</span>
                                    </li>
                                </ul>
                            </div>

                            <i className="fa-duotone fa-building-columns text-4xl text-slate-500"></i>
                        </div>
                    </div>
                </div>

                <div className="wrap-box tp-22 page-transition grow relative">
                    <div className="relative xl:absolute top-0 right-0 w-full h-full p-0 xl:p-4">
                        <div className="h-full overflow-auto spec-sb-c2">
                            <h2 className="tp-34 text-sm font-bold pb-4">کیف پول سیستمی</h2>

                            <div className="select-c1 mb-5">
                                <select onChange={e => setActiveSystemWallet(e.target.value)} value={activeSystemWallet}>
                                    {
                                        data.system_wallets.map((systemWallet, i) => (
                                            <option key={`system-wallet-${i}`} value={i}>{systemWallet.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="rounded-lg p-3 border border-gray-200 dark:border-slate-800 flex items-center">
                                <div className="grow pl-6">
                                    <ul className="financial-list">
                                        <li className="pb-3 mb-3">
                                            <span>موجودی</span>
                                            <b>{toMoneyFormat(data.system_wallets[activeSystemWallet].balance, true)}</b>
                                            <span className="text-xs">تومان</span>
                                        </li>

                                        <li>
                                            <span>موجودی قابل برداشت</span>
                                            <b>{toMoneyFormat(data.system_wallets[activeSystemWallet].withdrawble_balance, true)}</b>
                                            <span className="text-xs">تومان</span>
                                        </li>
                                    </ul>
                                </div>

                                <i className="fa-duotone fa-wallet text-4xl text-slate-500"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Wallet);