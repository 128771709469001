import List from "./list/list";
import Add from "./add/add";
import Edit from "./edit/edit";
import { memo } from "react";
import { useParams } from "react-router-dom";
import templateFetcherHook from "service/template-fetcher-hook";

const Control = props => {
    const { template } = props;
    const { mode } = useParams();
    const { data: pageData } = templateFetcherHook(template);

    return (
        <div className="m-container">
            {
                mode === "list" && <List pageData={pageData} />
            }

            {
                mode === "add" && <Add pageData={pageData} />
            }

            {
                mode === "edit" && <Edit pageData={pageData} />
            }
        </div>
    )
}

export default memo(Control);