import GoToAll from "components/shared/go-to-all";
import { memo } from "react"
import { useParams } from "react-router-dom";
import { toMoneyFormat } from "utils";

const States = props => {
    const { data } = props;
    const { id } = useParams();

    return (
        <div className="wrap-box tp-22 page-transition mb-4 h-full">
            <div className="financial-state">
                <div className="flex justify-between items-center pb-4 reset-i">
                    <h2 className="tp-34 text-sm font-bold">وضعیت مالی</h2>
                    <GoToAll label="کیف پول" link={`/panel/wallet?user_type=2&user_id=${id}`} />
                </div>

                <ul>
                    <li>
                        <div className="col-sbh">
                            <i className="fa-light fa-wallet tp-3"></i>
                            <span className="sec-title tp-34">موجودی</span>
                        </div>

                        <b className="tp-34">
                            {toMoneyFormat(data.wallet.balance)} <span className="text-xs font-normal">تومان</span>
                        </b>
                    </li>

                    <li>
                        <div className="col-sbh">
                            <i className="fa-light fa-credit-card tp-3"></i>
                            <span className="sec-title tp-34">موجوی قابل برداشت</span>
                        </div>

                        <b className="tp-34">
                            {toMoneyFormat(data.wallet.withdrawble_balance)} <span className="text-xs font-normal">تومان</span>
                        </b>
                    </li>

                    <li>
                        <div className="col-sbh">
                            <i className="fa-light fa-building-columns tp-3"></i>
                            <span className="sec-title tp-34">تسویه شده</span>
                        </div>

                        <b className="tp-34">
                            {toMoneyFormat(data.withdraw_sum.withdrew_amount)} <span className="text-xs font-normal">تومان</span>
                        </b>
                    </li>

                    <li>
                        <div className="col-sbh">
                            <i className="fa-light fa-hourglass-start tp-3"></i>
                            <span className="sec-title tp-34">در انتظار تسویه</span>
                        </div>

                        <b className="tp-34">
                            {toMoneyFormat(data.withdraw_sum.pending_amount)} <span className="text-xs font-normal">تومان</span>
                        </b>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default memo(States);