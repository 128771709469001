import { memo } from "react"

const BooleanBox = props => {
    const { label, value, active } = props;

    return (
        <div className="bg-gray-100 p-3 flex rounded-md items-center text-slate-800 dark:bg-slate-800 dark:text-slate-200">
            <span className="ml-2">{label}:</span>
            <b className="grow font-medium">{value}</b>

            {
                active
                    ? <i className="fa-regular fa-circle-check text-green-600 text-base"></i>
                    : <i className="fa-regular fa-circle-xmark text-red-600 text-base"></i>
            }
        </div>
    )
}

export default memo(BooleanBox);