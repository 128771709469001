import { memo } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "components/shared/breadcrumb";
import File from "./tabs/file/file";
import CssFile from "./tabs/css-file";
import JsFile from "./tabs/js-file";

const tabs = [
    {
        title: "css سایت",
        route: "css",
        icon: "fa-light fa-file-code"
    },
    // {
    //     title: "css پرینت",
    //     route: "print-css",
    //     icon: "fa-light fa-file-code"
    // },
    {
        title: "js سایت",
        route: "js",
        icon: "fa-brands fa-node-js"
    },
    {
        title: "ارسال فایل",
        route: "file",
        icon: "fa-light fa-files"
    }
];

const FileManager = () => {
    const { tab: orgTab } = useParams();
    const tab = orgTab || "css";

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">مدیریت فایلها</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت محتوا" },
                        { title: "مدیریت فایلها" }
                    ]} />
                </div>
            </div>

            <div className="grid grid-cols-8 gap-4 file-manager">
                <div className="col-span-8 md:col-span-3 lg:col-span-2">
                    <div className="wrap-box tp-22 page-transition">
                        <ul className="routes">
                            {
                                tabs.map(tabEl => {
                                    const attrs = {};
                                    if (tabEl.route === tab)
                                        attrs.className = "active";

                                    return (
                                        <li key={`route-${tabEl.route}`} {...attrs}>
                                            <Link to={`/panel/file-manager/${tabEl.route}`}>
                                                <i className={tabEl.icon} />
                                                {tabEl.title}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="col-span-8 md:col-span-5 lg:col-span-6">
                    <div className="wrap-box tp-22 page-transition">
                        {tab === "css" && <CssFile file="style/css/style.css" key={"css"} />}
                        {tab === "print-css" && <CssFile file="style/css/print.css" key={"print-css"} />}
                        {tab === "js" && <JsFile />}
                        {tab === "file" && <File />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(FileManager);