import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setDarkMode, setHeaderTheme, setMenuTheme } from 'redux/slices/theme';

const Theme = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.theme.darkMode);
    const headerTheme = useSelector(state => state.theme.headerTheme);
    const menuTheme = useSelector(state => state.theme.menuTheme);

    useEffect(() => {
        if (localStorage.THEME === 'dark' || (!('THEME' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))
            dispatch(setDarkMode(true));

        let headerTheme = localStorage.getItem("HEADER_THEME");
        headerTheme = headerTheme ? parseInt(headerTheme) : null;

        if (headerTheme && [0, 1, 2, 3].indexOf(headerTheme) !== -1)
            dispatch(setHeaderTheme(headerTheme));

        let menuTheme = localStorage.getItem("MENU_THEME");
        menuTheme = menuTheme ? parseInt(menuTheme) : null;

        if (menuTheme && [0, 1, 2, 3].indexOf(menuTheme) !== -1)
            dispatch(setMenuTheme(menuTheme));

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (darkMode)
            document.documentElement.classList.add("dark");
        else
            document.documentElement.classList.remove("dark");

        localStorage.setItem("HEADER_THEME", headerTheme);
        localStorage.setItem("MENU_THEME", menuTheme);

        // eslint-disable-next-line
    }, [darkMode, headerTheme, menuTheme])
}

export default Theme;