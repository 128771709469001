const InternetReceiptStatus = [
    {
        title: "در انتظار پرداخت",
        icon: "fa-solid fa-hourglass text-slate-500"
    },
    {
        title: "پرداخت موفق",
        icon: "fa-solid fa-circle-check text-green-500"
    },
    {
        title: "پرداخت ناموفق",
        icon: "fa-solid fa-circle-xmark text-red-500"
    },
    {
        title: "منقضی شده",
        icon: "fa-solid fa-clock text-red-500"
    }
]

export default InternetReceiptStatus;