import { memo, useCallback, useState } from "react";
import style from "../password-setting.module.scss";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import VerifyCode from "./verify-code/verify-code";
import { useSelector } from "react-redux";

const radios = [
    {
        title: "خاموش",
        value: 0
    },
    {
        title: "موبایل",
        value: 1
    },
    {
        title: "ایمیل",
        value: 2
    },
    {
        title: "موبایل و ایمیل",
        value: 3
    }
];

const TwoVerification = () => {
    const data = useSelector(state => state.main.info.data);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [method, setMethod] = useState(data.two_factor_auth_method || 0);
    const [step, setStep] = useState(1);

    const setStepCallback = useCallback(val => {
        setStep(val);
    }, []);

    const submitHandler = async () => {
        if (submitLoading || (data.two_factor_auth_method || 0) === method)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/change_two_factor",
                method: "PUT",
                payloadType: 3,
                data: {
                    method_id: method
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
            setStep(2);
        } catch (e) {
            setMethod(data.two_factor_auth_method || 0);
            toast.error(e.message);
        }
    }

    return (
        <div className="wrap-box tp-22 page-transition">
            <div className="mb-6">
                <b className="font-medium text-gray-700 dark:text-slate-200">ورود دو مرحله ای</b>
            </div>

            <div className={style.twoVerification}>
                <p className="text-sm text-gray-800 dark:text-slate-200 mb-4">
                    شما می توانید به منظور افزایش امنیت حساب کاربری خود، این قابلیت را فعال نمایید. با فعالسازی این قابلیت، هنگام ورود به حساب کاربری علاوه بر رمز عبور، شما نیاز به کد پیامک شده به شماره همراه خود را هم دارید.
                </p>

                <div className="mt-2">
                    {
                        step === 1 ? (
                            <div className="relative">
                                {
                                    radios.map(radio => (
                                        <div className="flex items-center text-sm my-2 text-gray-900 dark:text-slate-300" key={`two-verification-method-${radio.value}`}>
                                            <input
                                                type="radio"
                                                className={`${style.radio} border border-gray-300 dark:border-slate-500 ml-3`}
                                                name="two-verification-method"
                                                value={radio.value}
                                                id={`two-verification-method-${radio.value}`}
                                                checked={method === radio.value}
                                                onChange={() => setMethod(radio.value)}
                                            />

                                            <label htmlFor={`two-verification-method-${radio.value}`} className="ms-2 cursor-pointer">{radio.title}</label>
                                        </div>
                                    ))
                                }

                                <div className={`mt-6 ${style.subBtn} ${(data.two_factor_auth_method || 0) === method ? style.disable : ""}`}>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-primary-100 hover:bg-primary-200 transition-colors text-white py-2 px-4 text-sm relative overflow-hidden"
                                        onClick={submitHandler}
                                    >
                                        ثبت تغییرات

                                        <CSSTransition in={submitLoading} timeout={300} unmountOnExit={true}>
                                            <div className="bg-primary-200 fit-center pos-cover anim-c9">
                                                <i className="fa-solid fa-spinner spin"></i>
                                            </div>
                                        </CSSTransition>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <VerifyCode
                                setStep={setStepCallback}
                                method={method}
                                currentMethod={data.two_factor_auth_method || 0}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(TwoVerification);