import Modal from "components/shared/modal";
import { memo, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const RejectModal = props => {
    const {
        show,
        closeHandler,
        id,
        mutate
    } = props;

    const [description, setDescription] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        if (description.length === 0) {
            toast.error("دلیل رد تسویه حساب را وارد نمایید");
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/financial/balance/withdraw/reject",
                method: "PUT",
                payloadType: 3,
                data: {
                    id,
                    description
                },
                callback: () => setSubmitLoading(false)
            });

            mutate();
            toast.success(res.message);
            closeHandler();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal show={show} closeHandler={closeHandler} title="رد تسویه حساب">
            <div className="py-2 px-1">
                <form onSubmit={submitHandler}>
                    <label className={`textarea-c1 low-br${description.length ? " has-val" : ""}`}>
                        <label htmlFor={id} className="title">دلیل</label>

                        <TextareaAutosize
                            minRows={3}
                            id={id}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </label>

                    <button type="submit" className="bg-primary-100 w-full text-white mt-3 rounded-md text-sm py-3 hover:bg-primary-200 transition-colors relative overflow-hidden">
                        ثبت

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default memo(RejectModal);