const contentStatuses = [
    {
        title: "در انتظار تایید",
        icon: <i className="fa-regular fa-hourglass-clock status active" title="در انتظار تایید"></i>
    },
    {
        title: "تایید شده",
        icon: <i className="fa-solid fa-check status active" title="تایید شده"></i>
    },
    {
        title: "رد شده",
        icon: <i className="fa-solid fa-xmark status deactive" title="رد شده"></i>
    },
    {
        title: "حذف شده",
        icon: <i className="fa-regular fa-trash status deactive" title="حذف شده"></i>
    },
    {
        title: "پیش نویس",
        icon: <i className="fa-regular fa-hourglass-clock status active" title="پیش نویس"></i>
    }
];

export default contentStatuses;