import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { toMoneyFormat } from "utils";

const Balance = props => {
    const { data } = props;

    const newData = useMemo(() => {
        const { all, admin, provider, user } = data;

        return {
            all: {
                title: "همه",
                icon: "fa-square-list",
                data: all
            },
            admin: {
                title: "مدیران",
                icon: "fa-user-tie",
                data: admin,
                link: "/panel/wallet?user_type=4"
            },
            provider: {
                title: "تامین کنندگان",
                icon: "fa-user-vneck",
                data: provider,
                link: "/panel/wallet?user_type=2"
            },
            user: {
                title: "کاربران",
                icon: "fa-user",
                data: user,
                link: "/panel/wallet?user_type=1"
            }
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div className="balance-holder mb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                {
                    Object.keys(newData).map(key => {
                        const item = newData[key];

                        return (
                            <div className={`m-block relative${item.link ? " hover-act" : ""}`} key={`balance-block-${key}`}>
                                <div className="flex justify-between items-center">
                                    <h3 className="box-title">{item.title}</h3>

                                    <div className="icon-box">
                                        <i className={`fa-duotone ${item.icon}`}></i>
                                    </div>
                                </div>

                                <ul className="mt-2">
                                    <li>
                                        <span className="grow">موجودی</span>
                                        <b>{toMoneyFormat(item.data.balance)}</b>
                                        <span>تومان</span>
                                    </li>

                                    <li>
                                        <span className="grow">موجودی قابل برداشت</span>
                                        <b>{toMoneyFormat(item.data.withdrawble_balance)}</b>
                                        <span>تومان</span>
                                    </li>
                                </ul>

                                {
                                    !!item.link && <Link to={item.link} className="pos-cover" />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default memo(Balance);