import { useEffect, useState } from "react";
import extendDataFetcher from "service/extend-data-fetcher";

const useExtendData = (isLoading, error, data, mode) => {
    const [localData, setLocalData] = useState({});

    useEffect(() => {
        if (!isLoading && !error)
            fetcher(data.data);

        // eslint-disable-next-line
    }, [isLoading, error, data])

    const fetcher = async serviceData => {
        const routes = [
            {
                identifier: "userData",
                url: `/api/identity/${mode}/get?id=${serviceData.user_id}`
            }
        ];

        const newData = await extendDataFetcher(routes);
        setLocalData(newData);
    }

    return localData;
}

export default useExtendData;