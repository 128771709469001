import { memo, useState } from "react"
import AsyncSelect from 'react-select/async';
import { select2Styles } from "utils";
import { useSelector } from "react-redux";
import MasterAPI from "service/master";

const Select2Input = props => {
    const {
        placeholder,
        value,
        changeHandler,
        disabled = false,
        searchUrl,
        labelKey
    } = props;

    const darkMode = useSelector(state => state.theme.darkMode);
    const [loading, setLoading] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState([]);

    const onSelectHandler = e => {
        changeHandler(e);
    }

    const loadHandler = async e => {
        try {
            const res = await MasterAPI({
                url: searchUrl.replace(":key", e),
                method: "GET"
            });

            const newOptions = res.data.map(option => ({ label: option[labelKey], value: option.id }));
            return newOptions;
        } catch {
            return [];
        }
    }

    const onMenuOpenHandler = async () => {
        if (value)
            return;

        setLoading(true);

        try {
            const res = await MasterAPI({
                url: searchUrl.replace(":key", value?.label || ""),
                method: "GET",
                callback: () => setLoading(false)
            });

            const newOptions = res.data.map(option => ({ label: option[labelKey], value: option.id }));
            setDefaultOptions(newOptions);
        } catch {
            setDefaultOptions([]);
        }
    }

    const styles = select2Styles(darkMode);
    return (
        <div className='search-select relative'>
            <div className="label-c">
                {placeholder}
            </div>

            <AsyncSelect
                isRtl={true}
                isLoading={loading}
                isClearable
                styles={styles}
                noOptionsMessage={() => "موردی یافت نشد"}
                loadingMessage={() => "درحال دریافت اطلاعات ..."}
                placeholder={placeholder}
                loadOptions={loadHandler}
                onChange={onSelectHandler}
                value={value}
                isDisabled={disabled}
                onMenuOpen={onMenuOpenHandler}
                defaultOptions={defaultOptions}
            />
        </div>
    )
}

export default memo(Select2Input);