import InputText from "components/shared/inputs/input-text";
import Breadcrumb from "components/shared/breadcrumb";
import SwitchButton from "components/shared/switchButton";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import Skeleton from "./skeleton";
import { faNumToEng, numberRegex } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";

const CropSizes = () => {
    const { isLoading, error, data } = UseFetch("/api/setting/upload/image/crop_size/all");

    const [deletedIds, setDeletedIds] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [localLoading, setLocalLoading] = useState(true);
    const [failedFields, setFailedFields] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    useEffect(() => {
        if (!isLoading && !error) {
            setSizes(data.data || []);
            setLocalLoading(false);
        }
    }, [isLoading, error, data])

    const addHandler = () => {
        if (isLoading || error)
            return;

        setSizes(prev => [
            ...prev,
            {
                width: null,
                height: null,
                status_id: 1,
                label: null
            }
        ]);

        setSubmitEnabled(true);
    }

    const removeHandler = index => {
        const intendedSize = sizes[index];
        if (intendedSize.id)
            setDeletedIds(prev => [...prev, intendedSize.id]);

        setSizes(prev => prev.filter((_, i) => i !== index));
        setSubmitEnabled(true);
    }

    const widthOrHeightChangeHandler = (e, index, key) => {
        let newValue = e.target.value;
        const isEmpty = newValue === "";

        if (numberRegex.test(newValue) || isEmpty) {
            newValue = !isEmpty ? parseInt(faNumToEng(newValue)) : "";
            if (!isEmpty && newValue > Number.MAX_SAFE_INTEGER)
                return;

            setSizes(prev => {
                const newState = [...prev];
                newState[index][key] = newValue;

                return newState;
            });

            setFailedFields(prev => {
                const newState = { ...prev };
                delete newState[index];

                return newState;
            });

            setSubmitEnabled(true);
        }
    }

    const labelChangeHandler = (e, index) => {
        setSizes(prev => {
            const newState = [...prev];
            newState[index]["label"] = e.target.value.length ? e.target.value : null;

            return newState;
        });

        setSubmitEnabled(true);
    }

    const statusChangeHandler = index => {
        setSizes(prev => {
            const newState = [...prev];
            newState[index]["status_id"] = newState[index]["status_id"] === 1 ? 0 : 1;

            return newState;
        });

        setSubmitEnabled(true);
    }

    const submitHandler = async () => {
        if (submitLoading || error || localLoading || !submitEnabled)
            return;

        const newFailedFields = {};
        sizes.forEach((size, i) => {
            const thisErrors = [];

            if (!size.width > 0)
                thisErrors.push("width");

            if (!size.height > 0)
                thisErrors.push("height");

            if (thisErrors.length > 0)
                newFailedFields[i] = thisErrors;
        });

        setFailedFields(newFailedFields);
        if (Object.keys(newFailedFields).length === 0) {
            setSubmitLoading(true);

            try {
                const res = await MasterAPI({
                    url: "/api/setting/upload/image/crop_size/edit_multi",
                    data: {
                        deleted_ids: deletedIds,
                        image_crop_sizes: sizes
                    },
                    callback: () => setSubmitLoading(false),
                    payloadType: 3
                });

                toast.success(res.message);
                setSubmitEnabled(false);
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">سایزهای برش تصاویر</h1>

                    <Breadcrumb data={[
                        { title: "تنظیمات فروشگاه" },
                        { title: "تنظیمات سرور", link: "/panel/server-settings" },
                        { title: "سایزهای برش تصاویر" }
                    ]} />
                </div>

                <div className="flex">
                    <button
                        className="w-10 h-10 text-white bg-primary-100 hover:bg-primary-200 rounded-md fit-center"
                        title="افزودن سایز"
                        onClick={addHandler}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>

                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 mr-3 relative overflow-hidden ${!submitEnabled ? "opacity-50 cursor-default" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition unmountOnExit={true} in={submitLoading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            {
                !localLoading ? (
                    !error ? (
                        sizes.length ? (
                            sizes.map((size, i) => (
                                <div
                                    className={`wrap-box tp-22 page-transition flex flex-col items-stretch md:flex-row md:items-center mb-3 crop-size-block ${failedFields[i] ? "has-error" : ""}`}
                                    key={`crop-size-${i}`}
                                >
                                    <div className="md:ml-3 flex mb-4 md:mb-0">
                                        <div className="ml-3 fit-center w-12 h-12 min-w-[3rem] rounded-full bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 font-bold text-lg">
                                            {i + 1}
                                        </div>

                                        <InputText
                                            masterClass="grow"
                                            label="عنوان"
                                            id={`crop-size-${i}-title`}
                                            value={size.label || ""}
                                            changeHandler={e => labelChangeHandler(e, i)}
                                        />
                                    </div>

                                    <div className="flex md:ml-3 grow my-2 md:my-0">
                                        <div className="ml-3 grow">
                                            <InputText
                                                label="طول"
                                                masterClass="w-full"
                                                id={`crop-size-${i}-width`}
                                                value={size.width || ""}
                                                changeHandler={e => widthOrHeightChangeHandler(e, i, "width")}
                                            />

                                            {
                                                failedFields[i] && failedFields[i]?.indexOf("width") !== -1 ? <p className="local-error">طول باید به صورت عدد وارد شود</p> : <></>
                                            }
                                        </div>

                                        <div className="grow">
                                            <InputText
                                                label="عرض"
                                                masterClass="w-full"
                                                id={`crop-size-${i}-height`}
                                                value={size.height || ""}
                                                changeHandler={e => widthOrHeightChangeHandler(e, i, "height")}
                                            />

                                            {
                                                failedFields[i] && failedFields[i]?.indexOf("height") !== -1 ? <p className="local-error">عرض باید به صورت عدد وارد شود</p> : <></>
                                            }
                                        </div>
                                    </div>

                                    <div className="flex justify-between mt-3 md:mt-0">
                                        <div className="flex items-center ml-3">
                                            <SwitchButton
                                                checked={size.status_id === 1}
                                                changeHandler={() => statusChangeHandler(i)}
                                                id={`crop-size-${i}-status`}
                                            />

                                            <label
                                                className="tp-6 text-sm font-medium mr-2 cursor-pointer noselect"
                                                htmlFor={`crop-size-${i}-status`}
                                            >
                                                وضعیت
                                            </label>
                                        </div>

                                        <button
                                            className="bg-red-600 hover:bg-red-700 transition-colors rounded-md text-white text-sm px-3 py-1"
                                            onClick={() => removeHandler(i)}
                                        >
                                            حذف
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="text-sm text-red-600 dark:text-red-400">
                                    <i className="fa-light fa-clipboard-list ml-2 relative top-px"></i>
                                    موردی برای نمایش یافت نشد
                                </p>
                            </div>
                        )
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="text-sm text-red-600 dark:text-red-400">خطا در دریافت اطلاعات !</p>
                        </div>
                    )
                ) : <Skeleton />
            }
        </div>
    )
}

export default memo(CropSizes);