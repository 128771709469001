import { memo, useId, useState } from "react";
import style from "./password-setting.module.scss";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { useDispatch } from "react-redux";
import { fetchInfo } from "redux/slices/main";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ErrorMessage from "components/form-generator/error-msg";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.main.info.data);

    const formId = useId();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues
    } = useForm({
        mode: "onChange"
    });

    const submitHandler = async e => {
        if (removeLoading || submitLoading)
            return;

        const {
            password,
            rePassword,
            currentPassword
        } = e;

        if (password !== rePassword) {
            toast.error("رمز عبور و تکرار رمزعبور باهم یکسان نیستند");
            return;
        }

        setSubmitLoading(true);
        try {
            const payloadData = {
                new_password: password,
                new_password_confirm: rePassword,
                remove_password: false
            };

            if (data.has_password)
                payloadData.old_password = currentPassword;

            const res = await MasterAPI({
                url: "/api/user/change_password",
                data: payloadData,
                callback: () => setSubmitLoading(false),
                payloadType: 3,
                method: "PUT"
            });

            reset();
            toast.success(res.message);
            dispatch(fetchInfo());
        } catch (e) {
            toast.error(e.message);
        }
    }

    const removePasswordHandler = async () => {
        if (removeLoading || submitLoading)
            return;

        setRemoveLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/change_password",
                payloadType: 3,
                method: "PUT",
                data: {
                    remove_password: true,
                    old_password: getValues("currentPassword")
                },
                callback: () => setRemoveLoading(false)
            });

            toast.success(res.message);
            dispatch(fetchInfo());
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="wrap-box tp-22 page-transition mb-4">
            <div className="mb-6">
                <b className="font-medium text-gray-700 dark:text-slate-200">تغییر رمزعبور</b>
            </div>

            <form onSubmit={handleSubmit(submitHandler)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <CSSTransition in={data.has_password} appear={true} unmountOnExit={true} timeout={{ enter: 300, exit: 0 }}>
                        <div className="col-span-2 anim-c9">
                            <div className={`text-field ${getValues("currentPassword")?.length > 0 ? "has-val" : ""}`}>
                                <label htmlFor={`${formId}_currentPassword`}>رمزعبور فعلی</label>

                                <input
                                    type="password"
                                    id={`${formId}_currentPassword`}
                                    {...register("currentPassword", {
                                        required: data.has_password,
                                        minLength: data.has_password ? 8 : 0
                                    })}
                                />
                            </div>

                            {
                                !!errors.currentPassword && (
                                    <span className={style.error}>
                                        {
                                            errors.currentPassword.type === "minLength"
                                                ? "رمزعبور باید حداقل 8 کاراکتر باشد"
                                                : ErrorMessage(errors.currentPassword.type)
                                        }
                                    </span>
                                )
                            }
                        </div>
                    </CSSTransition>

                    <div>
                        <div className={`text-field ${getValues("password")?.length > 0 ? "has-val" : ""}`}>
                            <label htmlFor={`${formId}_password`}>رمزعبور</label>

                            <input
                                type="password"
                                id={`${formId}_password`}
                                {...register("password", {
                                    required: true,
                                    minLength: 8
                                })}
                            />
                        </div>

                        {
                            !!errors.password && (
                                <span className={style.error}>
                                    {
                                        errors.password.type === "minLength"
                                            ? "رمزعبور باید حداقل 8 کاراکتر باشد"
                                            : ErrorMessage(errors.password.type)
                                    }
                                </span>
                            )
                        }
                    </div>

                    <div>
                        <div className={`text-field ${getValues("rePassword")?.length > 0 ? "has-val" : ""}`}>
                            <label htmlFor={`${formId}_rePassword`}>تکرار رمزعبور</label>

                            <input
                                type="password"
                                id={`${formId}_rePassword`}
                                {...register("rePassword", {
                                    required: true,
                                    minLength: 8
                                })}
                            />
                        </div>

                        {!!errors.rePassword && <span className={style.error}>{ErrorMessage(errors.rePassword.type)}</span>}
                    </div>

                    <div className="col-span-2 flex items-stretch">
                        <button
                            type="submit"
                            className="rounded-md bg-primary-100 hover:bg-primary-200 transition-colors text-white py-2 px-4 text-sm relative overflow-hidden"
                        >
                            ثبت تغییرات

                            <CSSTransition in={submitLoading} timeout={300} unmountOnExit={true}>
                                <div className="bg-primary-200 fit-center pos-cover anim-c9">
                                    <i className="fa-solid fa-spinner spin"></i>
                                </div>
                            </CSSTransition>
                        </button>

                        {
                            data.has_password && (
                                <button className={`${style.removePass} mr-2 rounded-md`} type="button" onClick={removePasswordHandler}>
                                    حذف رمز عبور

                                    <CSSTransition in={removeLoading} unmountOnExit={true} timeout={300}>
                                        <span className={`pos-cover fit-center ${style.loading} anim-c9`}>
                                            <i className="fa-solid fa-spinner spin"></i>
                                        </span>
                                    </CSSTransition>
                                </button>
                            )
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default memo(ChangePassword)