import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    <div className="wrap-box tp-22 page-transition">
        <Skeleton className="w-24 h-5 mb-8" />

        <div className="address">
            <div className="flex mb-3">
                <b className="title">
                    <Skeleton className="w-12 h-4" />
                </b>

                <div className="value">
                    <Skeleton className="w-12 h-4" />
                </div>
            </div>

            <div className="flex mb-4">
                <b className="title">
                    <Skeleton className="w-12 h-4" />
                </b>

                <div className="value">
                    <Skeleton className="w-full h-4 mb-3" />
                    <Skeleton className="w-1/2 h-4" />
                </div>
            </div>

            <div className="flex items-center">
                <b className="title">
                    <Skeleton className="w-12 h-4" />
                </b>

                <div className="grow">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <Skeleton className="h-9" />
                        <Skeleton className="h-9" />
                        <Skeleton className="h-9" />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default memo(SkeletonComponent);