import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleDarkMode } from "redux/slices/theme";

const DarkMode = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.theme.darkMode);

    const clickHandler = () => {
        // before update new theme
        localStorage.setItem("THEME", darkMode ? "light" : "dark");

        dispatch(toggleDarkMode());
    }

    return (
        <button className="tp-7 mr-3" onClick={clickHandler} title="تغییر تم">
            <i className={`fa-regular ${darkMode ? "fa-sun-bright" : "fa-moon"}`}></i>
        </button>
    )
}

export default memo(DarkMode);