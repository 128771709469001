import Checkbox from "components/shared/inputs/checkbox";
import { memo, useCallback, useEffect, useId, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { arrayToRecursive } from "utils/recursive";
import Forms from "./forms/forms";
import Categories from "./categories";
import Routes from "./routes/routes";
import Skeleton from "./skeleton";

const Grid = props => {
    const {
        mode,
        submitLoading,
        setSubmitLoading,
        goSubmit,
        id: permissionId,
        pageData = {}
    } = props;

    const id = useId();
    const navigate = useNavigate();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [title, setTitle] = useState("");
    const [allAccess, setAllAccess] = useState(false);
    const [statusId, setStatusId] = useState(0);
    const [defaultPermission, setDefaulPermission] = useState(false);
    const [autoAssign, setAutoAssign] = useState(false);
    const [allCategories, setAllCategories] = useState(false);
    const [allForms, setAllForms] = useState(false);

    const [expandedCats, setExpandsCats] = useState({
        categories: [],
        forms: [],
        routes: []
    });

    const [selectedItems, setSelectedItems] = useState({
        categories: {},
        forms: {},
        routes: []
    });

    useEffect(() => {
        fetcher();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (goSubmit)
            submitHandler();

        // eslint-disable-next-line
    }, [goSubmit])

    const setExpandsCatsCallback = useCallback(val => {
        setExpandsCats(val);
    }, []);

    const setSelectedItemsCallback = useCallback(val => {
        setSelectedItems(val);
    }, []);

    const submitHandler = async () => {
        if (submitLoading)
            return;

        if (title.length === 0) {
            toast.error("عنوان را وارد نمایید");
            return;
        }

        try {
            setSubmitLoading(true);

            const payloadData = {
                title,
                all_access: allAccess,
                route_ids: selectedItems.routes,
                forms: selectedItems.forms,
                categories: selectedItems.categories,
                status_id: statusId ? 1 : 0,
                default_permission: defaultPermission,
                auto_assign: autoAssign,
                all_forms: allForms,
                all_categories: allCategories
            };

            if (permissionId)
                payloadData.id = permissionId;

            const res = await MasterAPI({
                url: permissionId ? `/api/permission/${mode}/edit` : `/api/permission/${mode}/create`,
                method: permissionId ? "PUT" : "POST",
                payloadType: 3,
                data: payloadData,
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
            navigate(`/panel/permission/${mode}`);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const fetcher = async () => {
        try {
            const categoriesRes = await MasterAPI({
                method: "GET",
                url: "/api/category/all"
            });

            const formsRes = await MasterAPI({
                method: "GET",
                url: "/api/form/all"
            });

            const routesRes = await MasterAPI({
                method: "GET",
                url: `/api/permission/${mode}/routes/all`
            });

            const categories = arrayToRecursive(categoriesRes.data.filter(cat => cat.status_id !== 2));
            const forms = arrayToRecursive(formsRes.data);
            const routes = arrayToRecursive(routesRes.data);

            if (permissionId) {
                const defaultValues = await MasterAPI({
                    method: "GET",
                    url: pageData.get_url.replace(":id", permissionId)
                });

                setTitle(defaultValues.data.title);
                setAllAccess(defaultValues.data.all_access);
                setAutoAssign(defaultValues.data.auto_assign);
                setStatusId(!!defaultValues.data.status_id);
                setDefaulPermission(defaultValues.data.default_permission);
                setAllCategories(defaultValues.data.all_categories);
                setAllForms(defaultValues.data.all_forms);

                setSelectedItems({
                    categories: defaultValues.data.categories || {},
                    routes: defaultValues.data.route_ids || [],
                    forms: defaultValues.data.forms || {}
                });

                setData({
                    categories,
                    forms,
                    routes
                });
            } else
                setData({
                    categories,
                    forms,
                    routes
                });

            setLoading(false);
        } catch {
            setError(true);
        }
    }

    return (
        <div className="wrap-box tp-22 page-transition">
            {
                !loading ? (
                    !error ? (
                        <>
                            <div className="mb-10 grid grid-cols-1 gap-5 text-gray-900 dark:text-gray-200">
                                <div className={`text-field${title.length ? " has-val" : ""}`}>
                                    <label htmlFor={`${id}_title`}>عنوان</label>

                                    <input
                                        type="text"
                                        id={`${id}_title`}
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={allAccess} changeHandler={() => setAllAccess(prev => !prev)} />
                                    <span className="mr-3 text-sm">دسترسی کل</span>
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={allCategories} changeHandler={() => setAllCategories(prev => !prev)} />
                                    <span className="mr-3 text-sm">دسترسی به همه دسته بندی ها</span>
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={allForms} changeHandler={() => setAllForms(prev => !prev)} />
                                    <span className="mr-3 text-sm">دسترسی به همه فرم ها</span>
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={statusId} changeHandler={() => setStatusId(prev => !prev)} />
                                    <span className="mr-3 text-sm">وضعیت</span>
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={defaultPermission} changeHandler={() => setDefaulPermission(prev => !prev)} />
                                    <span className="mr-3 text-sm">دسترسی پیشفرض</span>
                                </div>

                                <div className="flex items-center">
                                    <Checkbox value={autoAssign} changeHandler={() => setAutoAssign(prev => !prev)} />
                                    <span className="mr-3 text-sm">اختصاص خودکار</span>
                                </div>
                            </div>

                            <div className="permissions">
                                <div className="head">
                                    <div className="row">
                                        <div className="title-col">
                                            عناوین دسته بندی ها
                                        </div>

                                        <div className="checked-col">
                                            publish
                                        </div>

                                        <div className="checked-col">
                                            edit
                                        </div>

                                        <div className="checked-col">
                                            create
                                        </div>
                                    </div>
                                </div>

                                <div className="body">
                                    {
                                        data.categories.map(el => (
                                            <Categories
                                                key={`categories-${el.id}`}
                                                item={el}
                                                catType="categories"
                                                expandedCats={expandedCats}
                                                setExpandsCats={setExpandsCats}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems}
                                            />
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="permissions">
                                <div className="head">
                                    <div className="row">
                                        <div className="title-col">
                                            عناوین فرم ها
                                        </div>

                                        <div className="checked-col">
                                            publish
                                        </div>

                                        <div className="checked-col">
                                            edit
                                        </div>

                                        <div className="checked-col">
                                            create
                                        </div>
                                    </div>
                                </div>

                                <div className="body">
                                    {
                                        data.forms.map(el => (
                                            <Forms
                                                key={`forms-${el.id}`}
                                                item={el}
                                                catType="forms"
                                                expandedCats={expandedCats}
                                                setExpandsCats={setExpandsCatsCallback}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItemsCallback}
                                                mode={mode}
                                                editMode={!!permissionId}
                                            />
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="permissions">
                                <div className="head">
                                    <div className="row">
                                        <div className="title-col">
                                            مسیرها
                                        </div>

                                        <div className="checked-col">
                                            access
                                        </div>
                                    </div>
                                </div>

                                <div className="body">
                                    {
                                        data.routes.map(el => (
                                            <Routes
                                                key={`route-${el.id}`}
                                                item={el}
                                                catType="routes"
                                                expandedCats={expandedCats}
                                                setExpandsCats={setExpandsCatsCallback}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItemsCallback}
                                                mode={mode}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    ) : <h1>Error ...</h1 >
                ) : <Skeleton />
            }
        </div>
    )
}

export default memo(Grid);