import { memo } from "react";
import { setThemeSettingModal } from "redux/slices/theme";
import { useDispatch } from "react-redux";

const Setting = () => {
    const dispatch = useDispatch();

    const clickHandler = () => {
        dispatch(setThemeSettingModal({
            show: true,
            panelSettings: true
        }));
    }

    return (
        <button className="tp-7 lg:mr-3" onClick={clickHandler} title="تنظیمات">
            <i className="fa-regular fa-gear spin"></i>
        </button>
    )
}

export default memo(Setting);