import { Route, Routes } from 'react-router-dom';
import PanelLayer from 'components/panel/layer/layer';
import PanelRoutes from "constants/panel-routes";
import React from 'react';
import Login from 'view/auth/login';
import AuthLayer from "components/auth/layer";
import { skipRoutes } from 'constants/config';
import ForgetPassword from 'components/auth/forget-password/forget-password';

const crawel = (route, id) => {
    if (skipRoutes.indexOf(route.id) !== -1)
        return;

    if (route.childs?.length) {
        return (
            route.childs.map((childRoute, i) => (
                <React.Fragment key={`route-${id}-${i}`}>
                    {
                        crawel(childRoute, `${id}-${i}`)
                    }
                </React.Fragment>
            ))
        )
    } else {
        if (route.view) {
            const attrs = {
                element: route.view
            };

            if (route.index)
                attrs.index = true;

            if (route.route)
                attrs.path = route.route;

            if (route.params?.length) {
                return (
                    <>
                        <Route {...attrs} />

                        {
                            route.params.map((param, i) => (
                                <Route
                                    element={route.view}
                                    path={`${route.route}/${param}`}
                                    key={`param-route-${id}-${i}`}
                                />
                            ))
                        }
                    </>
                )
            }

            return <Route {...attrs} />
        }
    }
}

const RoutesProvider = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthLayer />}>
                <Route index={true} element={<Login />} />
                <Route path="forget" element={<ForgetPassword />} />
            </Route>

            <Route path="panel" element={<PanelLayer />}>
                {
                    PanelRoutes.map((route, i) => (
                        <React.Fragment key={`route-${i}`}>
                            {
                                crawel(route, i)
                            }
                        </React.Fragment>
                    ))
                }
            </Route>

            <Route path="auth" element={<AuthLayer />}>
                <Route index={true} element={<Login />} />
                <Route path="login" element={<Login />} />
            </Route>
        </Routes>
    )
}

export default RoutesProvider;