import { memo, useContext, useMemo } from "react";
import InitializeContext from "context/initialize-context";
import { generateContentTitle } from "utils";
import SkeletonComponent from "./skeleton";
import { contentStatusId } from "utils";
import BooleanBox from "./boolean-box";
import { Link, useParams } from "react-router-dom";
import GoToAll from "components/shared/go-to-all";

const Profiles = props => {
    const { loading, data } = props;

    const { id } = useParams();
    const enums = useContext(InitializeContext);

    const list = useMemo(() => {
        const output = [];

        if (!loading) {
            data.data?.forEach(profile => {
                const newProfile = { ...profile };
                const intendedForm = enums.data.forms.find(form => form.id === profile.form_id);

                newProfile.form_title = intendedForm?.title || "---";
                newProfile.profile_title = generateContentTitle(profile.form_id, profile.content.fields, enums.data.forms);

                output.push(newProfile);
            })
        }

        return output;
        // eslint-disable-next-line
    }, [loading, data]);

    return (
        <div className="wrap-box tp-22 page-transition">
            <div className="flex justify-between items-center pb-4">
                <h2 className="tp-34 text-sm font-bold">پروفایل های کاربر</h2>
                <GoToAll link={`/panel/user-profiles/${id}`} />
            </div>

            {
                !loading ? (
                    list.length ? (
                        list.map((profile, i) => {
                            return (
                                <div className="detail-block" key={`profile-item-${i}`}>
                                    <div className="flex mb-3">
                                        <b className="title">
                                            شناسه:
                                        </b>

                                        <div className="value cursor-default">
                                            {profile.id}

                                            <Link
                                                to={`/panel/user-profile-detail?form_id=${profile.form_id}&profile_id=${profile.oid}`}
                                                className="rounded-md text-xs bg-primary-100 text-white px-1 mr-2 transition-colors hover:bg-primary-200"
                                            >
                                                مشاهده
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="flex mb-3">
                                        <b className="title">
                                            نوع:
                                        </b>

                                        <div className="value">
                                            {profile.form_title}
                                        </div>
                                    </div>

                                    <div className="flex mb-3">
                                        <b className="title">
                                            عنوان:
                                        </b>

                                        <div className="value">
                                            {profile.profile_title}
                                        </div>
                                    </div>

                                    <div className="flex mb-3">
                                        <b className="title">
                                            شناسه پروفایل:
                                        </b>

                                        <div className="value">
                                            <Link to={`/panel/sellers/${profile.oid}?form_id=${profile.form_id}`} className="text-blue-800 dark:text-blue-300">
                                                {profile.oid}
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="flex mb-3">
                                        <b className="title">
                                            وضعیت محتوای پروفایل:
                                        </b>

                                        <div className="value">
                                            {contentStatusId(profile.content.status_id).title}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-4">
                                        <BooleanBox
                                            label="وضعیت اتصال کاربر"
                                            value={profile.content.status_id ? "فعال" : "غیر فعال"}
                                            active={profile.content.status_id}
                                        />

                                        <BooleanBox
                                            label="کاربر اصلی پروفایل"
                                            value={profile.is_primary ? "بله" : "خیر"}
                                            active={profile.is_primary}
                                        />

                                        <BooleanBox
                                            label="دارای پیش نویس"
                                            value={profile.content.has_draft ? "دارد" : "ندارد"}
                                            active={profile.content.has_draft}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    ) : <p className="global-error p-0">موردی برای نمایش یافت نشد !</p>
                ) : <SkeletonComponent />
            }
        </div >
    )
}

export default memo(Profiles);