import { memo, useId, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams, useSearchParams } from "react-router-dom";
import UseFetch from "hooks/useFetch";
import { convertShamsiToUnix, faNumToEng, toMoneyFormat } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import TextareaAutosize from 'react-textarea-autosize';
import SwitchButton from "components/shared/switchButton";
import DatePickerComponent from "components/form-generator/fields/date-picker";
import SearchSelect from "components/form-generator/fields/search-select";
import Loading from "components/shared/loading";

const AddWallet = () => {
    const { userId } = useParams();
    const [searhcParams] = useSearchParams();
    const formId = useId();
    const { isLoading, error, data, mutate } = UseFetch(`/api/financial/balance/wallet/get?user_id=${userId}&user_type=${searhcParams.get("user_type")}`);
    const [amount, setAmount] = useState("");
    const [receive, setReceive] = useState("1");
    const [fieldErros, setFieldErrors] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [reserved, setReserved] = useState(false);
    const [reservedUntil, setReservedUntil] = useState(null);
    const [incomeTypeId, setIncomeTypeId] = useState(null);

    const amountChangeHandler = e => {
        let newVal = e.target.value;
        newVal = faNumToEng(newVal);
        newVal = newVal.replaceAll(",", "");

        if (newVal === "")
            setAmount(newVal);
        else if (/^[0-9\b]+$/.test(newVal))
            setAmount(toMoneyFormat(parseInt(newVal)));
    }

    const submitHandler = async () => {
        if (submitLoading)
            return;

        const localAmount = amount.replaceAll(",", "");
        if (localAmount.length === 0) {
            setFieldErrors({ "amount": "لطفا مبلغ را وارد نمایید" });
            return;
        }

        if (isNaN(localAmount)) {
            setFieldErrors({ "amount": "مقدار وارد شده صحیح نمی باشد" });
            return;
        }

        if (description.length === 0) {
            setFieldErrors({ "description": "لطفا توضیحات را وارد نمایید" });
            return;
        }

        setFieldErrors({});
        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/financial/balance/create",
                payloadType: 3,
                callback: () => setSubmitLoading(false),
                data: {
                    user_id: parseInt(userId),
                    financial_user_type_id: parseInt(searhcParams.get("user_type")),
                    recive: receive === "1",
                    description,
                    amount: parseInt(localAmount),
                    status_id: 1,
                    reserved,
                    reserve_until: reservedUntil ? convertShamsiToUnix(reservedUntil, true) : null,
                    income_type_id: incomeTypeId
                }
            });

            toast.success(res.message);
            setAmount("");
            setReserved(false);
            setReservedUntil(null);
            setDescription("");
            setIncomeTypeId(null);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">افزایش موجودی</h1>
                    <Breadcrumb data={[
                        { title: "کیف پول", link: "/panel/wallet" },
                        { title: "افزایش موجودی" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to={"/panel/wallet"} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center ml-3" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        ثبت

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="tp-9 text-sm">
                                    <span>موجودی:</span>
                                    <b className="mr-2">{toMoneyFormat(data.data.balance)}</b>
                                    <span className="text-xs"> تومان</span>
                                </div>
                            </>
                        ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                    ) : (
                        <div className="fit-center">
                            <Loading className="loading-c5" />
                        </div>
                    )
                }

                <div className="mt-10">
                    <div className="grid grid-cols-1 gap-5">
                        <label className="select-c1">
                            <span>نوع سند</span>

                            <select id={`${formId}_recive`} value={receive} onChange={e => setReceive(e.target.value)}>
                                <option value="1">واریز</option>
                                <option value="0">برداشت</option>
                            </select>
                        </label>

                        <div className={`text-field has-val`}>
                            <label htmlFor={`${formId}_amount`}>مبلغ</label>

                            <input
                                type="text"
                                id={`${formId}_amount`}
                                value={amount}
                                onChange={amountChangeHandler}
                            />

                            {!!fieldErros["amount"] && <p className="text-xs text-red-500 mt-1 mr-3">{fieldErros["amount"]}</p>}
                        </div>

                        <label className="textarea-c1 has-val">
                            <label htmlFor={`${formId}_description`} className="title">توضیحات</label>

                            <TextareaAutosize
                                minRows={1}
                                id={`${formId}_description`}
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </label>
                        {!!fieldErros["description"] && <p className="text-xs text-red-500 mt-1 mr-3">{fieldErros["description"]}</p>}

                        <div className="flex items-center">
                            <SwitchButton
                                id={`${formId}_reserved`}
                                checked={reserved}
                                changeHandler={() => setReserved(prev => !prev)}
                            />
                            <label htmlFor={`${formId}_reserved`} className="mr-2 text-slate-800 dark:text-slate-300 text-sm noselect cursor-pointer">رزرو</label>
                        </div>

                        <DatePickerComponent
                            label="رزرو تا تاریخ"
                            id={`${formId}_reserve_until`}
                            name={`${formId}_reserve_until`}
                            value={reservedUntil}
                            changeHandler={val => setReservedUntil(val)}
                        />

                        <SearchSelect
                            placeholder="نوع مخزن درآمد"
                            changeHandler={val => setIncomeTypeId(val)}
                            setting={{
                                search_url: "/api/financial/income_type/list?key=:key",
                                value_key: "id",
                                label_key: "title",
                                get_url: "/api/financial/income_type/get?id=:id"
                            }}
                            value={incomeTypeId}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default memo(AddWallet);