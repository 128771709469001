import SelectLocation from "components/selectLocation/selectLocation";
import { useCallback } from "react";
import InfoBox from "../infoBox";

const Address = (props) => {
    const {
        field,
        changeHandler
    } = props;

    const doneCallback = useCallback((val) => {
        changeHandler(val);
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <label className="text-sm tp-27 mb-2 font-bold block">{field.caption}</label>

            <SelectLocation
                defaultValue={field.value}
                doneCallback={doneCallback}
            />

            <InfoBox text={field.helptxt} />
        </div>
    )
}

export default Address;