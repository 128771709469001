import useEscHandler from "hooks/useEscHandler";
import { memo } from "react"
import { CSSTransition } from "react-transition-group";
import Lightbox from "./lightbox";

const Confirm = props => {
    const {
        show,
        yesHandler,
        noHandler
    } = props;

    useEscHandler(noHandler);

    return (
        <>
            <Lightbox show={show} clickHandler={noHandler} />

            <CSSTransition in={show} timeout={{ enter: 300, exit: 100 }} unmountOnExit={true}>
                <div className="confirm">
                    <div className="pos-cover" onClick={noHandler} />

                    <div className="wrap-box shadow-xl border-primary-100 relative">
                        <p>آیا از انجام این عملیات مطمئن هستید؟</p>

                        <div className="mt-4">
                            <button
                                className="yes bg-primary-100 hover:bg-primary-200 ml-2 py-2 px-8"
                                onClick={yesHandler}
                            >
                                بله
                            </button>

                            <button
                                className="no bg-red-500 hover:bg-red-600 py-2 px-4"
                                onClick={noHandler}
                            >
                                خیر
                            </button>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}

export default memo(Confirm);