import SmartLogo from "components/shared/smart-logo";
import { useState } from "react";
import { useCallback } from "react";
import { memo } from "react";
import Step1 from "./step-1";
import Step2 from "./step-2";

const Login = () => {
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState();
    const [signupProcess, setSignupProcess] = useState(false);

    const setPhoneCallback = useCallback(value => {
        setPhone(value);
    }, []);

    const changeStep = useCallback(val => {
        setStep(val);
    }, [])

    const setSignupProcessCallback = useCallback(val => {
        setSignupProcess(val);
    }, [])

    return (
        <>
            <div className="col-r">
                <div className="w-full h-full relative">
                    <div className="pos-cover fit-center flex-col px-4 py-2">
                        <div className="flex mb-6">
                            <img src="/assets/image/logo/1-mini.png" alt="سریرمارکت" />
                            <img src="/assets/image/logo/1.png" alt="سریرمارکت" />
                        </div>

                        <img src="/assets/image/auth.svg" width={90} alt="authentication vector" />

                        <h2 className="text-white font-bold font-lg mt-6 mb-2">ورود به اکانت</h2>
                        <p className="sp-1 text-xs text-center leading-5">
                            مدیریت کلی سیستم، نقش های کاربری و ...
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-l tp-29 px-5 py-8">
                <div className="mb-6 md:hidden">
                    <SmartLogo />
                </div>

                {step === 1 && (
                    <Step1
                        phone={phone}
                        setPhone={setPhoneCallback}
                        changeStep={changeStep}
                        setSignupProcess={setSignupProcessCallback}
                    />
                )}

                {step === 2 && (
                    <Step2
                        phone={phone}
                        changeStep={changeStep}
                        signupProcess={signupProcess}
                    />
                )}
            </div>
        </>
    )
}

export default memo(Login);