const TransactionsTypes = {
    1: {
        title: "کیف پول",
        icon: ""
    },
    2: {
        title: "پرداخت اینترنتی",
        icon: ""
    },
    3: {
        title: "لغو سفارش توسط فروشنده",
        icon: ""
    },
    4: {
        title: "لایه فاکتور",
        icon: ""
    },
    5: {
        title: "قیمت سفارش",
        icon: ""
    },
    6: {
        title: "کارمزد",
        icon: ""
    },
    7: {
        title: "حواله داخلی",
        icon: ""
    },
    8: {
        title: "لغو سفارش توسط خریدار",
        icon: ""
    },
    9: {
        title: "لفو سفارش توسط مدیر",
        icon: ""
    },
    10: {
        title: "لغو سفارش توسط سیستم",
        icon: ""
    },
    11: {
        title: "تسویه حساب",
        icon: ""
    },
    12: {
        title: "بازگشت تسویه حساب",
        icon: ""
    },
    13: {
        title: "تغییر توسط ادمین",
        icon: ""
    },
    14: {
        title: "تغییر محصول سفارش توسط خریدار",
        icon: ""
    },
    15: {
        title: "تغییر محصول سفارش توسط فروشنده",
        icon: ""
    },
    16: {
        title: "تغییر محصول سفارش توسط مدیر",
        icon: ""
    },
    17: {
        title: "تغییر محصول سفارش توسط سیستم",
        icon: ""
    },
    18: {
        title: "پاداش امتیاز",
        icon: ""
    },
    19: {
        title: "واریز به حساب",
        icon: ""
    }
};

export default TransactionsTypes;