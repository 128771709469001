import Breadcrumb from "components/shared/breadcrumb";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import { useEffect, useState, useCallback } from "react";
import { memo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import FormGenerator from "components/form-generator/main/main";

const Add = props => {
    const {
        pageData
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const { pageId } = useParams();

    const [breadcrumb, setBreadcrumb] = useState();
    const [goSubmit, setGoSubmit] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (pageData)
            setBreadcrumb([
                ...pageData.breadcrumb,
                {
                    title: "افزودن کنترل جدید"
                }
            ]);

        // eslint-disable-next-line
    }, [pageData])

    const getPreviousRoute = () => {
        const splitedCurrentRoute = location.pathname.split("/");
        if (splitedCurrentRoute[splitedCurrentRoute.length - 1] === "")
            splitedCurrentRoute.pop();

        splitedCurrentRoute.pop();
        const newRoute = splitedCurrentRoute.join("/");

        return `${newRoute}/list`;
    }

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const setParentLoading = useCallback(val => {
        setSubmitLoading(val);
        // eslint-disable-next-line
    }, [])

    const doneCallback = useCallback(() => {
        navigate(getPreviousRoute(), { replace: true });
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">افزودن کنترل جدید</h1>
                    {
                        breadcrumb ? (
                            <Breadcrumb data={breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-square-plus ml-2"></i>
                        افزودن

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <FormGenerator
                    mode="add"
                    submitUrl={pageData.add_url}
                    fields={pageData.edit_setting}
                    goSubmit={goSubmit}
                    setParentLoading={setParentLoading}
                    doneCallback={doneCallback}
                    preData={{ page_id: parseInt(pageId) }}
                    submitMethod="PUT"
                />
            </div>
        </>
    )
}

export default memo(Add);