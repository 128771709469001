import { memo, useState } from "react";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const Sending = props => {
    const { data, mutate } = props;
    const [loading, setLoading] = useState(false);

    const clickHandler = async () => {
        if (loading)
            return;

        setLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/v2/financial/order/group/change_status",
                payloadType: 3,
                method: "PUT",
                data: {
                    id: data.id,
                    status_id: 5
                },
                callback: () => setLoading(false)
            });

            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <button
            className={`bg-primary-100 hover:bg-primary-200 mr-2 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${loading ? " cursor-progress" : ""}`}
            onClick={clickHandler}
        >
            <i className="fa-regular fa-person-carry-box ml-2"></i>
            درحال ارسال

            <CSSTransition in={loading} timeout={200} unmountOnExit={true}>
                <span className="pos-cover bg-primary-200 fit-center anim-c4">
                    <i className="fa-solid fa-spinner spin"></i>
                </span>
            </CSSTransition>
        </button>
    )
}

export default memo(Sending);