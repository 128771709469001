import Breadcrumb from "components/shared/breadcrumb";
import { memo, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import FormGenerator from "components/form-generator/main/main";
import MasterAPI from "service/master";
import Loading from "components/shared/loading";

const PageCode = () => {
    const { id } = useParams();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        fetcher();
        // eslint-disable-next-line
    }, [])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const fetcher = async () => {
        try {
            const res = await MasterAPI({
                url: `/api/page/get_code?id=${id}`,
                method: "GET",
                callback: () => setIsLoading(false)
            });

            setData(res);
        } catch (e) {
            setError(e);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">اصلاح کد</h1>
                    <Breadcrumb
                        data={[
                            { title: "مدیریت صفحات", link: "/panel/page" },
                            { title: "اصلاح کد" }
                        ]}
                    />
                </div>

                <div className="flex">
                    <Link to="/panel/page" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <FormGenerator
                                submitUrl="/api/page/edit_code"
                                defaultValues={{ code: data.data.code }}
                                goSubmit={goSubmit}
                                setParentLoading={setSubmitLoading}
                                submitMethod="PUT"
                                preData={{ id: parseInt(id) }}
                                fields={[
                                    {
                                        caption: "کد صفحه",
                                        name: "code",
                                        helptxt: null,
                                        data_type: 1,
                                        input_type: 22,
                                        default_value: "",
                                        priority: null,
                                        masterclass: null,
                                        required_field: null,
                                        validation_id: null,
                                        searchable: null,
                                        setting: {},
                                        status_id: 1
                                    }
                                ]}
                            />
                        ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                    ) : (
                        <div className="fit-center">
                            <Loading className="loading-c5" />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default memo(PageCode);