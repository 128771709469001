import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const TicketSkeleton = ({ id }) => (
    <>
        <Skeleton className="w-28 h-4 rounded-full mb-11" />

        <div className="flex justify-center">
            <div>
                {
                    Object.keys([...Array(4)]).map(i => (
                        <div className="flex mb-4 ml-9" key={`skeleton-${id}-${i}`}>
                            <Skeleton className="w-3 h-3 rounded-full ml-2" />
                            <Skeleton className="w-20 h-3 rounded-full" />
                        </div>
                    ))
                }
            </div>

            <div className="w-52 h-52 rounded-full border-[20px]" />
        </div>

        <div className="grid grid-cols-2 gap-2 mt-6">
            <Skeleton className="w-full h-14 rounded-sm" />
            <Skeleton className="w-full h-14 rounded-sm" />
        </div>
    </>
)

export default memo(TicketSkeleton);