import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    <>
        <div className="grid md:grid-cols-2 gap-4 mb-4">
            <div className="wrap-box tp-22 page-transition flex items-center">
                <Skeleton className="w-14 h-14 min-w-[3.5rem] rounded-full" />
                <Skeleton className="w-24 h-4 rounded-sm mr-3" />
            </div>

            <div className="wrap-box tp-22 page-transition flex items-center">
                <Skeleton className="w-14 h-14 min-w-[3.5rem] rounded-full" />
                <Skeleton className="w-24 h-4 rounded-sm mr-3" />
            </div>
        </div>

        <div className="wrap-box no-p tp-22 overflow-x-auto">
            <div className="min-w-[500px] p-4">
                <div className="table-c1">
                    <table>
                        <thead>
                            <tr className="spec-thead-c1 tp-25">
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                                <th><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></th>
                            </tr>
                        </thead>

                        <tbody className="tp-25 text-center text-sm">
                            {
                                Object.keys([...Array(5)]).map(i => (
                                    <tr key={`skeleton-user-row-${i}`}>
                                        <td>
                                            <Skeleton className="mx-auto w-5 h-5 rounded-full" />
                                        </td>

                                        <td><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-11 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-4 h-4 rounded-sm" /></td>
                                        <td><Skeleton className="mx-auto w-4 h-4 rounded-sm" /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
)

export default memo(SkeletonComponent);