import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Form from "../form/form";

const Add = props => {
    const { pageData, formId } = props;
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (pageData.breadcrumb) {
            setBreadcrumb([
                ...pageData.breadcrumb,
                { title: "افزودن" }
            ]);
        }
    }, [pageData])

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.splice(-2);

        const url = splitedRoute.join("/");
        return `${url}?form_id=${searchParams.get("form_id")}`
    }

    const setParentLoading = useCallback(value => {
        setSubmitLoading(value);
    }, [])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const doneCallback = useCallback(() => {
        navigate(getPreviousRoute());
        // eslint-disable-next-line
    }, []);

    const defaultCats = useMemo(() => {
        return [];
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={breadcrumb} />
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-square-plus ml-2"></i>
                        افزودن

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <Form
                    formId={formId}
                    setParentLoading={setParentLoading}
                    goSubmit={goSubmit}
                    doneCallback={doneCallback}
                    mode="create"
                    defaultCats={defaultCats}
                />
            </div>
        </>
    )
}

export default memo(Add);