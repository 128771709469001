import { memo, useCallback, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams } from "react-router-dom";
import UseFetch from "hooks/useFetch";
import { toMoneyFormat, unixToShamsi } from "utils";
import GetFinancialUserType, { GetFinancialUserTypeLink } from "constants/financial-user-type";
import AcceptModal from "./acceptModal/acceptModal";
import RejectModal from "./rejectModal";
import ServiceTypes from "constants/service-types";
import useExtendData from "./use-extend-data";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";
import Loading from "components/shared/loading";

const View = () => {
    const { id } = useParams();
    const { isLoading, error, data, mutate } = UseFetch(`/api/financial/balance/withdraw/history/${id}`);
    const extendData = useExtendData(isLoading, error, data);

    const [showAcceptModal, setShowAcceptModal] = useState();
    const [showRejectModal, setShowRejectModal] = useState();

    const closeModal = useCallback(() => {
        setShowAcceptModal(false);
        setShowRejectModal(false);
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات تسویه حساب</h1>
                    <Breadcrumb data={[
                        { title: "تسویه حساب", link: '/panel/checkout' },
                        { title: "جزئیات تسویه حساب" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/checkout" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    {
                        data.data?.status_id === 0 && (
                            <>
                                <button
                                    className={`bg-red-500 hover:bg-red-600 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3`}
                                    onClick={() => setShowRejectModal(true)}
                                >
                                    <i className="fa-regular fa-xmark ml-2"></i>
                                    رد کردن
                                </button>

                                <button
                                    className={`bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3`}
                                    onClick={() => setShowAcceptModal(true)}
                                >
                                    <i className="fa-regular fa-check ml-2"></i>
                                    تایید
                                </button>
                            </>
                        )
                    }
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                                    <p className="text-sm text-green-800 dark:text-slate-300">
                                        <b className="inline-block ml-2">وضعیت تسویه حساب</b>
                                        <span>
                                            {data.data.status_id === 0 && "در انتظار تایید"}
                                            {data.data.status_id === 1 && "تایید شده"}
                                            {data.data.status_id === 2 && "رد شده"}
                                        </span>
                                    </p>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه تسویه حساب</span>
                                            <b>{data.data.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>مبلغ</span>
                                            <b>{toMoneyFormat(data.data.amount)} تومان</b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع سرویس</span>
                                            <b>{ServiceTypes[data.data.service_type_id]?.title || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع کاربری</span>
                                            <b>{GetFinancialUserType(data.data.financial_user_type_id)}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>کاربر</span>
                                            <b>
                                                <ShowExtendUserLabel
                                                    value={extendData.user}
                                                    link={GetFinancialUserTypeLink(data.data.financial_user_type_id, data.data.user_id).link}
                                                />
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه تراکنش</span>
                                            <b>
                                                {
                                                    data.data.transaction_id
                                                        ? <Link to={`/panel/transactions/${data.data.transaction_id}`} className="link-c1">{data.data.transaction_id}</Link>
                                                        : "---"
                                                }
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>کد پیگیری</span>
                                            <b>{data.data.tracking_code || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>توضیحات</span>
                                            <b>{data.data.description || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>پیوست ها</span>
                                            <b>{data.data.attachments || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ ایجاد</span>
                                            <b>{unixToShamsi(data.data.created_at) || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ آخرین تغییرات</span>
                                            <b>{unixToShamsi(data.data.update_at) || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ تسویه</span>
                                            <b>{unixToShamsi(data.data.withdrew_at) || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>شناسه ادمین تسویه کننده</span>
                                            <b>
                                                {
                                                    data.data.admin_id ? (
                                                        <ShowExtendUserLabel
                                                            value={extendData.admin}
                                                            link={GetFinancialUserTypeLink(4, data.data.admin_id).link}
                                                        />
                                                    ) : "---"
                                                }
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>ip ادمین تسویه کننده</span>
                                            <b>{data.data.admin_ip || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>شناسه توکن ادمین تسویه کننده</span>

                                            <b>
                                                {data.data.admin_token_id ? (
                                                    <Link className="link-c1" to={`/panel/tokens/admin/${data.data.admin_id}/${data.data.admin_token_id}`}>
                                                        {data.data.admin_token_id}
                                                    </Link>
                                                ) : "---"}
                                            </b>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                    ) : (
                        <div className="fit-center">
                            <Loading className="loading-c5" />
                        </div>
                    )
                }
            </div>

            {
                <>
                    <AcceptModal
                        id={data.data?.id}
                        show={!isLoading && !error && showAcceptModal}
                        closeHandler={closeModal}
                        mutate={mutate}
                    />

                    <RejectModal
                        id={data.data?.id}
                        show={!isLoading && !error && showRejectModal}
                        closeHandler={closeModal}
                        mutate={mutate}
                    />
                </>
            }
        </>
    )
}

export default memo(View);