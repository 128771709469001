import { memo, useId } from "react";

const Radio = props => {
    const id = useId();

    const {
        label,
        name,
        value,
        changeHandler = () => {},
        clickHandler = () => {},
        checked
    } = props;

    return (
        <div className="radio-c1">
            <input
                type="radio"
                name={`radio-${name}`}
                value={value}
                onChange={changeHandler}
                onClick={clickHandler}
                id={`id-${id}`}
                checked={checked}
            />

            <label htmlFor={`id-${id}`}>{label}</label>
        </div>
    )
}

export default memo(Radio);