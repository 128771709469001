import { memo } from "react";
import { Link } from "react-router-dom";
import { getUserLabel } from "utils";

const Loading = () => (
    <div className="h-3 rounded-full w-10 overflow-hidden inline-block">
        <div className="skeleton-box w-full h-full">
            <span />
        </div>
    </div>
)

export const ShowExtendUserLabel = props => {
    const { value, link } = props;

    if (typeof value === "undefined")
        return <Loading />;

    if (value.error)
        return <span className="text-sm text-red-600 font-normal">خطای اطلاعات !</span>

    if (link)
        return (
            <Link
                to={link}
                className="link-c1"
            >
                {getUserLabel(value.value)}
            </Link>
        )

    return getUserLabel(value.value);
}

const ShowExtendData = props => {
    const { value } = props;

    if (typeof value === "undefined")
        return <Loading />;

    if (value.error)
        return <span className="text-sm text-red-600 font-normal">خطای اطلاعات !</span>

    return value.value;
}

export default memo(ShowExtendData);