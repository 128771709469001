import Breadcrumb from "components/shared/breadcrumb";
import { memo, useState, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import FormGenerator from "components/form-generator/main/main";
import Skeleton from "components/skeleton/form";
import templateFetcherHook from "service/template-fetcher-hook";
import UseFetch from "hooks/useFetch";

const UserProfile = props => {
    const { template } = props;

    const { data: pageData } = templateFetcherHook(template);
    const { data, error, isLoading } = UseFetch(pageData.index_url);

    const [goSubmit, setGoSubmit] = useState(0);
    const [loading, setLoading] = useState(false);

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const setParentLoading = useCallback(value => {
        setLoading(value);
        // eslint-disable-next-line
    }, [])

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>

                <button
                    className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${loading ? " cursor-progress" : ""}`}
                    onClick={submitHandler}
                >
                    <i className="fa-light fa-file ml-2"></i>
                    ثبت اطلاعات

                    <CSSTransition in={loading} timeout={200} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c4">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <FormGenerator
                                fields={pageData.edit_setting}
                                submitUrl="/api/score/edit_setting"
                                goSubmit={goSubmit}
                                setParentLoading={setParentLoading}
                                payloadType={3}
                                defaultValues={data.data}
                                submitMethod="PUT"
                            />
                        ) : <></> // error
                    ) : <Skeleton />
                }
            </div>
        </div>
    )
}

export default memo(UserProfile);