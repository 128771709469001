import { memo, useContext, useEffect, useId, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "hooks/useOutsideClick";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { List, arrayMove } from 'react-movable';
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import EnumsContext from "context/initialize-context";
import { getValueFromObjByKey, urlDecoder } from "utils";

const ContentsLink = props => {
    const {
        label,
        changeHandler,
        linkFormId,
        value,
        setting,
        targetCategory
    } = props;

    const [searchParams] = useSearchParams();
    const id = useId();
    const [inputValue, setInputValue] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [timeoutState, setTimeoutState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const enumsData = useContext(EnumsContext);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setShowResults(false));

    useEffect(() => {
        return () => clearTimeout(timeoutState);
        // eslint-disable-next-line
    }, [])

    const inputChangeHandler = e => {
        const value = e.target.value;

        setInputValue(value);
        setShowResults(true);
        setLoading(true);

        if (timeoutState)
            clearTimeout(timeoutState);

        const newTimeoutState = setTimeout(() => {
            fetcher(value);
        }, 2000);

        setTimeoutState(newTimeoutState);
        setShowResults(true);
    }

    const onFocusHandler = e => {
        const value = e.target.value;
        setShowResults(true);

        if (results.length === 0) {
            setLoading(true);
            fetcher(value);
        }
    }

    const onBlurHandler = e => {
        // const value = e.target.value;

        // if (value.length === 0)
        //     setShowResults(false);
    }

    const fetcher = async text => {
        try {
            const baseUrl = `/api/content/list`;
            const reqParams = createSearchParams(`form_id=${linkFormId}&key=${text}${setting?.extra_query || ""}`);

            for (const [key, value] of reqParams.entries()) {
                if (value.startsWith(":")) {
                    const thisKeyValue = searchParams.get(key);
                    if (thisKeyValue)
                        reqParams.set(key, thisKeyValue);
                    else
                        reqParams.delete(key);
                } else if (value.startsWith("*")) {
                    if (value === "*")
                        reqParams.set(key, targetCategory);
                    else {
                        const intendedTargetCat = enumsData.data.categories.find(el => el.id === targetCategory);

                        if (intendedTargetCat) {
                            const intendedValue = getValueFromObjByKey(intendedTargetCat, value.replace("*.", ""));
                            if (intendedValue)
                                reqParams.set(key, intendedValue);
                            else
                                reqParams.delete(key);
                        } else
                            reqParams.delete(key);
                    }
                }
            }

            const res = await MasterAPI({
                url: urlDecoder(`${baseUrl}?${reqParams.toString()}`),
                method: "GET",
                callback: () => setLoading(false)
            });

            if (res.data?.length) {
                const newData = res.data.map(el => ({ id: el.id, label: el.virtual_title }));
                setResults(newData);
            } else
                setResults([]);
        } catch (e) {
            toast.error(e.message);
            setResults([]);
        }
    }

    const itemClickHandler = item => {
        const itemSelected = value.some(el => el.id === item.id);
        if (!itemSelected) {
            setInputValue("");
            setShowResults(false);
            changeHandler([...value, { virtual_title: item.label, id: item.id }]);
        } else
            toast.error("این لینک قبلا انتخاب شده است");
    }

    const changeSorthandler = ({ oldIndex, newIndex }) => {
        const newArray = arrayMove(value, oldIndex, newIndex);
        changeHandler(newArray);
    }

    const deleteHandler = id => {
        changeHandler(value.filter(item => item.id !== id));
    }

    return (
        <div className="relative" ref={wrapperRef}>
            <div className={`text-field${inputValue.length ? " has-val" : ""}`}>
                <label htmlFor={id}>{label}</label>

                <input
                    type="text"
                    id={id}
                    value={inputValue}
                    onChange={inputChangeHandler}
                    onFocus={onFocusHandler}
                    autoComplete="off"
                    onBlur={onBlurHandler}
                />
            </div>

            <CSSTransition in={showResults} timeout={{ enter: 300, exit: 0 }} unmountOnExit={true}>
                <div className="cl-result-box">
                    <div className="box">
                        {
                            !loading ? (
                                results.length ? (
                                    <ul>
                                        {
                                            results.map(item => {
                                                return (
                                                    <li
                                                        key={`link-${id}-${item.id}`}
                                                        onClick={() => itemClickHandler(item)}
                                                        className="ls-block"
                                                    >
                                                        {item.label}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : <span className="lcr-error text-red-600">نتیجه ای یافت نشد</span>
                            ) : <span className="lcr-error text-gray-400">درحال دریافت اطلاعات ...</span>
                        }
                    </div>
                </div>
            </CSSTransition>

            {
                value.length > 0 && (
                    <div className="mt-3 mr-2">
                        <List
                            values={value}
                            onChange={changeSorthandler}
                            renderList={({ children, props }) => (
                                <ul {...props}>
                                    {children}
                                </ul>
                            )}
                            renderItem={({ value, props, isDragged }) => {
                                return (
                                    <li
                                        key={`s-link-${id}-${value.id}`}
                                        className={`fade-in fu-item ${isDragged ? "grabbing" : ""}`}
                                        {...props}
                                        style={{ ...props.style }}
                                    >
                                        <Link to={`/panel/content/${value.id}?form_id=${linkFormId}`} />

                                        <div className="flex items-center justify-between grow pr-2">
                                            <span>{value.virtual_title}</span>
                                            <button type="button" className="del h-full" onClick={() => deleteHandler(value.id)}></button>
                                        </div>
                                    </li>
                                )
                            }}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default memo(ContentsLink);