import BatchRequest from "./batch-request";

const extendDataFetcher = async routes => {
    if (!routes || routes.length === 0)
        return {};

    const reqRoutes = [];
    routes.forEach(route => {
        if (route.url)
            reqRoutes.push({
                identifier: route.identifier,
                url: route.url,
                method: "GET",
            });
    })

    try {
        const res = await BatchRequest({ routes: reqRoutes });

        const newData = {};
        routes.forEach(route => {
            const response = res[route.identifier];

            let value = response?.body?.data;
            if (value && route.key)
                value = value[route.key];

            if (response?.status_code === 200 && value)
                newData[route.identifier] = { value };
            else
                newData[route.identifier] = { error: true };
        });

        return newData;
    } catch (e) {
        const newData = {};
        routes.forEach(route => newData[route.identifier] = { error: true });

        return newData;
    }
}

export default extendDataFetcher;