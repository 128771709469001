import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useMemo, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import UseFetch from "hooks/useFetch";
import useExtendData from "./use-extend-data";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";
import subscriptionsEnum from "constants/subscriptions";
import { GetFinancialUserTypeLink } from "constants/financial-user-type";
import { unixToShamsi } from "utils";
import InitializeContext from "context/initialize-context";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb,
        mode
    } = props;

    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);
    const extendData = useExtendData(mode, isLoading, error, data);
    const enums = useContext(InitializeContext);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const enum1 = useMemo(() => {
        if (data?.data?.form_id) {
            const intendedItem = enums.data.forms.find(item => item.id === data.data.form_id);
            return intendedItem?.title || "خطا در یافتن فرم";
        }

        return "---";
        // eslint-disable-next-line
    }, [data])

    const enum2 = useMemo(() => {
        const output = [];

        if (data.data?.contain_forms?.length) {
            data.data.contain_forms.forEach(containForm => {
                const intendedContainForm = enums.data.forms.find(item => item.id === containForm);
                if (intendedContainForm)
                    output.push(intendedContainForm.title);
            })
        }

        return output.length ? output.join(", ") : "---";
        // eslint-disable-next-line
    }, [data])

    const enum3 = useMemo(() => {
        if (data?.data?.category_id) {
            const intendedItem = enums.data.categories.find(item => item.id === data.data.category_id);
            return intendedItem?.title || "خطا در یافتن دسته بندی";
        }

        return "---";
        // eslint-disable-next-line
    }, [data])

    const enum4 = useMemo(() => {
        const output = [];

        if (data.data?.contain_categories?.length) {
            data.data.contain_categories.forEach(containCategory => {
                const intendedContainForm = enums.data.categories.find(item => item.id === containCategory);
                if (intendedContainForm)
                    output.push(intendedContainForm.title);
            })
        }

        return output.length ? output.join(", ") : "---";
        // eslint-disable-next-line
    }, [data])

    const enum5 = useMemo(() => {
        if (data?.data?.form_element_id) {
            const intendedItem = enums.data.formElements.find(item => item.id === data.data.form_element_id);
            return intendedItem?.caption || "خطا در یافتن فیلد فرم";
        }

        return "---";
        // eslint-disable-next-line
    }, [data])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات پیام</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                                    <p className="text-sm text-green-800 dark:text-slate-300">
                                        <b className="inline-block ml-2">وضعیت اشتراک</b>
                                        <span>{subscriptionsEnum[data.data.status_id] || "---"}</span>
                                    </p>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>عنوان</span>
                                            <b>{data.data.title}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>نوع اشتراک</span>

                                            <Link to={`/panel/shop_setting/subscription_type/${data.data.subscription_type_id}`} className="link-c1">
                                                {
                                                    extendData["subscriptionType"]?.value?.title
                                                }
                                            </Link>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>کاربر</span>
                                            <b>
                                                <Link to={GetFinancialUserTypeLink(1, data.data.user_id)?.link || ""} className="link-c1">
                                                    <ShowExtendUserLabel value={extendData.userData} />
                                                </Link>
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>دسترسی دانلود</span>
                                            <b>{data.data.access_download ? "بله" : "خیر"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>روز</span>
                                            <b>{data.data.days}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ شروع</span>
                                            <b>{unixToShamsi(data.data.start_at)}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ پایان</span>
                                            <b>{unixToShamsi(data.data.end_at)}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>اتصال به فرم هدف</span>
                                            <b>{enum1}</b>
                                        </li>

                                        <li className="inline">
                                            <span>اتصال به فرم ها</span>
                                            <b>{enum2}</b>
                                        </li>

                                        <li className="inline">
                                            <span>اتصال به دسته بندی هدف</span>
                                            <b>{enum3}</b>
                                        </li>

                                        <li className="inline">
                                            <span>اتصال به دسته بندی ها</span>
                                            <b>{enum4}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>اتصال به فیلد فرم</span>
                                            <b>{enum5}</b>
                                        </li>

                                        <li className="inline">
                                            <span>مقدار فیلد فرم</span>
                                            <b>{data.data.form_element_condition}</b>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >
        </>
    )
}

export default memo(Edit);