import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from 'react-select/async';
import MasterAPI from "service/master";
import { toast } from 'react-toastify';
import { CSSTransition } from "react-transition-group";
import { getPathFromUrl, select2Styles } from "utils";
import { createSearchParams, useSearchParams } from "react-router-dom";

const SearchSelect = props => {
    const {
        multi = false,
        placeholder,
        setting,
        changeHandler,
        value,
        enumerationId,
        addValueToUrl,
        className
    } = props;

    let { search_url, value_key, label_key, get_url } = setting;
    if (enumerationId && enumerationId > 0) {
        let thisValue;

        if (value === null || value?.length === 0)
            thisValue = ";"
        else
            if (Array.isArray(value))
                thisValue = value[0];
            else
                thisValue = value;

        get_url = `/api/form/enumeration/list?parent_id=${enumerationId}&id=${thisValue}`;
        search_url = `/api/form/enumeration/list?parent_id=${enumerationId}&key=:key`;
        value_key = "id";
        label_key = "title"
    }

    const darkMode = useSelector(state => state.theme.darkMode);
    const [searchLoading, setSearchLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState([]);
    const [defaultValueLoading, setDefaultValueLoading] = useState(false);
    const [labelStorage, setLabelStorage] = useState({});
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        defaultValueFetcher();
        // eslint-disable-next-line
    }, [value])

    const defaultValueFetcherService = async id => {
        if (labelStorage[id])
            return labelStorage[id];

        let url = get_url.replace(":id", id);
        url = url.replace(":form_id", searchParams.get("form_id"));

        try {
            const res = await MasterAPI({
                method: "GET",
                url
            });

            const label = res.data[0][label_key];
            setLabelStorage(prev => {
                const newState = { ...prev };
                newState[id] = label;

                return newState;
            });

            return label;
        } catch (e) {
            return null;
        }
    }

    const defaultValueFetcher = async () => {
        if (multi) {
            if (typeof value === "object" && value?.length) {
                const existsNewLabel = value.some(el => !labelStorage[el]);
                if (!existsNewLabel)
                    setDefaultValueLoading(true);

                const newDefaultValue = [];
                for (let i = 0; i < value.length; i++) {
                    const val = value[i];
                    const label = await defaultValueFetcherService(val);
                    newDefaultValue.push({ label, value: val });
                }

                setDefaultValue(newDefaultValue);
                setDefaultValueLoading(false);
            } else
                setDefaultValue([]);
        } else {
            if (value) {
                if (!labelStorage[value])
                    setDefaultValueLoading(true);

                const label = await defaultValueFetcherService(value);
                setDefaultValue({ label, value });

                setDefaultValueLoading(false);
            } else
                setDefaultValue([]);
        }
    }

    const searchService = async keyword => {
        try {
            const baseUrl = getPathFromUrl(search_url);
            const searchUrlParams = createSearchParams(search_url.replace(baseUrl, ""));

            const newSearchParams = createSearchParams();
            newSearchParams.set("key", keyword);

            for (const [key, value] of searchUrlParams.entries()) {
                if (key !== "key") {
                    if (value.indexOf(":") === -1)
                        newSearchParams.set(key, value);
                    else {
                        const thisVal = value.replace(":", "");

                        if (searchParams.get(thisVal))
                            newSearchParams.set(key, searchParams.get(thisVal));
                    }
                }
            }

            const res = await MasterAPI({
                method: "GET",
                url: `${baseUrl}?${newSearchParams.toString()}`,
                callback: () => setSearchLoading(false)
            });

            if (res.data?.length) {
                const newData = [];
                const newLabelStorage = {};

                res.data.forEach(item => {
                    newLabelStorage[item[value_key]] = item[label_key];

                    newData.push({
                        label: item[label_key],
                        value: item[value_key]
                    });
                })

                setLabelStorage(prev => ({ ...prev, ...newLabelStorage }));
                return newData;
            } else
                return [];
        } catch (e) {
            toast.error(e.message);
            return [];
        }
    }

    const loadHandler = async value => {
        const res = await searchService(value);
        return res
    };

    const onSelectHandler = e => {
        if (multi) {
            const newVal = [];

            e?.forEach(el => {
                newVal.push(el.value);
            });

            changeHandler(newVal);
        } else {
            changeHandler(e?.value || null);

            if (addValueToUrl) {
                const newSearchParams = createSearchParams(searchParams);

                if (e?.value)
                    newSearchParams.set(addValueToUrl, e?.value);
                else
                    newSearchParams.delete(addValueToUrl);

                setSearchParams(newSearchParams);
            }
        }
    }

    const styles = select2Styles(darkMode);
    return (
        <div className={`search-select relative${className ? ` ${className}` : ""}`}>
            <div className="label-c">
                {placeholder}
            </div>

            {/* <CSSTransition in={!!defaultValue.length} timeout={200} unmountOnExit={true}>
                <span className='anim-c4'>{placeholder}</span>
            </CSSTransition> */}

            <AsyncSelect
                isMulti={multi}
                isRtl={true}
                isLoading={searchLoading}
                isClearable
                defaultOptions
                loadOptions={loadHandler}
                styles={styles}
                noOptionsMessage={() => "موردی یافت نشد"}
                loadingMessage={() => "درحال دریافت اطلاعات ..."}
                placeholder={placeholder}
                onChange={onSelectHandler}
                value={defaultValue}
            />

            <CSSTransition in={defaultValueLoading} timeout={200} unmountOnExit={true} appear={true}>
                <div className='pos-cover loading-c2 rounded-full fit-center anim-c4'>
                    <i className="fa-solid fa-spinner spin text-slate-700 dark:text-gray-300"></i>
                </div>
            </CSSTransition>
        </div>
    )
}

export default memo(SearchSelect);