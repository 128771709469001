import { memo, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import useScrolledFetch from "hooks/useScrolledFetch";
import moment from "jalali-moment";
import UseFetch from "hooks/useFetch";
import Send from "./send";
import { useInView } from "react-intersection-observer";
import Breadcrumb from "components/shared/breadcrumb";
import ticketStatuses from "constants/ticket-statuses";
import ChangeStatus from "./change-status";
import Loading from "components/shared/loading";
import { getProductImage } from "utils";

const getTicketTitle = (mode, reply) => {
    if (reply.user_kind === 3)
        return <>کارشناس پشتیبان - <Link to={`/panel/identity_admin?id=${reply.user_id}`} className="text-blue-800 dark:text-blue-300">{reply.name}</Link></>

    return mode === "user" ? "کاربر" : "تامین کننده";
}

const View = props => {
    const { mode } = props;
    const messagesPerPage = 5;

    const { id } = useParams();
    const { inView, ref: moreElRef } = useInView();
    const { isLoading, data, error, mutate: ticketMutate } = UseFetch(`/api/ticket/${mode}/get?id=${id}`);
    const [limit, setLimit] = useState(messagesPerPage);

    const {
        isLoading: messagesLoading,
        data: messagesData,
        error: messagesError,
        loadMoreLoading,
        mutate
    } = useScrolledFetch({ url: `/api/ticket/${mode}/list_replies?id=${id}&limit=${limit}` });

    useEffect(() => {
        if (!loadMoreLoading && inView)
            setLimit(prev => prev + messagesPerPage);

        // eslint-disable-next-line
    }, [inView])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات تیکت</h1>

                    <Breadcrumb data={[
                        { title: "تعاملات کاربران" },
                        { title: "تیکت", link: "/panel/ticket" },
                        { title: "جزئیات تیکت" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to={`/panel/${mode}Ticket`} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition tickets">
                {isLoading || messagesLoading ? <Loading className="loading-c5" /> : <></>}

                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="grid grid-cols-12 gap-4 text-sm pb-5 border-b border-gray-200 dark:border-slate-700 mb-4 text-gray-800 dark:text-slate-100">
                                    <div className="col-span-1">
                                        <b className="font-medium">عنوان</b>
                                    </div>

                                    <div className="col-span-11">
                                        {data.data.subject}
                                    </div>

                                    <div className="col-span-1">
                                        <b className="font-medium">{mode === "user" ? "کاربر" : "تامین کننده"}</b>
                                    </div>

                                    <div className="col-span-11">
                                        <Link to={`/panel/${mode === "user" ? "user-detail" : "provider-detail"}/${data.data.user_id}`} className="font-medium text-blue-800 dark:text-blue-300">
                                            {data.data.name}
                                        </Link>
                                    </div>

                                    <div className="col-span-1">
                                        <b className="font-medium">زمان ایجاد</b>
                                    </div>

                                    <div className="col-span-11">
                                        {moment.unix(data.data.created_at).format("hh:mm - jYYYY/jMM/jD")}
                                    </div>

                                    <div className="col-span-1">
                                        <b className="font-medium">وضعیت</b>
                                    </div>

                                    <div className="col-span-11">
                                        <div className="flex items-center">
                                            <span>{ticketStatuses[data.data.status_id].title}</span>
                                            <ChangeStatus statusId={data.data.status_id} mode={mode} mutate={ticketMutate} />
                                        </div>
                                    </div>

                                    {
                                        data.data.files?.length > 0 && (
                                            <>
                                                <div className="col-span-1">
                                                    <b className="font-medium">فایل های ضمیمه:</b>
                                                </div>

                                                <div className="col-span-11">
                                                    <div className="flex items-center flex-wrap">
                                                        {
                                                            data.data.files.map((file, i) => (
                                                                <a
                                                                    key={`main-file-${i}`}
                                                                    href={getProductImage(file)}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="bg-slate-100 hover:bg-slate-200 dark:bg-slate-800 hover:dark:bg-slate-700 dark:text-slate-200 text-slate-500 rounded-lg py-1 px-3 ml-4 mb-2 block flex items-center"
                                                                >
                                                                    <i className="fa-light fa-paperclip ml-1 relative top-px" />
                                                                    ضمیمه {i + 1}
                                                                </a>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>

                                <div className="grid grid-cols-12 gap-4 text-sm pb-5 border-b border-gray-200 dark:border-slate-700 mb-4 text-gray-800 dark:text-slate-100">
                                    <div className="col-span-1">
                                        <b className="font-medium">توضیحات</b>
                                    </div>

                                    <div className="col-span-11">
                                        {data.data.description}
                                    </div>
                                </div>

                                <Send mode={mode} mutate={mutate} />

                                {
                                    !messagesLoading ? (
                                        !messagesError ? (
                                            <>
                                                {
                                                    messagesData.data.map(reply => (
                                                        <div className="ticket" key={`reply-${reply.id}`}>
                                                            <div className="title hasBB">
                                                                <b>{getTicketTitle(mode, reply)}</b>

                                                                <div className="time">
                                                                    <span>{moment.unix(reply.created_at).format("jYYYY/jMM/jDD")}</span>
                                                                    <span className="line"></span>
                                                                    <span>{moment.unix(reply.created_at).format("hh:mm")}</span>
                                                                </div>
                                                            </div>

                                                            <p className="pre-line">{reply.message}</p>

                                                            {
                                                                reply.files?.length > 0 && (
                                                                    <div className="flex items-center flex-wrap mt-4">
                                                                        {
                                                                            reply.files.map((file, i) => (
                                                                                <a
                                                                                    key={`msg-${reply.id}-file-${i}`}
                                                                                    href={getProductImage(file)}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    className="text-sm bg-slate-100 hover:bg-slate-200 dark:bg-slate-800 hover:dark:bg-slate-700 dark:text-slate-200 text-slate-500 rounded-lg py-1 px-3 ml-4 mb-2 flex items-center"
                                                                                >
                                                                                    <i className="fa-light fa-paperclip ml-1 relative top-px" />
                                                                                    ضمیمه {i + 1}
                                                                                </a>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }

                                                {messagesData.count > limit && <div ref={moreElRef} />}
                                            </>
                                        ) : <p className="text-sm text-red-400">خطا در دریافت اطلاعات ...</p>
                                    ) : <></>
                                }
                            </>
                        ) : <p className="text-sm text-red-400">خطا در دریافت اطلاعات ...</p>
                    ) : <></>
                }
            </div>
        </>
    )
}

export default memo(View);