import { memo, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { setThemeSettingModal } from "redux/slices/theme";
import { useDispatch, useSelector } from "react-redux";

const AuthLayer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authorizationProcess = useSelector(state => state.main.authorizationProcess);
    const authorization = useSelector(state => state.main.authorization);

    useEffect(() => {
        if (authorizationProcess && authorization)
            navigate("/panel", { replace: true });

        // eslint-disable-next-line
    }, [authorizationProcess, authorization]);

    return (
        <div className="auth">
            <button className="tp-7 text-xl absolute left-5 top-5 p-2 rounded-md" onClick={() => dispatch(setThemeSettingModal({ show: true }))}>
                <i className="fa-regular fa-gear spin"></i>
            </button>

            <div className="content-box">
                <div className="wrap-box flex items-stretch w-full">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default memo(AuthLayer);