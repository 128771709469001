import { memo } from "react";
import { Link, useParams } from "react-router-dom";


const LinksHolder = props => {
    const { userType } = props;
    const userTypeStr = userType === "user" ? "کاربر" : "تامین کننده";
    const { id } = useParams();

    const links = [
        {
            title: `تراکنش های ${userTypeStr}`,
            link: `/panel/transactions?${userType}_id=${id}`
        },
        {
            title: `تراکنش های اینترنتی ${userTypeStr}`,
            link: `/panel/internet-receipt?${userType}_id=${id}`
        },
        {
            title: `تسویه حساب های ${userTypeStr}`,
            link: `/panel/checkout?${userType}_id=${id}`
        },
        {
            title: `پیام های ${userTypeStr}`,
            link: `/panel/${userType}sMessage?receiver_id=1`
        }
    ]

    if (userType === "user")
        links.push({
            title: "سبد کاربران",
            link: `/panel/basket/${id}`
        });

    const countIsOdd = links.length % 2 !== 0;
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {
                links.map((link, i) => (
                    <div className={`wrap-box tp-22 page-transition flex items-center justify-between ${countIsOdd && i === 0 ? "col-span-full md:col-span-2" : ""}`} key={`link-spc-${i}`}>
                        <h3 className="text-sm text-slate-900 dark:text-slate-300 font-medium">{link.title}</h3>
                        <Link
                            to={link.link}
                            className="py-1 px-2 rounded-md block text-white bg-primary-100 hover:bg-primary-200 transition-colors text-sm items-center"
                        >
                            مشاهده
                            <i className="fa-light fa-chevron-left text-white opacity-40 mr-2 relative top-px text-xs"></i>
                        </Link>
                    </div>
                ))
            }
        </div>
    )
}

export default memo(LinksHolder);