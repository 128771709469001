import { useState, useEffect, memo } from "react";

const FullScreen = () => {
    const [fullscreen, setFullscreen] = useState(false);

    useEffect(() => {
        document.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement)
                setFullscreen(true);
            else
                setFullscreen(false);
        });

        return () => document.addEventListener('fullscreenchange', () => { });
    }, [])

    const toggleFullSceen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setFullscreen(false);
            }
        }
    }

    return (
        <button className="tp-7 mr-3" onClick={toggleFullSceen} title="تمام صفحه">
            <i className={`fa-regular ${fullscreen ? "fa-compress" : "fa-expand"}`}></i>
        </button>
    )
}

export default memo(FullScreen);