import Searchbox from "./searchbox";
import Breadcrumb from "components/shared/breadcrumb";
import { memo, useEffect, useState } from "react";
import Checkbox from "components/shared/inputs/checkbox";
import Pagination from "components/shared/pagination";
import Skeleton from "components/skeleton/table-1";
import { Link, useLocation } from "react-router-dom";
import BottomSkeleton from "components/skeleton/table-grid-bottom";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import { generateIconLink, getPagesCount } from "utils";
import convertUtils from "utils/convert-utils";
import PriorityModal from "components/shared/priority-modal";
import UseFetch from "hooks/useFetch";

const List = props => {
    const {
        pageData,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [fetchUrl, setFetchUrl] = useState(null);
    const [urlParamsData, setUrlParamsData] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const [priorityData, setPriorityData] = useState({ show: false });

    useEffect(() => {
        if (!pageData)
            return;

        let newFetchUrl = pageData.index_url;
        let newUrlParams = [];
        let newUrlParamsData = {};

        pageData.search_valid_params.forEach(param => {
            const paramValue = urlParams.get(param);
            if (paramValue) {
                newUrlParamsData[param] = paramValue;
                newUrlParams.push(`${param}=${paramValue}`);
            }
        })

        if (Object.keys(newUrlParamsData).length) {
            newFetchUrl += `?${newUrlParams.join("&")}`;
            setUrlParamsData(newUrlParamsData);
        } else
            setUrlParamsData({});

        setFetchUrl(newFetchUrl);
        // eslint-disable-next-line
    }, [pageData, location.search])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);

        // eslint-disable-next-line
    }, [data])

    const selectRowHandler = id => {
        setSelectedRows(prev => {
            if (selectedRows.indexOf(id) !== -1) {
                const newState = prev.filter(rowId => rowId !== id);
                return newState;
            }

            return [...prev, id];
        })
    }

    const detailLink = userId => {
        let url = location.pathname;
        url += `/${userId}?user_type=${urlParamsData.user_type || 0}`;

        return url;
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="flex justify-between items-start">
                    <Searchbox fields={pageData.search_setting} />
                </div>

                <div className="table-c1 mt-10 mb-4">
                    <table>
                        <thead>
                            <tr>
                                <th className="w-5"></th>
                                <th className="w-5"></th>
                                <th className="w-5"></th>
                                <th></th>
                                <th className="w-5"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {isLoading && <Skeleton />}
                        </tbody>
                    </table>

                    {
                        isLoading || (data && data.data?.length) ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th className="w-5"></th>
                                        <th className="w-5"></th>
                                        <th className="w-5"></th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        !isLoading ? (
                                            data.data?.map((item, i) => {
                                                const hasIcon = pageData.index_setting.icon?.length > 0;
                                                const labels = [];
                                                ["label_1", "label_2", "label_3"].forEach(label => {
                                                    const labelObj = pageData.index_setting[label];

                                                    if (labelObj) {
                                                        const intendedKey = labelObj.key;
                                                        let value = item[intendedKey] || item[intendedKey] === 0 ? item[intendedKey] : "-";

                                                        if (labelObj.convert_function)
                                                            value = convertUtils(labelObj.convert_function, value);

                                                        labels.push(`${labelObj.label}: ${value}`);
                                                    }
                                                })

                                                return (
                                                    <tr key={`table-row-${i}`}>
                                                        <td>
                                                            <Checkbox
                                                                value={selectedRows.indexOf(item.id) !== -1}
                                                                changeHandler={() => selectRowHandler(item.id)}
                                                            />
                                                        </td>

                                                        <td>
                                                            {
                                                                item.status_id === 0 && <i className="fa-solid fa-xmark status deactive"></i>
                                                            }

                                                            {
                                                                item.status_id === 1 && <i className="fa-solid fa-check status active"></i>
                                                            }

                                                            {
                                                                item.status_id === 2 && <i className="fa-regular fa-trash status deactive"></i>
                                                            }
                                                        </td>

                                                        <td>
                                                            <Link className="edit" to={detailLink(item.user_id)}>
                                                                <i className="fa-regular fa-eye"></i>
                                                            </Link>
                                                        </td>

                                                        <td className={`desc-col${hasIcon ? " has-icn" : ""}`}>
                                                            {
                                                                hasIcon && typeof urlParamsData.user_type !== "undefined" && urlParamsData.user_type !== "0" && (
                                                                    <div className="icn-holder-c1">
                                                                        {
                                                                            pageData.index_setting.icon.map((icon, i) => {
                                                                                const className = {};

                                                                                if (icon.class)
                                                                                    className.className = icon.class;

                                                                                return (
                                                                                    <Link
                                                                                        key={`${i}-icon-${i}`}
                                                                                        to={`${generateIconLink(item, icon.url)}?user_type=${urlParamsData.user_type}`}
                                                                                        title={icon.title}
                                                                                        {...className}
                                                                                    >
                                                                                        <i className={icon.font_icon} />
                                                                                    </Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            }

                                                            <div className={`content${hasIcon ? " grow" : ""}`}>
                                                                <p className="tp-25">
                                                                    {
                                                                        item.name || item.last_name ? (
                                                                            `${item.name || ""} ${item.last_name || ""}`
                                                                        ) : "بدون عنوان"
                                                                    }
                                                                </p>

                                                                <h6 className="tp-26">
                                                                    {
                                                                        labels.join(" | ")
                                                                    }
                                                                </h6>

                                                                {
                                                                    pageData.index_setting.recursive && <Link to={`?parent_id=${item.id}`} className="pos-cover"></Link>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : <Skeleton />
                                    }
                                </tbody>
                            </table>
                        ) : (
                            <></>
                        )
                    }

                    {
                        !isLoading && data && !data.data?.length && (
                            <p className="font-medium text-sm text-center mb-10 text-slate-800 dark:text-gray-300">
                                اطلاعاتی برای نمایش یافت نشد
                            </p>
                        )
                    }

                    {
                        error && <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    }
                </div>

                {
                    isLoading && (
                        <BottomSkeleton />
                    )
                }

                {
                    data && (
                        <div className="flex justify-between items-center">
                            <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                {getPagesCount(data.count, urlParams.limit)}
                            </span>

                            {
                                !!data.data?.length && (
                                    <Pagination
                                        count={data.count}
                                        limit={urlParamsData.limit}
                                        offset={urlParamsData.offset}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </div>

            <PriorityModal
                data={priorityData}
                setData={setPriorityData}
                submitUrl={pageData.set_url}
                reload={mutate}
            />
        </>
    )
}

export default memo(List);