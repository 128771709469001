import { memo, useCallback, useState } from "react";
import Edit from "./edit-modal";
import { CSSTransition } from "react-transition-group";

const Routes = props => {
    const {
        item,
        expandedCats,
        setExpandsCats,
        selectedItems,
        setSelectedItems,
        mode
    } = props;

    const [editModalData, setEditModalData] = useState({ show: false });

    const closeModal = useCallback(() => {
        setEditModalData(prev => ({ ...prev, show: false }));
    }, [])

    const toggleCat = (key, id) => {
        setExpandsCats(prev => {
            const newState = { ...prev };

            if (newState[key].indexOf(id) !== -1) {
                const newIntendedCat = newState[key].filter(item => item !== id);
                newState[key] = newIntendedCat;
            } else
                newState[key] = [...newState[key], id];

            return newState;
        });
    }

    return (
        <>
            <div className="item">
                <div className="row">
                    <div className="title-col">
                        {
                            item.childs.length ? (
                                <button
                                    type="button"
                                    className={`exp-btn ${expandedCats["routes"].indexOf(item.id) !== -1 ? "minus" : "plus"}`}
                                    onClick={() => toggleCat("routes", item.id)}
                                />
                            ) : <span className="hide-btn"></span>
                        }

                        <input
                            type="checkbox"
                            checked={selectedItems["routes"].indexOf(item.id) !== -1}
                            onChange={e => {
                                setSelectedItems(prev => {
                                    const newState = { ...prev };

                                    if (e.target.checked)
                                        newState["routes"] = [...newState["routes"], item.id];
                                    else
                                        newState["routes"] = newState["routes"].filter(el => el !== item.id);

                                    return newState;
                                });
                            }}
                        />

                        <div className="flex">
                            {
                                !!item.title && (
                                    <span className="ml-2">{item.title} - </span>
                                )
                            }

                            <span className="ml-2">
                                <div className="dir-ltr">{item.path}</div>
                            </span>

                            <button onClick={() => setEditModalData({ show: true, id: item.id })}> - ویرایش</button>
                        </div>
                    </div>

                    <div className="checked-col">
                        <input
                            type="checkbox"
                            checked={selectedItems["routes"].indexOf(item.id) !== -1}
                            onChange={e => {
                                setSelectedItems(prev => {
                                    const newState = { ...prev };

                                    if (e.target.checked)
                                        newState["routes"] = [...newState["routes"], item.id];
                                    else
                                        newState["routes"] = newState["routes"].filter(el => el !== item.id);

                                    return newState;
                                });
                            }}
                        />
                    </div>
                </div>

                {
                    item.childs.length > 0 && (
                        <CSSTransition
                            in={expandedCats["routes"].indexOf(item.id) !== -1}
                            unmountOnExit={true}
                            timeout={{ enter: 300, exit: 0 }}
                        >
                            <div className="childs anim-c8">
                                {
                                    item.childs.map(el => <Routes {...props} item={el} key={`${"routes"}-${item.id}-${el.id}`} />)
                                }
                            </div>
                        </CSSTransition>
                    )
                }
            </div>

            <Edit data={editModalData} close={closeModal} mode={mode} />
        </>
    )
}

export default memo(Routes);