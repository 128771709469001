import { memo } from "react";

const Selectbox = props => {
    const {
        options,
        label,
        changeHandler,
        value,
        id
    } = props;

    return (
        <label className="select-c1">
            <span>{label}</span>

            <select onChange={e => changeHandler(e.target.value)} value={value || ""} id={id}>
                <option value="0">لطفا انتخاب نمایید</option>
                
                {
                    options.map((option, i) => (
                        <option key={`option-${i}`} value={option.value}>{option.title}</option>
                    ))
                }
            </select>
        </label>
    )
}

export default memo(Selectbox);