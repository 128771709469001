import Breadcrumb from "components/shared/breadcrumb";
import UseFetch from "hooks/useFetch";
import { memo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import Skeleton from "./skeleton";

const StatusViewer = ({ status }) => {
    if (status === 1)
        return <i className="fa-solid fa-square-check text-green-400 text-lg" title="فعال"></i>

    return <i className="fa-solid fa-square-xmark text-red-600 text-lg" title="غیرفعال"></i>
}

const UserProfileDetail = () => {
    const [searchParams] = useSearchParams();
    const form_id = searchParams.get("form_id");
    const profile_id = searchParams.get("profile_id");

    const { isLoading, error, data } = UseFetch(`/api/identity/user/profile/users?form_id=${form_id}&profile_id=${profile_id}`);
    const [primaryUser, setPrimaryUser] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const submitHandler = async () => {
        if (submitLoading || primaryUser === null)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/identity/user/profile/users/set_primary",
                data: {
                    form_id: parseInt(form_id),
                    profile_id,
                    user_id: primaryUser
                },
                payloadType: 3,
                callback: () => setSubmitLoading(false),
                method: "PUT"
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات پروفایل تامین کننده</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "تامین کنندگان", link: "/panel/identity_provider" },
                        { title: "جزئیات پروفایل تامین کننده" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/identity_provider" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3 ${primaryUser === null ? "opacity-50 cursor-default" : "hover:bg-primary-200 cursor-pointer"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            {
                !isLoading ? (
                    !error ? (
                        <>
                            <div className="grid md:grid-cols-2 gap-4 mb-4">
                                <div className="wrap-box tp-22 page-transition flex items-center text-sm text-slate-800 dark:text-slate-200">
                                    <div className="w-14 h-14 min-w-[3.5rem] rounded-full bg-sky-100 text-slate-600 fit-center text-xl ml-3 dark:bg-sky-900 dark:text-sky-300">
                                        <i className="fa-solid fa-bags-shopping"></i>
                                    </div>

                                    <span className="ml-2">نوع تامین کننده :</span>
                                    <b className="font-medium">{data.data.form.title}</b>
                                </div>

                                <div className="wrap-box tp-22 page-transition flex items-center text-sm text-slate-800 dark:text-slate-200">
                                    <div className="w-14 h-14 min-w-[3.5rem] rounded-full bg-sky-100 text-slate-600 fit-center text-xl ml-3 dark:bg-sky-900 dark:text-sky-300">
                                        <i className="fa-solid fa-bags-shopping"></i>
                                    </div>

                                    <span className="ml-2">پروفایل تامین کننده :</span>
                                    <Link to={`/panel/sellers/${data.data.profile.id}?form_id=${data.data.form.id}`} className="font-medium text-blue-500 dark:text-blue-300">{data.data.profile.virtual_title}</Link>
                                </div>
                            </div>

                            <div className="wrap-box no-p tp-22 overflow-x-auto">
                                {
                                    data.data.users?.length ? (
                                        <div className="min-w-[500px] p-4">
                                            <div className="table-c1">
                                                <table>
                                                    <thead>
                                                        <tr className="spec-thead-c1 tp-25">
                                                            <th>کاربر اصلی</th>
                                                            <th>شناسه</th>
                                                            <th>نام</th>
                                                            <th>نام خانوادگی</th>
                                                            <th>شماره همراه</th>
                                                            <th>ایمیل</th>
                                                            <th>وضعیت</th>
                                                            <th>وضعیت اتصال به پروفایل</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="tp-25 text-center text-sm">
                                                        {
                                                            data.data.users.map(user => (
                                                                <tr key={`user-row-${user.id}`}>
                                                                    <td>
                                                                        <div className="radio-c1">
                                                                            <input
                                                                                type="radio"
                                                                                name="primary-user"
                                                                                checked={primaryUser ? primaryUser === user.id : user.profile.is_primary}
                                                                                onChange={() => setPrimaryUser(user.id)}
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <Link to={`/panel/provider-detail/${user.id}`} className="font-bold text-blue-800 dark:text-blue-300">
                                                                            {user.id}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.last_name}</td>
                                                                    <td>{user.mobile}</td>
                                                                    <td>{user.email}</td>
                                                                    <td><StatusViewer status={user.status_id} /></td>
                                                                    <td><StatusViewer status={user.profile.status_id} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : <p className="global-error p-4">موردی برای نمایش یافت نشد !</p>
                                }
                            </div>
                        </>
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="global-error">خطا در دریافت اطلاعات !</p>
                        </div>
                    )
                ) : <Skeleton />
            }
        </div >
    )
}

export default memo(UserProfileDetail);