import { memo, useEffect, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import UseFetch from "hooks/useFetch";
import Skeleton from "components/skeleton/table-2";
import Pagination from "components/shared/pagination";
import { Link, createSearchParams, useLocation, useSearchParams } from "react-router-dom";

const List = () => {
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const [searchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        const newParams = createSearchParams(searchParams);
        newParams.set("user_kind_id", 1);

        setFetchUrl(`/api/basket/current/list?${newParams.toString()}`);
        // eslint-disable-next-line
    }, [location.search])

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">سبد کاربران</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "سبد کاربران" }
                    ]} />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            data.data?.length ? (
                                <div className="table-c1 mt-10 mb-4">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="w-5"></th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                data.data.map((item, i) => {
                                                    const title = `${item.profile.name || ""} ${item.profile.last_name || ""}`;

                                                    return (
                                                        <tr key={`table-row-${i}`}>
                                                            <td>
                                                                <Link to={`${item.user_id}`} className="edit">
                                                                    <i className="fa-regular fa-eye"></i>
                                                                </Link>
                                                            </td>

                                                            <td className="desc-col">
                                                                <div className="content">
                                                                    <p className="tp-25">
                                                                        {
                                                                            title.length > 1 ? title : item.profile.mobile
                                                                        }
                                                                    </p>

                                                                    <h6 className="tp-26">
                                                                        شناسه کاربر: {item.user_id}
                                                                    </h6>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>

                                    <div className="flex justify-between items-center mt-4">
                                        <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                            {data.count} مورد - {Math.ceil(data.count / (parseInt(searchParams.get("limit")) || 10))} صفحه
                                        </span>

                                        <Pagination
                                            count={data.count}
                                            limit={parseInt(searchParams.get("limit"))}
                                            offset={parseInt(searchParams.get("offset"))}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p className="text-sm text-red-600 dark:text-red-400 flex items-center justify-center py-4">
                                    <i className="fa-regular fa-clipboard-list ml-2 text-lg relative top-px"></i>
                                    موردی برای نمایش یافت نشد
                                </p>
                            )
                        ) : <p className="text-sm text-red-500 text-center py-4">خطا در دریافت اطلاعات</p>
                    ) : <Skeleton />
                }
            </div>
        </div>
    )
}

export default memo(List);