import { useEffect, useState } from "react";

const useAutoRequest = (active, url, callback, delay = 5000) => {
    const [timeHolder, setTimeHolder] = useState(0);
    const [intervalState, setIntervalState] = useState(null);

    const stopInterval = () => {
        if (intervalState) {
            clearInterval(intervalState);
            setIntervalState(null);
            setTimeHolder(0);
        }
    }

    useEffect(() => {
        return () => {
            stopInterval();
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (active) {
            if (intervalState)
                clearInterval(intervalState);

            const newInterval = setInterval(() => {
                setTimeHolder(prev => prev + 1);
            }, delay);

            setIntervalState(newInterval);
        } else
            stopInterval();

        // eslint-disable-next-line
    }, [active])

    useEffect(() => {
        if (timeHolder)
            callback();

        // eslint-disable-next-line
    }, [timeHolder])

    useEffect(() => {
        if (active && timeHolder) {
            clearInterval(intervalState);

            const newInterval = setInterval(() => {
                setTimeHolder(prev => prev + 1);
            }, delay);

            setIntervalState(newInterval);
        }

        // eslint-disable-next-line
    }, [url])
}

export default useAutoRequest;