import { productFormId } from "constants/config";
import { useState, memo, useRef } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const Input = () => {
    // const [showResultBox, setShowResultBox] = useState(false);
    // useOutsideClick(wrapperRef, () => setShowResultBox(false));

    const [searchParams] = useSearchParams();
    const [inputVal, setInputVal] = useState(searchParams.get("key") || "");
    const wrapperRef = useRef(null);
    const navigate = useNavigate();

    const changeHandler = e => {
        setInputVal(e.target.value);
    }

    const submitHandler = e => {
        e.preventDefault();

        const newSearchParams = createSearchParams();
        newSearchParams.set("form_id", productFormId);

        if (inputVal.length)
            newSearchParams.set("key", inputVal);

        navigate(`/panel/content?${newSearchParams.toString()}`);
    }

    return (
        <form className="grow flex items-stretch h-12 lg:h-auto" ref={wrapperRef} onSubmit={submitHandler}>
            <div className="grow tp-2 border lg:rounded-tr-lg lg:rounded-br-lg overflow-hidden flex items-stretch h-full" ref={wrapperRef}>
                <input
                    type="text"
                    className="grow text-sm px-4 tp-5 tp-1"
                    placeholder="جستجو نمایید ..."
                    value={inputVal}
                    onChange={changeHandler}
                />
            </div>

            <button type="submit" className={`go-btn text-sm tp-10`}>
                <i className="fa-regular fa-magnifying-glass"></i>
            </button>

            {/* <CSSTransition timeout={{ enter: 200, exit: 0 }} unmountOnExit={true} in={visibleResultBox && showResultBox}>
                <div className="result-box absolute right-0 w-full border tp-2 tp-1 rounded-md anim-c1">
                    <b className="font-medium text-sm border-b py-4 px-4 tp-2 block tp-11">نتایج یافت شده برای "{inputVal}"</b>

                    <div className="p-4 max-h-36 overflow-y-auto spec-sb">
                        <span className="font-bold text-red-500 text-sm">نتیجه ای یافت نشد</span>
                    </div>
                </div>
            </CSSTransition> */}
        </form>
    )
}

export default memo(Input);