import MapTile from "../mapTile";
import { MapContainer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useCallback, useContext, useEffect } from "react";
import { ReducerContext } from "../reducerProvider";
import style from "../selectLocation.module.scss";
import LocationBtn from "../locationBtn";

const Inner = () => {
    const { state, dispatch } = useContext(ReducerContext);

    useEffect(() => {
        if (state.flyTo.length) {
            dispatch({
                type: "SET_CENTER_POINT",
                payload: state.flyTo
            })

            map.flyTo([state.flyTo[0], state.flyTo[1]], 20, {
                animate: false
            });

            dispatch({
                type: "SET_FLY_TO",
                payload: []
            })
        }
        // eslint-disable-next-line
    }, [state.flyTo])

    const map = useMapEvents({
        movestart: () => {
            dispatch({
                type: "SET_POINT_INFO",
                payload: {
                    pending: true,
                    error: false,
                    data: {}
                }
            });
        },
        moveend: () => {
            const { lat, lng } = map.getCenter();
            dispatch({
                type: "SET_CENTER_POINT",
                payload: [lat, lng]
            });
        }
    })

    return <></>
}

const Map = () => {
    const { state, dispatch } = useContext(ReducerContext);

    const flyToCallback = useCallback((point) => {
        dispatch({
            type: "SET_FLY_TO",
            payload: point
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MapContainer
                center={state.centerPoint}
                zoom={16}
                className="h-full z-10"
                zoomControl={false}
            >
                <MapTile />
                <Inner />
            </MapContainer>

            <div className={style.marker} />
            <LocationBtn flyTo={flyToCallback} />
        </>
    )
}

export default Map;