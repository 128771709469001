import { memo } from "react"
import Skeleton from "./skeleton";

const Addresses = props => {
    const { loading, data } = props;

    if (loading)
        return <Skeleton />;

    return (
        <div className="wrap-box tp-22 page-transition">
            <h2 className="tp-34 text-sm font-bold pb-4">آدرس های کاربر</h2>

            {
                !data.error ? (
                    data.data.length ? (
                        <div className="max-h-[15rem] overflow-auto spec-sb-c2">
                            {
                                data.data.map(address => (
                                    <div className="detail-block" key={`address-${address.id}`}>
                                        <div className="flex mb-3">
                                            <b className="title">
                                                عنوان:
                                            </b>

                                            <div className="value">
                                                {address.name}
                                            </div>
                                        </div>

                                        <div className="flex mb-4">
                                            <b className="title">
                                                آدرس:
                                            </b>

                                            <div className="value">
                                                <a
                                                    href={`https://www.google.com/maps/search/?api=1&query=${address.coordinates[1]},${address.coordinates[0]}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i className="fa-regular fa-location-dot ml-1 text-primary-100 text-lg"></i>
                                                </a>

                                                {address.address}
                                            </div>
                                        </div>

                                        <div className="flex items-center">
                                            <b className="title">
                                                جزئیات:
                                            </b>

                                            <div className="grow">
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                    <div className="flex bg-gray-50 dark:bg-slate-900 p-2 rounded-md col-span-2 md:col-span-1">
                                                        <div className="title auto-w ml-2">پلاک:</div>
                                                        <div className="value">{address.number}</div>
                                                    </div>

                                                    <div className="flex bg-gray-50 dark:bg-slate-900 p-2 rounded-md">
                                                        <div className="title auto-w ml-2">واحد:</div>
                                                        <div className="value">{address.unit}</div>
                                                    </div>

                                                    <div className="flex bg-gray-50 dark:bg-slate-900 p-2 rounded-md">
                                                        <div className="title auto-w ml-2">کد پستی:</div>
                                                        <div className="value">{address.postal_code}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ) : <p className="global-error">آدرسی برای این کاربر ثبت نشده است</p>
                ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
            }
        </div>
    )
}

export default memo(Addresses);