import { memo, useState } from "react"
import Editor from "./editor";
import HtmlEditor from "./html-editor";

const SwitchInput = props => {
    const { field, changeHandler } = props;
    const [inputType, setInputType] = useState(3);

    return (
        <>
            <div className="flex justify-end">
                <button
                    className="text-gray-400 hover:text-gray-500 dark:text-slate-300 dark:hover:text-slate-200 transition-colors text-xs mb-3 ml-3"
                    onClick={() => setInputType(prev => prev === 3 ? 22 : 3)}
                    type="button"
                >
                    <i className="fa-light fa-arrows-rotate ml-2"></i>
                    تغییر نوع فیلد
                </button>
            </div>

            {
                inputType === 3 ? (
                    <HtmlEditor
                        value={field.value}
                        label={field.caption}
                        changeHandler={changeHandler}
                    />
                ) : (
                    <Editor
                        changeHandler={changeHandler}
                        value={field.value}
                        label={field.caption}
                    />
                )
            }
        </>
    )
}

export default memo(SwitchInput);