import React, { createContext, useReducer } from "react";

const initialState = {
    showModal: false,
    defaultValue: {},
    flyTo: [],
    centerPoint: [35.699810167174974, 51.338186230163295],
    pointInfo: {
        pending: true,
        data: {},
        error: false
    },
    cachedPoints: [],
    searchAddress: {
        showReslultBox: false,
        pending: false,
        data: [],
        error: false
    },
    showDetailForm: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SHOW_STATUS':
            return {
                ...state,
                showModal: action.payload
            }
        case 'SET_CENTER_POINT':
            return {
                ...state,
                centerPoint: action.payload
            }
        case 'SET_POINT_INFO':
            return {
                ...state,
                pointInfo: action.payload
            }
        case 'SET_CACHED_POINTS':
            return {
                ...state,
                cachedPoints: action.payload
            }
        case 'SET_SEARCH_ADDRESS':
            return {
                ...state,
                searchAddress: action.payload
            }
        case 'SET_SHOW_RESULTS_BOX':
            return {
                ...state,
                searchAddress: {
                    ...state.searchAddress,
                    showReslultBox: action.payload
                }
            }
        case 'SET_FLY_TO':
            return {
                ...state,
                flyTo: action.payload
            }
        case 'SET_SHOW_DETAIL_FORM':
            return {
                ...state,
                showDetailForm: action.payload
            }
        case 'CLOSE_MODALS':
            return {
                ...state,
                showModal: false,
                searchAddress: {
                    ...state.searchAddress,
                    showReslultBox: false
                },
                showDetailForm: false
            }
        case 'SET_DEFAULT_VALUE':
            return {
                ...state,
                defaultValue: action.payload
            }

        default:
            throw new Error();
    }
}

const ReducerProvider = props => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ReducerContext.Provider value={{ state, dispatch }}>
            {children}
        </ReducerContext.Provider>
    )
}

export default ReducerProvider;
export const ReducerContext = createContext({
    dispatch: () => { },
    state: initialState
});