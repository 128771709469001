const TransactionsStatus = [
    {
        title: "پرداخت نشده",
        icon: "fa-solid fa-xmark text-red-500"
    },
    {
        title: "پرداخت موفق",
        icon: "fa-solid fa-check text-green-500"
    }
];

export default TransactionsStatus;