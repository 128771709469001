import Breadcrumb from "components/shared/breadcrumb";
import { memo } from "react";
import { Link } from "react-router-dom";
import templateFetcherHook from "service/template-fetcher-hook";

const RoutesList = props => {
    const { template } = props;
    const { data: pageData } = templateFetcherHook(template);

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="table-c1">
                    <table>
                        <thead>
                            <tr>
                                <th className="w-5"></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                pageData.routes.map((route, i) => (
                                    <tr key={`route-item-${i}`}>
                                        <td>
                                            <i className={`${route.icon?.length > 0 ? route.icon : "fa-regular fa-page"} icn-holder-c2`} />
                                        </td>

                                        <td className="desc-col">
                                            <div className="content">
                                                <p className="tp-25">
                                                    {route.title}
                                                </p>

                                                {
                                                    route.description?.length > 0 && (
                                                        <h6 className="tp-26">
                                                            {route.description}
                                                        </h6>
                                                    )
                                                }

                                                <Link to={route.link} className="pos-cover" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default memo(RoutesList);