import Breadcrumb from "components/shared/breadcrumb";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Skeleton from "./skeleton";
import SwitchButton from "components/shared/switchButton";
import InputText from "components/shared/inputs/input-text";
import MasterAPI from "service/master";
import { toast } from "react-toastify";

const UploadExtensions = () => {
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [extensions, setExtensions] = useState([]);
    const [localLoading, setLocalLoading] = useState(true);
    const [failedFields, setFailedFields] = useState({});
    const [deletedIds, setDeletedIds] = useState([]);

    const { isLoading, error, data } = UseFetch("/api/setting/upload/content_type/all");
    useEffect(() => {
        if (!isLoading && !error) {
            setExtensions(data.data || []);
            setLocalLoading(false);
        }
    }, [isLoading, error, data])

    const addHandler = () => {
        if (isLoading || error)
            return;

        setExtensions(prev => [
            ...prev,
            {
                content_type: null,
                extension_0: "",
                extension_1: "",
                extension_2: "",
                status_id: 1
            }
        ]);

        setSubmitEnabled(true);
    }

    const removeHandler = index => {
        const intendedSize = extensions[index];
        if (intendedSize.id)
            setDeletedIds(prev => [...prev, intendedSize.id]);

        setExtensions(prev => prev.filter((_, i) => i !== index));
        setSubmitEnabled(true);
    }

    const statusChangeHandler = index => {
        setExtensions(prev => {
            const newState = [...prev];
            newState[index]["status_id"] = newState[index]["status_id"] === 1 ? 0 : 1;

            return newState;
        });

        setSubmitEnabled(true);
    }

    const contentTypeChangeHandler = (e, index) => {
        setExtensions(prev => {
            const newState = [...prev];
            newState[index].content_type = e.target.value.length ? e.target.value : null;

            return newState;
        });

        setSubmitEnabled(true);
    }

    const extensionChangeHandler = (e, index, extensionIndex) => {
        const newVal = e.target.value;
        const regex = /^[a-zA-Z0-9۰۱۲۳۴۵۶۷۸۹]+$/;

        if (regex.test(newVal) || newVal === "") {
            setExtensions(prev => {
                const newState = [...prev];
                newState[index][extensionIndex] = newVal;

                return newState;
            });

            setSubmitEnabled(true);
        }
    }

    const submitHandler = async () => {
        if (submitLoading || error || localLoading || !submitEnabled)
            return;

        const newFailedFields = {};
        extensions.forEach((extension, i) => {
            const thisErrors = [];

            if (!extension.content_type?.length > 0)
                thisErrors.push("content_type");

            if (extension.extension_0.length === 0)
                thisErrors.push("extension_item");

            if (thisErrors.length > 0)
                newFailedFields[i] = thisErrors;
        })

        setFailedFields(newFailedFields);
        if (Object.keys(newFailedFields).length === 0) {
            setSubmitLoading(true);

            try {
                const res = await MasterAPI({
                    url: "/api/setting/upload/content_type/edit_multi",
                    data: {
                        deleted_ids: deletedIds,
                        content_types: extensions
                    },
                    callback: () => setSubmitLoading(false),
                    payloadType: 3
                });

                toast.success(res.message);
                setSubmitEnabled(false);
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">انواع فرمت قابل آپلود</h1>

                    <Breadcrumb data={[
                        { title: "تنظیمات فروشگاه" },
                        { title: "تنظیمات سرور", link: "/panel/server-settings" },
                        { title: "انواع فرمت قابل آپلود" }
                    ]} />
                </div>

                <div className="flex">
                    <button
                        className="w-10 h-10 text-white bg-primary-100 hover:bg-primary-200 rounded-md fit-center"
                        title="افزودن سایز"
                        onClick={addHandler}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>

                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 mr-3 relative overflow-hidden ${!submitEnabled ? "opacity-50 cursor-default" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition unmountOnExit={true} in={submitLoading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            {
                !localLoading ? (
                    !error ? (
                        extensions.length ? (
                            extensions.map((extension, i) => (
                                <div
                                    className={`wrap-box tp-22 page-transition flex flex-col items-stretch md:flex-row md:items-center mb-3 crop-size-block ${failedFields[i] ? "has-error" : ""}`}
                                    key={`upload-extension-${i}`}
                                >
                                    <div className="ml-3 mb-8 md:mb-0 fit-center w-full md:w-12 h-12 min-w-[3rem] rounded-md md:rounded-full bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 font-bold text-lg">
                                        {i + 1}
                                    </div>

                                    <div className="grow grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ml-3 gap-3">
                                        <div>
                                            <InputText
                                                label="نوع محتوا"
                                                id={`upload-extension-${i}-content-type`}
                                                value={extension.content_type || ""}
                                                changeHandler={e => contentTypeChangeHandler(e, i)}
                                                masterClass="ltr-input w-full"
                                            />

                                            {
                                                failedFields[i] && failedFields[i]?.indexOf("content_type") !== -1 ? <p className="local-error">نوع محتوا باید پر شود</p> : <></>
                                            }
                                        </div>

                                        {
                                            ["اول", "دوم", "سوم"].map((keyStr, j) => {
                                                const id = `upload-extension-${i}-extension-${j}`;
                                                const thisKey = `extension_${j}`;
                                                const previousKeyHasValue = extension[`extension_${j - 1}`]?.length > 0;

                                                return (
                                                    <div key={id}>
                                                        <InputText
                                                            label={`پسوند ${keyStr}`}
                                                            id={id}
                                                            value={extension[thisKey] || ""}
                                                            changeHandler={e => extensionChangeHandler(e, i, thisKey)}
                                                            masterClass="ltr-input w-full"
                                                            disabled={j === 0 ? false : !previousKeyHasValue}
                                                        />

                                                        {
                                                            j === 0 && failedFields[i] && failedFields[i]?.indexOf("content_type") !== -1 ? <p className="local-error">حداقل یک پسوند باید وارد شود</p> : <></>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="flex justify-between mt-3 md:mt-0">
                                        <div className="flex items-center ml-3">
                                            <SwitchButton
                                                checked={extension.status_id === 1}
                                                changeHandler={() => statusChangeHandler(i)}
                                                id={`upload-extension-${i}-status`}
                                            />

                                            <label
                                                className="tp-6 text-sm font-medium mr-2 cursor-pointer noselect"
                                                htmlFor={`upload-extension-${i}-status`}
                                            >
                                                وضعیت
                                            </label>
                                        </div>

                                        <button
                                            className="bg-red-600 hover:bg-red-700 transition-colors rounded-md text-white text-sm px-3 py-1"
                                            onClick={() => removeHandler(i)}
                                        >
                                            حذف
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="text-sm text-red-600 dark:text-red-400">
                                    <i className="fa-regular fa-clipboard-list ml-2 text-lg relative top-px"></i>
                                    موردی برای نمایش یافت نشد
                                </p>
                            </div>
                        )
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="text-sm text-red-600 dark:text-red-400">خطا در دریافت اطلاعات !</p>
                        </div>
                    )
                ) : <Skeleton />
            }
        </div>
    )
}

export default memo(UploadExtensions);