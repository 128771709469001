import InitializeContext from "context/initialize-context";
import { useContext, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";

const EnumerationLocation = props => {
    const { field, changeHandler, isSingle = false } = props;

    const {
        value,
        caption
    } = field;

    const enums = useContext(InitializeContext);
    const [expandedNodes, setExpandedNodes] = useState([]);

    const nodes = useMemo(() => {
        try {
            const recursiveFunc = list => {
                return list.map(el => ({
                    value: el.id,
                    label: el.title,
                    ...(el.children?.length && { children: recursiveFunc(el.children) })
                }))
            }

            return recursiveFunc(enums.data.enumerationLocationInheritance[0].children);
        } catch {
            return [];
        }
    }, [])

    const selectedTitles = useMemo(() => {
        try {
            const numericValues = value.map(el => parseInt(el));
            return enums.data.enumerationLocations.filter(el => numericValues.indexOf(el.id) !== -1).map(el => el.title);
        } catch {
            return [];
        }

    }, [value])

    const localChangeHandler = val => {
        if (isSingle) {
            const newValue = val.find(el => value.indexOf(el) === -1);
            changeHandler([newValue]);
        } else
            changeHandler(val);
    }

    return (
        <>
            <label className="text-sm tp-27 mb-2 font-bold block" htmlFor={props.id}>
                {caption}
            </label>

            {
                selectedTitles.length > 0 && (
                    <ul className="flex mb-2 fade-in mt-4">
                        {
                            selectedTitles.map((el, i) => (
                                <li className="text-sm bg-slate-100 dark:bg-slate-800 dark:text-slate-300 rounded-md font-medium text-slate-500 ml-2 mb-2 px-2 py-1" key={`selected-loc-${i}`}>
                                    {el}
                                </li>
                            ))
                        }
                    </ul>
                )
            }

            <div className="tree-holder">
                <CheckboxTree
                    nodes={nodes}
                    checked={value}
                    onCheck={localChangeHandler}
                    expanded={expandedNodes}
                    onExpand={val => setExpandedNodes(val)}
                    noCascade={true}
                    showNodeIcon={false}
                    icons={{
                        uncheck: <i className="fa-regular fa-square text-lg text-gray-400" />,
                        check: <i className="fa-solid fa-square-check text-lg text-primary-100"></i>,
                        expandClose: <i className="fa-regular fa-angle-left text-lg text-gray-300" />,
                        expandOpen: <i className="fa-regular fa-angle-up text-lg text-gray-300" />
                    }}
                />
            </div>
        </>
    )
}

export default EnumerationLocation;