import { memo, useCallback, useState } from "react";
import EditModal from "./edit-modal";
import { CSSTransition } from "react-transition-group";

const Forms = props => {
    const {
        mode,
        item,
        expandedCats,
        setExpandsCats,
        selectedItems,
        setSelectedItems,
        editMode = false
    } = props;

    const [editModalData, setEditModalData] = useState({ show: false });
    const closeModal = useCallback(() => {
        setEditModalData(prev => ({ ...prev, show: false }));
    }, [])

    const toggleCat = (key, id) => {
        setExpandsCats(prev => {
            const newState = { ...prev };

            if (newState[key].indexOf(id) !== -1) {
                const newIntendedCat = newState[key].filter(item => item !== id);
                newState[key] = newIntendedCat;
            } else
                newState[key] = [...newState[key], id];

            return newState;
        });
    }

    return (
        <>
            <div className="item">
                <div className="row">
                    <div className="title-col">
                        {
                            item.childs.length ? (
                                <button
                                    type="button"
                                    className={`exp-btn ${expandedCats["forms"].indexOf(item.id) !== -1 ? "minus" : "plus"}`}
                                    onClick={() => toggleCat("forms", item.id)}
                                />
                            ) : <span className="hide-btn"></span>
                        }

                        <input
                            type="checkbox"
                            checked={!!selectedItems["forms"][item.id] && selectedItems["forms"][item.id]?.length === 3}
                            onChange={e => {
                                setSelectedItems(prev => {
                                    const newState = { ...prev };

                                    if (e.target.checked)
                                        newState["forms"][item.id] = [1, 2, 3];
                                    else
                                        delete newState["forms"][item.id];

                                    return newState;
                                });
                            }}
                        />

                        <div className="flex">
                            <span className="ml-2">
                                {item.title}
                            </span>

                            {
                                editMode && <button onClick={() => setEditModalData({ show: true, id: item.id })}> - ویرایش</button>
                            }
                        </div>
                    </div>

                    {
                        [3, 2, 1].map(i => (
                            <div className="checked-col" key={`${item.id}-${i}`}>
                                <input
                                    type="checkbox"
                                    checked={!!selectedItems["forms"][item.id] && selectedItems["forms"][item.id].indexOf(i) !== -1}
                                    onChange={e => {
                                        setSelectedItems(prev => {
                                            const newState = { ...prev };

                                            if (e.target.checked) {
                                                let oldVal = newState["forms"][item.id] || [];
                                                newState["forms"][item.id] = [...oldVal, i];
                                            } else
                                                newState["forms"][item.id] = newState["forms"][item.id].filter(el => el !== i);

                                            return newState;
                                        });
                                    }}
                                />
                            </div>
                        ))
                    }
                </div>

                {
                    item.childs.length > 0 && (
                        <CSSTransition
                            in={expandedCats["forms"].indexOf(item.id) !== -1}
                            unmountOnExit={true}
                            timeout={{ enter: 300, exit: 0 }}
                        >
                            <div className="childs anim-c8">
                                {
                                    item.childs.map(el => <Forms {...props} item={el} key={`${"forms"}-${item.id}-${el.id}`} />)
                                }
                            </div>
                        </CSSTransition>
                    )
                }
            </div>

            <EditModal
                data={editModalData}
                closeHandler={closeModal}
                mode={mode}
            />
        </>
    )
}

export default memo(Forms);