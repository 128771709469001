import { memo, useId } from "react";

const JsonField = props => {
    const {
        label,
        changeHandler,
        value
    } = props;

    const id = useId();
    const addHandler = () => {
        changeHandler([
            ...value,
            { key: "", value: "" }
        ])
    }

    const removeHandler = id => {
        const newList = value.filter((el, i) => i !== id); // eslint-disable-line
        changeHandler(newList);
    }

    const fieldChangeHandler = (index, type, val) => {
        const newList = [...value];
        newList[index][type] = val;

        changeHandler(newList);
    }

    return (
        <div className="cat-holder">
            <span className="label tp-27">{label}</span>

            <ul className="json-field py-4">
                <li>
                    <div className="text-field has-val">
                        <label>کلید</label>

                        <input
                            type="text"
                            disabled={true}
                        />
                    </div>

                    <div className="text-field has-val">
                        <label>مقدار</label>

                        <input
                            type="text"
                            disabled={true}
                        />
                    </div>

                    <button type="button" className="add" onClick={addHandler}>
                        <i className="fa-regular fa-plus"></i>
                    </button>
                </li>

                {
                    value.map((row, i) => (
                        <li key={`json-field-${i}`}>
                            <div className={`text-field${row.key.length ? " has-val" : ""}`}>
                                <label htmlFor={`jf-${id}-${i}-key`}>کلید</label>

                                <input
                                    id={`jf-${id}-${i}-key`}
                                    type="text"
                                    value={row.key}
                                    onChange={e => fieldChangeHandler(i, "key", e.target.value)}
                                />
                            </div>

                            <div className={`text-field${row.value.length ? " has-val" : ""}`}>
                                <label htmlFor={`jf-${id}-${i}-value`}>مقدار</label>

                                <input
                                    id={`jf-${id}-${i}-value`}
                                    type="text"
                                    value={row.value}
                                    onChange={e => fieldChangeHandler(i, "value", e.target.value)}
                                />
                            </div>

                            <button type="button" className="del" onClick={() => removeHandler(i)}>
                                <i className="fa-regular fa-minus"></i>
                            </button>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default memo(JsonField);