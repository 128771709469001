import { memo } from "react";

const SmartLogo = () => (
    <>
        <div className="flex dark:hidden">
            <img src="/assets/image/logo/2-mini.png" alt="سریرمارکت" />
            <img src="/assets/image/logo/2.png" alt="سریرمارکت" />
        </div>

        <div className="hidden dark:flex">
            <img src="/assets/image/logo/1-mini.png" alt="سریرمارکت" />
            <img src="/assets/image/logo/1.png" alt="سریرمارکت" />
        </div>
    </>
)

export default memo(SmartLogo);