import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const ChartBox = ({ selectBox = true, twoBlocks = false }) => (
    <div className="wrap-box tp-22 page-transition">
        <div className="pb-4 mb-2">
            <Skeleton className="w-28 h-4 rounded-lg" />
        </div>

        {
            selectBox && <Skeleton className="h-11 rounded-full mb-5" />
        }

        {
            twoBlocks && (
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <Skeleton className="h-12 rounded-md" />
                    <Skeleton className="h-12 rounded-md" />
                </div>
            )
        }

        <Skeleton className="h-72 rounded-md" />
        <div className="flex justify-center mt-3">
            <Skeleton className="w-20 h-4 rounded-full ml-2" />
            <Skeleton className="w-20 h-4 rounded-full" />
        </div>
    </div>
)

export default memo(ChartBox);