import { memo } from "react";
import List from "./list/list";
import View from "./view/view";
import { useParams } from "react-router-dom";
import templateFetcherHook from "service/template-fetcher-hook";

const Tokens = props => {
    const { template } = props;
    const { data: pageData } = templateFetcherHook(template);
    const { id } = useParams();

    return (
        <div className="m-container">
            {
                id
                    ? <View pageData={pageData} />
                    : <List pageData={pageData} />
            }
        </div>
    )
}

export default memo(Tokens);