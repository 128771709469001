import { memo, useRef, useState, useEffect } from "react"
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import MasterAPI from "service/master";
import { MEDIA, SERVICE_1 } from "service/routes";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCropSizes } from "redux/slices/theme";
import Loading from "components/shared/loading";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import { nowTimestamp } from "utils";

const CropModal = props => {
    const { data } = props;

    const dispatch = useDispatch();
    const cropSizes = useSelector(state => state.theme.cropSizes);

    useEffect(() => {
        if (!cropSizes.data?.length)
            dispatch(getCropSizes());

        // eslint-disable-next-line
    }, [])

    return (
        !cropSizes.pending ? (
            !cropSizes.error ? (
                <CropBox cropSizeList={cropSizes.data} data={data} />
            ) : <p className="text-red-500 text-sm font-medium overflow-hidden pt-3 pb-4">خطا در یافت سایزهای برش تصویر !</p>
        ) : <div className="fit-center"><Loading className="loading-c5" /></div>
    )
}

const CropBox = props => {
    const { data, cropSizeList } = props;
    const cropImgRef = useRef(null);
    const ufRef = useRef(null);

    const [cropValue, setCropValue] = useState(cropSizeList[0]);
    const firstCropSize = cropSizeList[0].split("x");
    const [cropAspect, setCropAspect] = useState(parseInt(firstCropSize[0]) / parseInt(firstCropSize[1]));
    const [uploadLoadings, setUploadLoadings] = useState({});
    const accessToken = useSelector(state => state.main.accessToken);

    const [crop, setCrop] = useState({
        width: parseInt(firstCropSize[0]),
        height: parseInt(firstCropSize[1]),
        unit: 'px',
        aspect: parseInt(firstCropSize[0]) / parseInt(firstCropSize[1]),
    })

    useEffect(() => {
        if (cropImgRef)
            changeCropHandler(cropSizeList[0]);

        // eslint-disable-next-line
    }, [cropImgRef])

    const changeCropHandler = value => {
        setCropValue(value);

        if (value === "orginal") {
            setCropAspect(false);

            setCrop({
                width: 100,
                height: 100,
                aspect: 1,
                unit: "%"
            });
        } else {
            const splitedValue = value.split("x");
            const selectedWidth = parseInt(splitedValue[0]);
            const selectedHeight = parseInt(splitedValue[1]);

            const currentWidth = cropImgRef.current.clientWidth;
            const currentHeight = cropImgRef.current.clientHeight;

            const a = makeAspectCrop(
                {
                    unit: 'px',
                    width: selectedWidth,
                    height: selectedHeight
                },
                selectedWidth / selectedHeight,
                currentWidth,
                currentHeight
            );
            setCropAspect(selectedWidth / selectedHeight);

            setCrop({
                width: a.width,
                height: a.height,
                x: 0,
                y: 0,
                unit: 'px'
            })
        }
    }

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            parseInt(firstCropSize[0]) / parseInt(firstCropSize[1]),
            width,
            height
        )

        setCrop(crop)
    }

    const submitHandler = async () => {
        const image = data.image.replaceAll(MEDIA, "");

        try {
            const res = await MasterAPI({
                payloadType: 3,
                url: "/api/upload/crop",
                method: "PUT",
                data: {
                    image_name: image,
                    size: cropValue === "orginal" ? "org" : cropValue,
                    width: Math.floor(crop.width),
                    height: Math.floor(crop.height),
                    x: Math.ceil(crop.x),
                    y: Math.ceil(crop.y),
                    relative_width: cropImgRef.current.clientWidth,
                    relative_height: cropImgRef.current.clientHeight
                }
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const uploadHandler = async e => {
        const localCropValue = cropValue === "orginal" ? "org" : cropValue;

        if (!e.target.files?.length || uploadLoadings[cropValue])
            return;

        const file = e.target.files[0];
        let imageName = data.image.split("/public/");
        imageName = `/public/${imageName[1].replaceAll("org", localCropValue)}`;

        const formData = new FormData();
        formData.append("image_name", imageName);
        formData.append("size", localCropValue);
        formData.append("file", file);

        try {
            const res = await axios({
                method: "POST",
                url: `${SERVICE_1}/api/upload/replace`,
                data: formData,
                headers: {
                    Authorization: accessToken
                },
                onUploadProgress: progressEvent => {
                    let percentComplete = progressEvent.loaded / progressEvent.total;
                    percentComplete = parseInt(percentComplete * 100);

                    setUploadLoadings(prev => {
                        const newState = { ...prev };
                        newState[cropValue] = percentComplete;

                        return newState
                    });
                }
            });

            toast.success(res.data.message);
        } catch (e) {
            ufRef.current.value = "";
            toast.error(e.response?.data?.message || "خطای سیستم !");
        }

        setUploadLoadings(prev => {
            const newState = { ...prev };
            delete newState[cropValue];

            return newState
        });
    }

    return (
        <>
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                    {
                        cropSizeList.map((size, i) => (
                            <div className="radio-c1" key={`crop-size-${i}`}>
                                <input
                                    checked={cropValue === size}
                                    type="radio"
                                    name={`size-radio`}
                                    value={size}
                                    onChange={e => changeCropHandler(e.target.value)}
                                />
                                <label>{size}</label>
                            </div>
                        ))
                    }

                    <div className="radio-c1">
                        <input
                            type="radio"
                            name={`size-radio`}
                            value={"orginal"}
                            checked={cropValue === "orginal"}
                            onChange={e => changeCropHandler(e.target.value)}
                        />
                        <label>اورجینال</label>
                    </div>
                </div>

                <div>
                    <ReactCrop
                        crop={crop}
                        aspect={cropAspect}
                        onChange={c => {
                            setCrop(c);
                        }}
                    >
                        <img src={data.image} alt="" ref={cropImgRef} onLoad={onImageLoad} />
                    </ReactCrop>
                </div>

                <div className="col-span-2 flex justify-between">
                    <button className="block bg-gray-200 mt-4 px-10 rounded-md" onClick={submitHandler}>ادامه</button>

                    <a
                        href={`${cropValue !== "orginal" ? data.image.replaceAll("org", cropValue) : data.image}?ut=${nowTimestamp()}`}
                        className="block bg-gray-200 mt-4 p-3 rounded-md"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-solid fa-up-right-from-square"></i>
                    </a>
                </div>
            </div>

            <div className="rounded-md flex items-center p-4 relative">
                <input
                    type="file"
                    className="hidden"
                    id={`uf-${cropValue}`}
                    onChange={uploadHandler}
                    ref={ufRef}
                />

                <i className="fa-duotone fa-files text-xl ml-2 text-primary-100 dark:text-blue-300"></i>
                <span className="text-sm text-gray-800 dark:text-slate-300">آپلود تصویر {cropValue}</span>

                {
                    !uploadLoadings[cropValue] && <label className="pos-cover cursor-pointer border border-gray-200 dark:border-slate-700" htmlFor={`uf-${cropValue}`} />
                }

                <CSSTransition in={!!uploadLoadings[cropValue]} timeout={300} unmountOnExit={true}>
                    <div className="pos-cover bg-white dark:bg-secondary flex items-center dir-ltr anim-c8">
                        <div className="rounded-full bg-gray-200 dark:bg-slate-700 overflow-hidden h-4 grow dir-ltr">
                            <div className="bg-primary-100 rounded-full h-full" style={{ width: `${uploadLoadings[cropValue]}%` }} />
                        </div>

                        <span className="text-gray-500 dark:text-slate-300 text-sm w-10">{uploadLoadings[cropValue]}%</span>
                    </div>
                </CSSTransition>
            </div>
        </>
    )
}

export default memo(CropModal);