import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FormGenerator from "components/form-generator/main/main";
import { CSSTransition } from "react-transition-group";

const Add = props => {
    const {
        pageData
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState();
    const [goSubmit, setGoSubmit] = useState(0);
    const urlParams = new URLSearchParams(location.search);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    const handlerBack = () => {
        navigate(getPreviousRoute(), { replace: true });
    }

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const setParentLoading = useCallback(val => {
        setSubmitLoading(val);
        // eslint-disable-next-line
    }, [])

    const doneCallback = useCallback(() => {
        handlerBack();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (pageData)
            setBreadcrumb([
                ...pageData.breadcrumb,
                {
                    title: "افزودن دسته جدید"
                }
            ]);

        // eslint-disable-next-line
    }, [pageData])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">افزودن دسته جدید</h1>
                    {
                        breadcrumb ? (
                            <Breadcrumb data={breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-square-plus ml-2"></i>
                        افزودن

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <FormGenerator
                    mode="add"
                    submitUrl={pageData.add_url}
                    fields={pageData.edit_setting}
                    goSubmit={goSubmit}
                    setParentLoading={setParentLoading}
                    doneCallback={doneCallback}
                    preData={{ parent_id: parseInt(urlParams.get("parent_id")) }}
                />
            </div>
        </>
    )
}

export default memo(Add);