import Modal from "components/shared/modal";
import { memo, useCallback, useEffect, useId, useState } from "react";
import DatePicker from "components/form-generator/fields/date-picker";
import moment from "jalali-moment";
import { convertShamsiToUnix } from "utils";
import SwitchButton from "components/shared/switchButton";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { useParams } from "react-router-dom";

const ReserveModal = props => {
    const formId = useId();
    const { id } = useParams();

    const { data, closeHandler, mutate } = props;
    const [reserved, setReserved] = useState(false);
    const [reservedTime, setReservedTime] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        setReservedTime(data.data.reserve_until ? moment.unix(data.data.reserve_until).format("jYYYY/jMM/jDD HH:mm:ss") : null);
        setReserved(data.data.reserved);
    }, [data])

    const setReservedTimeCallback = useCallback(val => {
        if (!val || val?.length === 0)
            setReservedTime(null);

        setReservedTime(val);
    }, [])

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/financial/balance/edit",
                method: "PUT",
                payloadType: 3,
                data: {
                    id: parseInt(id),
                    reserved,
                    reserve_until: convertShamsiToUnix(reservedTime, true)
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
            closeHandler();
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal show={data.show} closeHandler={closeHandler} title="ویرایش رزرو">
            <form onSubmit={submitHandler}>
                <div className="grid grid-cols-1 gap-4 p-2">
                    <DatePicker
                        label="تاریخ رزرو"
                        value={reservedTime}
                        changeHandler={setReservedTimeCallback}
                        name={`${formId}_reserveTime`}
                        id={`${formId}_reserveTime`}
                    />

                    <div className="flex items-center">
                        <SwitchButton
                            id={`${formId}_reserved`}
                            checked={reserved}
                            changeHandler={() => setReserved(prev => !prev)}
                        />

                        <label htmlFor={`${formId}_reserved`} className="mr-2 text-slate-800 dark:text-slate-300 text-sm noselect cursor-pointer">رزرو</label>
                    </div>

                    <button
                        type="submit"
                        className="bg-primary-100 w-full text-white rounded-md text-sm py-3 hover:bg-primary-200 transition-colors relative overflow-hidden"
                    >
                        ثبت

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </form>
        </Modal>
    )
}

export default memo(ReserveModal);