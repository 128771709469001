import MasterAPI from "service/master";

const getPointInfoController = async (state, dispatch, point) => {
    dispatch({
        type: "SET_POINT_INFO",
        payload: {
            pending: true,
            error: false,
            data: {}
        }
    });

    try {
        let response = {};

        const cachedPointsState = state.cachedPoints;
        const findInCache = cachedPointsState.find((cacheItem) => cacheItem.point[0] === point[0] && cacheItem.point[1] === point[1]);

        if (findInCache)
            response = findInCache;
        else {
            const res = await MasterAPI({
                url: `/api/location_service/coordination_search?lat=${point[0]}&lng=${point[1]}`,
                method: "GET",
                sendAuthorizationHeader: false,
                sendProfileIdHeader: false
            });

            response = {
                point,
                data: res.data
            };

            const existsInCache = state.cachedPoints.find(cacheItem => cacheItem.point[0] === point[0] && cacheItem.point[1] === point[1]);
            if (!existsInCache) {
                const newCache = [response, ...state.cachedPoints];

                dispatch({
                    type: "SET_CACHED_POINTS",
                    payload: newCache.slice(0, 50)
                });
            }
        }

        dispatch({
            type: "SET_POINT_INFO",
            payload: {
                pending: false,
                error: false,
                data: response
            }
        });
    } catch {
        dispatch({
            type: "SET_POINT_INFO",
            payload: {
                pending: false,
                error: true,
                data: {}
            }
        });
    }
}

export default getPointInfoController;