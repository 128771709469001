import { convertShamsiToUnix, isObject } from "utils";

const jsonFieldConverter = value => {
    const output = {};

    value.forEach(el => {
        if (output[el.key])
            return;

        output[el.key] = el.value;
    })

    return output;
}

const fileUploadConverter = (value, dataType) => {
    if (dataType === 6) {
        const newVal = value.map(file => file.toLowerCase());
        return newVal;
    }

    return value.join(";").toLowerCase();
}

const jsonTextConverter = value => {
    try {
        const parsedData = JSON.parse(value);
        if (isObject(parsedData))
            return parsedData;
        else
            throw Object.assign(new Error());
    } catch {
        return {};
    }
}

const timeConverter = (val, time = false) => {
    if (!val || val?.length === 0)
        return null;

    return convertShamsiToUnix(val, time);
}

const contentsLinkConverter = val => {
    try {
        const newVal = [];

        val.forEach(item => {
            if (item.id.indexOf("-") === -1)
                newVal.push(item.id);
        });

        return newVal;
    } catch {
        return [];
    }
}

const validArrayType = (value, dataType) => {
    if (dataType === 6) {
        if (Array.isArray(value))
            return value;
        else
            return value ? [parseInt(value)] : [];
    }

    if (dataType === 1) {
        if (value === "0")
            return null;
    }

    return value;
}

const addressConverter = (value) => {
    if (Object.keys(value).length) {
        return {
            latitude: value.coordinates[0],
            longitude: value.coordinates[1],
            detail: {
                address: value.address,
                number: value.number,
                unit: value.unit,
                postal_code: value.postal_code
            }
        }
    }

    return null;
}

const sendTimeConverter = (inputType, value, dataType) => {
    if (dataType === 2)
        return parseInt(value);
    else if (dataType === 3)
        return parseFloat(value);

    switch (inputType) {
        case 1:
            return value || "";
        case 2:
            return value || "";
        case 5:
            return validArrayType(value, dataType);
        case 7:
            return validArrayType(value, dataType);
        case 8:
            return validArrayType(value, dataType);
        case 9:
        case 10:
            return fileUploadConverter(value, dataType);
        case 13:
            return timeConverter(value);
        case 14:
            return timeConverter(value, true);
        case 16:
            return jsonFieldConverter(value);
        case 19:
            return jsonTextConverter(value);
        case 17:
            if (dataType === 6)
                return value !== null ? [value] : [];
            else
                return value || 0;
        case 18:
            return value?.length ? value : [];
        case 20:
            return dataType === 2 ? parseInt(value) : value;
        case 25:
            return contentsLinkConverter(value);
        case 27:
            return contentsLinkConverter(value);
        case 28:
            return addressConverter(value);
        case 31:
        case 32:
            return value.map(el => parseInt(el))
        default:
            return value;
    }
}

export default sendTimeConverter;