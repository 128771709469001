import FieldMapper from "components/form-generator/fields/mapper";
import React, { useState, useEffect, useCallback, memo, useContext, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import readTimeConverter from "./converter/readTime";
import sendTimeConverter from "./converter/sendTime";
import validator from "../validator";
import EnumsContext from "context/initialize-context";
import { useId } from "react";
import ErrorMessage from "../error-msg";
import CheckboxTree from "react-checkbox-tree";
import { userKind } from "constants/config";

const FormHOC = ({ submitHandler, subMode, children }) => {
    if (subMode)
        return children;

    return (
        <form onSubmit={submitHandler}>{children}</form>
    )
}

const MainGenerator = props => {
    const {
        mode,
        id,
        submitUrl,
        submitMethod = "POST",
        payloadType = 3,
        fields: orgFields,
        defaultValues,
        goSubmit,
        setParentLoading = () => { },
        doneCallback = () => { },
        preData = {},
        categoryModule = false,
        categories,
        categoriesFields,
        defaultSelectedCategories,
        targetCategory,
        compareWith,
        subMode = false,
        activeIds = null,
        formId = null
    } = props;

    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [failedFields, setFailedFields] = useState({});
    const location = useLocation();
    const enumsData = useContext(EnumsContext);
    const formUniqueId = useId();
    const [searchParams] = useSearchParams();

    const [checkedCategories, setCheckedCategories] = useState(defaultSelectedCategories);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [newCategories, setNewCategories] = useState([]);
    const [masterCat, setMasterCat] = useState(targetCategory);

    useEffect(() => {
        setMasterCat(targetCategory || "");
    }, [targetCategory])

    useEffect(() => {
        if (orgFields) {
            const newFields = {};

            orgFields.forEach(field => {
                if ((!field.show_only || field.show_only === mode) && (!field.access_denied_user_kinds || field.access_denied_user_kinds.indexOf(userKind) === -1)) {
                    if (field.input_type === 20) // querystring type
                    {
                        const queryStringParams = new URLSearchParams(location.search);
                        const defaultValue = queryStringParams.get(field.name);

                        if (defaultValue)
                            newFields[field.name] = {
                                ...field,
                                value: readTimeConverter(field.input_type, defaultValue, field.data_type)
                            }
                    } else {
                        let defaultValue = defaultValues ? defaultValues[field.name] : null;
                        if (defaultValue == null && field.default_value) {
                            if (field.default_value.startsWith(":")) {
                                const intendedSearchParam = searchParams.get(field.default_value.replace(":", ""));
                                if (intendedSearchParam)
                                    defaultValue = intendedSearchParam;
                            } else
                                defaultValue = field.default_value;
                        }

                        let compareError = false;
                        if (typeof defaultValue !== "object" || defaultValue === null)
                            compareError = compareWith ? defaultValue !== compareWith[field.name] : false;

                        defaultValue = readTimeConverter(field.input_type, defaultValue, field.data_type);
                        
                        newFields[field.name] = {
                            ...field,
                            value: defaultValue,
                            htmlId: `${formUniqueId}-${field.name}`,
                            compareError
                        }
                    }
                }
            })

            setFields(prev => ({ ...prev, ...newFields }));
        }
        // eslint-disable-next-line
    }, [defaultValues, orgFields, compareWith])

    useEffect(() => {
        if (goSubmit)
            submitHandler();

        // eslint-disable-next-line
    }, [goSubmit])

    useEffect(() => {
        validator(fields, setFailedFields, enumsData.data.formValidations);
        // eslint-disable-next-line
    }, [fields, enumsData])

    useEffect(() => {
        setCheckedCategories(defaultSelectedCategories);
    }, [defaultSelectedCategories])

    useEffect(() => {
        if (categoryModule) {
            let newFields = {};
            const newCategoriesHolder = [];

            checkedCategories.forEach(cat => {
                const catId = parseInt(cat);
                newCategoriesHolder.push(catId);

                const catFields = {};
                categoriesFields.forEach(field => {
                    if (field.category_id === catId && (field.form_id === null || field.form_id === formId)) {
                        if (!field.access_denied_user_kinds || field.access_denied_user_kinds.indexOf(userKind) === -1) {
                            const fieldName = `e${field.id}_${field.name}`;
                            catFields[fieldName] = {
                                ...field,
                                name: fieldName,
                                htmlId: `${formUniqueId}-${fieldName}`,
                                extendedField: true
                            };

                            if (defaultValues && defaultValues[fieldName])
                                catFields[fieldName].value = defaultValues[fieldName];

                            if (fields[fieldName]?.value)
                                catFields[fieldName].value = fields[fieldName]?.value;

                            catFields[fieldName].value = readTimeConverter(field.input_type, catFields[fieldName].value || null, field.data_type);
                        }
                    }
                })

                if (Object.keys(catFields).length) {
                    const catTitle = enumsData.data.categories.find(el => el.id === catId);

                    newFields[`section-title-${catId}`] = {
                        input_type: 21,
                        caption: catTitle?.title || "",
                        extendedField: true,
                        category_id: catId
                    };
                    newFields = { ...newFields, ...catFields };
                }
            })

            setFields(prev => {
                const removedUnselectCatsList = {};
                Object.keys(prev).forEach(key => {
                    if (!prev[key].extendedField || checkedCategories.indexOf(prev[key].category_id) !== -1)
                        removedUnselectCatsList[key] = prev[key];
                })

                return { ...removedUnselectCatsList, ...newFields }
            });

            setNewCategories(newCategoriesHolder);
        }
        // eslint-disable-next-line
    }, [checkedCategories, categories, categoriesFields])

    const selectedCategoriesList = useMemo(() => {
        const output = [];

        checkedCategories?.forEach(cat => {
            const findedCat = enumsData.data.categories.find(el => el.id === parseInt(cat));

            if (findedCat)
                output.push({ id: findedCat.id, title: findedCat.title });
        })

        return output;
        // eslint-disable-next-line
    }, [checkedCategories]);

    const setLoadingCallback = val => {
        setLoading(val);
        setParentLoading(val);
    }

    const changeHandler = useCallback((name, value) => {
        setFields(prev => {
            const newState = { ...prev, };
            newState[name] = { ...prev[name], value, changed: true };
            return newState;
        });
        // eslint-disable-next-line
    }, [])

    const submitHandler = async () => {
        if (loading)
            return;

        if (categoryModule && checkedCategories.length && masterCat === "") {
            toast.error("لطفا دسته بندی اصلی را انتخاب نمایید");
            return;
        }

        let filteredFields = fields;
        if (activeIds?.length) {
            filteredFields = {};
            Object.keys(fields).forEach(fieldKey => {
                if (activeIds.indexOf(fields[fieldKey].id) !== -1)
                    filteredFields[fieldKey] = fields[fieldKey];
            })
        }

        const validate = validator(filteredFields, setFailedFields, enumsData.data.formValidations, true);
        if (validate) {
            setLoadingCallback(true);
            const data = {
                ...preData
            };

            Object.keys(fields).forEach(fieldKey => {
                const field = fields[fieldKey];
                if (field.input_type === 21) return;
                if (activeIds && activeIds.indexOf(field.id) === -1) return;

                data[fieldKey] = sendTimeConverter(field.input_type, field.value, field.data_type);
            })

            if (id) data.id = isNaN(id) ? id : parseInt(id);
            if (categoryModule) {
                data.categories = newCategories
                data.target_category = checkedCategories.length ? masterCat : null;
            };

            try {
                const res = await MasterAPI({
                    method: submitMethod,
                    url: submitUrl,
                    payloadType,
                    data,
                    callback: () => setLoadingCallback(false)
                });

                toast.success(res.message);
                doneCallback(res);
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    return (
        <div className={`grid gap-3 ${categoryModule ? "grid-cols-12" : ""}`}>
            <div className={`${categoryModule ? "col-span-full md:col-span-7 lg:col-span-8" : "col-span-12"}`}>
                <FormHOC submitHandler={submitHandler} subMode={subMode}>
                    <div className="grid grid-cols-2 gap-4 form-base">
                        {
                            Object.keys(fields).map(fieldKey => {
                                let field = fields[fieldKey];
                                const halfRow = field.masterclass && field.masterclass.indexOf("half-row") !== -1;

                                if (field.enumeration_id && !isNaN(field.enumeration_id)) {
                                    const options = [];
                                    enumsData.data.formEnumerations.forEach(el => {
                                        if (el.parent_id === field.enumeration_id)
                                            options.push({ title: el.title, value: el.id });
                                    })

                                    field = { ...field, options };
                                }

                                if (activeIds && activeIds.indexOf(field.id) === -1)
                                    return <React.Fragment key={`form-fields-${fieldKey}`} />

                                return (
                                    <div key={`form-fields-${fieldKey}`} className={`field${!halfRow ? " col-span-2" : ""}${field.compareError ? " compare-error" : ""}`}>
                                        <FieldMapper
                                            field={field}
                                            changeHandler={changeHandler}
                                            targetCategory={masterCat}
                                        />

                                        {
                                            !!failedFields[field.name] && <span className="f-error">{ErrorMessage(failedFields[field.name])}</span>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </FormHOC>
            </div>

            {
                categoryModule && (
                    <div className={`tree-holder col-span-full md:col-span-5 lg:col-span-4 relative ${activeIds !== null ? "hidden" : ""}`}>
                        <CheckboxTree
                            nodes={categories}
                            direction="ltr"
                            icons={{
                                expandClose: <i className="fa-solid fa-plus" />,
                                expandOpen: <i className="fa-solid fa-minus" />
                            }}
                            checked={checkedCategories}
                            expanded={expandedCategories}
                            onCheck={value => {
                                setMasterCat("");
                                setCheckedCategories(value)
                            }}
                            onExpand={value => setExpandedCategories(value)}
                            checkModel={"all"}
                            noCascade={true}
                        />

                        {
                            selectedCategoriesList.length > 0 && (
                                <div className="pr-3 mt-5">
                                    <h3 className="text-gray-500 text-sm mb-2">دسته بندی اصلی</h3>

                                    {
                                        selectedCategoriesList.map(cat => (
                                            <div className="radio-c1 inline-block ml-5" key={`master-cat-${cat.id}`}>
                                                <input
                                                    type="radio"
                                                    name="master-category"
                                                    value={cat.id}
                                                    id={`master-category-${cat.id}`}
                                                    checked={masterCat === cat.id}
                                                    onChange={() => setMasterCat(cat.id)}
                                                />

                                                <label htmlFor={`master-category-${cat.id}`}>{cat.title}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default memo(MainGenerator);