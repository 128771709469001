import { Link } from "react-router-dom";

const Comment = () => {
    return (
        <button className="tp-7 relative mr-3" title="نظرات">
            <Link to="/panel/comment" className="w-full h-full flex items-center justify-center">
                <i className="fa-regular fa-message"></i>
            </Link>
        </button>
    )
}

export default Comment;