import { memo } from "react";
import { CSSTransition } from "react-transition-group";

const Lightbox = ({ show, clickHandler }) => {
    return (
        <CSSTransition in={show} timeout={{ enter: 0, exit: 200 }} unmountOnExit={true}>
            <div className="lightbox" onClick={clickHandler}></div>
        </CSSTransition>
    )
}

export default memo(Lightbox);