import Modal from "components/shared/modal";
import ticketStatuses from "constants/ticket-statuses";
import { memo, useCallback, useState } from "react"
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const ChangeStatus = props => {
    const { mode, statusId: defaultStatusId, mutate } = props;
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [statusId, setStatusId] = useState(defaultStatusId);
    const [showModal, setShowModal] = useState(false);

    const closeModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const submitHandler = async () => {
        if (loading)
            return;

        setLoading(true);
        try {
            const res = await MasterAPI({
                url: `/api/ticket/${mode}/change_status`,
                method: "PUT",
                payloadType: 3,
                data: {
                    id,
                    status_id: parseInt(statusId)
                },
                callback: () => setLoading(false)
            });

            toast.success(res.message);
            mutate();
            closeModal();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <button
                type="button"
                className="bg-primary-100 hover:bg-primary-200 transition-colors rounded-md text-xs text-white px-2 py-1 mr-3"
                onClick={() => setShowModal(true)}
            >
                تغییر وضعیت
            </button>

            <Modal show={showModal} closeHandler={closeModal} title="تغییر وضعیت تیکت">
                <div className="py-3">
                    <label className="select-c1" htmlFor="new-ticket-status">
                        <span>وضعیت</span>

                        <select onChange={e => setStatusId(e.target.value)} value={statusId} id="new-ticket-status">
                            {
                                ticketStatuses.map((status, i) => (
                                    <option key={`ticket-status-${i}`} value={i}>{status.title}</option>
                                ))
                            }
                        </select>
                    </label>

                    <button
                        type="button"
                        className="bg-primary-100 text-white rounded-full text-sm transition-colors px-10 py-3 mt-3 hover:bg-primary-200 relative overflow-hidden w-full"
                        onClick={submitHandler}
                    >
                        <CSSTransition unmountOnExit={true} in={loading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>

                        ثبت
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default memo(ChangeStatus);