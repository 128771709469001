import { memo } from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ data }) => {
    return (
        <ul className="breadcrumb">
            {
                data.map((item, i) => {
                    const attrs = {};
                    if((i + 1) === data.length)
                        attrs.className = "active";

                    return (
                        <li key={`breadcrumb-${i}`}>
                            {
                                item.link?.length ? (
                                    <Link to={item.link} {...attrs}>{item.title}</Link>
                                ) : (
                                    <span {...attrs}>{item.title}</span>
                                )
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default memo(Breadcrumb);