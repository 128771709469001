import { memo, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "hooks/useOutsideClick";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import SubForm from "./subForm";
import { getValueFromObjByKey, nowTimestamp, urlDecoder } from "utils";
import EnumsContext from "context/initialize-context";
import { productRoute } from "constants/config";
import { createSearchParams, useSearchParams } from "react-router-dom";

const FormContentsLink = props => {
    const {
        label,
        changeHandler,
        linkFormId,
        value,
        setting,
        targetCategory
    } = props;

    const [searchParams] = useSearchParams();
    const id = useId();
    const [inputValue, setInputValue] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [timeoutState, setTimeoutState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const enumsData = useContext(EnumsContext);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setShowResults(false));

    useEffect(() => {
        return () => clearTimeout(timeoutState);
        // eslint-disable-next-line
    }, [])

    const intendedForm = useMemo(() => {
        const output = enumsData.data.forms.find(form => form.id === linkFormId);
        return output || {};
        // eslint-disable-next-line
    }, [])

    const inputChangeHandler = e => {
        const value = e.target.value;

        setInputValue(value);
        setShowResults(true);
        setLoading(true);

        if (timeoutState)
            clearTimeout(timeoutState);

        const newTimeoutState = setTimeout(() => {
            fetcher(value);
        }, 2000);

        setTimeoutState(newTimeoutState);
        setShowResults(true);
    }

    const onFocusHandler = e => {
        const value = e.target.value;
        setShowResults(true);

        if (results.length === 0) {
            setLoading(true);
            fetcher(value);
        }
    }

    const onBlurHandler = e => {
        // const value = e.target.value;

        // if (value.length === 0)
        //     setShowResults(false);
    }

    const fetcher = async text => {
        try {
            const baseUrl = `/api/content/list`;
            const reqParams = createSearchParams(`form_id=${linkFormId}&key=${text}${setting?.extra_query || ""}`);
            reqParams.set("show_content_link", true);

            for (const [key, value] of reqParams.entries()) {
                if (value.startsWith(":")) {
                    const thisKeyValue = searchParams.get(key);
                    if (thisKeyValue)
                        reqParams.set(key, thisKeyValue);
                    else
                        reqParams.delete(key);
                } else if (value.startsWith("*")) {
                    if (value === "*")
                        reqParams.set(key, targetCategory);
                    else {
                        const intendedTargetCat = enumsData.data.categories.find(el => el.id === targetCategory);

                        if (intendedTargetCat) {
                            const intendedValue = getValueFromObjByKey(intendedTargetCat, value.replace("*.", ""));

                            if (intendedValue)
                                reqParams.set(key, intendedValue);
                            else
                                reqParams.delete(key);
                        } else
                            reqParams.delete(key);
                    }
                }
            }

            const res = await MasterAPI({
                url: urlDecoder(`${baseUrl}?${reqParams.toString()}`),
                method: "GET",
                callback: () => setLoading(false)
            });

            if (res.data?.length) {
                console.log(res.data);
                
                setResults(res.data);
            } else
                setResults([]);
        } catch (e) {
            toast.error(e.message);
            setResults([]);
        }
    }

    const itemClickHandler = item => {
        const itemSelected = value.some(el => el.id === item.id);
        if (!itemSelected) {
            setInputValue("");
            setShowResults(false);
            console.log(item);

            changeHandler([...value, { ...item }]);
        } else
            toast.error("این لینک قبلا انتخاب شده است");
    }

    const updateSubForm = (currentId, newData) => {
        const intendedIndex = value.findIndex(sb => sb.id === currentId);
        if (intendedIndex !== -1) {
            const newValue = value.map(val => {
                if (val.id === currentId)
                    return newData;
                else
                    return val;
            })

            changeHandler(newValue);
        }
    }

    const changeSorthandler = (currentIndex, type) => {
        const newIndex = type === "goUp" ? currentIndex - 1 : currentIndex + 1;
        if (!value[newIndex])
            return;

        const holder = value[newIndex];
        value[newIndex] = value[currentIndex];
        value[currentIndex] = holder;

        changeHandler(value);
    }

    const deleteHandler = id => {
        changeHandler(value.filter(item => item.id !== id));
    }

    const addSubFormHandler = e => {
        e.preventDefault();
        changeHandler([...value, { id: `${nowTimestamp()}-${Math.floor(Math.random() * 1000)}` }]);
    }

    return (
        <div className="relative" ref={wrapperRef}>
            <div className={`text-field flex${inputValue.length ? " has-val" : ""}`}>
                <label htmlFor={id}>{label}</label>

                <input
                    type="text"
                    id={id}
                    value={inputValue}
                    onChange={inputChangeHandler}
                    onFocus={onFocusHandler}
                    autoComplete="off"
                    onBlur={onBlurHandler}
                />

                <button type="button" className="bg-primary-100 text-white mr-3 rounded-md px-4 hover:bg-primary-200 transition-colors" onClick={addSubFormHandler}>
                    <i className="fa-regular fa-plus relative top-px"></i>
                </button>
            </div>

            <CSSTransition in={showResults} timeout={{ enter: 300, exit: 0 }} unmountOnExit={true}>
                <div className="cl-result-box">
                    <div className="box">
                        {
                            !loading ? (
                                results.length ? (
                                    <ul>
                                        {
                                            results.map(item => {
                                                return (
                                                    <li
                                                        key={`link-${id}-${item.id}`}
                                                        onClick={() => itemClickHandler(item)}
                                                        className="ls-block"
                                                    >
                                                        {item.virtual_title}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : <span className="lcr-error text-red-600">نتیجه ای یافت نشد</span>
                            ) : <span className="lcr-error text-gray-400">درحال دریافت اطلاعات ...</span>
                        }
                    </div>
                </div>
            </CSSTransition>

            {
                value.map((subFormData, i) => {
                    const productBaseUrl = intendedForm.setting?.url || productRoute;
                    let productUrl = null;
                    if (subFormData.group_id)
                        productUrl = `${productBaseUrl}/${subFormData.group_id}`;

                    return (
                        <div className="flex flex-nowrap items-start my-2" key={`s-link-${id}-${subFormData.id}`}>
                            <div className="grow">
                                <SubForm
                                    data={subFormData}
                                    formId={linkFormId}
                                    removeCallback={() => deleteHandler(subFormData.id)}
                                    updateSubForm={updateSubForm}
                                    productUrl={productUrl}
                                />
                            </div>

                            <div>
                                <button
                                    type="button"
                                    onClick={() => changeSorthandler(i, "goUp")}
                                    className="ml-1 mr-3 h-11 border rounded-md text-gray-600 dark:text-gray-200 border-gray-200 dark:border-slate-700 bg-gray-50 dark:bg-slate-800 text-sm px-4"
                                >
                                    <i class="fa-regular fa-chevron-up"></i>
                                </button>

                                <button
                                    type="button"
                                    onClick={() => changeSorthandler(i, "goDown")}
                                    className="h-11 border rounded-md text-gray-600 dark:text-gray-200 border-gray-200 dark:border-slate-700 bg-gray-50 dark:bg-slate-800 text-sm px-4"
                                >
                                    <i class="fa-regular fa-chevron-down"></i>
                                </button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default memo(FormContentsLink);