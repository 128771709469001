import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo } from "react";
import { useLocation, Link } from "react-router-dom";
import { unixToShamsi } from "utils/convert-utils";
import UseFetch from "hooks/useFetch";
import useExtendData from "./use-extend-data";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb,
        mode
    } = props;

    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);
    const extendData = useExtendData(mode, isLoading, error, data);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات پیام</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <div className="grid grid-cols-1 gap-4 text-sm">
                                <div className="text-left text-sm mb-3 text-slate-900 dark:text-slate-200">
                                    {data.data?.created_at ? unixToShamsi(data.data?.created_at) : ""}
                                </div>

                                <div>
                                    <b className="block font-medium text-slate-900 dark:text-slate-200">موضوع</b>
                                    <span className="text-slate-800 dark:text-slate-300">{data.data?.subject}</span>
                                </div>

                                <div>
                                    <b className="block font-medium text-slate-900 dark:text-slate-200">عنوان</b>
                                    <span className="text-slate-800 dark:text-slate-300">{data.data?.subject}</span>
                                </div>

                                <div>
                                    <b className="block font-medium text-slate-900 dark:text-slate-200">{mode === "user" ? "کاربر" : "تامین کننده"}</b>
                                    <span className="text-slate-800 dark:text-slate-300">
                                        <ShowExtendUserLabel value={extendData.userData} link={`/panel/${mode}-detail/${data.data.receiver_id}`} />
                                    </span>
                                </div>

                                <div>
                                    <b className="block font-medium text-slate-900 dark:text-slate-200">مشاهده شده</b>
                                    <i className={`fa-solid ml-2 ${data.data?.seen ? "fa-eye text-green-500" : "fa-eye-slash text-red-500"}`} />
                                    <span className="text-slate-800 dark:text-slate-300">{data.data?.seen ? "بله" : "خیر"}</span>
                                </div>

                                <div>
                                    <b className="block font-medium text-slate-900 dark:text-slate-200">متن</b>
                                    <span className="text-slate-800 dark:text-slate-300">{data.data?.text}</span>
                                </div>
                            </div>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >
        </>
    )
}

export default memo(Edit);