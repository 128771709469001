import { memo } from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "./sidemenu/sidemenu";
import { useSelector } from "react-redux";

const Content = () => {
    const menuFadeLg = useSelector(state => state.theme.menuFadeLg);

    return (
        <>
            <SideMenu />

            <main>
                <div className={`p-content${menuFadeLg ? " lg-action" : ""}`}>
                    <Outlet />
                </div>
            </main>
        </>
    )
}

export default memo(Content);