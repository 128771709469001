import { memo, useState, useEffect } from "react"
import { Link, useLocation, useSearchParams } from "react-router-dom";
import UseFetch from "hooks/useFetch";
import Breadcrumb from "components/shared/breadcrumb";
import Searchbox from "./searchbox";
import SelectOptions from "./select-options";
import Skeleton from "components/skeleton/table-2";
import Checkbox from "components/shared/inputs/checkbox";
import convertUtils from "utils/convert-utils";
import Pagination from "components/shared/pagination";
import FixCrop from "./fixCrop";

const List = props => {
    const { pageData } = props;

    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [selectedRows, setSelectedRows] = useState([]);
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);

    useEffect(() => {
        let newFetchUrl = pageData.index_url;
        let newUrlParams = [];
        let newUrlParamsData = {};

        pageData.search_valid_params.forEach(param => {
            const paramValue = searchParams.get(param);
            if (paramValue) {
                newUrlParamsData[param] = paramValue;
                newUrlParams.push(`${param}=${paramValue}`);
            }
        })

        if (Object.keys(newUrlParamsData).length)
            newFetchUrl += `?${newUrlParams.join("&")}`;

        setFetchUrl(newFetchUrl);
        // eslint-disable-next-line
    }, [location.search])

    const selectRowHandler = id => {
        setSelectedRows(prev => {
            if (selectedRows.indexOf(id) !== -1) {
                const newState = prev.filter(rowId => rowId !== id);
                return newState;
            }

            return [...prev, id];
        })
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>

                <div className="flex">
                    <Link to={pageData.breadcrumb[1].link} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    <FixCrop mutate={mutate} />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="flex justify-between items-start">
                    <Searchbox fields={pageData.search_setting} />

                    <SelectOptions
                        pageData={pageData}
                        data={data}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        reload={mutate}
                    />
                </div>

                {
                    !isLoading ? (
                        !error ? (
                            <div className="table-c1 mt-10 mb-4">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="w-5"></th>
                                            <th className="w-5"></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            data.data.map(file => {
                                                const labels = [];

                                                Object.keys(pageData.index_setting).forEach(IS_Key => {
                                                    if (IS_Key.indexOf("label") !== -1) {
                                                        const labelObj = pageData.index_setting[IS_Key];

                                                        const intendedKey = labelObj.key;
                                                        let value = file[intendedKey] || "-";

                                                        if (labelObj.convert_function)
                                                            value = convertUtils(labelObj.convert_function, value);

                                                        labels.push(`${labelObj.label}: ${value}`);
                                                    }
                                                })

                                                return (
                                                    <tr key={`file-indentity-${file.id}`}>
                                                        <td>
                                                            <Checkbox
                                                                value={selectedRows.indexOf(file.id) !== -1}
                                                                changeHandler={() => selectRowHandler(file.id)}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Link className="edit" to={`${location.pathname}/${file.id}`}>
                                                                <i className="fa-regular fa-eye"></i>
                                                            </Link>
                                                        </td>

                                                        <td className="desc-col">
                                                            <div className="content">
                                                                <p className="tp-25">
                                                                    {file[pageData.index_setting.title.key]}
                                                                </p>

                                                                <h6 className="tp-26">
                                                                    {
                                                                        labels.join(" | ")
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className="flex justify-between items-center mt-4">
                                    <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                        {data.count} مورد - {Math.ceil(data.count / (searchParams.get("limit") || 10))} صفحه
                                    </span>

                                    <Pagination
                                        count={data.count}
                                        limit={searchParams.get("limit")}
                                        offset={searchParams.get("offset")}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="global-error pt-8 pb-4 text-center block">خطا در دریافت اطلاعات</p>
                        )
                    ) : <Skeleton />
                }
            </div>
        </>
    )
}

export default memo(List);