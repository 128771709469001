import { memo, useMemo, useState } from "react";
import moment from "jalali-moment";
import ChartLine from "../../../shared/charts/line";
import { toMoneyFormat } from "utils";
import GoToAll from "components/shared/go-to-all";

const statuses = [
    {
        id: 0,
        title: "همه",
        value: "all"
    },
    {
        id: 3,
        title: "مدیر",
        value: "admin"
    },
    {
        id: 2,
        title: "تامین کننده",
        value: "provider"
    },
    {
        id: 1,
        title: "کاربر",
        value: "user"
    }
]

const Withdraw = props => {
    const { data } = props;
    const [activeTab, setActiveTab] = useState(statuses[0].value);

    const chartData = useMemo(() => {
        const output = {
            amounts: [],
            days: []
        };

        data[activeTab].graph_last_month.forEach(el => {
            output.amounts.push(el.amount);
            output.days.push(moment.unix(el.day).format("jYYYY/jMM/jD"));
        })

        return output;
        // eslint-disable-next-line
    }, [activeTab]);

    const goToAllLink = useMemo(() => {
        const output = "/panel/checkout";

        if (activeTab !== statuses[0].value) {
            const intendedStatus = statuses.find(status => status.value === activeTab);
            return `${output}?user_id=${intendedStatus.id}`;
        }

        return output;
    }, [activeTab]);

    return (
        <div className="wrap-box tp-22 page-transition">
            <div className="flex justify-between items-center pb-4 mb-2">
                <h2 className="tp-34 text-sm font-bold">تسویه حساب</h2>
                <GoToAll link={goToAllLink} />
            </div>

            <div className="pt-3">
                <label className="select-c1 mb-5">
                    <span>نوع کاربری</span>

                    <select onChange={e => setActiveTab(e.target.value)} value={activeTab}>
                        {
                            statuses.map(status => (
                                <option key={`option-${status.value}`} value={status.value}>{status.title}</option>
                            ))
                        }
                    </select>
                </label>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4 text-slate-900 dark:text-slate-200 text-sm">
                <div className="bg-blue-50 dark:bg-slate-800 rounded-lg py-4 px-3 flex items-center">
                    <span className="grow">تسویه شده</span>
                    <b className="ml-1">{toMoneyFormat(data[activeTab].count.withdrew_amount)}</b>
                    <span className="text-xs">تومان</span>
                </div>

                <div className="bg-blue-50 dark:bg-slate-800 rounded-lg py-4 px-3 flex items-center">
                    <span className="grow">در انتظار تسویه</span>
                    <b className="ml-1">{toMoneyFormat(data[activeTab].count.pending_amount)}</b>
                    <span className="text-xs">تومان</span>
                </div>
            </div>

            <ChartLine
                series={[
                    {
                        name: 'مبلغ',
                        data: chartData.amounts
                    }
                ]}
                xaxis={chartData.days}
            />
        </div>
    )
}

export default memo(Withdraw);