import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { toMoneyFormat, unixToShamsi } from "utils";
import InternetReceiptStatus from "constants/internet-receipt-status";
import { GetFinancialUserTypeLink } from "constants/financial-user-type";
import { MEDIA } from "service/routes";
import { dracula } from '@uiw/codemirror-theme-dracula';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { useSelector } from "react-redux";
import useExtendData from "./use-extend-data";
import UseFetch from "hooks/useFetch";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const darkMode = useSelector(state => state.theme.darkMode);
    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);
    const extendData = useExtendData(isLoading, error, data);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const serviceData = useMemo(() => {
        if (!isLoading && !error && data)
            return data.data.receipt;

        return {};
    }, [data, isLoading, error])

    const userType = serviceData.financial_user_type_id
        ? GetFinancialUserTypeLink(serviceData.financial_user_type_id, serviceData.user_id)
        : {};

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات رسید اینترنتی</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                    <p className="text-sm text-green-800 dark:text-slate-300">
                        <b className="inline-block ml-2">وضعیت رسید</b>
                        <span>{InternetReceiptStatus[serviceData.status_id] ? InternetReceiptStatus[serviceData.status_id].title : "---"}</span>
                    </p>
                </div>

                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه رسید</span>
                                            <b>{serviceData.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>مبلغ رسید</span>
                                            <b>{toMoneyFormat(serviceData.price)} تومان</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        {
                                            !!serviceData.order_oid && (
                                                <li className="inline">
                                                    <span>شناسه سفارش</span>
                                                    <b><Link to={`/panel/order/${serviceData.order_oid}`}>#{serviceData.order_oid}</Link></b>
                                                </li>
                                            )
                                        }

                                        <li className="inline">
                                            <span>نوع کاربر</span>
                                            <b>{userType.title}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>شناسه کاربر</span>
                                            <b>
                                                <Link to={userType.link} className="link-c1">
                                                    <ShowExtendUserLabel value={extendData.user} />
                                                </Link>
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>ip کاربر</span>
                                            <b>
                                                {serviceData.user_ip}
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        {
                                            serviceData.failed_description?.length > 0 && (
                                                <li className="inline">
                                                    <span>توضیحات پرداخت ناموفق</span>
                                                    <b>
                                                        {serviceData.failed_description || "---"}
                                                    </b>
                                                </li>
                                            )
                                        }

                                        <li className="inline">
                                            <span>توضیحات</span>
                                            <b>
                                                {serviceData.description || "---"}
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ ایجاد</span>
                                            <b>{serviceData.created_at ? unixToShamsi(serviceData.created_at) : "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ آخرین تغییرات</span>
                                            <b>{serviceData.updated_at ? unixToShamsi(serviceData.updated_at) : "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ بازگشت از درگاه</span>
                                            <b>{serviceData.callback_from_bank_date ? unixToShamsi(serviceData.callback_from_bank_date) : "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center mb-4">
                                            <span>درگاه</span>

                                            <Link
                                                to={`/panel/shop_setting/payline?id=${data.data.payline.id}`}
                                                className="flex items-center border border-slate-200 rounded-md text-sm px-4 py-1 dark:border-slate-800"
                                            >
                                                <img
                                                    src={`${MEDIA}/${data.data.payline.icon?.replace("/storage", "")}`}
                                                    width={50}
                                                    height={50}
                                                    alt={data.data.payline.title}
                                                    className="ml-3"
                                                />

                                                <b>{data.data.payline.title}</b>
                                            </Link>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>کد بازگشت از درگاه</span>
                                            <b>{serviceData.bank_code || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>وضعیت بازگشت از درگاه</span>
                                            <b>{serviceData.bank_status || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>شناسه بازگشت از درگاه</span>
                                            <b>{serviceData.au || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="py-2">
                                    <span className="text-[#979797] text-sm mb-2 block">پاسخ درگاه</span>

                                    <div className="cm-holder">
                                        <CodeMirror
                                            value={JSON.stringify(serviceData.bank_result, null, "\t")}
                                            height="300px"
                                            extensions={[json()]}
                                            theme={darkMode ? dracula : "light"}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >
        </>
    )
}

export default memo(Edit);