import { useEffect, useState } from "react";
import MasterAPI from "service/master";

const UseFetch = url => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetcher = async (enableLoading = true) => {
        if (url === null)
            return;

        if (enableLoading)
            setIsLoading(true);
        
        try {
            const res = await MasterAPI({
                method: "GET",
                url,
                callback: () => setIsLoading(false)
            });

            setError(false);
            setData(res);
        } catch {
            setError(true);
        }
    }

    useEffect(() => {
        fetcher();
        // eslint-disable-next-line
    }, [url])

    return { isLoading, data, error, mutate: (enableLoading = true) => fetcher(enableLoading) };
}

export default UseFetch;