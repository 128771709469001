import { memo } from "react";
import SettingModal from "components/theme-setting/modal";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

const ThemeSetting = () => {
    const { show, panelSettings = false } = useSelector(state => state.theme.themeSettingModal);

    return (
        <CSSTransition in={show} timeout={300} unmountOnExit={true}>
            <SettingModal panelSettings={panelSettings} />
        </CSSTransition>
    )
}

export default memo(ThemeSetting);