import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkPermission, themeIdToStr } from "utils";
import panelRoutes from "constants/panel-routes";
import { Collapse } from "react-collapse";
import { useLocation, matchRoutes } from "react-router-dom";
import Logo from "./logo";
import { setActivePanelRoute, setMenuFadeSm } from "redux/slices/theme";
import InitializeContext from "context/initialize-context";
import { routesTitle, skipRoutes } from "constants/config";
import { Fragment } from "react";

const PrimaryCat = ({ item, children, setOpenedCat }) => {
    const clickHandler = () => {
        setOpenedCat(prev => {
            if (prev === item.id)
                return null;

            return item.id;
        })
    }

    if (!item.childs?.length)
        return <Link to={item.route} className="sp-1 tp-15 tp-16">{children}</Link>

    return <span className="sp-1 tp-15 tp-16" onClick={() => clickHandler(item.id)}>{children}</span>
}

const fullRoutesMapper = (routes, holder) => {
    routes.forEach(route => {
        holder.all.push(route);

        if (route.route) {
            holder.router.push({ path: route.route });

            if (route.params?.length) {
                route.params.forEach(param => {
                    holder.router.push({ path: `${route.route}/${param}` });
                })
            }
        }

        if (route.childs?.length)
            fullRoutesMapper(route.childs, holder);
    })
}

const getChildRoute = ({ route, extendRoute }) => {
    let output = "/";

    if (route)
        output = route;

    if (extendRoute)
        output += extendRoute;

    return output;
}

const SideMenu = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const dispatch = useDispatch();
    const enums = useContext(InitializeContext);

    const menuTheme = useSelector(state => state.theme.menuTheme);
    const menuFadeLg = useSelector(state => state.theme.menuFadeLg);
    const menuFadeSm = useSelector(state => state.theme.menuFadeSm);

    const [openedCat, setOpenedCat] = useState(null);
    const [activeCat, setActiveCat] = useState(null);
    const [fullRoutes, setFullRoutes] = useState({ router: [], all: [] });
    const [intendedActiveRoute, setIntendedActiveRoute] = useState(null);

    const sideMenuClassNames = [];
    if (menuTheme)
        sideMenuClassNames.push((menuTheme === 2) ? "dark-c" : themeIdToStr(menuTheme));

    if (menuFadeLg)
        sideMenuClassNames.push("lg-action");

    if (menuFadeSm)
        sideMenuClassNames.push("sm-action");

    const data = useMemo(() => {
        const output = [];

        panelRoutes.forEach(cat => {
            if (cat.show !== false) {
                const childs = [];

                cat.childs?.forEach(route => {
                    if (skipRoutes.indexOf(route.id) === -1)
                        childs.push(route);

                    if (routesTitle[route.id])
                        route.title = routesTitle[route.id];
                });

                let clearedChildsInSkipedLinks = false;
                if (cat.childs?.length > 0) {
                    const hiddenChilds = cat.childs.filter(child => child.show === false);
                    clearedChildsInSkipedLinks = hiddenChilds.length === childs.length;
                }

                output.push({
                    ...cat,
                    childs,
                    clearedChildsInSkipedLinks
                })
            }
        })

        return output;
    }, []);

    useEffect(() => {
        const newFullRoutes = { ...fullRoutes };
        fullRoutesMapper(data, newFullRoutes);
        setFullRoutes(newFullRoutes);

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(setMenuFadeSm(false));
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (fullRoutes.router.length) {
            let route = matchRoutes(fullRoutes.router, location)
            if (route) {
                route = route[0];

                const currentRoute = route.route.path.split("/");
                const paramsCount = Object.keys(route.params).length;

                if (paramsCount)
                    currentRoute.splice(paramsCount * -1);

                const currentRouteStr = currentRoute.join("/");
                const currentRouteData = fullRoutes.all.find(el => el.route === currentRouteStr);
                if (currentRouteData) {
                    dispatch(setActivePanelRoute({
                        id: currentRouteData.id,
                        route: currentRouteData.route,
                        title: currentRouteData.title,
                        forceSelect: currentRouteData.force_select
                    }))

                    if (currentRouteData.force_select)
                        setIntendedActiveRoute(currentRouteData.force_select);
                    else
                        setIntendedActiveRoute(currentRouteData.id);
                }
            }
        }

        // eslint-disable-next-line
    }, [currentPath, fullRoutes]);

    useEffect(() => {
        if (intendedActiveRoute) {
            let intendedId = null;

            data.forEach(item => {
                if (!intendedId) {
                    if (item.id === intendedActiveRoute)
                        intendedId = item.id;
                    else {
                        if (item.childs?.length) {
                            const findedChild = item.childs.find(child => child.id === intendedActiveRoute);

                            if (findedChild)
                                intendedId = item.id;
                        }
                    }
                }
            })

            setActiveCat(intendedId);
            setOpenedCat(intendedId);
        }

        // eslint-disable-next-line
    }, [intendedActiveRoute])

    return (
        <aside className={`sidemenu${sideMenuClassNames.length ? ` ${sideMenuClassNames.join(" ")}` : ""}`}>
            <div className="wrapper tp-14 tp-17">
                <Logo />

                <ul className="routes spec-sb-c1">
                    {
                        data.map(item => {
                            const isActive = activeCat === item.id;
                            const itemAttrs = {};
                            const classNames = [];

                            if (isActive)
                                classNames.push("active-c1");

                            if (openedCat === item.id)
                                classNames.push("open");

                            if (classNames.length)
                                itemAttrs.className = classNames.join(" ");

                            let activeChilds = [];
                            let showThisCat = !item.clearedChildsInSkipedLinks;

                            if (showThisCat) {
                                if (!enums.permissions.all_access) {
                                    if (item.permissionUrl)
                                        showThisCat = checkPermission(item.permissionUrl, enums.permissions);
                                    else {
                                        if (item.childs?.length) {
                                            item.childs.forEach(child => {
                                                if (checkPermission(child.permissionUrl, enums.permissions))
                                                    activeChilds.push(child);
                                            })

                                            showThisCat = activeChilds.length > 0;
                                        }
                                    }
                                } else
                                    activeChilds = item.childs || [];

                                if (!activeChilds.length && !item.route)
                                    return <Fragment key={`sidemenu-primary-${item.id}`} />

                                return (
                                    <li key={`sidemenu-primary-${item.id}`} {...itemAttrs}>
                                        <PrimaryCat item={item} setOpenedCat={setOpenedCat}>
                                            <span className="icn-box">
                                                <i className={`fa-light ${item.icon}`}></i>
                                            </span>

                                            <span className="add-lf">
                                                {item.title}
                                            </span>

                                            {
                                                isActive && (
                                                    <>
                                                        <span className="t-shape tp-17"></span>
                                                        <span className="b-shape tp-17"></span>
                                                    </>
                                                )
                                            }
                                        </PrimaryCat>

                                        {
                                            activeChilds.length > 0 && (
                                                <Collapse
                                                    isOpened={openedCat === item.id}
                                                    theme={{ collapse: 'collapse-anim', content: 'collapse-content' }}
                                                >
                                                    <ul className="childs">
                                                        {
                                                            activeChilds.map((child, i) => (
                                                                child.show !== false ? (
                                                                    <li key={`sidemenu-subnav-${item.id}-${i}`}>
                                                                        <Link
                                                                            to={getChildRoute(child)}
                                                                            className={`tp-18${child.id === intendedActiveRoute ? " tp-20" : ""}`}
                                                                        >
                                                                            {child.title}
                                                                        </Link>
                                                                    </li>
                                                                ) : <React.Fragment key={`sidemenu-subnav-${item.id}-${i}`}></React.Fragment>
                                                            ))
                                                        }
                                                    </ul>
                                                </Collapse>
                                            )
                                        }
                                    </li>
                                )
                            }

                            return <React.Fragment key={`sidemenu-primary-${item.id}`}></React.Fragment>;
                        })
                    }
                </ul>
            </div>
        </aside>
    )
}

export default SideMenu;