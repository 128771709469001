import Breadcrumb from "components/shared/breadcrumb";
import React, { useEffect, useState, memo, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { calculateDiscountPercentage, toMoneyFormat, unixToShamsi } from "utils";
import { paymentTypes } from "constants/payment-types";
import { paymentStatuses } from "constants/statuses";
import Actions from "./actions/actions";
import UseFetch from "hooks/useFetch";
import useExtendData from "./use-extend-data";
import ShowExtendData, { ShowExtendUserLabel } from "components/shared/show-extend-data";
import SubOrders from "./sub-orders";
import { CSSTransition } from "react-transition-group";
import AutoRequestBtn from "../auto-request-btn";
import useAutoRequest from "hooks/useAutoRequest";
import EnumsContext from "context/initialize-context";
import OrderCard from "components/order/view/orderCard/orderCard";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
    } = props;

    const enums = useContext(EnumsContext);
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const extendData = useExtendData(isLoading, error, data);

    const [autoRequestStatus, setAutoRequestStatus] = useState(false);
    useAutoRequest(autoRequestStatus, fetchUrl, () => mutate(false));

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const serviceData = useMemo(() => {
        if (!isLoading && !error && data)
            return data.data;

        return {};
    }, [data, isLoading, error])

    const serviceStatus = useMemo(() => {
        if (!isLoading && !error) {
            const val = paymentStatuses.find(el => el.id === data.data.status_id);
            return val.title;
        }

        return "";
    }, [data, isLoading, error])

    const orderWeight = useMemo(() => {
        if (serviceData.factor?.layers?.length) {
            let newWeight = 0;

            serviceData.factor.layers.forEach(layer => {
                if (layer.delivery_calculation?.weight)
                    newWeight += layer.delivery_calculation.weight;
            })

            return newWeight;
        }

        return 0;
    }, [serviceData])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات سفارش</h1>
                    <Breadcrumb data={[
                        { title: "مدیریت اپلیکیشن" },
                        { title: "سفارشات", link: "/panel/order" },
                        { title: "جزئیات سفارش" }
                    ]} />
                </div>

                <div className="flex">
                    {
                        <CSSTransition in={!autoRequestStatus} timeout={200} unmountOnExit={true} appear={true}>
                            <button className="anim-c4 w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                                <i className="fa-regular fa-arrows-retweet"></i>
                            </button>
                        </CSSTransition>
                    }

                    <AutoRequestBtn
                        active={autoRequestStatus}
                        clickHandler={() => setAutoRequestStatus(prev => !prev)}
                    />

                    <Actions data={serviceData} mutate={mutate} />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                    <p className="flex text-sm text-green-800 dark:text-slate-300">
                        <b className="inline-block ml-2">وضعیت گروه سفارش</b>
                        <span>{serviceStatus}</span>

                        {
                            serviceData.accept_change_by_customer === false && (
                                <span className="mr-4 text-red-600">سفارش در انتظار تایید خریدار است !</span>
                            )
                        }
                    </p>
                </div>

                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه گروه سفارش</span>
                                            <b>{serviceData.id}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>مبلغ فاکتور</span>
                                            <b>{toMoneyFormat(serviceData.factor.price)}<span className="mr-1"> تومان</span></b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع پرداخت</span>
                                            <b>{serviceData.payment_type_id ? paymentTypes[serviceData.payment_type_id] : "نامشخص"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ ثبت سفارش</span>
                                            <b>{serviceData.created_at ? unixToShamsi(serviceData.created_at) : "--"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ تایید سفارش</span>
                                            <b>{serviceData.confirmed_at ? unixToShamsi(serviceData.confirmed_at) : "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ تحویل به مشتری</span>
                                            <b>{serviceData.delivered_at ? unixToShamsi(serviceData.delivered_at) : "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>خریدار</span>
                                            <ShowExtendUserLabel value={extendData.userData} link={`/panel/user-detail/${data.data.customer_id}`} />
                                        </li>
                                    </ul>
                                </div>

                                {
                                    serviceData.seller_cancel_description || serviceData.canceled_date ? (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                {
                                                    !!serviceData.seller_cancel_description && (
                                                        <li className="inline">
                                                            <span>توضیحات لغو فروشنده</span>
                                                            <b>{serviceData.seller_cancel_description}</b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.canceled_date && (
                                                        <li className="inline">
                                                            <span>تاریخ لغو</span>
                                                            <b>{serviceData.canceled_date}</b>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    ) : <></>
                                }

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع ارسال</span>
                                            <Link to={`/panel/shop_setting/transport_type/${serviceData.delivery_type_id}`} className="link-c1 font-medium">
                                                <ShowExtendData value={extendData.deliveryType} />
                                            </Link>
                                        </li>

                                        <li className="inline">
                                            <b>{serviceData.delivery_by_provider ? "تحویل توسط فروشنده" : "تحویل توسط ادمین"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>وزن محسابه شده توسط لایه های فاکتور</span>
                                            <b>{orderWeight} گرم</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>آدرس مشتری</span>
                                            <a
                                                className="dark:text-white"
                                                href={`https://www.google.com/maps/search/?api=1&query=${serviceData.address?.coordinates[1]},${serviceData.address?.coordinates[0]}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fa-regular fa-location-dot ml-1"></i>
                                                {serviceData.address?.address}
                                            </a>
                                        </li>

                                        <li className="inline">
                                            <span>پلاک</span>
                                            <b>{serviceData.address?.number}</b>
                                        </li>

                                        <li className="inline">
                                            <span>واحد</span>
                                            <b>{serviceData.address?.unit}</b>
                                        </li>

                                        <li className="inline">
                                            <span>کد پستی</span>
                                            <b>{serviceData.address?.postal_code}</b>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    serviceData.description && serviceData.description?.length ? (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                <li className="inline">
                                                    <span>توضحیات مشتری</span>
                                                    <b>{serviceData.description}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : <></>
                                }

                                {
                                    serviceData.items?.length && (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            {
                                                serviceData.items?.map(el => <OrderCard order={el} enums={enums} key={`product-${el.id}`} />)
                                            }
                                        </div>
                                    )
                                }

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <Link
                                        to={`/panel/transactions?order_group_id=${serviceData.id}`}
                                        className="text-sm bg-green-500 hover:bg-green-600 rounded-md text-white px-2 py-1 dark:bg-slate-500 dark:hover:bg-slate-600 transition-colors"
                                    >
                                        نمایش تراکنش های گروه سفارش
                                    </Link>
                                </div>

                                <div className="flex factor-sum items-stretch rounded-md overflow-hidden mt-4">
                                    <div className="bg-primary-100 text-white px-3 fit-center">
                                        <i className="fa-regular fa-square-list"></i>
                                    </div>

                                    <div className="grow">
                                        <ul>
                                            <li className="has-bb">
                                                <span>قیمت اصلی کالاها</span>
                                                <b>
                                                    {toMoneyFormat(serviceData.factor?.total)}{" "}
                                                    <span className="unit">تومان</span>
                                                </b>
                                            </li>

                                            {
                                                serviceData.factor?.profit > 0 && (
                                                    <li>
                                                        <span>تخفیف کالاها</span>
                                                        <b>
                                                            <span className="text-sm font-normal text-gray-500">
                                                                ({calculateDiscountPercentage(serviceData.factor?.total, serviceData.factor?.total - serviceData.factor?.profit)}%)
                                                            </span> {" "}

                                                            {toMoneyFormat(serviceData.factor?.profit)} {" "}
                                                            <span className="unit"> تومان</span>
                                                        </b>
                                                    </li>
                                                )
                                            }

                                            <li className="has-bb">
                                                <span>قیمت پس از تخفیف</span>
                                                <b>
                                                    {toMoneyFormat(serviceData.factor?.total - serviceData.factor?.profit)} {" "}
                                                    <span className="unit"> تومان</span>
                                                </b>
                                            </li>

                                            {
                                                serviceData.factor?.layers.length > 0 && (
                                                    serviceData.factor?.layers.map(layer => (
                                                        <li key={`layer-section-${layer.id}`}>
                                                            <span>{layer.title}</span>
                                                            <b>
                                                                {toMoneyFormat(layer.amount)}{" "}
                                                                <span className="unit">تومان</span>
                                                            </b>
                                                        </li>
                                                    ))
                                                )
                                            }

                                            <li className={serviceData.factor?.layers.length ? "has-bt" : ""}>
                                                <span>جمع سبد خرید</span>
                                                <b>
                                                    {toMoneyFormat(serviceData.factor?.price)}{" "}
                                                    <span className="unit">تومان</span>
                                                </b>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div >

            {
                !isLoading && !error ? (
                    <SubOrders
                        data={data}
                        serviceData={serviceData}
                        mutate={mutate}
                    />
                ) : <></>
            }
        </>
    )
}

export default memo(Edit);