import Modal from "components/shared/modal";
import { useId, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const PriorityModal = props => {
    const {
        data,
        setData,
        submitUrl,
        reload,
        payload,
        addSearchParamsToSubmitUrl = false
    } = props;

    const [searchParams] = useSearchParams();
    const fieldId = useId();
    const [loading, setLoading] = useState(false);

    const inputChangeHandler = e => {
        setData(prev => ({
            ...prev,
            value: e.target.value
        }));
    }

    const submitHandler = async e => {
        e.preventDefault();

        if (loading)
            return;

        setLoading(true);
        let payloadData = {};
        if (!payload) {
            payloadData = {
                key: "priority",
                ids: [data.id]
            };

            if (data.value)
                payloadData.value = parseInt(data.value);
        } else
            payloadData = payload;

        try {
            const res = await MasterAPI({
                method: "PUT",
                url: addSearchParamsToSubmitUrl ? `${submitUrl}?${searchParams.toString()}` : submitUrl,
                payloadType: 3,
                data: payloadData,
                callback: () => setLoading(false)
            });

            toast.success(res.message);
            setData({ show: false });
            reload();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal
            show={data.show}
            title="برتری"
            closeHandler={() => setData({ show: false })}
        >
            <form onSubmit={submitHandler}>
                <div className="flex p-px py-4">
                    <div className={`text-field grow items-stretch${data?.value?.toString()?.length ? " has-val" : ""}`}>
                        <label htmlFor={fieldId}>مقدار</label>

                        <input
                            type="number"
                            id={fieldId}
                            value={data?.value?.toString() || ""}
                            onChange={inputChangeHandler}
                            min={0}
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-primary-100 text-white rounded-full text-sm transition-colors px-10 mr-2 hover:bg-primary-200 relative overflow-hidden"
                    >
                        <CSSTransition unmountOnExit={true} in={loading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                        ثبت
                    </button>
                </div>
            </form>
        </Modal>
    )
}

export default PriorityModal;