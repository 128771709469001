import { memo } from "react"
import { useParams } from "react-router-dom";
import List from "./list/list";
import Edit from "./edit/edit";
import Add from "./add/add";
import templateFetcherHook from "service/template-fetcher-hook";

const Permissions = props => {
    const {
        template,
        mode
    } = props;

    const { data: pageData } = templateFetcherHook(template);
    const { id } = useParams();

    return (
        <div className="m-container">
            {
                id ? (
                    id === "add"
                        ? <Add mode={mode} pageData={pageData} />
                        : <Edit  mode={mode} pageData={pageData} />
                ) : <List pageData={pageData} />
            }
        </div>
    )
}

export default memo(Permissions);