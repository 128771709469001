import { memo } from "react";
import Checkbox from "components/shared/inputs/checkbox";

const CheckboxList = props => {
    const {
        options,
        label,
        value,
        name,
        changeHandler,
        masterClass
    } = props;

    const localChangeHandler = (e, valKey) => {
        const checked = e.target.checked;
        let newVal = [...value];

        if (checked)
            newVal.push(valKey);
        else
            newVal = newVal.filter(el => el !== valKey);

        changeHandler(newVal);
    }

    return (
        <div className="cat-holder">
            <span className="label tp-27">{label}</span>

            {
                options?.map((checkbox, i) => (
                    <div key={`checkbox-${name}-${i}`}>
                        <label className="noselect checkbox-holder">
                            <Checkbox
                                value={value.indexOf(checkbox.value) !== -1}
                                changeHandler={e => localChangeHandler(e, checkbox.value)}
                                className={masterClass}
                            />

                            <span className="tp-27">{checkbox.title}</span>
                        </label>
                    </div>
                ))
            }
        </div>
    )
}

export default memo(CheckboxList);