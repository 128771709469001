import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import Selectbox from "components/shared/inputs/selectbox";
import EnumsContext from "context/initialize-context";
import { useLocation, useSearchParams, Link, createSearchParams, Navigate, useNavigate } from "react-router-dom";
import Searchbox from "./searchbox";
import SelectOptions from "./select-options";
import Skeleton from "components/skeleton/table-1";
import convertUtils from "utils/convert-utils";
import Checkbox from "components/shared/inputs/checkbox";
import { contentStatusId, copyToClipboard, generateIconLink, getContentCreatorLable, getPagesCount, unixToShamsi } from "utils";
import BottomSkeleton from "components/skeleton/table-grid-bottom";
import Pagination from "components/shared/pagination";
import UseFetch from "hooks/useFetch";
import PriorityModal from "components/shared/priority-modal";
import { toast } from "react-toastify";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import { setBeforeEditRoute } from "redux/slices/stack";
import { useDispatch } from "react-redux";
import SelectPageSize from "components/shared/selectPageSize";
import DownloadExcel from "./btns/download-excel";
import PriceLink from "./btns/price-link";

const List = props => {
    const {
        pageData
    } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const [addItems, setAddItems] = useState([]);
    const enumsData = useContext(EnumsContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [urlParamsData, setUrlParamsData] = useState({});
    // eslint-disable-next-line
    const [priorityData, setPriorityData] = useState({ show: false });


    const form = enumsData.data.forms.find(form => form.id === parseInt(searchParams.get("form_id")))

    useEffect(() => {
        searchParams.set("show_creator_label", "true");
        if (searchParams.get("form_id")) {
            let newUrl = `${pageData.index_url}?${searchParams.toString()}`;
            if (pageData.index_setting.priority && searchParams.getAll("categories").length === 1)
                newUrl += `&sort=priority:asc&sort=_id:desc`;

            setFetchUrl(newUrl);
        }

        const newUrlParamsData = {};
        for (const [key, value] of searchParams.entries()) {
            newUrlParamsData[key] = value;
        }
        setUrlParamsData(newUrlParamsData);
        // eslint-disable-next-line
    }, [searchParams])

    useEffect(() => {
        if (enumsData.data.forms.length) {
            // set add items
            const newItems = [];

            enumsData.data.forms.forEach(item => {
                if (item.status_id === 1)
                    newItems.push({
                        title: item.title,
                        link: `add/${item.id}?form_id=${item.id}`
                    });
            })

            setAddItems(newItems);
        }
        // eslint-disable-next-line
    }, [enumsData.data.forms, searchParams])

    const selectRowHandler = id => {
        setSelectedRows(prev => {
            if (selectedRows.indexOf(id) !== -1) {
                const newState = prev.filter(rowId => rowId !== id);
                return newState;
            }

            return [...prev, id];
        })
    }

    const createOrEditLink = id => {
        let url = location.pathname;

        if (id)
            url += `/${id}?form_id=${searchParams.get("form_id")}`;

        return url;
    }

    const childLink = item => {
        let url = location.pathname;
        const newSearchParams = createSearchParams(location.search)
        newSearchParams.delete("parent_id");
        newSearchParams.append("parent_id", pageData.index_setting.recursive_key ? item[pageData.index_setting.recursive_key] : item.id)

        if (item)
            url += `?${newSearchParams.toString()}`;

        return url;
    }

    const priorityClickHandler = (id, value) => {
        setPriorityData({
            show: true,
            id,
            value
        })
    }

    const breadcrumb = useMemo(() => {
        if (!isLoading) {
            if (!error) {
                let output = [...pageData.breadcrumb];
                if (data.breadcrumbs?.length)
                    output = [...output, ...data.breadcrumbs.reverse()];

                return { list: output };
            } else
                return { error: true };
        }

        return { loading: true };
        // eslint-disable-next-line
    }, [isLoading, data, error])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>

                    {
                        !breadcrumb.loading
                            ? !breadcrumb.error ? <Breadcrumb data={breadcrumb.list} /> : <></>
                            : <BreadcrumbSkeleton />
                    }
                </div>

                <div className="flex">
                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    {
                        form.modules?.indexOf(1) !== -1 ?
                            <PriceLink />
                            : <></>
                    }

                    <DownloadExcel />

                    <Selectbox
                        className="c2 tp-24"
                        placeholder="افزودن"
                        loading={enumsData.loading}
                        options={addItems}
                    />
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="flex justify-between items-start">
                    <Searchbox fields={pageData.search_setting} />

                    <div className="flex options-holder">
                        {
                            !!pageData.show_page_size_input && (
                                <div className="ml-2">
                                    <SelectPageSize />
                                </div>
                            )
                        }

                        <SelectOptions
                            pageData={pageData}
                            data={data}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            reload={mutate}
                        />
                    </div>
                </div>

                {
                    searchParams.get("form_id") ? (
                        <>
                            <div className="table-c1 mt-10 mb-4">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="w-5"></th>
                                            <th className="w-5"></th>
                                            <th className="w-5"></th>
                                            <th></th>
                                            <th className="w-5"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isLoading && <Skeleton />}
                                    </tbody>
                                </table>

                                {
                                    isLoading || (data && data.data?.length) ? (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="w-5"></th>
                                                    <th className="w-5"></th>
                                                    <th className="w-16"></th>
                                                    <th></th>
                                                    <th className="w-5"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isLoading ? (
                                                        data.data?.map((item, i) => {
                                                            const hasIcon = pageData.index_setting.icon?.length > 0;
                                                            const labels = [];
                                                            ["label_1", "label_2", "label_3"].forEach(label => {
                                                                const labelObj = pageData.index_setting[label];

                                                                if (labelObj) {
                                                                    const intendedKey = labelObj.key;
                                                                    let value = item[intendedKey] || "-";

                                                                    if (labelObj.convert_function)
                                                                        value = convertUtils(labelObj.convert_function, value);

                                                                    labels.push(`${labelObj.label}: ${value}`);
                                                                }
                                                            })

                                                            if (item.system_fields?.visits > 0)
                                                                labels.push(`بازدید: ${item.system_fields.visits}`)

                                                            let showPriorityBtn = false;
                                                            if (pageData.index_setting.priority && searchParams.getAll("categories").length < 2) {
                                                                const priorityValidParams = ["categories", "parent_id", "provider_oid", "provider_id", "user_id", "admin_id"];
                                                                let existsCounter = 0;

                                                                for (const priorityValidParam of priorityValidParams) {
                                                                    if (searchParams.getAll(priorityValidParam).length === 1) {
                                                                        existsCounter++;

                                                                        if (existsCounter > 1)
                                                                            break;
                                                                    }
                                                                }

                                                                showPriorityBtn = existsCounter === 1;
                                                            }

                                                            return (
                                                                <tr key={`table-row-${i}`}>
                                                                    <td>
                                                                        <Checkbox
                                                                            value={selectedRows.indexOf(item.id) !== -1}
                                                                            changeHandler={() => selectRowHandler(item.id)}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        {contentStatusId(item.status_id).icon}
                                                                    </td>

                                                                    <td>
                                                                        <Link
                                                                            to={`/panel/adminLogs?body.*id*=${item.id}`}
                                                                            className="ml-2 text-green-800 dark:text-green-600" title="تاریخچه"
                                                                        >
                                                                            <i className="fa-regular fa-clock-rotate-left"></i>
                                                                        </Link>

                                                                        <Link
                                                                            className="edit"
                                                                            to={createOrEditLink(item.id)}
                                                                            onClick={() => dispatch(setBeforeEditRoute(`${location.pathname}${location.search}`))}
                                                                        >
                                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                                        </Link>
                                                                    </td>

                                                                    <td className={`desc-col${hasIcon ? " has-icn" : ""}`}>
                                                                        {
                                                                            hasIcon && (
                                                                                <div className="icn-holder-c1">
                                                                                    {
                                                                                        pageData.index_setting.icon.map((icon, i) => {
                                                                                            const className = {};

                                                                                            if (icon.class)
                                                                                                className.className = icon.class;

                                                                                            return (
                                                                                                <Link
                                                                                                    key={`${i}-icon-${i}`}
                                                                                                    to={generateIconLink(item, icon.url)}
                                                                                                    title={icon.title}
                                                                                                    {...className}
                                                                                                >
                                                                                                    <i className={icon.font_icon} />
                                                                                                </Link>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <div className={`content${hasIcon ? " grow" : ""}`}>
                                                                            <p className="tp-25" data-fn={pageData.index_setting.title.key}>
                                                                                {item.group_type === 1 && <i className="fa-regular fa-layer-group ml-2 text-blue-400 z-10" title="گروه بندی: کپی متصل" />}
                                                                                {item.group_type === 2 && <i className="fa-regular fa-layer-group ml-2 text-green-400 z-10" title="گروه بندی: قالب" />}

                                                                                {
                                                                                    item.has_draft && <i className="fa-regular fa-hourglass-clock text-blue-400 ml-2 z-10" title="در انتظار تایید پیش نویس"></i>
                                                                                }

                                                                                {
                                                                                    item.virtual_title
                                                                                }
                                                                            </p>

                                                                            <h6 className="tp-26">
                                                                                {
                                                                                    labels.map((label, i) => {
                                                                                        let output;

                                                                                        if (label.indexOf("شناسه") !== -1) {
                                                                                            try {
                                                                                                const splitedLabel = label.split(":");
                                                                                                const labelKey = splitedLabel[0];
                                                                                                const labelVal = splitedLabel[1].trim();

                                                                                                output = (
                                                                                                    <span
                                                                                                        className="relative z-[1] cursor-pointer hover:text-slate-500 dark:hover:text-slate-300 transition-colors"
                                                                                                        onClick={() => {
                                                                                                            copyToClipboard(labelVal)
                                                                                                                .then(() => toast.success("آیدی با موفقیت کپی شد"))
                                                                                                                .catch(() => null);
                                                                                                        }}
                                                                                                    >
                                                                                                        {labelKey}: {labelVal}
                                                                                                    </span>
                                                                                                )
                                                                                            } catch {
                                                                                                output = label;
                                                                                            }
                                                                                        } else if (label.indexOf("تاریخ آخرین تغییرات") !== -1) {
                                                                                            const labelVal = label.replace("تاریخ آخرین تغییرات: ", "")

                                                                                            output = (
                                                                                                <span className="z-[1] relative cursor-default" title={`تاریخ ایجاد: ${unixToShamsi(item.created_at)}`}>
                                                                                                    تاریخ آخرین تغییرات: {labelVal}
                                                                                                </span>
                                                                                            )
                                                                                        } else if (label.indexOf("ایجاد کننده") !== -1) {
                                                                                            const labelVal = label.replace("ایجاد کننده: ", "")

                                                                                            output = (
                                                                                                <span
                                                                                                    className="relative z-[1] cursor-pointer hover:text-slate-500 dark:hover:text-slate-300 transition-colors"
                                                                                                    title={`ایجاد کننده: ${getContentCreatorLable(item.creator).lable}`}
                                                                                                    onClick={() => {
                                                                                                        let link = getContentCreatorLable(item.creator).link;
                                                                                                        if (link)
                                                                                                            navigate(link)
                                                                                                    }}
                                                                                                >
                                                                                                    ایجاد کننده: {getContentCreatorLable(item.creator).lable}
                                                                                                </span>
                                                                                            )
                                                                                        } else
                                                                                            output = label;

                                                                                        return (
                                                                                            <React.Fragment key={`tirp-${i}`}>
                                                                                                {output}
                                                                                                {
                                                                                                    (i + 1) !== labels.length && <span className="mx-1">|</span>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </h6>

                                                                            {
                                                                                pageData.index_setting.recursive
                                                                                && <Link to={childLink(item)} className="pos-cover"></Link>
                                                                            }
                                                                        </div>
                                                                    </td>

                                                                    <td className="priority-col">
                                                                        {
                                                                            showPriorityBtn && (
                                                                                <button className="noti" onClick={() => priorityClickHandler(item.id, item.priority)}>
                                                                                    {item.priority !== null ? <span>{item.priority}</span> : <></>}
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : <Skeleton />
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    !isLoading && data && !data.data?.length && (
                                        <p className="font-medium text-sm text-center mb-10 text-slate-800 dark:text-gray-300">
                                            اطلاعاتی برای نمایش یافت نشد
                                        </p>
                                    )
                                }

                                {
                                    error && <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                                }
                            </div>

                            {
                                isLoading ? (
                                    <BottomSkeleton />
                                ) : (
                                    <div className="flex justify-between items-center">
                                        <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                            {getPagesCount(data.count, urlParamsData.limit)}
                                        </span>

                                        {
                                            !!data.data?.length && (
                                                <Pagination
                                                    count={data.count}
                                                    limit={urlParamsData.limit}
                                                    offset={urlParamsData.offset}
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <div className="table-c1 mt-10 mb-4">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="w-5"></th>
                                        <th className="w-5"></th>
                                        <th className="w-5"></th>
                                        <th></th>
                                        <th className="w-5"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <Skeleton />
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>

            <PriorityModal
                data={priorityData}
                setData={setPriorityData}
                submitUrl="/api/content/change_priority"
                addSearchParamsToSubmitUrl={true}
                reload={mutate}
                payload={{
                    priority: parseInt(priorityData.value),
                    form_id: parseInt(searchParams.get("form_id")),
                    ids: [priorityData.id]
                }}
            />
        </>
    )
}

export default memo(List);