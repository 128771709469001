import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }) => {
    const [mounted, setMounted] = useState(false) // this state hold document object after mount

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, [])

    return mounted ? createPortal(children, document.querySelector("#modal")) : null;
}

export default Portal