import { memo, useState } from "react";
import Modal from "components/shared/modal";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { CSSTransition } from "react-transition-group";

const EditName = props => {
    const { data, mutate } = props;

    const [editNameLoading, setEditNameLoading] = useState(false);
    const [showEditNameModal, setShowEditNameModal] = useState(false);
    const [newName, setNewName] = useState("");

    const cancelHandler = async e => {
        e.preventDefault();

        if (editNameLoading)
            return;

        setEditNameLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/file_identity/manager/edit_name",
                payloadType: 3,
                method: "PUT",
                data: {
                    id: data.id,
                    name: newName,
                },
                callback: () => setEditNameLoading(false)
            });

            setShowEditNameModal(false);
            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <button
                className={`bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 mr-2 relative overflow-hidden`}
                onClick={() => setShowEditNameModal(true)}
            >
                <i className="fa-light fa-pen-to-square ml-2"></i>
                ویرایش نام
            </button>

            <Modal
                title="ویرایش نام"
                show={showEditNameModal}
                closeHandler={() => setShowEditNameModal(false)}
            >
                <form onSubmit={cancelHandler}>
                    <span className="block text-sm mb-4">نام</span>
                    <textarea
                        value={newName}
                        className="border w-full rounded-lg p-3 text-sm"
                        onChange={e => setNewName(e.target.value)}
                    />

                    <button type="submit" className="rounded-lg bg-primary-100 py-2 px-4 text-white text-sm mt-4 relative overflow-hidden">
                        ثبت

                        <CSSTransition in={editNameLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </form>
            </Modal>
        </>
    )
}

export default memo(EditName);