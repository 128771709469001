import { memo, useState } from "react";
import { Link } from "react-router-dom";
import Setting from "./buttons/setting";
import SearchboxLG from "./searchbox/searchbox-lg";
import SearchboxSm from "./searchbox/searchbox-sm";
import { useDispatch, useSelector } from "react-redux";
import { themeIdToStr } from "utils";
import User from "./buttons/user";
import { CSSTransition } from "react-transition-group";
import Comment from "./buttons/comment";
import FullScreen from "./buttons/fullscreen";
import DarkMode from "./buttons/darkMode";
import { toggleMenuFadeLg, toggleMenuFadeSm } from "redux/slices/theme";

const Header = () => {
    const dispatch = useDispatch();
    const headerTheme = useSelector(state => state.theme.headerTheme);
    const menuFadeLg = useSelector(state => state.theme.menuFadeLg);
    const [showButtonsBox, setShowButtonsBox] = useState(false);

    return (
        <header>
            <div className={`${headerTheme ? themeIdToStr(headerTheme) : "no-theme"}`}>
                <div className={`header tp-1 tp-2 fixed top-0 w-full clearfix flex items-center px-4 tp-1${menuFadeLg ? " no-pr" : ""}`}>
                    <button className="btn-bar fix-size" title="تغییر سایز منو">
                        <span className="pos-cover hidden lg:block" onClick={() => dispatch(toggleMenuFadeLg())}></span>
                        <span className="pos-cover block lg:hidden" onClick={() => dispatch(toggleMenuFadeSm())}></span>

                        <span className="box tp-19">
                            <span></span>
                        </span>
                    </button>

                    <SearchboxLG />

                    <div className="grow center-logo block lg:hidden">
                        <Link to="/" className="w-full h-full flex items-center justify-center">
                            <img src="/assets/image/logo/2.png" className="l-c1" alt="sarirmarket" />
                            <img src="/assets/image/logo/1.png" className="l-c2" alt="sarirmarket" />
                        </Link>
                    </div>

                    <div className="btns flex items-center justify-between lg:justify-start fix-size">
                        <CSSTransition in={showButtonsBox} timeout={200}>
                            <div className="tp-1 tp-2 btns-box-c1 anim-c3">
                                <SearchboxSm />
                                <DarkMode />
                                <FullScreen />
                                <Comment />
                                <User />
                            </div>
                        </CSSTransition>

                        <button className={`tp-7 lg:mr-3 toggle-btns${showButtonsBox ? " active" : ""}`} onClick={() => setShowButtonsBox(prev => !prev)}>
                            <i className="fa-regular fa-ellipsis-vertical"></i>
                        </button>

                        <Setting />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default memo(Header);