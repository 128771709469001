import useOutsideClick from "hooks/useOutsideClick";
import { memo, useRef } from "react";
import ThemeCat from "./cats/theme";
import ChangeBg from "./cats/changeBg";
import { useDispatch } from "react-redux";
import { setThemeSettingModal, resetTheme } from "redux/slices/theme";
import ClearCache from "./clear-cache";
import useEscHandler from "hooks/useEscHandler";

const Modal = props => {
    const { panelSettings } = props;
    const dispatch = useDispatch();

    const closeHandler = () => {
        dispatch(setThemeSettingModal({ show: false, panelSettings }));
    }

    useEscHandler(closeHandler);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, closeHandler);

    const resetHandler = () => {
        localStorage.removeItem("THEME");
        dispatch(resetTheme());
    }

    return (
        <div className="setting-modal fixed top-0 h-screen tp-1" ref={wrapperRef}>
            <ThemeCat />

            {
                panelSettings && (
                    <>
                        <ChangeBg bgType="MenuTheme" catTitle="پس زمینه منو" />
                        <ChangeBg bgType="HeaderTheme" catTitle="پس زمینه هدر" />
                    </>
                )
            }

            <div className="px-4 pt-4">
                <button
                    className="bg-red-500 text-white w-full rounded-md py-3 text-sm hover:bg-red-600 transition-colors mb-3"
                    onClick={resetHandler}
                >
                    تنظیمات پیشفرض
                </button>

                <ClearCache />
            </div>
        </div>
    )
}

export default memo(Modal);