import { memo } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { List, arrayMove } from 'react-movable';
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import ControlBoxSkeleton from "components/skeleton/control-box";
import UseFetch from "hooks/useFetch";

const ListCom = props => {
    const {
        pageData
    } = props;

    const { pageId } = useParams();
    const location = useLocation();

    const { isLoading, data, error, mutate } = UseFetch(`/api/page/get?id=${pageId}`, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0
    });

    const [localData, setLocalData] = useState([]);
    const [parseError, setParseError] = useState(false);
    const [changed, setChanged] = useState(false);
    const [deletedIds, setDeletedIds] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (!isLoading && !error) {
            try {
                const controls = JSON.parse(data.data?.controls);

                if (typeof controls === "object")
                    setLocalData(controls.controls || []);
                else
                    throw Object.assign(new Error(), { code: 402 });

                setParseError(false);
            } catch (e) {
                setLocalData([]);
                setParseError(true);
            }
        } else
            setLocalData([]);
    }, [isLoading, data, error])

    const changeSortHandler = ({ oldIndex, newIndex }) => {
        setChanged(true);

        const newArray = arrayMove(localData, oldIndex, newIndex);
        setLocalData(newArray);
    }

    const deleteHandler = id => {
        setChanged(true);

        setDeletedIds(prev => [...prev, id]);
        setLocalData(prev => {
            return prev.filter(el => el.id !== id);
        });
    }

    const submitHandler = async () => {
        if (!changed || submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const ids = localData.map(el => el.id);

            const res = await MasterAPI({
                payloadType: 3,
                method: "PUT",
                url: "/api/page/priority_control",
                data: {
                    page_id: parseInt(pageId),
                    control_ids: ids.length ? ids.join(",") : "0",
                    delete_ids: deletedIds
                },
                callback: () => setSubmitLoading(false)
            });

            mutate();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const getCreateOrEditRoute = id => {
        const splitedCurrentRoute = location.pathname.split("/");
        if (splitedCurrentRoute[splitedCurrentRoute.length - 1] === "")
            splitedCurrentRoute.pop();

        splitedCurrentRoute.pop();
        const newRoute = splitedCurrentRoute.join("/");


        if (typeof id !== "undefined")
            return `${newRoute}/edit/${id}`;

        return `${newRoute}/add`;
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>

                    {
                        <Breadcrumb data={pageData.breadcrumb} />
                    }
                </div>

                <div className="flex">
                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    <Link
                        to={getCreateOrEditRoute()}
                        className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 ml-3"
                    >
                        <i className="fa-light fa-folder-plus ml-2"></i>
                        افزودن
                    </Link>

                    <button
                        className={`bg-primary-100 overflow-hidden px-3 transition-all text-white rounded-md fit-center text-sm h-10 relative ${changed ? "opacity-100 cursor-pointer hover:bg-primary-200" : "opacity-30 cursor-default"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !error && !parseError ? (
                        !isLoading ? (
                            localData.length > 0 ? (
                                <List
                                    values={localData}
                                    onChange={changeSortHandler}
                                    renderList={({ children, props }) => (
                                        <div className="control-box" {...props}>
                                            {children}
                                        </div>
                                    )}
                                    renderItem={({ value, props, isDragged }) => (
                                        <div className="control-item" {...props} style={{
                                            ...props.style
                                        }}>
                                            <div className={`inner ${isDragged ? "drag" : ""}`}>
                                                <span className="grow sp-1">
                                                    {value.title}
                                                </span>

                                                <button className="relative">
                                                    <Link to={getCreateOrEditRoute(value.id)} className="pos-cover fit-center">
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </Link>
                                                </button>

                                                <button className="del" onClick={() => deleteHandler(value.id)}>
                                                    <i className="fa-regular fa-trash text-red-600"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                />
                            ) : (
                                <p className="font-medium text-sm text-center text-slate-800 dark:text-gray-300 py-4">
                                    اطلاعاتی برای نمایش یافت نشد
                                </p>
                            )
                        ) : <ControlBoxSkeleton />
                    ) : (
                        <p className="font-medium text-sm text-red-500 text-center py-4">خطا در دریافت اطلاعات!</p>
                    )
                }
            </div>
        </>
    )
}

export default memo(ListCom);