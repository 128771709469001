import { memo } from "react";

const Properties = props => {
    const { properties } = props;

    return (
        <table className="w-full text-sm text-gray-800 dark:text-slate-200">
            <thead>
                <tr>
                    <th className="w-32" />
                    <th />
                </tr>
            </thead>

            <tbody className="[&>*:nth-child(even)]:bg-slate-50 dark:[&>*:nth-child(even)]:bg-slate-800">
                {
                    properties.length ? (
                        properties.map((item, i) => (
                            <tr key={`property-${i}`}>
                                <td className="p-3 font-medium">{item.caption}</td>
                                <td className="p-3">
                                    {
                                        item.values.map((val, j) => (
                                            <span
                                                key={`property-${i}-${j}`}
                                                className="rounded-md py-1 px-2 bg-slate-100 dark:bg-slate-700 ml-1"
                                            >
                                                {val}
                                            </span>
                                        ))
                                    }
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2}>
                                <p className="global-error mb-1">مشخصه ای برای نمایش وجود ندارد</p>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}

export default memo(Properties);