import Loading from "components/shared/loading";
import Modal from "components/shared/modal";
import SwitchButton from "components/shared/switchButton";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useId, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const EditModal = props => {
    const {
        data,
        close,
        mode
    } = props;

    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data: defaultData, error } = UseFetch(fetchUrl);
    const id = useId();
    const [path, setPath] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [visible, setVisible] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (data.show)
            setFetchUrl(`/api/permission/${mode}/routes/get?id=${data.id}`);

        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        if (!isLoading && !error) {
            setTitle(defaultData.data.title || "");
            setPriority(defaultData.data.priority || "");
            setVisible(defaultData.data.visible || false);
            setDescription(defaultData.data.description || "");
            setPath(defaultData.data.path);
        }
    }, [defaultData, isLoading, error])

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        if (title.length === 0) {
            toast.error("عنوان را وارد نمایید");
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: `/api/permission/${mode}/routes/edit`,
                method: "PUT",
                payloadType: 3,
                data: {
                    id: data.id,
                    title,
                    description,
                    priority: parseInt(priority),
                    visible,
                    ui_group_id: ""
                },
                callback: () => setSubmitLoading(false)
            });

            close();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal show={data.show} title="ویرایش مسیر" closeHandler={close}>
            {
                !isLoading ? (
                    !error ? (
                        <div className="px-1 py-4">
                            <form onSubmit={submitHandler}>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className={`text-field${path.length ? " has-val" : ""}`}>
                                        <label htmlFor={`${id}_route_title`}>مسیر</label>

                                        <input
                                            type="text"
                                            id={`${id}_route_title`}
                                            value={path}
                                            onChange={() => { }}
                                            disabled
                                        />
                                    </div>

                                    <div className={`text-field${title.length ? " has-val" : ""}`}>
                                        <label htmlFor={`${id}_route_title`}>عنوان</label>

                                        <input
                                            type="text"
                                            id={`${id}_route_title`}
                                            value={title}
                                            onChange={e => setTitle(e.target.value)}
                                        />
                                    </div>

                                    <div className={`textarea-c1${description.length ? " has-val" : ""}`}>
                                        <label htmlFor={`${id}_route_description`} className="title">توضیحات</label>

                                        <TextareaAutosize
                                            minRows={1}
                                            id={`${id}_route_description`}
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                    </div>

                                    <div className={`text-field grow items-stretch${priority?.length ? " has-val" : ""}`}>
                                        <label htmlFor={`${id}_route_priority`}>مقدار</label>

                                        <input
                                            type="number"
                                            id={`${id}_route_priority`}
                                            value={priority}
                                            onChange={e => setPriority(e.target.value)}
                                            min={0}
                                        />
                                    </div>

                                    <label className="noselect checkbox-holder">
                                        <SwitchButton
                                            checked={visible}
                                            changeHandler={() => setVisible(prev => !prev)}
                                        />

                                        <span className="tp-27">نمایش</span>
                                    </label>

                                    <button type="submit" className="bg-primary-100 w-full text-white rounded-md text-sm py-3 hover:bg-primary-200 transition-colors relative overflow-hidden">
                                        ثبت تغییرات

                                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                                <i className="fa-solid fa-spinner spin"></i>
                                            </span>
                                        </CSSTransition>
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                ) : (
                    <div className="fit-center">
                        <Loading className="loading-c5" />
                    </div>
                )
            }
        </Modal >
    )
}

export default memo(EditModal);