const commentStatuses = [
    {
        title: "در انتظار تایید",
        icon: "fa-regular fa-hourglass-clock text-green-500"
    },
    {
        title: "تایید شده",
        icon: "fa-regular fa-check text-green-500"
    },
    {
        title: "رد شده",
        icon: "fa-regular fa-xmark text-red-500"
    },
    {
        title: "حذف شده",
        icon: "fa-regular fa-trash text-red-500"
    }
]

export default commentStatuses;