import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    <div className="wrap-box tp-22 page-transition flex flex-col">
        <Skeleton className="w-20 h-4" />

        <div className="grow mt-6">
            <ul className="user-info">
                {
                    Object.keys([...Array(5)]).map(i => (
                        <li key={`user-info-skeleton-${i}`}>
                            <span className="col-t">
                                <Skeleton className={`${i % 2 === 0 ? "w-full" : "w-1/2"} h-3`} />
                            </span>

                            <b className="col-v">
                                <Skeleton className="w-24 h-3" />
                            </b>
                        </li>
                    ))
                }
            </ul>
        </div>

        <Skeleton className="h-14 rounded-sm" />
    </div>
)

export default memo(SkeletonComponent);