import InitializeContext from "context/initialize-context";
import { memo, useCallback, useContext, useMemo, useState } from "react"
import FormGenerator from "components/form-generator/main/main";
import { Collapse } from "react-collapse";
import { CSSTransition } from "react-transition-group";

const SubForm = props => {
    const {
        formId,
        removeCallback,
        updateSubForm,
        data = {},
        productUrl
    } = props;

    const defaultValues = useMemo(() => {
        return {
            ...data.fields,
            provider_id: data.provider?.id
        }
        // eslint-disable-next-line
    }, [])

    const enumsData = useContext(InitializeContext);
    const [showFields, setShowFields] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const [parentLoading, setParentLoading] = useState(false);

    const setParentLoadingCallback = useCallback(val => {
        setParentLoading(val);
    }, [])

    const doneCallback = res => {
        setShowFields(false);
        updateSubForm(data.id, res.data);
        // eslint-disable-next-line
    }

    const fields = useMemo(() => {
        let newFields = [];

        enumsData.data.formElements.forEach(field => {
            if (field.form_id === formId) {
                newFields.push({
                    ...field,
                    name: `e${field.id}_${field.name}`
                });
            }
        });

        const intendedForm = enumsData.data.forms.find(form => form.id === formId);
        if (intendedForm?.parent_id) {
            const parentForm = enumsData.data.forms.find(form => form.id === intendedForm.parent_id);
            if (parentForm?.is_provider) {
                newFields = [
                    {
                        caption: "تامین کننده",
                        name: "provider_id",
                        data_type: 1,
                        input_type: 17,
                        setting: {
                            "get_url": `/api/content/list?id=${defaultValues?.provider_id}&form_id=${intendedForm.parent_id}`,
                            "search_url": `/api/content/list?form_id=${intendedForm.parent_id}&key=:key`,
                            "value_key": "id",
                            "label_key": "virtual_title"
                        },
                        status_id: 1,
                        required_field: true
                    },
                    {
                        input_type: 21
                    },
                    ...newFields
                ];
            }
        }

        return newFields;
        // eslint-disable-next-line
    }, [])

    const editMode = Object.keys(data.fields || {}).length > 0;
    return (
        <div className="border border-gray-200 dark:border-slate-700 rounded-md last:mb-0 overflow-hidden grow">
            <div className="bg-gray-50 dark:bg-slate-800 flex justify-between items-center">
                <div className="text-gray-600 dark:text-gray-300">
                    <button type="button" onClick={() => setShowFields(prev => !prev)} className="py-2 px-4">
                        <i className={`fa-solid fa-chevron-down transition-transform ${showFields ? "rotate-180" : ""}`}></i>
                    </button>

                    <span className="text-sm">{data.virtual_title}</span>
                </div>

                <div className="flex p-2">
                    {
                        editMode && (
                            <>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={productUrl}
                                    className="text-gray-700 dark:text-slate-200 bg-gray-200 px-3 fit-center dark:bg-slate-700 rounded-md text-sm fit-center"
                                >
                                    <i className="fa-regular fa-link"></i>
                                </a>

                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`/panel/content/${data.id}?form_id=${formId}`}
                                    className="text-gray-700 dark:text-slate-200 bg-gray-200 px-3 fit-center dark:bg-slate-700 rounded-md text-sm fit-center mr-1"
                                >
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </a>
                            </>
                        )
                    }

                    <button
                        type="button"
                        className="bg-green-400 transition-colors hover:bg-green-500 rounded-md text-white text-sm py-1 px-3 mx-1 relative overflow-hidden"
                        onClick={() => setGoSubmit(prev => prev + 1)}
                    >
                        ثبت

                        <CSSTransition in={parentLoading} timeout={300} unmountOnExit={true}>
                            <span className="pos-cover fit-center bg-green-500">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>

                    <button
                        type="button"
                        className="bg-red-400 transition-colors hover:bg-red-500 rounded-md text-white text-sm py-1 px-3"
                        onClick={removeCallback}
                    >
                        حذف
                    </button>
                </div>
            </div>

            <Collapse
                isOpened={showFields}
                theme={{ collapse: 'collapse-anim', content: 'collapse-content' }}
            >
                <div className="p-4">
                    <FormGenerator
                        fields={fields}
                        submitUrl={`/api/content/${editMode ? "edit" : "create"}`}
                        submitMethod={editMode ? "PUT" : "POST"}
                        goSubmit={goSubmit}
                        setParentLoading={setParentLoadingCallback}
                        payloadType={3}
                        defaultValues={defaultValues}
                        doneCallback={doneCallback}
                        subMode={true}
                        preData={{
                            form_id: formId,
                            ...(editMode && { id: data.id })
                        }}
                    />
                </div>
            </Collapse>
        </div>
    )
}

export default memo(SubForm);