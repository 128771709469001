import React, { memo, useContext, useState } from "react";
import { GetFinancialUserTypeLink } from "constants/financial-user-type";
import orderStatuses from "constants/order-statuses";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import { toMoneyFormat } from "utils";
import { isSingleProvirderMode } from "constants/config";
import { calculateDiscountPercentage } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import EnumsContext from "context/initialize-context";
import OrderCard from "components/order/view/orderCard/orderCard";

const SubOrders = props => {
    const {
        data,
        serviceData,
        mutate
    } = props;

    const enums = useContext(EnumsContext);
    const [deliveredLoading, setDeliveredLoading] = useState([]);

    const deliveredHandler = async id => {
        if (deliveredLoading.indexOf(id) !== -1)
            return;

        setDeliveredLoading(prev => [...prev, id]);
        try {
            const res = await MasterAPI({
                url: "/api/v2/financial/order/change_status",
                method: "PUT",
                payloadType: 3,
                data: {
                    id,
                    status_id: 5
                },
                callback: () => setDeliveredLoading(prev => prev.filter(el => el !== id))
            });

            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        data.data.orders.map(order => {
            const thisStatus = orderStatuses.find(status => status.id === order.status_id);

            return (
                <div className="wrap-box tp-22 page-transition mt-4" key={`order-ss-${order.id}`}>
                    <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800 justify-between">
                        <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                        <p className="text-sm text-green-800 dark:text-slate-300 grow">
                            <b className="inline-block ml-2">وضعیت سفارش</b>
                            <span>{thisStatus.title}</span>
                        </p>

                        {
                            !isSingleProvirderMode && order.status_id === 4 && (
                                <button
                                    onClick={() => deliveredHandler(order.id)}
                                    className="text-white text-xs bg-primary-100 hover:bg-primary-200 transition-colors rounded-md py-1 px-2 relative overflow-hidden"
                                >
                                    تحویل داده شده

                                    <CSSTransition in={deliveredLoading.indexOf(order.id) !== -1} timeout={300} unmountOnExit={true}>
                                        <div className="pos-cover fit-center anim-c4 bg-primary-200">
                                            <i className="fa-solid fa-spinner spin"></i>
                                        </div>
                                    </CSSTransition>
                                </button>
                            )
                        }
                    </div>

                    <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                        <ul className="order-detail">
                            <li className="inline">
                                <span>شناسه سفارش</span>
                                <b>{order.id}</b>
                            </li>

                            <li className="inline-flex">
                                <span>مبلغ فاکتور</span>
                                <b>{toMoneyFormat(order.factor.price)}</b>
                            </li>
                        </ul>
                    </div>

                    <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                        <ul className="order-detail">
                            <li className="inline">
                                <span>تاریخ تایید</span>
                                <b>{order.delivered_at ? moment.unix(order.delivered_at).format("hh:mm - jD/jMM/jYYYY") : "---"}</b>
                            </li>

                            <li className="inline">
                                <span>تاریخ تحویل</span>
                                <b>{order.delivered_at ? moment.unix(order.delivered_at).format("hh:mm - jD/jMM/jYYYY") : "---"}</b>
                            </li>
                        </ul>
                    </div>

                    <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                        <ul className="order-detail">
                            <li className="inline-flex items-center">
                                <span>تامین کننده</span>
                                <b>
                                    <Link to={GetFinancialUserTypeLink(2, order.provider_id)?.link || ""} className="link-c1">
                                        {order.title_provider_user}
                                    </Link>
                                </b>
                            </li>

                            <li className="inline-flex items-center">
                                <span>پروفایل تامین کننده</span>
                                <b>
                                    <Link to={`/panel/provider-profile-detail?form_id=${order.provider_form_id}&profile_id=${order.provider_oid}`} className="link-c1">
                                        {order.title_provider_profile}
                                    </Link>
                                </b>
                            </li>
                        </ul>
                    </div>

                    {
                        serviceData?.items?.length && (
                            <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                {
                                    serviceData?.items?.map(el => {
                                        if (el.order_id === order.id)
                                            return <OrderCard order={el} enums={enums} key={`${order.id}-product-${el.id}`} />

                                        return <React.Fragment key={`${order.id}-product-${el.product_id}`}></React.Fragment>
                                    })
                                }
                            </div>
                        )
                    }

                    <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                        <Link
                            to={`/panel/transactions?order_oid=${order.id}`}
                            className="text-sm bg-green-500 hover:bg-green-600 rounded-md text-white px-2 py-1 dark:bg-slate-500 dark:hover:bg-slate-600 transition-colors"
                        >
                            نمایش تراکنش های سفارش
                        </Link>
                    </div>

                    <div className="flex factor-sum items-stretch rounded-md overflow-hidden mt-4">
                        <div className="bg-primary-100 text-white px-3 fit-center">
                            <i className="fa-regular fa-square-list"></i>
                        </div>

                        <div className="grow">
                            <ul>
                                <li className="has-bb">
                                    <span>قیمت اصلی کالاها</span>
                                    <b>
                                        {toMoneyFormat(order?.factor?.total)}{" "}
                                        <span className="unit">تومان</span>
                                    </b>
                                </li>

                                {
                                    order?.factor?.profit > 0 && (
                                        <li>
                                            <span>تخفیف کالاها</span>
                                            <b>
                                                <span className="text-sm font-normal text-gray-500">
                                                    ({calculateDiscountPercentage(order?.factor?.total, order?.factor?.total - order?.factor?.profit)}%)
                                                </span> {" "}

                                                {toMoneyFormat(order?.factor?.profit)} {" "}
                                                <span className="unit"> تومان</span>
                                            </b>
                                        </li>
                                    )
                                }

                                <li className="has-bb">
                                    <span>قیمت پس از تخفیف</span>
                                    <b>
                                        {toMoneyFormat(order?.factor?.total - order?.factor?.profit)} {" "}
                                        <span className="unit"> تومان</span>
                                    </b>
                                </li>

                                {
                                    order?.factor?.layers.length > 0 && (
                                        order?.factor?.layers.map(layer => (
                                            <li key={`layer-section-${layer.id}`}>
                                                <span>{layer.title}</span>
                                                <b>
                                                    {toMoneyFormat(layer.amount)}{" "}
                                                    <span className="unit">تومان</span>
                                                </b>
                                            </li>
                                        ))
                                    )
                                }

                                <li className={order?.factor?.layers.length ? "has-bt" : ""}>
                                    <span>جمع سبد خرید</span>
                                    <b>
                                        {toMoneyFormat(order?.factor?.price)}{" "}
                                        <span className="unit">تومان</span>
                                    </b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

export default memo(SubOrders);