import { useEffect } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { refreshTokenAction, setAccessToken, setAuthorizationProcess } from "redux/slices/main";
import { nowTimestamp } from "utils";

const AuthController = () => {
    const dispatch = useDispatch();

    const handleAuthorization = async () => {
        const nowTime = nowTimestamp();

        const refreshToken = localStorage.getItem("_rt");
        let refreshTokenExpireTime = localStorage.getItem("_rtet");
        refreshTokenExpireTime = refreshTokenExpireTime && !isNaN(refreshTokenExpireTime) ? parseInt(refreshTokenExpireTime) : 0;

        const accessToken = localStorage.getItem("_at");
        let accessTokenExpireTime = localStorage.getItem("_atet");
        accessTokenExpireTime = accessTokenExpireTime && !isNaN(accessTokenExpireTime) ? parseInt(accessTokenExpireTime) : 0;

        if (refreshToken && refreshTokenExpireTime > nowTime) {
            if (accessToken && accessTokenExpireTime > nowTime) {
                dispatch(setAuthorizationProcess(true));
                dispatch(setAccessToken({ accessToken, accessTokenExpireTime }));
            } else
                dispatch(refreshTokenAction({ refreshToken }));
        } else
            dispatch(setAuthorizationProcess(true));
    }

    useEffect(() => {
        handleAuthorization();
        // eslint-disable-next-line
    }, [])
}

export default memo(AuthController);