import Modal from "components/shared/modal";
import { memo, useState, useEffect, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import Checkbox from "components/shared/inputs/checkbox";
import MasterAPI from "service/master";
import InitializeContext from "context/initialize-context";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import SwitchButton from "components/shared/switchButton";
import Loading from "components/shared/loading";

const groupPoliciesItems = [
    { id: 1, label: "کپی" },
    { id: 2, label: "کپی متصل" },
    { id: 3, label: "قالب" }
];

const EditModal = props => {
    const { data: modalData, closeHandler, mode } = props;
    const { id } = useParams();
    const enums = useContext(InitializeContext);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});
    const [selectedGroupPolicies, setSelectedGroupPolicies] = useState([]);
    const [selectedElements, setSelectedElements] = useState([]);
    const [statusId, setStatusId] = useState(false);

    useEffect(() => {
        if (modalData.show)
            fetcher();

        // eslint-disable-next-line
    }, [modalData])

    useEffect(() => {
        if (!isLoading && !error) {
            setSelectedGroupPolicies(data.data.policies || []);
            setSelectedElements(data.data.elemets_exclude_group_template || []);
            setStatusId(!!data.data.status_id);
        }

        // eslint-disable-next-line
    }, [data, isLoading, error])

    const elements = useMemo(() => {
        if (modalData.show) {
            const newElements = enums.data.formElements.filter(el => el.form_id === modalData.id);
            return newElements;
        }

        return [];
        // eslint-disable-next-line
    }, [modalData])

    const fetcher = async () => {
        try {
            const res = await MasterAPI({
                url: `/api/permission/${mode}/forms_group_policies/get?id=${id}&form_id=${modalData.id}`,
                method: "GET"
            });

            setData(res);
            setIsLoading(false);
        } catch {
            setError(true);
        }
    }

    const groupPoliciesChangeHandler = id => {
        setSelectedGroupPolicies(prev => {
            if (selectedGroupPolicies.indexOf(id) !== -1)
                return prev.filter(item => item !== id);
            else
                return [...prev, id];
        });
    }

    const elementsChangeHandler = id => {
        setSelectedElements(prev => {
            if (selectedElements.indexOf(id) !== -1)
                return prev.filter(item => item !== id);
            else
                return [...prev, id];
        });
    }

    const submitHandler = async () => {
        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: `/api/permission/${mode}/forms_group_policies/edit`,
                method: "PUT",
                payloadType: 3,
                data: {
                    id,
                    form_id: modalData.id,
                    policies: selectedGroupPolicies,
                    elemets_exclude_group_template: selectedElements,
                    status_id: statusId ? 1 : 0
                },
                callback: () => setSubmitLoading(false)
            });

            closeHandler();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal show={modalData.show} title="ویرایش فرم" closeHandler={closeHandler}>
            {
                !isLoading ? (
                    !error ? (
                        <>
                            <label className="noselect checkbox-holder mb-5">
                                <SwitchButton
                                    checked={statusId}
                                    changeHandler={() => setStatusId(prev => !prev)}
                                />

                                <span className="tp-27">وضعیت</span>
                            </label>

                            {
                                groupPoliciesItems.map(item => (
                                    <div key={`group-policy-${item.id}`}>
                                        <label className="inline-flex mb-1 cursor-pointer noselect">
                                            <Checkbox
                                                value={selectedGroupPolicies.indexOf(item.id) !== -1}
                                                changeHandler={() => groupPoliciesChangeHandler(item.id)}
                                            />

                                            <span className="tp-6 text-sm mr-2">{item.label}</span>
                                        </label>
                                    </div>
                                ))
                            }

                            {
                                selectedGroupPolicies.indexOf(3) !== -1 && (
                                    elements.length > 0 ? (
                                        <div className="table-c1 mt-5 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="w-5"></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        elements.map(field => (
                                                            <tr key={`field-row-${field.id}`}>
                                                                <td>
                                                                    <Checkbox
                                                                        value={selectedElements.indexOf(field.id) !== -1}
                                                                        changeHandler={() => elementsChangeHandler(field.id)}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <p className="tp-25 text-sm">
                                                                        {field.caption}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <p className="text-sm text-red-600">فرم مورد نظر فیلدی ندارد</p>
                                    )
                                )
                            }

                            <button className="bg-primary-100 w-full text-white rounded-md text-sm py-3 mt-3 hover:bg-primary-200 transition-colors relative overflow-hidden" onClick={submitHandler}>
                                ثبت تغییرات

                                <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                                    <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                        <i className="fa-solid fa-spinner spin"></i>
                                    </span>
                                </CSSTransition>
                            </button>
                        </>
                    ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                ) : (
                    <div className="fit-center">
                        <Loading className="loading-c5" />
                    </div>
                )
            }
        </Modal>
    )
}

export default memo(EditModal);