import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MasterAPI from 'service/master';

const initialState = {
    authorizationProcess: false,
    authorization: false,
    accessToken: null,
    info: {
        fetched: false,
        error: false,
        data: {},
    }
}

export const refreshTokenAction = createAsyncThunk(
    'refreshTokenAction',
    async data => {
        const { refreshToken, reloadPage = false } = data;

        const res = await MasterAPI({
            url: "/api/user/refresh_token",
            sendAuthorizationHeader: false,
            data: {
                refresh_token: refreshToken
            }
        });

        if (res.access_token && res.refresh_token)
            return { result: res, reloadPage };
        else
            throw Object.assign(new Error("access token not exists"));
    }
)

export const fetchInfo = createAsyncThunk(
    'fetchInfo',
    async () => {
        try {
            const fetchUserInfo = await MasterAPI({
                method: "GET",
                url: "/api/user/get_profile"
            });

            if (fetchUserInfo.profile)
                return fetchUserInfo.profile;
            else
                throw Object.assign(new Error("data error"));
        } catch {
            throw Object.assign(new Error("data error"));
        }
    }
)

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        overwriteState: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setMainState: (_, action) => {
            return action.payload;
        },
        setAccessToken: (state, action) => {
            const { accessToken, accessTokenExpireTime } = action.payload;

            return {
                ...state,
                authorizationProcess: true,
                authorization: true,
                accessToken,
                accessTokenExpireTime
            }
        },
        setAuthorizationProcess: (state, action) => {
            state.authorizationProcess = action.payload;
        },
        setUserInfo: (state, action) => {
            return {
                ...state,
                info: {
                    data: action.payload,
                    fetched: true,
                    errror: false
                }
            }
        },
        setEnableReloadAction: state => {
            state.enableReloadAction = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshTokenAction.fulfilled, (state, action) => {
            const {
                result: {
                    access_token,
                    access_token_expire_in,
                    refresh_token,
                    refresh_token_expire_in
                },
                reloadPage
            } = action.payload;

            localStorage.setItem("_at", access_token);
            localStorage.setItem("_atet", access_token_expire_in);

            localStorage.setItem("_rt", refresh_token);
            localStorage.setItem("_rtet", refresh_token_expire_in);

            if(reloadPage)
                window.location.reload();

            return {
                ...state,
                authorizationProcess: true,
                authorization: true,
                accessToken: access_token,
                accessTokenExpireTime: access_token_expire_in
            }
        })

        builder.addCase(refreshTokenAction.rejected, state => {
            localStorage.removeItem("_at");
            localStorage.removeItem("_atet");
            localStorage.removeItem("_rt");
            localStorage.removeItem("_rtet");

            return {
                ...state,
                authorizationProcess: true,
                authorization: false,
                accessToken: null
            }
        })

        // user info
        builder.addCase(fetchInfo.fulfilled, (state, action) => {
            return {
                ...state,
                info: {
                    fetched: true,
                    data: action.payload
                }
            }
        })

        builder.addCase(fetchInfo.pending, state => {
            return {
                ...state,
                info: {
                    fetched: false,
                    data: {},
                    error: false
                }
            }
        })

        builder.addCase(fetchInfo.rejected, state => {
            return {
                ...state,
                info: {
                    fetched: true,
                    error: true,
                    data: {}
                }
            }
        })
    }
})

export const {
    setAccessToken,
    setAuthorizationProcess,
    overwriteState,
    setMainState,
    setUserInfo,
    setEnableReloadAction
} = mainSlice.actions

export default mainSlice.reducer;