import { memo, useState } from "react";
import Modal from "components/shared/modal";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { CSSTransition } from "react-transition-group";

const FixCrop = props => {
    const { data, mutate } = props;

    const [editNameLoading, setFixCropLoading] = useState(false);
    const [showFixCropModal, setShowFixCropModal] = useState(false);
    const [newConcurency, setConcurency] = useState(1);

    const cancelHandler = async e => {
        e.preventDefault();

        if (editNameLoading)
            return;

        setFixCropLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/file_identity/manager/fix_crop_size",
                payloadType: 3,
                method: "PATCH",
                data: {
                    concurency: parseInt(newConcurency),
                },
                callback: () => setFixCropLoading(false)
            });

            setShowFixCropModal(false);
            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <button
                className={`bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 mr-2 relative overflow-hidden`}
                onClick={() => setShowFixCropModal(true)}
            >
                <i className="fa-light fa-pen-to-square ml-2"></i>
                اعمال برش تصاویر جدید
            </button>

            <Modal
                title="اعمال برش تصاویر جدید"
                show={showFixCropModal}
                closeHandler={() => setShowFixCropModal(false)}
            >
                <form onSubmit={cancelHandler}>
                    <span className="block text-sm mb-4">همزمانی</span>
                    <textarea
                        value={newConcurency}
                        className="border w-full rounded-lg p-3 text-sm"
                        onChange={e => setConcurency(e.target.value)}
                    />

                    <button type="submit" className="rounded-lg bg-primary-100 py-2 px-4 text-white text-sm mt-4 relative overflow-hidden">
                        اجرا

                        <CSSTransition in={editNameLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </form>
            </Modal>
        </>
    )
}

export default memo(FixCrop);