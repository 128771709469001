import { memo } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams } from "react-router-dom";
import UserData from "../common/user-data/user-data";
import Ticket from "../common/ticket/ticket";
import Financial from "../common/financial/financial";
import Orders from "../common/orders/orders";
import Content from "../common/content/content";
import UseFetch from "hooks/useFetch";
import Profiles from "./profiles/list/profiles";
import LinksHolder from "../common/links-holder/links-holder";

const Provider = () => {
    const { id } = useParams();
    const { isLoading, error, data } = UseFetch(`/api/summary/by_provider?id=${id}`);

    return (
        <div className="m-container user-detail">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات تامین کننده</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "تامین کنندگان", link: "/panel/identity_provider" },
                        { title: "جزئیات تامین کننده" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/identity_provider" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            {
                !error ? (
                    <>
                        <div className="mb-4">
                            <div className="grid md:grid-cols-2 gap-4">
                                <UserData
                                    loading={isLoading}
                                    data={{ data: data.data?.profile?.user }}
                                    showScore={false}
                                    userType="provider"
                                />

                                <Ticket loading={isLoading} data={{ data: data.data }} userType="provider" />
                            </div>
                        </div>

                        <div className="mb-4">
                            <LinksHolder userType="provider" />
                        </div>

                        <div className="mb-4">
                            <Profiles loading={isLoading} data={{ data: data.data?.profile?.profiles }} />
                        </div>

                        <div className="mb-4">
                            <Financial loading={isLoading} data={{ data: data.data }} userType="provider" />
                        </div>

                        <div className="mb-4">
                            <Orders loading={isLoading} data={{ data: data.data }} userType="provider" />
                        </div>

                        <div className="mb-4">
                            <Content loading={isLoading} data={{ data: data.data }} userType="provider" />
                        </div>
                    </>
                ) : (
                    <div className="wrap-box tp-22 page-transition">
                        <p className="global-error">خطا در دریافت اطلاعات !</p>
                    </div>
                )
            }
        </div>
    )
}

export default memo(Provider);