import userKinds from "constants/user-kinds";
import { useEffect, useState } from "react";
import extendDataFetcher from "service/extend-data-fetcher";

const useExtendData = (isLoading, error, data) => {
    const [localData, setLocalData] = useState({});

    useEffect(() => {
        if (!isLoading && !error)
            fetcher(data.data);

        // eslint-disable-next-line
    }, [isLoading, error, data])

    const fetcher = async serviceData => {
        const routes = [];
        const userKindEnTitle = userKinds[serviceData.user_kind];

        if (userKindEnTitle !== "guest")
            routes.push({
                identifier: "userData",
                url: `/api/identity/${userKinds[serviceData.user_kind].enTitle}/get?id=${serviceData.user_id}`
            });

        const newData = await extendDataFetcher(routes);
        setLocalData(newData);
    }

    return localData;
}

export default useExtendData;