import useOutsideClick from "hooks/useOutsideClick";
import { memo, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Input from "./input";

const Searchbox = () => {
    const [visible, setVisible] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setVisible(false));

    return (
        <div className="lg:hidden" ref={wrapperRef}>
            <button className={`tp-7${visible ? " active" : ""}`} onClick={() => setVisible(prev => !prev)}>
                <i className="fa-regular fa-magnifying-glass"></i>
            </button>

            <CSSTransition in={visible} timeout={{enter: 200, exit: 0}} unmountOnExit={true}>
                <div className="searchbox fixed p-4 tp-2 border border-t-0 anim-c1 tp-13">
                    {/* <Cats setCat={setSelectedCatCallback} /> */}
                    <Input visibleResultBox={false} />
                </div>
            </CSSTransition>
        </div>
    )
}

export default memo(Searchbox);