import { memo } from "react"

const AutoRequestBtn = props => {
    const { clickHandler, active } = props;

    return (
        <button
            className={`autoRequestBtn h-10 tp-22 text-[#424e79] dark:text-white ${active ? "active" : ""}`}
            onClick={clickHandler}
        >
            <i className="circle" />
            بروزرسانی خودکار
        </button>
    )
}

export default memo(AutoRequestBtn);