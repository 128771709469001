import Breadcrumb from "components/shared/breadcrumb";
import { smallCropSize } from "constants/config";
import UseFetch from "hooks/useFetch";
import { memo } from "react"
import { Link, useParams } from "react-router-dom";
import { getProductImage, toMoneyFormat } from "utils";

const View = () => {
    const { id } = useParams();
    const { isLoading, data, error } = UseFetch(`/api/basket/current/get?user_kind_id=1&id=${id}`);

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">سبد کاربران</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "سبد کاربران", link: "/panel/basket" },
                        { title: "جزئیات سبد خرید" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/basket" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            {
                !isLoading ? (
                    !error ? (
                        <>
                            <div className="wrap-box tp-22 page-transition">
                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نام</span>
                                            <b>{data.data.profile.name || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نام خانوادگی</span>
                                            <b>{data.data.profile.last_name || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>موبایل</span>
                                            <b>{data.data.profile.mobile || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>ایمیل</span>
                                            <b>{data.data.profile.email || "---"}</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {
                                Object.values(data.data.basket).map(basket => {
                                    return (
                                        <div className="wrap-box tp-22 page-transition mt-4" key={`basket-${basket.id}`}>
                                            <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                                <ul className="order-detail">
                                                    <li className="inline-flex items-center">
                                                        <span>شناسه محصول</span>
                                                        <b>
                                                            <Link to={`/panel/content?form_id=${basket.form_id}&id=${basket.id}`}>#{basket.id}</Link>
                                                        </b>
                                                    </li>

                                                    <li className="inline-flex items-center">
                                                        <span>پروفایل</span>
                                                        <b>
                                                            <Link to={`/panel/content/${basket.id}?form_id=${basket.form_id}`}>
                                                                #{basket.provider_id}
                                                            </Link>
                                                        </b>
                                                    </li>

                                                    <li className="inline">
                                                        <span>عنوان</span>
                                                        <b>{basket.title || "---"}</b>
                                                    </li>

                                                    <li className="inline">
                                                        <span>تعداد</span>
                                                        <b>{basket.count}</b>
                                                    </li>

                                                    <li className="inline">
                                                        <span>قیمت</span>
                                                        <b>{toMoneyFormat(basket.price)} <span className="mr-1"> تومان</span></b>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="py-4">
                                                <ul className="order-detail">
                                                    <li className="inline-flex items-center">
                                                        <Link to={`/panel/content?form_id=${basket.form_id}&id=${basket.id}`} className="hover:opacity-90 transition-opacity">
                                                            <img
                                                                src={getProductImage(basket.meta_fields.e18_images, smallCropSize)}
                                                                alt={basket.title}
                                                                width={100}
                                                                height={100}
                                                                className="rounded-lg ml-2"
                                                            />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <span className="text-sm text-red-500">خطای سیستم !</span>
                        </div>
                    )
                ) : (
                    Object.keys([...Array(3)]).map(i => (
                        <div className="skeleton-box rounded-sm w-full h-24 mb-4" key={`basket-skeleton-${i}`}>
                            <span />
                        </div>
                    ))
                )
            }
        </div >
    )
}

export default memo(View);