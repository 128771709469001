import List from "./list/list";
import templateFetcherHook from "service/template-fetcher-hook";
import { useLocation, useParams } from "react-router-dom";
import Edit from "./edit/edit";
import Add from "./add/add";
import { useCallback, useState, useEffect, memo } from "react";

const TableGrid = props => {
    const {
        template
    } = props;

    const [serverBreadcrumb, setServerBreadcrumb] = useState([]);
    const [serverBreadcrumbData, setServerBreadcrumbData] = useState([]);
    const { data: pageData } = templateFetcherHook(template);
    const { id } = useParams();
    const location = useLocation();

    const setServerBreadcrumbDataCallback = useCallback(newData => {
        setServerBreadcrumbData(newData);
    }, [])

    useEffect(() => {
        if (serverBreadcrumbData.length) {
            let newBreadcrumb = [];
            const breadcrumb = [...serverBreadcrumbData];
            breadcrumb.reverse();

            breadcrumb.forEach(el => {
                const thisObj = {
                    title: el.title,
                    link: `?parent_id=${el.id}`
                }

                newBreadcrumb.push(thisObj);
            })

            newBreadcrumb = [
                {
                    title: pageData.title,
                    link: location.pathname
                },
                ...newBreadcrumb
            ]

            setServerBreadcrumb(newBreadcrumb);
        } else
            setServerBreadcrumb([]);

        // eslint-disable-next-line
    }, [serverBreadcrumbData])

    return (
        <div className="m-container">
            {
                id ? (
                    id === "add" ? (
                        <Add
                            pageData={pageData}
                            id={id} />
                    ) : (
                        <Edit
                            pageData={pageData}
                            id={id}
                            serverBreadcrumb={serverBreadcrumb}
                            setServerBreadcrumbDataCallback={setServerBreadcrumbDataCallback} />
                    )
                ) : (
                    <List
                        pageData={pageData}
                        serverBreadcrumb={serverBreadcrumb}
                        setServerBreadcrumbDataCallback={setServerBreadcrumbDataCallback} />
                )
            }
        </div>
    )
}

export default memo(TableGrid);