import { memo, useId, useState } from "react";
import SwitchButton from "components/shared/switchButton";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";

const Upload = props => {
    const { mutate } = props;

    const id = useId();
    const [overwrite, setOverwrite] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [file, setFile] = useState(null);

    const fileChangeHandler = e => {
        const selectedFiles = e.target.files;

        if (selectedFiles.length)
            setFile(selectedFiles[0]);
    }

    const submitHandler = async () => {
        if (submitLoading)
            return;

        if (file === null) {
            toast.error("فایلی را انتخاب نمایید");
            return;
        }

        setSubmitLoading(true);
        try {
            const data = new FormData();
            data.append("file", file);
            data.append("overwrite", overwrite);
            data.append("site_id", 1);

            const res = await MasterAPI({
                url: "/api/upload/static/file",
                payloadType: 2,
                data,
                callback: () => setSubmitLoading(false)
            })

            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="bg-blue-50 rounded-md mt-8 p-4 relative dark:bg-slate-900">
            <h2 className="font-medium text-slate-700 dark:text-slate-300 text-sm mb-6">آپلود فایل</h2>
            <img src="/assets/image/upload.svg" alt="upload" className="w-60 absolute -top-10 left-0 hidden md:block" />

            <div className="inline-block">
                <div className="text-sm flex items-center">
                    <div>
                        <label htmlFor={`${id}_file`} className="cursor-pointer inline-block border border-dashed rounded-lg px-10 py-3 border-blue-300 text-slate-700 dark:text-slate-300">
                            <i className="fa-regular fa-file ml-4"></i>
                            انتخاب فایل
                        </label>

                        <input type="file" id={`${id}_file`} className="hidden" onChange={fileChangeHandler} />
                    </div>

                    <div className="mr-10">
                        <label className="noselect checkbox-holder">
                            <SwitchButton
                                checked={overwrite}
                                changeHandler={() => setOverwrite(prev => !prev)}
                            />

                            <span className="tp-27">جایگزینی</span>
                        </label>
                    </div>
                </div>

                <button
                    className="bg-primary-100 text-white text-sm rounded-md py-2 block w-full mt-6 hover:bg-primary-200 transition-colors overflow-hidden relative"
                    onClick={submitHandler}
                >
                    ارسال

                    <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c4">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </div>
        </div>
    )
}

export default memo(Upload);