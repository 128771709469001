import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const CropSizesSkeleton = () => (
    Object.keys([...Array(3)]).map(i => (
        <div className="wrap-box tp-22 page-transition flex flex-col items-stretch md:flex-row md:items-center mb-3" key={`skeleton-crop-size-${i}`}>
            <div className="md:ml-3 flex mb-4 md:mb-0">
                <Skeleton className="ml-3 w-12 h-12 min-w-[3rem] rounded-full" />
                <Skeleton className="w-full min-w-[195px] h-12 rounded-full" />
            </div>

            <div className="flex md:ml-3 grow my-2 md:my-0">
                <Skeleton className="grow h-12 rounded-full ml-3" />
                <Skeleton className="grow h-12 rounded-full" />
            </div>

            <div className="flex items-center justify-between mt-3 md:mt-0">
                <Skeleton className="w-24 h-4 rounded-full ml-3" />
                <Skeleton className="w-14 h-7 rounded-md" />
            </div>
        </div>
    ))
)

export default memo(CropSizesSkeleton);