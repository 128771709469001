import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import Upload from "./upload";
import { MEDIA } from "service/routes";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import Loading from "components/shared/loading";

const File = () => {
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const [deleteLoading, setDeleteLoading] = useState([]);
    const [activeDir, setActiveDir] = useState("public");

    useEffect(() => {
        setFetchUrl(`/api/upload/static/file/list?is_trash=${activeDir === "trash" ? "true" : "false"}&site_id=1`);
    }, [activeDir])

    const deleteHandler = async file => {
        if (deleteLoading.indexOf(file) !== -1)
            return;

        setDeleteLoading(prev => [...prev, file]);
        try {
            const res = await MasterAPI({
                url: "/api/upload/static/file/move",
                payloadType: 3,
                method: "PUT",
                data: {
                    site_id: 1,
                    to_trash: true,
                    overwrite: true,
                    filename: file
                },
                callback: () => setDeleteLoading(prev => prev.filter(el => el !== file))
            });

            mutate();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <div className="grid grid-cols-6">
                <ul className="dirs border-l border-gray-200 dark:border-slate-700">
                    <li className={activeDir === "public" ? "active" : ""} onClick={() => setActiveDir("public")}>
                        public
                    </li>

                    <li className={activeDir === "trash" ? "active" : ""} onClick={() => setActiveDir("trash")}>
                        trash
                    </li>
                </ul>

                <div className="pr-3 col-span-5">
                    {
                        !isLoading ? (
                            !error ? (
                                data?.length ? (
                                    <ul className="files">
                                        {
                                            data.map(file => (
                                                <li>
                                                    <div className="grow">
                                                        <a href={`${MEDIA}/static/sites/1/uploads/${file}`} target="_blank" rel="noreferrer">
                                                            {file}
                                                        </a>
                                                    </div>

                                                    {
                                                        deleteLoading.indexOf(file) === -1 ? (
                                                            <button type="button" className="text-red-500" onClick={() => deleteHandler(file)}>
                                                                <i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        ) : (
                                                            <i className="fa-duotone fa-spinner-third text-gray-400"></i>
                                                        )
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                ) : <span className="text-sm text-red-500 block pt-3 py-5 text-center">فایلی یافت نشد</span>
                            ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                        ) : (
                            <div className="fit-center">
                                <Loading className="loading-c5" />
                            </div>
                        )
                    }
                </div>
            </div>

            <Upload mutate={mutate} />
        </>
    )
}

export default memo(File);