import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { css } from "@codemirror/lang-css";
import { dracula } from '@uiw/codemirror-theme-dracula';
import CodeMirror from '@uiw/react-codemirror';
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import Loading from "components/shared/loading";

const CssFile = props => {
    const { file } = props;

    const { isLoading, data, error } = UseFetch(`/api/upload/static/text/get?file_name=${file}&site_id=1`);
    const darkMode = useSelector(state => state.theme.darkMode);
    const [value, setValue] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (!isLoading && !error)
            setValue(data);

    }, [isLoading, error, data])

    const submitHandler = async () => {
        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/upload/static/text/edit",
                method: "PUT",
                payloadType: 3,
                data: {
                    file_name: file,
                    site_id: 1,
                    content: value
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <div className="flex justify-end mb-6">
                <button
                    className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden"
                    onClick={submitHandler}
                >
                    <i className="fa-light fa-floppy-disk ml-2 text-lg"></i>
                    ثبت تغییرات

                    <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c4">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </div>

            {
                !isLoading ? (
                    !error ? (
                        <div className="cm-holder">
                            <CodeMirror
                                value={value}
                                height="500px"
                                extensions={[css()]}
                                onChange={e => setValue(e)}
                                theme={darkMode ? dracula : "light"}
                            />
                        </div>
                    ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                ) : (
                    <div className="fit-center">
                        <Loading className="loading-c5" />
                    </div>
                )
            }
        </>
    )
}

export default memo(CssFile);