import UseFetch from "hooks/useFetch";
import { memo } from "react";
import Balance from "./balance";
import Withdraw from "./withdraw";
import Wallet from "./wallet";
import FinancialSkeleton from "components/skeleton/financial-skeleton";

const Financial = props => {
    const { fetchUrl } = props;
    const { isLoading, data, error } = UseFetch(fetchUrl);

    return (
        <div className="mb-4">
            {
                !isLoading ? (
                    !error ? (
                        <>
                            <Balance data={data.data.balance} />

                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-full xl:col-span-8">
                                    <Withdraw data={data.data.withdraw} />
                                </div>

                                <div className="col-span-full xl:col-span-4">
                                    <Wallet data={data.data.wallet} />
                                </div>
                            </div>
                        </>
                    ) : <h1>error ...</h1 >
                ) : <FinancialSkeleton />
            }
        </div>
    )
}

export default memo(Financial);