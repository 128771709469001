import { memo, useCallback, useState } from "react"
import Input from "./input";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { fetchInfo } from "redux/slices/main";
import { useDispatch } from "react-redux";

const VerifyCode = props => {
    const {
        method,
        setStep,
        currentMethod
    } = props;

    const dispatch = useDispatch();
    const intendedMethod = method === 0 ? currentMethod : method;
    const [smsCode, setSmsCode] = useState("");
    const [emailCode, setEmailCode] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    const smsChangeHandler = useCallback(val => {
        setSmsCode(val);
    }, []);

    const emailChangeHandler = useCallback(val => {
        setEmailCode(val);
    }, []);

    const verifyHandler = async () => {
        if (submitLoading)
            return;

        setSubmitLoading(true);

        let payloadData = { method_id: method };
        if (intendedMethod === 1)
            payloadData.sms_code = parseInt(smsCode);
        else if (intendedMethod === 2)
            payloadData.email_code = parseInt(emailCode);
        else if (intendedMethod === 3)
            payloadData = {
                ...payloadData,
                sms_code: parseInt(smsCode),
                email_code: parseInt(emailCode)
            };

        try {
            const res = await MasterAPI({
                url: "/api/user/change_two_factor_confirm",
                data: payloadData,
                method: "PUT",
                payloadType: 3,
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
            setStep(1);
            dispatch(fetchInfo());
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <button type="button" className="mb-3 text-gray-500 text-sm flex items-center dark:text-slate-200" onClick={() => setStep(1)}>
                <i className="fa-regular fa-arrow-right ml-2"></i>
                بازگشت
            </button>

            {
                intendedMethod === 1 && <Input mode={1} value={smsCode} changeHandler={smsChangeHandler} />
            }

            {
                intendedMethod === 2 && <Input mode={2} value={emailCode} changeHandler={emailChangeHandler} />
            }

            {
                intendedMethod === 3 && (
                    <>
                        <div className="mb-3">
                            <Input mode={1} value={smsCode} changeHandler={smsChangeHandler} />
                        </div>

                        <Input mode={2} value={emailCode} changeHandler={emailChangeHandler} />
                    </>
                )
            }

            <div className="mt-4">
                <button
                    type="submit"
                    className="rounded-md bg-primary-100 hover:bg-primary-200 transition-colors text-white py-2 px-4 text-sm relative overflow-hidden"
                    onClick={verifyHandler}
                >
                    اعتبار سنجی

                    <CSSTransition in={submitLoading} timeout={300} unmountOnExit={true}>
                        <div className="bg-primary-200 fit-center pos-cover anim-c9">
                            <i className="fa-solid fa-spinner spin"></i>
                        </div>
                    </CSSTransition>
                </button>
            </div>
        </>
    )
}

export default memo(VerifyCode);