import SmartLogo from "components/shared/smart-logo";
import { siteTitle } from "constants/config";
import { memo, useState, useCallback } from "react";
import Step1 from "./step-1";
import Step2 from "./step-2";

const ForgetPassword = () => {
    const [mode, setMode] = useState("step1");
    const [phone, setPhone] = useState("");

    const setPhoneCallback = useCallback(val => {
        setPhone(val);
    }, [])

    const setModeCallback = useCallback(val => {
        setMode(val);
    }, [])

    return (
        <>
            <div className="col-r">
                <div className="w-full h-full relative">
                    <div className="fit-center flex-col px-4 py-4">
                        <div className="flex mb-6">
                            <img src="/assets/image/logo/1-mini.png" alt={siteTitle} />
                            <img src="/assets/image/logo/1.png" alt={siteTitle} />
                        </div>

                        <img src="/assets/image/auth.svg" width={90} alt="authentication vector" />

                        <h2 className="text-white font-bold font-lg mt-6 mb-2">ورود به اکانت</h2>
                        <p className="sp-1 text-xs text-center leading-5">جهت مدیریت محصولات، موجودی و سفارشات می توانید با شماره همراه مدیر اکانت وارد شوید</p>
                    </div>
                </div>
            </div>

            <div className="col-l tp-29 px-5 py-8">
                <div className="mb-6 md:hidden">
                    <SmartLogo />
                </div>

                {
                    mode === "step1" && (
                        <Step1
                            phone={phone}
                            setPhone={setPhoneCallback}
                            setMode={setModeCallback}
                        />
                    )
                }

                {
                    mode === "step2" && (
                        <Step2
                            phone={phone}
                            setMode={setModeCallback}
                        />
                    )
                }
            </div>
        </>
    )
}

export default memo(ForgetPassword);