import { faNumToEng, isJson, isObject } from "utils";

const validator = (fields, setFailedFields, enums, submitAction) => {
    const failedFields = {};

    Object.keys(fields).forEach(fieldKey => {
        const field = fields[fieldKey];

        if (!field.changed && !submitAction)
            return

        try {
            if (field.required_field) {
                if (
                    !field.value ||
                    field.value.length === "" ||
                    (field.data_type === 8 && field.value.length === 0) ||
                    (field.input_type === 9 && field.value.length === 0) ||
                    (field.input_type === 10 && field.value.length === 0)
                ) {
                    if (field.htmlId)
                        throw Object.assign(new Error("required"), { code: 1 });
                }
            }

            if (field.validation_id && field.value?.length) {
                const validation = enums[field.validation_id];

                if (validation && validation?.value !== ".*") {
                    const regex = new RegExp(validation.value);
                    const value = faNumToEng(field.value);

                    if (!regex.test(value))
                        throw Object.assign(new Error("format"), { code: 2 });
                }
            }

            if (field.input_type === 19) { // json editor
                if (!isJson(field.value) || !isObject(JSON.parse(field.value)))
                    throw Object.assign(new Error("format"), { code: 3 });
            }
        } catch (e) {
            failedFields[field.name] = e.message;
        }
    })

    setFailedFields(failedFields);
    const failedFieldsKeys = Object.keys(failedFields);

    if (failedFieldsKeys.length) {
        if (submitAction) {
            const intendedField = fields[failedFieldsKeys[0]];
            document.querySelector(`[id='${intendedField.htmlId}']`)?.focus();
        }

        return false;
    }

    return true;
}

export default validator;