import Modal from "components/shared/modal";
import { memo, useCallback, useId, useState } from "react"
import TimePicker from "./time-picker";
import { convertShamsiToUnix, faNumToEng } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";

const AcceptModal = props => {
    const {
        show,
        closeHandler,
        id,
        mutate
    } = props;

    const formId = useId();
    const [trackingCode, setTrackingCode] = useState("");
    const [withdrewAt, setWithdrewAt] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    const changeTrackingCodeHandler = e => {
        const value = faNumToEng(e.target.value);
        const regex = /^[0-9\b]+$/;

        if (value === "" || regex.test(value))
            setTrackingCode(value);
    }

    const changeWithdrewAtHandler = useCallback(val => {
        setWithdrewAt(val);
    }, []);

    const submitHandler = async e => {
        e.preventDefault();

        if(submitLoading)
            return;

        if(trackingCode.length === 0) {
            toast.error("لطفا کد رهگیری را وارد نمایید");
            return;
        }

        if(withdrewAt.length === 0) {
            toast.error("لطفا تاریخ تسویه را انتخاب نمایید");
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/financial/balance/withdraw/approve",
                method: "PUT",
                payloadType: 3,
                data: {
                    id,
                    tracking_code: trackingCode,
                    withdrew_at: convertShamsiToUnix(withdrewAt, true)
                },
                callback: () => setSubmitLoading(false)
            });

            mutate();
            closeHandler();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <Modal show={show} title="تایید تسویه حساب" closeHandler={closeHandler}>
            <div className="pt-4 px-1">
                <form onSubmit={submitHandler}>
                    <div className="mb-4">
                        <div className={`text-field${trackingCode.length ? " has-val" : ""}`}>
                            <label htmlFor={`${formId}-tracking-code`}>کد رهگیری</label>

                            <input
                                type="number"
                                id={`${formId}-tracking-code`}
                                value={trackingCode}
                                onChange={changeTrackingCodeHandler}
                                className="nch-input"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <TimePicker value={withdrewAt} setValue={changeWithdrewAtHandler} />
                    </div>

                    <button type="submit" className="bg-primary-100 w-full text-white rounded-md text-sm py-3 hover:bg-primary-200 transition-colors relative overflow-hidden">
                        ثبت

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default memo(AcceptModal);