import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useMemo, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import { toMoneyFormat, unixToShamsi } from "utils";
import TransactionsStatus from "constants/transactions-status";
import TransactionsTypes from "constants/transaction-types";
import ServiceTypes from "constants/service-types";
import GetFinancialUserType, { GetFinancialUserTypeLink } from "constants/financial-user-type";
import UseFetch from "hooks/useFetch";
import useExtendData from "./use-extend-data";
import ShowExtendData, { ShowExtendUserLabel } from "components/shared/show-extend-data";
import ReserveModal from "./reserve-modal";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const location = useLocation();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error, mutate } = UseFetch(fetchUrl);
    const urlParams = new URLSearchParams(location.search);
    const extendData = useExtendData(isLoading, error, data);
    const [reserveModalData, setReserveModalData] = useState({ show: false, data: {} });

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    const serviceData = useMemo(() => {
        if (!isLoading && !error && data)
            return data.data;

        return {};
    }, [data, isLoading, error])

    const closeReserveModal = useCallback(() => {
        setReserveModalData(prev => ({
            ...prev,
            show: false
        }));
    }, []);

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات تراکنش</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    {
                        serviceData.manual && (
                            <button
                                className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3"
                                onClick={() => setReserveModalData({ data: serviceData, show: true })}
                            >
                                <i className="fa-light fa-timer ml-2"></i>
                                ویرایش رزرو
                            </button>
                        )
                    }
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                    <p className="text-sm text-green-800 dark:text-slate-300">
                        <b className="inline-block ml-2">وضعیت تراکنش</b>
                        <span>{TransactionsStatus[serviceData.status_id]?.title || "---"}</span>
                    </p>
                </div>

                {
                    !isLoading ? (
                        !error ? (
                            <>
                                {
                                    !!serviceData.meta_data?.title && (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                <li className="inline">
                                                    <span>عنوان</span>
                                                    <b>{serviceData.meta_data.title}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه تراکنش</span>
                                            <b>{serviceData.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع تراکنش</span>
                                            <b>{TransactionsTypes[serviceData.type_id]?.title || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع سرویس</span>
                                            <b>{ServiceTypes[serviceData.service_type_id]?.title || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>مبلغ تراکنش</span>
                                            <b>{toMoneyFormat(serviceData.final_price)} <span> تومان</span></b>
                                            {
                                                serviceData.payer_user_id === 0
                                                    ? <span className="rounded-md bg-green-500 text-xs py-1 px-2" style={{ color: "#fff" }}>واریزی</span>
                                                    : <span className="rounded-md bg-red-500 text-xs py-1 px-2" style={{ color: "#fff" }}>برداشتی</span>
                                            }
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع کاربر مالی</span>
                                            <b>{GetFinancialUserType(serviceData.financial_user_type_id)}</b>
                                        </li>

                                        <li className="inline">
                                            <span>کاربر</span>
                                            <b>
                                                <Link to={GetFinancialUserTypeLink(serviceData.financial_user_type_id, serviceData.payer_user_id || serviceData.receiver_user_id)?.link || ""} className="link-c1">
                                                    <ShowExtendUserLabel value={extendData.user} />
                                                </Link>
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    !!serviceData.order_oid ||
                                        !!serviceData.meta_data?.order_id ||
                                        !!serviceData.internet_receipt_id ||
                                        !!serviceData.withdraw_id ||
                                        !!serviceData.factor_layer_id ||
                                        !!serviceData.income_type_id ||
                                        !!serviceData.remittance_id ? (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                {
                                                    !!serviceData.order_oid && (
                                                        <li className="inline-flex items-center">
                                                            <span>شناسه گروه سفارش</span>
                                                            <b><Link to={`/panel/order/${serviceData.order_oid}`} className="link-c1">{serviceData.order_oid}</Link></b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.meta_data?.order_id && (
                                                        <li className="inline-flex items-center">
                                                            <span>شناسه سفارش</span>
                                                            <b><Link to={`/panel/order/${serviceData.order_oid}`} className="link-c1">{serviceData.meta_data.order_id}</Link></b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.internet_receipt_id && (
                                                        <li className="inline">
                                                            <span>شناسه رسید اینترنتی</span>
                                                            <b><Link to={`/panel/internet-receipt/${serviceData.internet_receipt_id}`} className="link-c1">{serviceData.internet_receipt_id}</Link></b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.withdraw_id && (
                                                        <li className="inline">
                                                            <span>شناسه تسویه حساب</span>
                                                            <b>
                                                                <Link to={`/panel/checkout/${serviceData.withdraw_id}`} className="link-c1">{serviceData.withdraw_id}</Link>
                                                            </b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.remittance_id && (
                                                        <li className="inline">
                                                            <span>شناسه واریز به حساب</span>
                                                            <b>
                                                                <Link to={`/panel/remittance/${serviceData.remittance_id}`} className="link-c1">{serviceData.remittance_id}</Link>
                                                            </b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.factor_layer_id && (
                                                        <li className="inline">
                                                            <span>لایه فاکتور</span>

                                                            <b>
                                                                <Link to={`/panel/shop_setting/factor_layer/${serviceData.factor_layer_id}`} className="link-c1">
                                                                    <ShowExtendData value={extendData["factorLayer"]} />
                                                                </Link>
                                                            </b>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    !!serviceData.income_type_id && (
                                                        <li className="inline">
                                                            <span>مخزن درآمد</span>

                                                            <b>
                                                                <Link to={`/panel/shop_setting/income_type/${serviceData.income_type_id}`} className="link-c1">
                                                                    <ShowExtendData value={extendData["incomeType"]} />
                                                                </Link>
                                                            </b>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    ) : <></>
                                }


                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ ایجاد تراکنش</span>
                                            <b>{serviceData.created_at ? unixToShamsi(serviceData.created_at) : "--"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ تایید تراکنش</span>
                                            <b>{serviceData.accounting_confirm_date ? unixToShamsi(serviceData.accounting_confirm_date) : "--"}</b>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    !!serviceData.description && (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                <li className="inline">
                                                    <span>توضیحات</span>
                                                    <b>{serviceData.description}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }

                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ رزرو</span>
                                            <b>{serviceData.reserve_until ? unixToShamsi(serviceData.reserve_until) : "---"}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>رزرو</span>

                                            {
                                                serviceData.reserved
                                                    ? <i className="fa-solid fa-square-check text-green-500 text-base" title="رزرو شده"></i>
                                                    : <i className="fa-solid fa-rectangle-xmark text-red-500 text-base" title="رزرو نشده"></i>
                                            }
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>تراکنش دستی</span>

                                            {
                                                serviceData.manual
                                                    ? <i className="fa-solid fa-square-check text-green-500 text-base"></i>
                                                    : <i className="fa-solid fa-rectangle-xmark text-red-500 text-base"></i>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                    ) : <></>
                }
            </div>

            <ReserveModal data={reserveModalData} closeHandler={closeReserveModal} mutate={mutate} />
        </>
    )
}

export default memo(Edit);