import { memo, useCallback } from "react";
import SwitchButton from "components/shared/switchButton";

const Checkbox = props => {
    const labelAttrs = {};
    if (props.labelClassName)
        labelAttrs.className = props.labelClassName;

    const dataConverter = value => {
        if (props.numeric)
            return value ? 1 : 0

        return !!value;
    }

    const localChangeHandler = useCallback(e => {
        props.changeHandler(dataConverter(e.target.checked));
        // eslint-disable-next-line
    }, [])

    return (
        <label className="noselect checkbox-holder">
            <SwitchButton
                checked={dataConverter(props.value)}
                changeHandler={localChangeHandler}
            />

            <span className="tp-27">{props.label}</span>
        </label>
    )
}

export default memo(Checkbox);