import { memo, useContext, useMemo, useState } from "react";
import UseFetch from "hooks/useFetch";
import moment from "jalali-moment";
import InitializeContext from "context/initialize-context";
import ChartLine from "../../shared/charts/line";
import ChartBoxSkeleton from "components/skeleton/chart-box";
import TinyListSkeleton from "components/skeleton/tiny-list";

const statuses = [
    {
        id: 1,
        title: "کاربر",
        value: "user"
    },
    {
        id: 3,
        title: "مدیر",
        value: "admin"
    },
    {
        id: 2,
        title: "تامین کننده",
        value: "provider"
    }
]

const User = props => {
    const { fetchUrl } = props;
    const { data, isLoading, error } = UseFetch(fetchUrl);
    const enums = useContext(InitializeContext);
    const [activeTab, setActiveTab] = useState(statuses[0].value);

    const chartData = useMemo(() => {
        const output = {
            amounts: [],
            days: []
        };

        if (!isLoading && !error) {
            data.data.new_users[activeTab].graph_last_month.forEach(el => {
                output.amounts.push(el.amount);
                output.days.push(moment.unix(el.day).format("jYYYY/jMM/jD"));
            })
        }

        return output;
        // eslint-disable-next-line
    }, [isLoading, error, data, activeTab]);

    if (enums.data.forms.length === 0)
        return <></>;

    return (
        <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-full lg:col-span-8">
                {
                    !isLoading ? (
                        !error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <h2 className="tp-34 text-sm font-bold pb-4">کاربران جدید</h2>

                                <div className="pt-3">
                                    <label className="select-c1 mb-5">
                                        <span>نوع کاربری</span>

                                        <select onChange={e => setActiveTab(e.target.value)} value={activeTab}>
                                            {
                                                statuses.map(status => (
                                                    <option key={`option-${status.value}`} value={status.value}>{status.title}</option>
                                                ))
                                            }
                                        </select>
                                    </label>

                                    <ChartLine
                                        series={[
                                            {
                                                name: 'تعداد',
                                                data: chartData.amounts
                                            }
                                        ]}
                                        xaxis={chartData.days}
                                    />
                                </div>
                            </div>
                        ) : <p className="text-red-500 text-xs">خطای سیستم!</p>
                    ) : <ChartBoxSkeleton />
                }
            </div>

            <div className="col-span-full lg:col-span-4">
                {
                    !isLoading ? (
                        !error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <div className="state-box h-full flex flex-col">
                                    <div className="flex mb-4 items-center justify-between reset-i">
                                        <h2 className="title tp-34">تعداد کاربران</h2>
                                    </div>

                                    <div className="overflow-auto spec-sb-c2">
                                        <ul>
                                            {
                                                statuses.map((status, i) => (
                                                    <li key={`content-status-${i}`}>
                                                        <div>
                                                            <span className="sec-title tp-34">{status.title}</span>
                                                        </div>

                                                        <b className="tp-34">
                                                            {data.data.new_users[status.value]?.count || 0}
                                                        </b>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : <p className="text-red-500 text-xs">خطای سیستم!</p>
                    ) : <TinyListSkeleton />
                }
            </div>
        </div>
    )
}

export default memo(User);