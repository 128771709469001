import { memo, useCallback, useState } from "react";
import Confirm from "components/shared/confirm";

const ClearCache = () => {
    const [showConfirm, setShowConfirm] = useState(false);

    const noHandler = useCallback(() => {
        setShowConfirm(false);
    }, [])

    const yesHandler = useCallback(() => {
        localStorage.removeItem("_app");
        window.location.reload(true);

        caches.keys().then((keyList) =>
            Promise.all(
                keyList.map((key) => {
                    return caches.delete(key);
                }),
            ),
        )
    }, [])

    return (
        <>
            <button
                className="text-red-500 border-2 border-red-500 w-full rounded-md py-2 text-sm hover:bg-red-500 transition-colors hover:text-white mb-2"
                onClick={() => setShowConfirm(true)}
            >
                بروزرسانی نرم افزار
            </button>

            <Confirm
                show={showConfirm}
                noHandler={noHandler}
                yesHandler={yesHandler}
            />
        </>
    )
}

export default memo(ClearCache);