import Breadcrumb from "components/shared/breadcrumb";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import { faNumToEng } from "utils";
import ConvertScore from "./convert-score";

const UpdateScore = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    // eslint-disable-next-line
    const { isLoading, data, error } = UseFetch(`/api/identity/${searchParams.get("user_kind_id") == 1 ? "user" : "provider"}/get?id=${id}`);
    const [value, setValue] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (!isLoading && !error)
            setValue(data.data.score || 0);
    }, [isLoading, error, data])

    const changeHandler = e => {
        let newVal = e.target.value;
        newVal = faNumToEng(newVal);
        newVal = newVal.replaceAll(",", "");

        if (newVal === "")
            setValue(0);
        else if (/^[0-9\b]+$/.test(newVal))
            setValue(newVal);
    }

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/score/change_user_score",
                method: "PUT",
                payloadType: 3,
                data: {
                    user_id: parseInt(id),
                    user_kind_id: parseInt(searchParams.get("user_kind_id")),
                    score: parseInt(value)
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">تغییر امتیاز</h1>
                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "کاربران", link: "/panel/identity_user" },
                        { title: "تغییر امتیاز" }
                    ]} />
                </div>
            </div>

            {
                !isLoading ? (
                    !error ? (
                        <>
                            <div className="wrap-box tp-22 page-transition mb-4">
                                <div className="py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>نام</span>
                                            <b>{data.data.name || "---"}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>نام خانوادگی</span>
                                            <b>{data.data.last_name || "---"}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>موبایل</span>
                                            <b>{data.data.mobile || "---"}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>ایمیل</span>
                                            <b>{data.data.email || "---"}</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="wrap-box tp-22 page-transition mb-4">
                                <h2 className="text-sm tp-34 font-medium mb-5">امتیازات</h2>

                                <form onSubmit={submitHandler}>
                                    <div className="flex">
                                        <div className="grow text-field has-val">
                                            <label htmlFor="new-score">امتیاز</label>

                                            <input
                                                type="text"
                                                id="new-score"
                                                value={value}
                                                onChange={changeHandler}
                                            />
                                        </div>

                                        <button type="submit" className="bg-primary-100 text-white rounded-full text-sm px-10 mr-3 hover:bg-primary-200 transition-colors relative overflow-hidden">
                                            ثبت

                                            <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                                                <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                                    <i className="fa-solid fa-spinner spin"></i>
                                                </span>
                                            </CSSTransition>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <span className="text-sm text-red-500">خطای سیستم !</span>
                        </div>
                    )
                ) : (
                    Object.keys([...Array(2)]).map(i => (
                        <div className="skeleton-box rounded-sm w-full h-24 mb-4" key={`basket-skeleton-${i}`}>
                            <span />
                        </div>
                    ))
                )
            }

            <ConvertScore />
        </div>
    )
}

export default memo(UpdateScore);