import { memo, useId, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMenuTheme, setHeaderTheme } from "redux/slices/theme";
import SwitchButton from "components/shared/switchButton";

const MenuBg = ({ bgType, catTitle }) => {
    const dispatch = useDispatch();
    const currentBg = useSelector(state => bgType === "HeaderTheme" ? state.theme.headerTheme : state.theme.menuTheme);
    const setTheme = (bgType === "HeaderTheme") ? setHeaderTheme : setMenuTheme;

    const defaultModeId = useId();
    const lightModeId = useId();
    const darkModeId = useId();
    const primaryModeId = useId();

    const changeDefaultCallback = useCallback(e => {

        const val = e.target.checked ? 0 : 1;
        dispatch(setTheme(val));
        // eslint-disable-next-line
    }, [])

    const globalChangeHandler = (checked, val) => {
        const newVal = checked ? val : 0;
        dispatch(setTheme(newVal));
    }

    const changeLightCallback = useCallback(e => {
        globalChangeHandler(e.target.checked, 1);
        // eslint-disable-next-line
    }, [])

    const changeDarkCallback = useCallback(e => {
        globalChangeHandler(e.target.checked, 2);
        // eslint-disable-next-line
    }, [])

    const changePrimaryCallback = useCallback(e => {
        globalChangeHandler(e.target.checked, 3);
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="cat tp-8 tp-9">{catTitle}</div>

            <label className="item" htmlFor={defaultModeId}>
                <span className="tp-9">حالت پیشفرض</span>

                <SwitchButton
                    checked={currentBg === 0}
                    changeHandler={changeDefaultCallback}
                    id={defaultModeId}
                />
            </label>

            <label className="item" htmlFor={lightModeId}>
                <span className="tp-9">رنگ روشن</span>

                <SwitchButton
                    checked={currentBg === 1}
                    changeHandler={changeLightCallback}
                    id={lightModeId}
                />
            </label>

            <label className="item" htmlFor={darkModeId}>
                <span className="tp-9">رنگ تاریک</span>

                <SwitchButton
                    checked={currentBg === 2}
                    changeHandler={changeDarkCallback}
                    id={darkModeId}
                />
            </label>

            <label className="item" htmlFor={primaryModeId}>
                <span className="tp-9">رنگ سازمانی</span>

                <SwitchButton
                    checked={currentBg === 3}
                    changeHandler={changePrimaryCallback}
                    id={primaryModeId}
                />
            </label>
        </div>
    )
}

export default memo(MenuBg);