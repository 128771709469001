export const arrayToRecursive = data => {
    const mapper = (item, holder) => {
        const myChilds = data.filter(el => el.parent_id === item.id)

        if (myChilds.length) {
            const childsHolder = [];
            myChilds.forEach(child => {
                mapper(child, childsHolder);
            })

            holder.push({
                ...item,
                childs: childsHolder
            });
        } else
            holder.push({
                ...item,
                childs: []
            });
    }

    const holder = [];
    data.forEach(item => {
        if (item.parent_id === null)
            mapper(item, holder);
    })

    return holder;
}

export const arrayToRecursiveInTree = data => {
    const mapper = (item, holder) => {
        const myChilds = data.filter(el => el.parent_id === item.id)

        if (myChilds.length) {
            const childsHolder = [];
            myChilds.forEach(child => {
                mapper(child, childsHolder);
            })

            holder.push({
                label: item.title,
                value: item.id,
                children: childsHolder
            });
        } else
            holder.push({
                label: item.title,
                value: item.id,
            });
    }

    const holder = [];
    data.forEach(item => {
        if (item.parent_id === null)
            mapper(item, holder);
    })

    return holder;
}

export const searchInRecursive = (arr, key, value) => {
    let output = null;

    arr.forEach(el => {
        if (el[key] === value) {
            output = el;
        } else {
            if (el.children)
                output = searchInRecursive(el.children, key, value);
        }
    })

    return output;
}