import { useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const FormCard = props => {
    const {
        form,
        styleType = 1,
        onHoverHandler,
        setHoveredForm,
        countDetail
    } = props;

    const [searchParams] = useSearchParams();
    const cardLink = useMemo(() => {
        const linkParams = createSearchParams();
        linkParams.set("form_id", form.id);

        if (searchParams.get("user_id"))
            linkParams.set("user_id", searchParams.get("user_id"));

        if (searchParams.get("provider_id"))
            linkParams.set("provider_id", searchParams.get("provider_id"));

        return linkParams.toString();
        // eslint-disable-next-line
    }, [searchParams])

    if (styleType === 1)
        return (
            <Link
                to={`?${cardLink}`}
                className="font-black tp-6 inline-block px-4 relative -top-7 bg-[#E7EAF6] dark:bg-[#262642]"
            >
                {form.title}
                <span
                    className="w-5 h-5 cat-c359-el mr-2 relative top-1"
                    onMouseEnter={() => onHoverHandler(form.id)}
                    onMouseLeave={() => setHoveredForm(null)}
                >
                    <i className="fa-regular fa-circle-info relative text-gray-400"></i>

                    <div className="results-box">
                        {
                            countDetail ? (
                                <ul className="text-xs text-gray-500 text-center">
                                    <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                        <span>درحال تایید</span>
                                        <span>{countDetail[0].response.body.count}</span>
                                    </li>

                                    <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                        <span>تایید شده </span>
                                        <span>{countDetail[1].response.body.count}</span>
                                    </li>

                                    <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                        <span>رد شده </span>
                                        <span>{countDetail[2].response.body.count}</span>
                                    </li>

                                    <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                        <span>حذف شده </span>
                                        <span>{countDetail[3].response.body.count}</span>
                                    </li>

                                    <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                        <span>پیش نویس</span>
                                        <span>{countDetail[4].response.body.count}</span>
                                    </li>
                                </ul>
                            ) : <div className="py-2 text-center"><i className="fa-solid fa-spinner spin text-primary-100"></i></div>
                        }
                    </div>
                </span>
            </Link>
        )

    return (
        <div className="cat-c358-el relative">
            <Link
                to={`?${cardLink}`}
                className="bg-white dark:bg-slate-700 dark:text-white dark:border-r-slate-500 text-sm rounded-md border-r-2 border-r-secondary shadow-sm font-medium text-center py-4 relative overflow-hidden block pl-4"
            >
                {form.title}
            </Link>

            <div
                className="w-5 h-full absolute left-0 top-0 detail-icon"
                onMouseEnter={() => onHoverHandler(form.id)}
                onMouseLeave={() => setHoveredForm(null)}
            >
                <i className="fa-regular fa-circle-info relative top-2 left-1 text-gray-400"></i>

                <div className="results-box">
                    {
                        countDetail ? (
                            <ul className="text-xs text-gray-500 text-center">
                                <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                    <span>درحال تایید</span>
                                    <span>{countDetail[0].response.body.count}</span>
                                </li>

                                <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                    <span>تایید شده </span>
                                    <span>{countDetail[1].response.body.count}</span>
                                </li>

                                <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                    <span>رد شده </span>
                                    <span>{countDetail[2].response.body.count}</span>
                                </li>

                                <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                    <span>حذف شده </span>
                                    <span>{countDetail[3].response.body.count}</span>
                                </li>

                                <li className="py-2 px-4 flex justify-between even:bg-gray-100">
                                    <span>پیش نویس</span>
                                    <span>{countDetail[4].response.body.count}</span>
                                </li>
                            </ul>
                        ) : <div className="py-2 text-center"><i className="fa-solid fa-spinner spin text-primary-100"></i></div>
                    }
                </div>
            </div>
        </div >
    )
}

export default FormCard;