import axios from "axios";
import { SERVICE_1 } from "./routes";
import { store } from "redux/store";
import { setEnableReloadAction } from "redux/slices/main";
import { nowTimestamp } from "utils";

const services = [SERVICE_1];

const MasterAPI = options => {
    const stateStore = store.getState();
    const accessToken = stateStore.main.accessToken;
    const accessTokenExpireTime = stateStore.main.accessTokenExpireTime;

    return new Promise(async (resolve, reject) => {
        const {
            callback = () => { },
            payloadType = 1,
            method = "POST",
            sendAuthorizationHeader = true,
            headers = {},
            responseType = null,
            returnData = true
        } = options;

        try {
            if (sendAuthorizationHeader && (!accessToken || !accessTokenExpireTime || accessTokenExpireTime <= nowTimestamp()))
                throw { response: { status: 401 } }; // eslint-disable-line

            const serviceId = options.service || 1;
            const baseUrl = services[serviceId - 1];
            let url = `${baseUrl}${options.url}`;

            const finalOptions = {
                url,
                method,
            };

            const localHeaders = { ...headers };
            if (sendAuthorizationHeader)
                localHeaders.Authorization = accessToken;

            if (Object.keys(localHeaders).length)
                finalOptions.headers = localHeaders;

            if (options.data) {
                if (payloadType === 1) { // obj to formData
                    const formData = new FormData();

                    Object.keys(options.data).forEach(dataKey => {
                        const value = options.data[dataKey];

                        if (value !== null && typeof value === "object") {
                            if (value?.length > 0)
                                options.data[dataKey].forEach(el => {
                                    formData.append(`${dataKey}`, el);
                                })
                            else
                                formData.append(dataKey, 0);
                        } else
                            formData.append(dataKey, options.data[dataKey]);
                    });

                    formData.append("site_id", 1);
                    finalOptions.data = formData;
                } else if (payloadType === 2) // formData
                    finalOptions.data = options.data;
                else if (payloadType === 3) { // json format
                    finalOptions.data = JSON.stringify({
                        site_id: 1,
                        ...options.data
                    });
                }
            }

            if (responseType)
                finalOptions.responseType = responseType;

            const response = await axios(finalOptions);
            callback();

            resolve(returnData ? response.data : response);
        } catch (e) {
            const statusCode = e.response?.status;

            if (statusCode === 401)
                store.dispatch(setEnableReloadAction(true));
            else {
                callback();

                reject({
                    status: statusCode,
                    message: e.response?.data?.message || e.message || "خطای سیتم !"
                });
            }
        }
    })
}

export default MasterAPI;