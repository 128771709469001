const TableSkeleton = () => {
    return (
        Object.keys([...Array(3)]).map((i) => (
            <tr key={`form-skeleton-${i}`}>
                <td>
                    <div className="skeleton-box rounded-sm w-5 h-5">
                        <span />
                    </div>
                </td>

                <td>
                    <div className="skeleton-box w-4 h-4 rounded-full">
                        <span />
                    </div>
                </td>

                <td>
                    <div className="skeleton-box rounded-sm w-5 h-5">
                        <span />
                    </div>
                </td>

                <td>
                    <div className="skeleton-box rounded-full w-4/12 h-4 mb-1">
                        <span />
                    </div>

                    <div className="skeleton-box rounded-full w-9/12 h-4">
                        <span />
                    </div>
                </td>

                <td>
                    <div className="skeleton-box rounded-sm w-4 h-6">
                        <span />
                    </div>
                </td>
            </tr>
        ))
    )
}

export default TableSkeleton;