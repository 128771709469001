import { memo, useId, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleDarkMode } from "redux/slices/theme";
import SwitchButton from "components/shared/switchButton";

const ThemeSetting = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.theme.darkMode);

    const lightModeId = useId();
    const darkModeId = useId();

    const changeDarkMode = useCallback(() => {
        // before update new theme
        localStorage.setItem("THEME", darkMode ? "light" : "dark");

        dispatch(toggleDarkMode());
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="cat tp-8 tp-9">حالت تم</div>

            <label className="item" htmlFor={lightModeId}>
                <span className="tp-9">حالت روز</span>

                <SwitchButton
                    checked={!darkMode}
                    changeHandler={changeDarkMode}
                    id={lightModeId}
                />
            </label>

            <label className="item" htmlFor={darkModeId}>
                <span className="tp-9">حالت شب</span>

                <SwitchButton
                    checked={darkMode}
                    changeHandler={changeDarkMode}
                    id={darkModeId}
                />
            </label>
        </div>
    )
}

export default memo(ThemeSetting);