import { useState, useEffect, memo } from "react";
import Selectbox from "components/shared/inputs/selectbox";
import SelectboxSkeleton from "components/skeleton/selectbox-1";
import MasterAPI from "service/master";
import { toast } from "react-toastify";

const SelectOptions = props => {
    const {
        pageData,
        data,
        selectedRows,
        setSelectedRows,
        reload
    } = props;

    const [selectOptions, setSelectOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const selectAllRows = () => {
            if (data) {
                const ids = [];

                if (data.data?.length !== selectedRows.length)
                    data.data?.forEach(row => ids.push(row.id));
                    
                setSelectedRows(ids);
            }
        }

        const optionsFetcher = async value => {
            if(selectedRows.length === 0) {
                toast.error("موردی انتخاب نشده است");
                return;
            }

            try {
                setLoading(true);
                
                const res = await MasterAPI({
                    method: "PUT",
                    url: pageData.set_url,
                    payloadType: 3,
                    data: {
                        status_id: value,
                        ids: selectedRows
                    },
                    callback: () => setLoading(false)
                });
    
                toast.success(res.message);
                setSelectedRows([]);
                reload();
            } catch (e) {
                toast.error(e.message);
            }
        }

        const newSelectOptions = [
            {
                title: "انتخاب همه",
                clickHandler: () => selectAllRows()
            }
        ];

        pageData.action_menu.forEach(action => {
            newSelectOptions.push({
                title: action.label,
                clickHandler: () => optionsFetcher(action.value)
            });
        })

        setSelectOptions(newSelectOptions);
        // eslint-disable-next-line
    }, [pageData, data, selectedRows, loading])

    return (
        selectOptions.length ? (
            <Selectbox
                className="c1 tp-24 mr-1"
                placeholder="عملیات گروهی"
                options={selectOptions}
                loading={loading}
            />
        ) : <SelectboxSkeleton />
    )
}

export default memo(SelectOptions);