import { memo, useRef, useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const UploadExcel = props => {
    const { mutate } = props;

    const [searchParams] = useSearchParams();
    const [dragIn, setDragIn] = useState(false);
    const [loading, setLoading] = useState(false);

    const labelRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (labelRef.current) {
            const tag = labelRef.current;

            const dragenter = e => {
                e.preventDefault();
                e.stopPropagation();

                setDragIn(true);
            }

            const dragleave = e => {
                e.preventDefault();
                e.stopPropagation();

                setDragIn(false);
            }

            const dragover = e => {
                e.preventDefault();
                e.stopPropagation();
            }

            const drop = e => {
                e.preventDefault();
                e.stopPropagation();
                setDragIn(false);

                if (e.dataTransfer.files && e.dataTransfer.files.length > 0)
                    setFileHandler(e.dataTransfer.files[0]);
            }

            tag.addEventListener("dragenter", dragenter)
            tag.addEventListener("dragleave", dragleave)
            tag.addEventListener("dragover", dragover)
            tag.addEventListener("drop", drop)

            return () => {
                tag.removeEventListener("dragenter", dragenter);
                tag.removeEventListener("dragleave", dragleave);
                tag.removeEventListener("dragover", dragover);
                tag.removeEventListener("drop", drop);
            }
        }

        // eslint-disable-next-line
    }, [labelRef])

    const inputChangeHandler = e => {
        setFileHandler(e.target.files[0]);
    }

    const setFileHandler = async selectedFile => {
        if (loading)
            return;

        try {
            const spliedFilename = selectedFile.name.split(".");
            const fileExtenstion = spliedFilename.pop();

            if (selectedFile?.size === 0) {
                toast.error("حجم فایل مورد نظر معتبر نمی باشد");
                return;
            } else if (fileExtenstion.toLowerCase() !== "xlsx") {
                toast.error("فایل انتخابی باید فرمت اکسل باشد");
                return;
            }

            setLoading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("form_id", searchParams.get("form_id"));

            const res = await MasterAPI({
                url: `/api/excel/content/price/import`,
                data: formData,
                payloadType: 2,
                callback: () => {
                    setLoading(false);
                    inputRef.current.value = "";
                }
            });

            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <label
            className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden ml-3 cursor-pointer"
            htmlFor="excel-upload"
        >
            <input
                type="file"
                id="excel-upload"
                className="hidden"
                onChange={inputChangeHandler}
                ref={inputRef}
            />

            <i className="fa-light fa-file-excel ml-2"></i>
            آپلود اکسل

            <CSSTransition in={dragIn || loading} timeout={200} unmountOnExit={true}>
                <span className="pos-cover bg-primary-200 fit-center anim-c4">
                    {
                        dragIn ? <i className="fa-light fa-files"></i> : <i className="fa-solid fa-spinner spin"></i>
                    }
                </span>
            </CSSTransition>

            <span className="pos-cover" ref={labelRef} />
        </label>
    )
}

export default memo(UploadExcel);