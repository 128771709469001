import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { themeIdToStr } from "utils";

const Logo = () => {
    const headerTheme = useSelector(state => state.theme.headerTheme);
    const menuTheme = useSelector(state => state.theme.menuTheme);
    const darkMode = useSelector(state => state.theme.darkMode);

    const [forceBorderState, setForceBorderState] = useState(null);
    useEffect(() => {
        let newForceBorderState = null;

        if (darkMode) {
            if (headerTheme === 1 && menuTheme === 1)
                newForceBorderState = "force-bc-1";
            else if (headerTheme === 0 && menuTheme === 3)
                newForceBorderState = "force-bc-2";
            else if (headerTheme === 3 && menuTheme === 3)
                newForceBorderState = "force-bc-4";
            else if (headerTheme === 1 && menuTheme === 3)
                newForceBorderState = "force-bc-3";
        } else {
            if (headerTheme === 2 && menuTheme === 0)
                newForceBorderState = "force-bc-2";
            else if (headerTheme === 3 && menuTheme === 0)
                newForceBorderState = "force-bc-3";
            else if (headerTheme === 2 && menuTheme === 2)
                newForceBorderState = "force-bc-2";
            else if (headerTheme === 3 && menuTheme === 2)
                newForceBorderState = "force-bc-3";
            else if (headerTheme === 3 && menuTheme === 3)
                newForceBorderState = "force-bc-4";
            else if (headerTheme === 2 && menuTheme === 3)
                newForceBorderState = "force-bc-4";
        }

        if (newForceBorderState !== forceBorderState)
            setForceBorderState(newForceBorderState);

        // eslint-disable-next-line
    }, [headerTheme, menuTheme, darkMode])

    return (
        <div className={`logo hidden lg:block${menuTheme ? ` ${themeIdToStr(menuTheme)}-1` : ""}${forceBorderState ? ` ${forceBorderState}` : ""}`}>
            <Link to="/" className="w-full h-full flex items-center justify-center">
                <img src="/assets/image/logo/1-mini.png" alt="sarirmarket" className="img-c1" />
                <img src="/assets/image/logo/1.png" alt="sarirmarket" className="img-c1 text-img img-light" />
                <img src="/assets/image/logo/2-mini.png" alt="sarirmarket" className="img-c2" />
                <img src="/assets/image/logo/2.png" alt="sarirmarket" className="img-c2 text-img img-dark" />
            </Link>
        </div>
    )
}

export default Logo;