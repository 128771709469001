import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const TinyList = ({ fullHeight = false }) => (
    <div className={`wrap-box tp-22 page-transition ${fullHeight ? "h-full" : ""}`}>
        <div className="state-box h-full flex flex-col">
            <div className="mb-4">
                <Skeleton className="w-24 h-4 rounded-full" />
            </div>

            <div className="overflow-auto spec-sb-c2">
                <ul>
                    {
                        Object.keys([...Array(5)]).map(i => (
                            <li key={`skeleton-order-status-${i}`}>
                                <div>
                                    <Skeleton className="w-16 h-4 rounded-sm" />
                                </div>

                                <b className="tp-34">
                                    <Skeleton className="w-24 h-4 rounded-sm" />
                                </b>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
)

export default memo(TinyList);