import { configureStore } from '@reduxjs/toolkit';
import themeSlice from "./slices/theme";
import mainSlice from './slices/main';
import stackSlice from "./slices/stack";

export const store = configureStore({
    reducer: {
        main: mainSlice,
        theme: themeSlice,
        stack: stackSlice
    },
})