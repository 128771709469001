import { memo, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const PieChart = props => {
    const { series, labels } = props;
    const darkMode = useSelector(state => state.theme.darkMode);

    const [options, setOptions] = useState({
        labels,
        chart: {
            type: 'pie',
            fontFamily: 'IRANSans'
        },
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
          },
    });

    useEffect(() => {
        setOptions(prev => ({
            ...prev.options,
            theme: {
                mode: darkMode ? "dark" : "light"
            }
        }));
    }, [darkMode])

    return (
        <ReactApexChart options={options} series={series} type="donut" />
    )
}

export default memo(PieChart);