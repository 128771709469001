import Modal from "components/shared/modal";
import { productRoute, smallCropSize } from "constants/config";
import { useCallback, useMemo, useState } from "react";
import { getProductImage, toMoneyFormat } from "utils";
import Properties from "./properties";

const OrderCard = props => {
    const { order, enums } = props;
    const [showPropertyModal, setShowPropertyModal] = useState(false);
    const [showModalLink, setShowModalLink] = useState(false);

    const closeModalLink = useCallback(() => {
        setShowModalLink(false);
    }, [])

    const closeModal = useCallback(() => {
        setShowPropertyModal(false);
    }, [])

    let intendedForm = enums.data.forms.find(form => form.id === order.product_form_id);
    intendedForm = intendedForm || {};

    let productImage = getProductImage(order.meta_fields.e18_images, smallCropSize);
    if (order.fields[intendedForm.setting?.image])
        productImage = getProductImage(order.fields[intendedForm.setting.image], smallCropSize);

    const productBaseUrl = intendedForm.setting?.url || productRoute;

    const orderProperties = useMemo(() => {
        const output = [];

        enums.data.formElements.forEach(formElement => {
            if (
                (formElement.form_id === order.product_form_id || (formElement.category_id && order.categories?.length && order.categories.indexOf(formElement.category_id) !== -1)) &&
                formElement.enumeration_id !== null
            ) {
                const formKey = `e${formElement.id}_${formElement.name}`;
                const values = enums.data.formEnumerations.filter(formEnumeration => {
                    if (formEnumeration.parent_id === formElement.enumeration_id) {
                        const existsInMaster = !!(order.fields[formKey] && order.fields[formKey].indexOf(formEnumeration.id) !== -1);
                        return existsInMaster;
                    }

                    return false;
                });

                if (values.length)
                    output.push({
                        id: formElement.id,
                        caption: formElement.caption,
                        values: values.map(val => val.title),
                        setting: formElement.setting,
                        key: formKey
                    })
            }
        })

        return output;
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-12 text-sm text-gray-900 dark:text-slate-300 gap-4 mb-4 bg-gray-50 dark:bg-slate-900 p-4 lg:p-2 rounded-md">
                <div className="col-span-1 lg:col-span-6">
                    <div className="flex flex-col lg:flex-row items-center">
                        <a
                            href={`${productBaseUrl}/${order.product_id}`}
                            className="rounded-md border border-slate-300 dark:border-slate-600 p-1 inline-block ml-3 mb-3 lg:mb-0"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={productImage} alt={order.title} width={100} height={100} className="rounded-sm" />
                        </a>

                        <div className="text-center lg:text-right">
                            <a href={`${productBaseUrl}/${order.product_id}`} target="_blank" rel="noreferrer">{order.title}</a>

                            {
                                orderProperties.length > 0 && (
                                    <div className="text-gray-500 dark:text-slate-400 my-2 text-xs">
                                        {
                                            orderProperties.map((property, i) => (
                                                <div className="flex" key={`property-${i}`}>
                                                    <span className="ml-1">{property.caption}:</span>
                                                    <span>{property.values.join(", ")}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }

                            <div className="mt-1 flex flex-col items-start">
                                <button
                                    className="text-xs rounded-full py-1 px-2 bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 ml-1 inline-block"
                                    onClick={() => setShowPropertyModal(true)}
                                >
                                    مشخصه های محصول
                                </button>

                                {
                                    !!order.meta_fields.admin_modal && (
                                        <button
                                            type="button"
                                            className="rounded-full bg-primary-100 py-1 px-4 text-xs text-white mt-2"
                                            onClick={() => setShowModalLink(prev => !prev)}
                                        >
                                            {order.meta_fields.admin_modal.title}
                                        </button>
                                    )
                                }

                                {
                                    !!order.meta_fields.admin_link && (
                                        <a
                                            target="_blank"
                                            no
                                            href={order.meta_fields.admin_link.link}
                                            className="rounded-full bg-primary-100 py-1 px-4 text-xs text-white mt-2"
                                            rel="noreferrer"
                                        >
                                            <i className="fa-light fa-share-from-square ml-2 relative top-px"></i>
                                            {order.meta_fields.admin_link.title}
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-1 justify-center lg:justify-start lg:col-span-2 flex items-center">
                    قیمت: <b className="font-medium mr-1">{toMoneyFormat(order.price)}</b>
                </div>

                <div className="col-span-1 justify-center lg:justify-start lg:col-span-2 flex items-center">
                    تعداد: <b className="font-medium mr-1">{order.quantity}</b>
                </div>

                <div className="col-span-1 justify-center lg:justify-start lg:col-span-2 flex items-center">
                    قیمت نهایی: <b className="font-medium mr-1">{toMoneyFormat(order.factor.price)}</b>
                </div>
            </div>

            <Modal show={showPropertyModal} title="مشخصه های محصول" closeHandler={closeModal}>
                <Properties properties={orderProperties} />
            </Modal>

            <Modal
                show={showModalLink}
                closeHandler={closeModalLink}
                title={order.meta_fields.admin_modal?.title}
                className="designModal"
            >
                <iframe
                    src={order.meta_fields.admin_modal?.link}
                    className="w-full h-full"
                    title={order.meta_fields.admin_modal?.title}
                />
            </Modal>
        </>
    )
}

export default OrderCard;