import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    Object.keys([...Array(3)]).map(i => (
        <div className="wrap-box tp-22 page-transition mb-3 crop-size-block low-p" key={`user-profile-skeleton-${i}`}>
            <Skeleton className="h-9 rounded-sm mb-5" />

            <div className="flex md:items-center flex-col md:flex-row items-stretch">
                <Skeleton className="mx-auto md:ml-3 md:mr-0 fit-center w-12 h-12 min-w-[3rem] rounded-full" />

                <div className="flex flex-col md:flex-row grow md:ml-3 my-4 md:my-0">
                    <div className="md:w-1/2 md:ml-1 mb-6 md:mb-0">
                        <Skeleton className="h-11 rounded-full" />
                    </div>

                    <div className="md:w-1/2 md:mr-1">
                        <Skeleton className="h-11 rounded-full" />
                    </div>
                </div>

                <div className="flex items-center justify-between md:justify-start">
                    <div className="ml-3 flex items-center">
                        <Skeleton className="w-20 h-4 rounded-full" />
                    </div>

                    <Skeleton className="w-14 h-7 rounded-md" />
                </div>
            </div>
        </div>
    ))
)

export default memo(SkeletonComponent);