import Breadcrumb from "components/shared/breadcrumb";
import Loading from "components/shared/loading";
import signinMethods from "constants/signin-methods";
import UseFetch from "hooks/useFetch";
import { memo, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { unixToShamsi } from "utils";
import useExtendData from "./use-extend-data";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";

const View = props => {
    const { pageData } = props;
    const { userId, id } = useParams();
    const { isLoading, error, data, mutate } = UseFetch(pageData.get_url.replace(":id", id));
    const extendData = useExtendData(isLoading, error, data, pageData.mode.name);

    const breadcrumbData = useMemo(() => {
        const output = [...pageData.breadcrumb];
        output[2].link = `/panel/tokens/${pageData.mode.name}/${userId}`;

        return [...output, { title: "جزئیات توکن" }];
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات توکن {pageData.mode.title}</h1>
                    <Breadcrumb data={breadcrumbData} />
                </div>

                <div className="flex">
                    <Link to={pageData.breadcrumb[1].link} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="border-b border-gray-200 dark:border-slate-700 pb-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>شناسه</span>
                                            <b>{data.data.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>وضعیت</span>
                                            <b>{data.data.status_id ? "فعال" : "غیر فعال"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع وورد</span>
                                            <b>{signinMethods[data.data.signin_method] || "نامشخص"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>کاربر</span>
                                            <b>
                                                <ShowExtendUserLabel
                                                    value={extendData.userData}
                                                    link={`/panel/identity_${pageData.mode.name}?id=${data.data.user_id}`}
                                                />
                                            </b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>ip کاربر</span>
                                            <b>{data.data.ip_address}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="flex items-center dir-ltr">
                                            <span className="mr-3">Access Token</span>
                                            <b>{data.data.access_token}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="flex items-center dir-ltr">
                                            <span className="mr-3">Refresh Token</span>
                                            <b>{data.data.refresh_token}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>تاریخ انقضای access token</span>
                                            <b>{unixToShamsi(data.data.access_token_expires_in)}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>تاریخ انقضای refresh token</span>
                                            <b>{unixToShamsi(data.data.refresh_token_expires_in)}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="flex items-center dir-ltr">
                                            <span className="mr-3">UserAgent</span>
                                            <b>{data.data.user_agent}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="pt-4">
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>تاریخ ایجاد</span>
                                            <b>{unixToShamsi(data.data.created_at)}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>تاریخ آخرین بروزرسانی</span>
                                            <b>{unixToShamsi(data.data.updated_at)}</b>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : <div className="global-error text-cnter">خطا در دریافت اطلاعات !</div>
                    ) : <div className="fit-center"><Loading className="loading-c5" /></div>
                }
            </div >
        </>
    )
}

export default memo(View);