export const errorMsg = key => {
    const errors = {
        "incorrect_prices": "قیمت پیش از تخفیف محصول باید از قیمت نهایی بیشتر باشد",
        "incorrect_min_order": "حداقل سفارش نمی تواند از حداکثر سفارش بزرگتر باشد"
    };

    return errors[key];
}

const validate = field => {
    const errors = [];

    if (field.price.price_before_discount) {
        if (field.price.price_before_discount <= field.price.price)
            errors.push("incorrect_prices");
    }

    if (field.price.min_order && field.price.max_order) {
        if (field.price.min_order > field.price.max_order)
            errors.push("incorrect_min_order");
    }

    return errors;
}

export const singleValidator = newVal => {
    const errors = validate(newVal.module_fields);
    return errors;
}

export const multiValidator = (data, setData) => {
    let errorNotExists = true;
    const newData = [];

    data.forEach(product => {
        const thisErrors = validate(product.module_fields);
        newData.push({
            ...product,
            error: thisErrors
        });

        if (thisErrors.length)
            errorNotExists = false;
    });

    if (!errorNotExists)
        setData(newData);

    return errorNotExists;
}