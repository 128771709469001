import Breadcrumb from "components/shared/breadcrumb";
import React, { memo, useContext, useEffect, useState, useMemo, useRef, useCallback } from "react"
import templateFetcherHook from "service/template-fetcher-hook";
import Searchbox from "./searchbox";
import { productFormId, smallCropSize } from "constants/config";
import { Link, useSearchParams } from "react-router-dom";
import PriceList from "components/skeleton/price-list";
import SwitchButton from "components/shared/switchButton";
import InputNumber from "components/shared/inputs/input-number";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import useFetch from "hooks/useFetch";
import Pagination from "components/shared/pagination";
import BottomSkeleton from "components/skeleton/table-grid-bottom";
import { multiValidator, singleValidator } from "./validator";
import { calculateDiscountPercentage, contentStatusId, getPagesCount, getProductImage } from "utils";
import EnumsContext from "context/initialize-context";
import { productRoute } from "constants/config";
import useFreeSlider from "hooks/useFreeSlider";
import DownloadExcel from "./excel-btns/download-excel";
import UploadExcel from "./excel-btns/upload-excel";
import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import ErrorMessage from "components/form-generator/error-msg";

const Price = props => {
    const { template } = props;
    const { data: pageData } = templateFetcherHook(template);
    const [urlParams] = useSearchParams();

    const enumsData = useContext(EnumsContext);
    const [fetchUrl, setFetchUrl] = useState(null);
    const [dataState, setDataState] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { isLoading, data, error, mutate } = useFetch(fetchUrl);
    const [urlParamsData, setUrlParamsData] = useState({});

    const containerRef = useRef(null);
    useFreeSlider(containerRef);

    const mutateCallback = useCallback(() => {
        mutate();
        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        if (data?.data)
            setDataState(data.data);
        else
            setDataState([]);
    }, [data])

    const currencies = useMemo(() => {
        let output = enumsData.data.shopSettingCurrency.map(el => ({
            id: el.id,
            title: el.name
        }));

        output = [
            { title: "تومان", id: 0 },
            ...output
        ];

        return output;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!pageData)
            return;

        let newFetchUrl = pageData.index_url;
        let newUrlParams = [];
        let newUrlParamsData = {};

        pageData.search_valid_params.forEach(param => {
            const paramValue = urlParams.get(param);
            if (paramValue) {
                newUrlParamsData[param] = paramValue;
                newUrlParams.push(`${param}=${paramValue}`);
            }
        })

        newUrlParamsData["form_id"] = productFormId;

        if (Object.keys(newUrlParamsData).length) {
            newFetchUrl += `?${newUrlParams.join("&")}`;
            setUrlParamsData(newUrlParamsData);
        } else
            setUrlParamsData({});

        setFetchUrl(newFetchUrl);
        // eslint-disable-next-line
    }, [pageData, location.search])

    const changePriceLevel = (id, key, value, convertToNum = false) => {
        setDataState(prev => {
            const newData = [...prev];
            const intendedIndex = newData.findIndex(product => product.id === id);

            let newVal = value;
            if (convertToNum) {
                if (!isNaN(newVal) && newVal !== "")
                    newVal = parseInt(newVal);
                else
                    newVal = null;
            }

            newData[intendedIndex] = {
                ...newData[intendedIndex],
                changed: true
            }
            newData[intendedIndex].module_fields.price[key] = newVal;

            const error = singleValidator(newData[intendedIndex]);
            newData[intendedIndex].error = error;

            return newData;
        });
    }

    const submitHandler = async () => {
        if (submitLoading)
            return;

        if (!multiValidator(dataState, setDataState))
            return;

        setSubmitLoading(true);
        try {
            const payloadData = dataState.map(product => ({ id: product.id, module_fields: product.module_fields }));

            const res = await MasterAPI({
                url: "/api/content/edit_module_fields_multiple",
                method: "PUT",
                payloadType: 3,
                data: {
                    form_id: parseInt(urlParams.get("form_id")),
                    items: payloadData
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const intendedForm = useMemo(() => {
        const output = enumsData.data.forms.find(form => form.id === parseInt(urlParams.get("form_id")));
        return output || {};
        // eslint-disable-next-line
    }, [urlParams])

    const breadcrumb = useMemo(() => {
        if (!isLoading) {
            if (!error) {
                let output = [...pageData.breadcrumb];
                if (data.breadcrumbs?.length)
                    output = [...output, ...data.breadcrumbs.reverse()];

                return { list: output };
            } else
                return { error: true };
        }

        return { loading: true };
        // eslint-disable-next-line
    }, [isLoading, data, error])

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>

                    {
                        !breadcrumb.loading
                            ? !breadcrumb.error ? <Breadcrumb data={breadcrumb.list} /> : <></>
                            : <BreadcrumbSkeleton />
                    }
                </div>

                <div className="flex">
                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    <DownloadExcel />
                    <UploadExcel mutate={mutateCallback} />

                    <button
                        className="bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden"
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-file ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <div className="flex justify-between items-start">
                    <Searchbox fields={pageData.search_setting} />
                </div>

                <div className="overflow-x-hidden overflow-y-hidden" ref={containerRef}>
                    {
                        !isLoading ? (
                            !error ? (
                                dataState.length ? (
                                    <div className="my-5">
                                        <div className="price-table">
                                            <table className="main-tbl border tp-32">
                                                <colgroup>
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-2/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-2/12" />
                                                    <col className="w-2/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                </colgroup>

                                                <thead>
                                                    <tr className="tp-32">
                                                        <th></th>
                                                        <th>#</th>
                                                        <th>قیمت نهایی</th>
                                                        <th>قیمت پیش از تخفیف</th>
                                                        <th>نرخ ارز</th>
                                                        <th>حداقل سفارش</th>
                                                        <th>حداکثر سفارش</th>
                                                        <th>بازه ارسال (روز)</th>
                                                        <th>موجودی</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        dataState.map((product, i) => {
                                                            const priceObj = product.module_fields?.price || {};

                                                            let selectboxOptions = Object.keys([...Array(7)]).map(i => ({
                                                                id: parseInt(i) + 1,
                                                                title: parseInt(i) + 1
                                                            }))

                                                            selectboxOptions = [
                                                                {
                                                                    id: 0,
                                                                    title: "آماده ارسال"
                                                                },
                                                                ...selectboxOptions
                                                            ];

                                                            const discountPercentage = calculateDiscountPercentage(priceObj.price_before_discount, priceObj.price);
                                                            const title = product.virtual_title?.length > 0 ? product.virtual_title : "بدون عنوان";

                                                            let image = getProductImage(product.fields.e18_images, smallCropSize);
                                                            if (product.fields[intendedForm.setting?.image])
                                                                image = getProductImage(product.fields[intendedForm.setting?.image], smallCropSize);

                                                            const productBaseUrl = intendedForm.setting?.url || productRoute;
                                                            return (
                                                                <React.Fragment key={`product-${product.id}`}>
                                                                    <tr className={`bb-0 tp-32${i % 2 !== 0 ? " has-bg" : ""}`}>
                                                                        <td rowSpan={2}>
                                                                            <div className="img-box tp-32">
                                                                                <span className="im-status bg-green-400 dark:bg-slate-400 dark:text-slate-100">
                                                                                    {contentStatusId(product.status_id).icon}
                                                                                </span>

                                                                                <img src={image} alt={title} />
                                                                            </div>
                                                                        </td>

                                                                        <td colSpan={8}>
                                                                            <div className="title">
                                                                                <div className="flex">
                                                                                    {
                                                                                        product.status_id === 1 && (
                                                                                            <a href={`${productBaseUrl}/${product.group_id}`} className="ml-2" target="_blank" rel="noreferrer" title="مشاهده محصول در سایت" draggable="false">
                                                                                                <i className="fa-regular fa-share-from-square"></i>
                                                                                            </a>
                                                                                        )
                                                                                    }

                                                                                    <Link to={`/panel/content/${product.id}?form_id=${productFormId}`} draggable="false">
                                                                                        <span className="in">
                                                                                            {title}
                                                                                        </span>

                                                                                        {
                                                                                            discountPercentage > 0 && (
                                                                                                <span className="discount">
                                                                                                    {discountPercentage}
                                                                                                    % تخفیف
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </Link>
                                                                                </div>

                                                                                <div className="switch-status">
                                                                                    <SwitchButton
                                                                                        changeHandler={e => changePriceLevel(product.id, "sellable", e.target.checked)}
                                                                                        checked={priceObj.sellable}
                                                                                    />
                                                                                    <span className="lbl mr-1 ml-4">فروش</span>

                                                                                    <SwitchButton
                                                                                        changeHandler={e => changePriceLevel(product.id, "unlimited", e.target.checked)}
                                                                                        checked={priceObj.unlimited}
                                                                                    />
                                                                                    <span className="lbl mr-1">نامحدود</span>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                product.error?.length > 0 && (
                                                                                    <div className="d-flex bg-red-300 rounded-full dark:rounded-l-none text-white text-right flex items-stretch mt-3 overflow-hidden text-xs dark:bg-transparent">
                                                                                        <div className="bg-red-400 w-9 min-h-[2.25rem] flex items-center justify-center">
                                                                                            <i className="fa-regular fa-circle-exclamation text-base"></i>
                                                                                        </div>

                                                                                        <div className="py-1 flex items-center pr-2 dark:border border-red-400 grow dark:rounded-l-full text-red-700 dark:text-red-400">
                                                                                            {
                                                                                                product.error.map((error, i) => (
                                                                                                    <span key={`product-${product.id}-e${i}`} className="block">{ErrorMessage(error)}</span>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                    <tr className={`tp-32${i % 2 !== 0 ? " has-bg" : ""}`}>
                                                                        <td>
                                                                            <span className="sp-1">
                                                                                {product.id}
                                                                            </span>
                                                                        </td>

                                                                        <td>
                                                                            <InputNumber
                                                                                moneyFormat={true}
                                                                                value={priceObj.price}
                                                                                setValue={val => changePriceLevel(product.id, "price", val, true)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <InputNumber
                                                                                moneyFormat={true}
                                                                                value={priceObj.price_before_discount}
                                                                                setValue={val => changePriceLevel(product.id, "price_before_discount", val, true)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <select
                                                                                className="tp-36"
                                                                                value={priceObj.currency}
                                                                                onChange={e => {
                                                                                    changePriceLevel(product.id, "currency", parseInt(e.target.value))
                                                                                }}
                                                                            >
                                                                                {
                                                                                    currencies.map(el => (
                                                                                        <option key={`curency-el-${el.id}`} value={el.id}>{el.title}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </td>

                                                                        <td>
                                                                            <InputNumber
                                                                                value={priceObj.min_order}
                                                                                setValue={val => changePriceLevel(product.id, "min_order", val, true)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <InputNumber
                                                                                value={priceObj.max_order}
                                                                                setValue={val => changePriceLevel(product.id, "max_order", val, true)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <select
                                                                                className="tp-36"
                                                                                value={priceObj.delivery_duration || 0}
                                                                                onChange={e => {
                                                                                    changePriceLevel(product.id, "delivery_duration", parseInt(e.target.value))
                                                                                }}
                                                                            >
                                                                                {
                                                                                    selectboxOptions.map(el => (
                                                                                        <option key={`options-el-${el.id}`} value={el.id}>{el.title}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </td>

                                                                        <td className="relative">
                                                                            <CSSTransition in={priceObj.unlimited} timeout={200} unmountOnExit={true} appear={true}>
                                                                                <div className="pos-cover bg-white dark:bg-[#0E0E23] anim-c7"></div>
                                                                            </CSSTransition>

                                                                            <InputNumber
                                                                                value={priceObj.stock_count}
                                                                                setValue={val => changePriceLevel(product.id, "stock_count", val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <p className="font-medium text-sm text-center text-slate-800 dark:text-gray-300">
                                        اطلاعاتی برای نمایش یافت نشد
                                    </p>
                                )
                            ) : (
                                <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                            )
                        ) : <PriceList />
                    }
                </div>

                {
                    !isLoading ? (
                        <div className="flex justify-between items-center">
                            <span className="text-[#1d212f] dark:text-[#bdbdca] text-sm font-medium">
                                {getPagesCount(data.count, urlParams.limit)}
                            </span>

                            {
                                !!data.data?.length && (
                                    <Pagination
                                        count={data.count}
                                        limit={urlParamsData.limit}
                                        offset={urlParamsData.offset}
                                    />
                                )
                            }
                        </div>
                    ) : <BottomSkeleton />
                }
            </div>
        </div>
    )
}

export default memo(Price);