import { memo } from "react";
import { CSSTransition } from "react-transition-group";

const Categories = props => {
    const {
        item,
        expandedCats,
        setExpandsCats,
        selectedItems,
        setSelectedItems
    } = props;

    const toggleCat = (key, id) => {
        setExpandsCats(prev => {
            const newState = { ...prev };

            if (newState[key].indexOf(id) !== -1) {
                const newIntendedCat = newState[key].filter(item => item !== id);
                newState[key] = newIntendedCat;
            } else
                newState[key] = [...newState[key], id];

            return newState;
        });
    }

    return (
        <div className="item">
            <div className="row">
                <div className="title-col">
                    {
                        item.childs.length ? (
                            <button
                                type="button"
                                className={`exp-btn ${expandedCats["categories"].indexOf(item.id) !== -1 ? "minus" : "plus"}`}
                                onClick={() => toggleCat("categories", item.id)}
                            />
                        ) : <span className="hide-btn"></span>
                    }

                    <input
                        type="checkbox"
                        checked={!!selectedItems["categories"][item.id] && selectedItems["categories"][item.id]?.length === 3}
                        onChange={e => {
                            setSelectedItems(prev => {
                                const newState = { ...prev };

                                if (e.target.checked)
                                    newState["categories"][item.id] = [1, 2, 3];
                                else
                                    delete newState["categories"][item.id];

                                return newState;
                            });
                        }}
                    />

                    {item.title}
                </div>

                {
                    [3, 2, 1].map(i => (
                        <div className="checked-col" key={`${item.id}-${i}`}>
                            <input
                                type="checkbox"
                                checked={!!selectedItems["categories"][item.id] && selectedItems["categories"][item.id].indexOf(i) !== -1}
                                onChange={e => {
                                    setSelectedItems(prev => {
                                        const newState = { ...prev };

                                        if (e.target.checked) {
                                            let oldVal = newState["categories"][item.id] || [];
                                            newState["categories"][item.id] = [...oldVal, i];
                                        } else
                                            newState["categories"][item.id] = newState["categories"][item.id].filter(el => el !== i);

                                        return newState;
                                    });
                                }}
                            />
                        </div>
                    ))
                }
            </div>

            {
                item.childs.length > 0 && (
                    <CSSTransition
                        in={expandedCats["categories"].indexOf(item.id) !== -1}
                        unmountOnExit={true}
                        timeout={{ enter: 300, exit: 0 }}
                    >
                        <div className="childs anim-c8">
                            {
                                item.childs.map(el => <Categories {...props} item={el} key={`${"categories"}-${item.id}-${el.id}`} />)
                            }
                        </div>
                    </CSSTransition>
                )
            }
        </div>
    )
}

export default memo(Categories);