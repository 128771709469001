import { memo, useMemo } from "react";
import PieChart from "../../shared/charts/pie";
import ticketStatuses from "constants/ticket-statuses";
import UseFetch from "hooks/useFetch";
import TickSkeleton from "components/skeleton/ticket";
import GoToAll from "components/shared/go-to-all";

const Ticket = props => {
    const { fetchUrl } = props;
    const { isLoading, data, error } = UseFetch(fetchUrl);

    const chartData = useMemo(() => {
        const output = {
            user: {
                series: [],
                labels: [],
                useful: {
                    useful: 0,
                    not_useful: 0
                }
            },
            provider: {
                series: [],
                labels: [],
                useful: {
                    useful: 0,
                    not_useful: 0
                }
            }
        };

        if (!isLoading && !error) {
            const keys = ["user", "provider"];

            keys.forEach(key => {
                ticketStatuses.forEach((status, i) => {
                    output[key].labels.push(status.title);
                    output[key].series.push(data.data[key].status[i] || 0);
                    output[key].useful = {
                        useful: data.data[key].useful.useful || 0,
                        not_useful: data.data[key].useful.not_useful || 0
                    };
                })
            })
        }

        return output;
    }, [data, isLoading, error]);

    const sections = [
        {
            type: "user",
            title: "تیکت کاربران"
        },
        {
            type: "provider",
            title: "تیکت تامین کنندگان"
        }
    ];

    return (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
            {
                sections.map(sec => (
                    <div className="wrap-box tp-22 page-transition" key={`ticket-sec-${sec.type}`}>
                        {
                            !isLoading ? (
                                !error ? (
                                    <>
                                        <div className="flex mb-8 justify-between items-center">
                                            <h2 className="font-bold text-sm text-slate-800 dark:text-slate-300">
                                                {sec.title}
                                            </h2>

                                            <GoToAll link={`/panel/${sec.type}Ticket`} />
                                        </div>

                                        <div className="max-w-[25rem] mx-auto">
                                            <PieChart series={chartData[sec.type].series} labels={chartData[sec.type].labels} />
                                        </div>

                                        <div className="grid grid-cols-2 gap-2 mt-3 text-xs">
                                            <div className="bg-blue-50 dark:bg-slate-800 rounded-sm flex justify-between items-center py-3 px-4">
                                                <span className="text-slate-800 dark:text-slate-300">تیکت های مفید: <b className="text-base">{chartData[sec.type].useful.useful}</b></span>
                                                <i className="fa-light fa-thumbs-up text-blue-300 dark:text-slate-600 text-3xl"></i>
                                            </div>

                                            <div className="bg-blue-50 dark:bg-slate-800 rounded-sm flex justify-between items-center py-3 px-4">
                                                <span className="text-slate-800 dark:text-slate-300">تیکت های غیر مفید: <b className="text-base">{chartData[sec.type].useful.useful}</b></span>
                                                <i className="fa-light fa-thumbs-down text-blue-300 dark:text-slate-600 text-3xl"></i>
                                            </div>
                                        </div>
                                    </>
                                ) : <p className="text-red-500 text-xs">خطای سیستم!</p>
                            ) : <TickSkeleton id={sec.type} />
                        }
                    </div>
                ))
            }
        </div >
    )
}

export default memo(Ticket);