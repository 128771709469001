import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { nowTimestamp } from "utils";
import DotLoading from "components/shared/loading";
import { refreshTokenAction } from "redux/slices/main";
import { useDispatch } from "react-redux";

const reload = () => {
    window.location.reload();
}

const ReloadTokenController = () => {
    const dispatch = useDispatch();
    const enableReloadAction = useSelector(state => state.main.enableReloadAction);

    useEffect(() => {
        if (enableReloadAction) {
            const nowTime = nowTimestamp();

            const refreshToken = localStorage.getItem("_rt");
            let refreshTokenExpireTime = localStorage.getItem("_rtet");
            refreshTokenExpireTime = refreshTokenExpireTime && !isNaN(refreshTokenExpireTime) ? parseInt(refreshTokenExpireTime) : 0;

            if (refreshToken && refreshTokenExpireTime > nowTime)
                dispatch(refreshTokenAction({ refreshToken, reloadPage: true }));
            else {
                localStorage.removeItem("_rt");
                reload();
            }
        }

        // eslint-disable-next-line
    }, [enableReloadAction])

    return (
        <CSSTransition in={enableReloadAction} unmountOnExit={true} timeout={200}>
            <div className="fixed w-full h-screen top-0 right-0 bg-primary-100 z-[102] flex flex-col items-center justify-center anim-c4">
                <b className="text-white text-lg mb-2">خطای احراز هویت</b>
                <span className="text-gray-300 text-sm">درحال بروزرسانی توکن ها ...</span>

                <div className="absolute w-full bottom-0 flex justify-center">
                    <DotLoading />
                </div>
            </div>
        </CSSTransition>
    )
}

export default ReloadTokenController;