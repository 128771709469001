import Breadcrumb from "components/shared/breadcrumb";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Select2Input from "./select2-input";
import SwitchButton from "components/shared/switchButton";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import Skeleton from "./skeleton";

const UpdateProfile = () => {
    const { id } = useParams();
    const { isLoading, error, data } = UseFetch(`/api/identity/provider/profile/all?id=${id}`);

    const [profiles, setProfiles] = useState([]);
    const [localLoading, setLocalLoading] = useState(true);
    const [failedFields, setFailedFields] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    useEffect(() => {
        if (!isLoading && !error) {
            setProfiles(data.data || []);
            setLocalLoading(false);
        }
    }, [isLoading, error, data])

    const addHandler = () => {
        if (isLoading || error)
            return;

        setProfiles(prev => [
            ...prev,
            {
                content: {},
                form: {},
                status_id: 1
            }
        ]);

        setSubmitEnabled(true);
    }

    const providerTypeChangeHandler = (e, index) => {
        setProfiles(prev => {
            const newState = [...prev];
            newState[index] = {
                ...newState[index],
                form: {
                    ...newState[index].form,
                    id: e?.value || null,
                    title: e?.label || null
                },
                content: {
                    ...newState[index].content,
                    id: null,
                    virtual_title: null
                }
            }

            return newState;
        });

        setSubmitEnabled(true);
    }

    const providerProfileChangeHandler = (e, index) => {
        setProfiles(prev => {
            const newState = [...prev];
            newState[index] = {
                ...newState[index],
                content: {
                    ...newState[index].content,
                    id: e?.value || null,
                    virtual_title: e?.label || null
                }
            }

            return newState;
        });

        setSubmitEnabled(true);
    }

    const statusChangeHandler = index => {
        setProfiles(prev => {
            const newState = [...prev];
            newState[index].status_id = newState[index].status_id === 1 ? 0 : 1;

            return newState;
        });

        setSubmitEnabled(true);
    }

    const removeHandler = index => {
        const intendedIndex = profiles[index];

        if (intendedIndex.id) {
            toast.error("امکان حذف پروفایل ثبت شده وجود ندارد");
            return;
        }

        setProfiles(prev => prev.filter((_, i) => i !== index));
    }

    const submitHandler = async () => {
        if (submitLoading || error || localLoading || !submitEnabled)
            return;

        const profilesPayload = [];
        const newFailedFields = {};

        profiles.forEach((profile, i) => {
            const formId = profile.form.id;
            const contentId = profile.content.id;

            const thisProfile = {
                form_id: formId,
                oid: contentId,
                status_id: profile.status_id
            };

            if (profile.id)
                thisProfile.id = profile.id;

            profilesPayload.push(thisProfile);

            const thisErrors = [];
            if (!formId)
                thisErrors.push("providerType");

            if (!contentId)
                thisErrors.push("providerProfile");

            if (thisErrors.length)
                newFailedFields[i] = thisErrors
        })

        setFailedFields(newFailedFields);
        if (Object.keys(newFailedFields).length === 0) {
            setSubmitLoading(true);

            try {
                const res = await MasterAPI({
                    url: "/api/identity/provider/profile/edit_multi",
                    data: {
                        id: parseInt(id),
                        profiles: profilesPayload
                    },
                    payloadType: 3,
                    callback: () => setSubmitLoading(false)
                });

                toast.success(res.message);
                setSubmitEnabled(false);
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">بروزرسانی پروفایل های تامین کننده</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "تامین کنندگان", link: "/panel/identity_provider" },
                        { title: "بروزرسانی پروفایل های تامین کننده" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/identity_provider" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className="w-10 h-10 text-white bg-primary-100 hover:bg-primary-200 rounded-md fit-center mx-3"
                        title="افزودن سایز"
                        onClick={addHandler}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>

                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 relative overflow-hidden ${!submitEnabled ? "opacity-50 cursor-default" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition unmountOnExit={true} in={submitLoading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            {
                !localLoading ? (
                    !error ? (
                        profiles.length ? (
                            profiles.map((profile, i) => (
                                <div
                                    className={`wrap-box tp-22 page-transition mb-3 crop-size-block low-p ${failedFields[i] ? "has-error" : ""}`}
                                    key={`user-profile-${i}`}
                                >
                                    {
                                        !!profile.id && (
                                            <Link
                                                to={`/panel/provider-profile-detail?form_id=${profile.form.id}&profile_id=${profile.oid}`}
                                                className="flex bg-blue-50 hover:bg-blue-100 dark:bg-slate-800 dark:text-slate-200 transition-colors p-3 text-sm mb-5 rounded-sm"
                                            >
                                                <div>شناسه:<b className="mr-1">{profile.id}</b></div>
                                                <div className="mr-2 pr-2 border-r border-dashed border-blue-200 dark:border-slate-600">کاربر اصلی پروفایل: <b className="font-medium">{profile.is_primary ? "بله" : "خیر"}</b></div>
                                            </Link>
                                        )
                                    }

                                    <div className="flex md:items-center flex-col md:flex-row items-stretch">
                                        <div className="mx-auto md:ml-3 md:mr-0 fit-center w-12 h-12 min-w-[3rem] rounded-full bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 font-bold text-lg">
                                            {i + 1}
                                        </div>

                                        <div className="flex flex-col md:flex-row grow md:ml-3 my-4 md:my-0">
                                            <div className="md:w-1/2 md:ml-1 mb-6 md:mb-0">
                                                <Select2Input
                                                    placeholder="نوع تامین کننده"
                                                    value={profile.form.id ? { label: profile.form.title, value: profile.form.id } : null}
                                                    changeHandler={e => providerTypeChangeHandler(e, i)}
                                                    searchUrl="/api/form/list?parent_child=true&is_provider=true&key=:key"
                                                    labelKey="title"
                                                />

                                                {
                                                    failedFields[i] && failedFields[i]?.indexOf("providerType") !== -1 ? <p className="local-error">انتخاب این فیلد ضروری می باشد</p> : <></>
                                                }
                                            </div>

                                            <div className="md:w-1/2 md:mr-1">
                                                <Select2Input
                                                    placeholder="پروفایل تامین کننده"
                                                    value={profile.content.id ? { label: profile.content.virtual_title, value: profile.content.id } : null}
                                                    changeHandler={e => providerProfileChangeHandler(e, i)}
                                                    disabled={!profile.form.id}
                                                    searchUrl={`/api/content/list?form_id=${profile.form.id}&key=:key`}
                                                    labelKey="virtual_title"
                                                />
                                                {
                                                    failedFields[i] && failedFields[i]?.indexOf("providerProfile") !== -1 ? <p className="local-error">انتخاب این فیلد ضروری می باشد</p> : <></>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between md:justify-start">
                                            <div className="ml-3 flex items-center">
                                                <SwitchButton
                                                    checked={profile.status_id === 1}
                                                    changeHandler={() => statusChangeHandler(i)}
                                                    id={`profile-${i}-status`}
                                                />

                                                <label
                                                    className="tp-6 text-sm font-medium mr-2 cursor-pointer noselect"
                                                    htmlFor={`profile-${i}-status`}
                                                >
                                                    وضعیت
                                                </label>
                                            </div>

                                            <button
                                                className="bg-red-600 hover:bg-red-700 transition-colors rounded-md text-white text-sm px-3 py-1"
                                                onClick={() => removeHandler(i)}
                                            >
                                                حذف
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="text-sm text-red-600 dark:text-red-400 flex items-center">
                                    <i className="fa-regular fa-clipboard-list ml-2 text-lg relative top-px"></i>
                                    موردی برای نمایش یافت نشد
                                </p>
                            </div>
                        )
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="text-sm text-red-600 dark:text-red-400">خطا در دریافت اطلاعات !</p>
                        </div>
                    )
                ) : <Skeleton />
            }
        </div>
    )
}

export default memo(UpdateProfile);