import ModalPortal from "components/HOC/modalPortal";
import { memo, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Lightbox from "./lightbox";
import useEscHandler from "hooks/useEscHandler";

const Modal = props => {
    const {
        children,
        show,
        closeHandler,
        title,
        className
    } = props;

    useEscHandler(closeHandler);

    useEffect(() => {
        const bodyEl = document.body;

        if (show)
            bodyEl.classList.add("overflow-hidden");
        else
            bodyEl.classList.remove("overflow-hidden");
            
        return () => bodyEl.classList.remove("overflow-hidden");
    }, [show])

    return (
        <ModalPortal>
            <Lightbox show={show} clickHandler={closeHandler} />

            <CSSTransition in={show} timeout={{ enter: 200, exit: 0 }} unmountOnExit={true}>
                <div className={`modal${className ? ` ${className}` : ""}`}>
                    <div className="close-cover" onClick={closeHandler}></div>

                    <div className="wrapper">
                        <div className="title">
                            <b>{title}</b>

                            <button onClick={closeHandler}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>

                        <div className="content spec-sb">
                            {children}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </ModalPortal>
    )
}

export default memo(Modal);