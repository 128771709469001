import { useCallback, useId, useRef, useState } from "react";
import MasterAPI from "service/master";
import { CSSTransition } from "react-transition-group";
import { MEDIA } from "service/routes";
import { getFilename } from "utils";
import { toast } from "react-toastify";
import { List, arrayMove } from 'react-movable';
import Modal from "components/shared/modal";
import CropModal from "./crop-modal";

const FileUpload = props => {
    const {
        multi = false,
        value,
        name,
        label,
        changeHandler,
        folder = "public"
    } = props;

    const id = useId();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [cropModal, setCropModal] = useState({ show: false });

    const closeCropModal = useCallback(() => {
        setCropModal(prev => ({ ...prev, show: false }))
    }, [])

    const responseCallback = () => {
        inputRef.current.value = '';
        setLoading(false);
    }

    const inputChangeHandler = async e => {
        const selectedFiles = e.target.files;

        if (!multi && value.length) {
            toast.error("برای انتخاب فایل جدید، لطفا ابتدا فایل قبلی را حذف نمایید");
            return;
        }

        if (selectedFiles.length) {
            let goUpload = false;
            const formData = new FormData();

            [...selectedFiles].forEach(file => {
                if (true) { // validate here
                    goUpload = true;
                    formData.append("files", file);
                }
            });

            if (goUpload) {
                formData.append("folder", folder);
                setLoading(true);

                try {
                    const res = await MasterAPI({
                        url: "/api/upload/multi",
                        data: formData,
                        payloadType: 2,
                        callback: responseCallback
                    });

                    const newFiels = res.file.map(file => file.original);
                    const newValue = [...value, ...newFiels];

                    changeHandler(newValue);
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }

    const deleteHandler = index => {
        const newValue = value.filter((_, i) => index !== i); // eslint-disable-line
        changeHandler(newValue);
    }

    const changeSorthandler = ({ oldIndex, newIndex }) => {
        const newArray = arrayMove(value, oldIndex, newIndex);
        changeHandler(newArray);
    }

    return (
        <>
            <div className="uploader">
                <label htmlFor={`file-${id}`} className="file-field">
                    <input
                        type="file"
                        id={`file-${id}`}
                        multiple={multi}
                        onChange={inputChangeHandler}
                        ref={inputRef}
                    />

                    <span className="grow">{label}</span>
                    <CSSTransition in={loading} timeout={200}>
                        <i className="fa-solid fa-spinner spin ml-3 text-primary-100 anim-c5"></i>
                    </CSSTransition>
                    <i className={`fa-light file-icn ${multi ? "fa-files" : "fa-file"}`}></i>
                </label>

                {
                    multi ? (
                        <List
                            values={value}
                            onChange={changeSorthandler}
                            renderList={({ children, props }) => (
                                <ul {...props}>
                                    {children}
                                </ul>
                            )}
                            renderItem={({ value, props, isDragged }) => {
                                const i = props.key;
                                const filename = getFilename(value);
                                const splitedFilename = filename.split(".");
                                const imageUrl = `${MEDIA}${value.replace("storage/", "")}`;

                                return (
                                    <li
                                        key={`file-${name}-${i}`}
                                        className={`fade-in fu-item ${isDragged ? "grabbing" : ""}`}
                                        {...props}
                                        style={{ ...props.style }}
                                    >
                                        {
                                            // eslint-disable-next-line
                                            <a href={imageUrl} target="_blank" rel="noreferrer" />
                                        }

                                        <button className="ml-3 crop-btn px-2" type="button" onClick={() => setCropModal({ show: true, image: imageUrl })}>
                                            <i className="fa-regular fa-crop"></i>
                                        </button>

                                        <span className="flex grow items-center">
                                            <span className="sp-c2">{splitedFilename[1]}</span>
                                            <span className="sp-c1">{splitedFilename[0]}</span>
                                        </span>

                                        <button type="button" className="del" onClick={() => deleteHandler(i)}></button>
                                    </li>
                                )
                            }}
                        />
                    ) : (
                        <ul>
                            {
                                value.map((value, i) => {
                                    const filename = getFilename(value);
                                    const splitedFilename = filename.split(".");
                                    const imageUrl = `${MEDIA}${value.replace("storage/", "")}`;

                                    return (
                                        <li
                                            key={`file-${name}-${i}`}
                                            className={`fade-in fu-item no-grab`}
                                        >
                                            {
                                                // eslint-disable-next-line
                                                <a href={imageUrl} target="_blank" rel="noreferrer" />
                                            }

                                            <button className="ml-3 crop-btn px-2" type="button" onClick={() => setCropModal({ show: true, image: imageUrl })}>
                                                <i className="fa-regular fa-crop"></i>
                                            </button>

                                            <span className="flex grow items-center">
                                                <span className="sp-c2">{splitedFilename[1]}</span>
                                                <span className="sp-c1">{splitedFilename[0]}</span>
                                            </span>

                                            <button type="button" className="del" onClick={() => deleteHandler(i)}></button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            </div>

            <Modal show={cropModal.show} closeHandler={closeCropModal} title="برش تصویر">
                <CropModal data={cropModal} />
            </Modal>
        </>
    )
}

export default FileUpload;