import { useEffect, useState, memo } from "react";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import { toast } from "react-toastify";

const Resend = props => {
    const { phone } = props;

    const [timer, setTimer] = useState(60);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (timer !== 0) {
            const timeout = setTimeout(() => {
                setTimer(prev => prev - 1);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, [timer])

    const resendHandler = async () => {
        if (timer !== 0 || loading)
            return;

        setLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/signin",
                data: {
                    username: phone
                },
                sendAuthorizationHeader: false,
                callback: () => setLoading(false)
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <button
            className="text-[#6259ca] hover:text-[#4c4597] text-sm font-medium transition-colors flex items-center relative p-2"
            onClick={resendHandler}
        >
            <i className="fa-regular fa-mobile ml-2 relative top-px"></i>
            ارسال مجدد کد

            {
                timer !== 0 && (
                    <span className="mr-1 text-xs">(<span className="w-4 inline-block">{timer}</span>)</span>
                )
            }

            <CSSTransition in={loading} timeout={200} unmountOnExit={true}>
                <span className="pos-cover fit-center anim-c4 tp-30">
                    <i className="fa-solid fa-spinner spin"></i>
                </span>
            </CSSTransition>
        </button>
    )
}

export default memo(Resend);