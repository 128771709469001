import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    <div className="grid grid-cols-12 gap-4">
        <div className="col-span-full lg:col-span-8">
            <div className="wrap-box tp-22 page-transition mb-4">
                <h3 className="font-bold text-sm tp-34">
                    <Skeleton className="w-24 h-4 mb-3" />
                </h3>

                <span className="text-xs tp-35">
                    <Skeleton className="w-24 h-4" />
                </span>

                <div className="flex items-center justify-between mt-5">
                    <Skeleton className="w-11 h-11 rounded-full" />
                    <Skeleton className="w-11 h-4 rounded-full" />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                {
                    Object.keys([...Array(2)]).map(i => (
                        <div className="wrap-box tp-22 page-transition" key={`financial-report-skeleton-${i}`}>
                            <h3 className="font-bold text-sm tp-34">
                                <Skeleton className="w-24 h-4 mb-3" />
                            </h3>

                            <span className="text-xs tp-35">
                                <Skeleton className="w-24 h-4" />
                            </span>

                            <div className="flex items-center justify-between mt-5">
                                <Skeleton className="w-11 h-11 rounded-full" />
                                <Skeleton className="w-11 h-4 rounded-full" />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

        <div className="col-span-full lg:col-span-4">
            <div className="wrap-box tp-22 page-transition mb-4 h-full">
                <Skeleton className="w-20 h-4 mb-6" />

                <div className="financial-state">
                    <ul>
                        {
                            Object.keys([...Array(4)]).map(i => (
                                <li key={`financial-item-skeleton-${i}`}>
                                    <div className="flex items-center grow">
                                        <Skeleton className="w-5 h-5 rounded-full ml-3" />
                                        <Skeleton className="w-24 h-3" />
                                    </div>

                                    <b>
                                        <Skeleton className="w-10 h-3" />
                                    </b>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>

)

export default memo(SkeletonComponent);