import { convertShamsiToUnix } from "utils";

const SendTime = field => {
    const { input_type, value } = field;

    if (input_type === 13 || input_type === 14)
        return value ? convertShamsiToUnix(value, input_type === 14) : null;

    return value;
}

export default SendTime;