const GetFinancialUserType = id => {
    switch (id) {
        case 1:
            return "کاربر";
        case 2:
            return "تامین کننده";
        case 3:
            return "کیف پول سیستمی";
        case 4:
            return "مدیر";
        default:
            return "";
    }
}

export const GetFinancialUserTypeLink = (typeId, userId) => {
    switch (typeId) {
        case 1:
            return {
                title: "کاربر",
                link: `/panel/user-detail/${userId}`
            }
        case 2:
            return {
                title: "تامین کننده",
                link: `/panel/provider-detail/${userId}`
            }
        case 3:
            return {
                title: "کیف پول سیستمی",
                link: `/panel/wallet?user_type=3&user_id=${userId}`
            }
        case 4:
            return {
                title: "ادمین",
                link: `/panel/identity_admin?id=${userId}`
            }
        default:
            return {};
    }
}

export const GetUserTypeLink = (typeId, userId) => {
    switch (typeId) {
        case 1:
            return {
                title: "کاربر",
                link: `/panel/user-detail/${userId}`
            }
        case 2:
            return {
                title: "تامین کننده",
                link: `/panel/provider-detail/${userId}`
            }
        case 3:
            return {
                title: "مدیر",
                link: `/panel/identity_admin?id=${userId}`
            }
        default:
            return {};
    }
}

export default GetFinancialUserType;