const ReadTime = field => {
    const { input_type, value } = field;
    let newVal = value;

    if (input_type === 13 || input_type === 14)
        newVal = value ? new Date(parseInt(value) * 1000) : null;

    return {
        ...field,
        value: newVal
    }
}

export default ReadTime;