import { memo, useId } from "react";

const SwitchButton = props => {
    const {
        id = null,
        checked = false,
        changeHandler = () => { }
    } = props;

    let switchId = useId();
    if (id) switchId = id;

    return (
        <label htmlFor={switchId} className={`switch${checked ? " active" : ""}`}>
            <input
                type="checkbox"
                checked={checked || ""}
                onChange={changeHandler}
                id={switchId}
            />
        </label>
    )
}

export default memo(SwitchButton);