import Footer from "./footer/footer";
import Header from "./header/header";
import Content from "./content/content";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PanelLayer = () => {
    const navigate = useNavigate();

    const authorizationProcess = useSelector(state => state.main.authorizationProcess);
    const authorization = useSelector(state => state.main.authorization);

    useEffect(() => {
        if (authorizationProcess && !authorization)
            navigate("/auth/login", { replace: true });

        // eslint-disable-next-line
    }, [authorizationProcess, authorization]);

    return (
        authorization && (
            <>
                <Header />
                <Content />
                <Footer />
            </>
        )
    )
}

export default memo(PanelLayer);