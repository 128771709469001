import { useEffect } from "react";

const EscHandler = handler => {
    const localHandler = e => {
        if (e.code === "Escape")
            handler();
    }

    useEffect(() => {
        document.addEventListener("keydown", localHandler, false);

        return () => {
            document.removeEventListener("keydown", localHandler, false);
        }
    })
}

export default EscHandler;