import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { memo } from 'react';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { useSelector } from 'react-redux';

const JsonEditor = props => {
    const {
        value,
        label,
        changeHandler
    } = props;

    const darkMode = useSelector(state => state.theme.darkMode);

    return (
        <label className="textarea-c1 has-val editor-box">
            <label className="title">{label}</label>

            <div className="cm-holder">
                <CodeMirror
                    value={value}
                    height="200px"
                    extensions={[json()]}
                    onChange={changeHandler}
                    theme={darkMode ? dracula : "light"}
                />
            </div>
        </label>
    );
}

export default memo(JsonEditor);