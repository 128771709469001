export const ProviderOrderStatuses = [
    {
        id: 1,
        title: "در انتظار تایید",
    },
    {
        id: 2,
        title: "تایید شده",
    },
    {
        id: 3,
        title: "درحال آماده سازی",
    },
    {
        id: 4,
        title: "درحال ارسال",
    },
    {
        id: 5,
        title: "تحویل داده شده",
    },
    {
        id: 6,
        title: "لغو شده",
    }
]