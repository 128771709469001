import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchInfo, overwriteState } from "redux/slices/main";

const GetProfile = () => {
    const dispatch = useDispatch();

    const authorizationProcess = useSelector(state => state.main.authorizationProcess);
    const authorization = useSelector(state => state.main.authorization);

    useEffect(() => {
        if (authorizationProcess && authorization)
            dispatch(fetchInfo());
        else
            dispatch(overwriteState({
                info: {
                    fetched: true,
                    error: false,
                    data: {}
                }
            }));

        // eslint-disable-next-line
    }, [authorizationProcess, authorization])
}

export default memo(GetProfile);