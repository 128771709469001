import MasterAPI from "./master";

const BatchRequest = options => {
    return new Promise(async (resolve, reject) => {
        try {
            const {
                routes,
                returnArray = false
            } = options;

            const res = await MasterAPI({
                url: "/api/batch",
                data: {
                    requests: routes
                },
                payloadType: 3
            });

            if(returnArray)
                resolve(res.data);

            const output = {};
            res.data.forEach(el => {
                output[el.identifier] = el.response;
            });

            resolve(output);
        } catch (e) {
            reject(e);
        }
    })
}

export default BatchRequest;