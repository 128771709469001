import { memo } from "react";
import Skeleton from "components/shared/skeleton";
import ChartBox from "./chart-box";

const FinancialSkeleton = () => (
    <>
        <div className="balance-holder mb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                {
                    Object.keys([...Array(4)]).map(i => (
                        <div className="m-block" key={`skeleton-balance-block-${i}`}>
                            <div className="flex justify-between items-center">
                                <div className="box-title">
                                    <Skeleton className="w-20 h-5 rounded-sm" />
                                </div>

                                <Skeleton className="w-11 h-11 rounded-full" />
                            </div>

                            <ul className="mt-2 py-2">
                                <li>
                                    <div className="grow">
                                        <Skeleton className="w-24 h-3 rounded-sm" />
                                    </div>

                                    <Skeleton className="w-10 h-3 rounded-sm" />
                                </li>

                                <li>
                                    <div className="grow">
                                        <Skeleton className="w-24 h-3 rounded-sm" />
                                    </div>

                                    <Skeleton className="w-10 h-3 rounded-sm" />
                                </li>
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>

        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-full xl:col-span-8">
                <ChartBox twoBlocks={true} />
            </div>

            <div className="col-span-full xl:col-span-4">
                <div className="relative h-full">
                    <div className="relative xl:absolute top-0 right-0 w-full h-full flex flex-col">
                        <div className="wrap-box tp-22 page-transition mb-4">
                            <h2 className="tp-34 text-sm font-bold pb-4"><Skeleton className="w-24 h-5 rounded-full" /></h2>

                            <div className="grow">
                                <div className="rounded-lg p-3 border border-gray-200 dark:border-slate-800 flex items-center">
                                    <div className="grow pl-6 overflow-hidden">
                                        <ul className="financial-list">
                                            <li className="pb-3 mb-3">
                                                <div className="grow">
                                                    <Skeleton className="w-12 h-5 rounded-md" />
                                                </div>

                                                <Skeleton className="w-16 h-5 rounded-md" />
                                            </li>

                                            <li>
                                                <div className="grow">
                                                    <Skeleton className="w-12 h-5 rounded-md" />
                                                </div>

                                                <Skeleton className="w-16 h-5 rounded-md" />
                                            </li>
                                        </ul>
                                    </div>

                                    <Skeleton className="w-9 h-10 rounded-md" />
                                </div>
                            </div>
                        </div>

                        <div className="wrap-box tp-22 page-transition grow relative">
                            <h2 className="tp-34 text-sm font-bold pb-4"><Skeleton className="w-24 h-5 rounded-full" /></h2>

                            <div className="grow">
                                <div className="rounded-lg p-3 border border-gray-200 dark:border-slate-800 flex items-center">
                                    <div className="grow pl-6 overflow-hidden">
                                        <ul className="financial-list">
                                            <li className="pb-3 mb-3">
                                                <div className="grow">
                                                    <Skeleton className="w-12 h-5 rounded-md" />
                                                </div>

                                                <Skeleton className="w-16 h-5 rounded-md" />
                                            </li>

                                            <li>
                                                <div className="grow">
                                                    <Skeleton className="w-12 h-5 rounded-md" />
                                                </div>

                                                <Skeleton className="w-16 h-5 rounded-md" />
                                            </li>
                                        </ul>
                                    </div>

                                    <Skeleton className="w-9 h-10 rounded-md" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default memo(FinancialSkeleton);