import { memo, useCallback, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams } from "react-router-dom";
import UseFetch from "hooks/useFetch";
import { toMoneyFormat, unixToShamsi } from "utils";
import GetFinancialUserType, { GetFinancialUserTypeLink } from "constants/financial-user-type";
import RejectModal from "./rejectModal";
import ServiceTypes from "constants/service-types";
import useExtendData from "./use-extend-data";
import { ShowExtendUserLabel } from "components/shared/show-extend-data";
import Loading from "components/shared/loading";
import { toast } from "react-toastify";
import MasterAPI from "service/master";

const View = () => {
    const { id } = useParams();
    const { isLoading, error, data, mutate } = UseFetch(`/api/financial/balance/remittance/history/${id}`);

    const extendData = useExtendData(isLoading, error, data);
    const [showRejectModal, setShowRejectModal] = useState();

    const closeModal = useCallback(() => {
        setShowRejectModal(false);
    }, [])

    const acceptHandler = async () => {
        try {
            const res = await MasterAPI({
                url: "/api/financial/balance/remittance/approve",
                method: "PUT",
                payloadType: 3,
                data: {
                    id: data.data.id
                }
            })

            mutate();
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات واریز به حساب</h1>
                    <Breadcrumb data={[
                        { title: "واریز به حساب", link: '/panel/remittance' },
                        { title: "جزئیات واریز به حساب" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/remittance" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center" onClick={mutate} title="بروز رسانی">
                        <i className="fa-regular fa-arrows-retweet"></i>
                    </button>

                    {
                        data.data?.status_id === 0 && (
                            <>
                                <button
                                    className={`bg-red-500 hover:bg-red-600 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3`}
                                    onClick={() => setShowRejectModal(true)}
                                >
                                    <i className="fa-regular fa-xmark ml-2"></i>
                                    رد کردن
                                </button>

                                <button
                                    className={`bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden mr-3`}
                                    onClick={acceptHandler}
                                >
                                    <i className="fa-regular fa-check ml-2"></i>
                                    تایید
                                </button>
                            </>
                        )
                    }
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                                    <p className="text-sm text-green-800 dark:text-slate-300">
                                        <b className="inline-block ml-2">وضعیت واریز به حساب</b>
                                        <span>
                                            {data.data.status_id === 0 && "در انتظار تایید"}
                                            {data.data.status_id === 1 && "تایید شده"}
                                            {data.data.status_id === 2 && "رد شده"}
                                        </span>
                                    </p>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه واریز به حساب</span>
                                            <b>{data.data.id}</b>
                                        </li>

                                        <li className="inline">
                                            <span>مبلغ</span>
                                            <b>{toMoneyFormat(data.data.amount)} تومان</b>
                                        </li>

                                        <li className="inline">
                                            <span>نوع سرویس</span>
                                            <b>{ServiceTypes[data.data.service_type_id]?.title || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>نوع کاربری</span>
                                            <b>{GetFinancialUserType(data.data.financial_user_type_id)}</b>
                                        </li>

                                        <li className="inline-flex items-center">
                                            <span>کاربر</span>
                                            <b>
                                                <ShowExtendUserLabel
                                                    value={extendData.user}
                                                    link={GetFinancialUserTypeLink(data.data.financial_user_type_id, data.data.user_id).link}
                                                />
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>شناسه تراکنش</span>
                                            <b>
                                                {
                                                    data.data.transaction_id
                                                        ? <Link to={`/panel/transactions/${data.data.transaction_id}`} className="link-c1">{data.data.transaction_id}</Link>
                                                        : "---"
                                                }
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>کد پیگیری</span>
                                            <b>{data.data.tracking_code || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>کد پیگیری بانک</span>
                                            <b>{data.data.bank_tracking_code || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>توضیحات</span>
                                            <b>{data.data.description || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>توضیحات ادمین</span>
                                            <b>{data.data.admin_note || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>پیوست ها</span>
                                            <b>{data.data.attachments || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ واریز</span>
                                            <b>{unixToShamsi(data.data.remitted_at) || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ ایجاد</span>
                                            <b>{unixToShamsi(data.data.created_at) || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>تاریخ آخرین تغییرات</span>
                                            <b>{unixToShamsi(data.data.update_at) || "---"}</b>
                                        </li>
                                    </ul>
                                </div>

                                <div className={`${data.data.order_oid?.length ? "border-b border-gray-200 dark:border-slate-700" : ""} py-4`}>
                                    <ul className="order-detail">
                                        <li className="inline-flex items-center">
                                            <span>شناسه ادمین</span>
                                            <b>
                                                {
                                                    data.data.admin_id ? (
                                                        <ShowExtendUserLabel
                                                            value={extendData.admin}
                                                            link={GetFinancialUserTypeLink(4, data.data.admin_id).link}
                                                        />
                                                    ) : "---"
                                                }
                                            </b>
                                        </li>

                                        <li className="inline">
                                            <span>ip ادمین</span>
                                            <b>{data.data.admin_ip || "---"}</b>
                                        </li>

                                        <li className="inline">
                                            <span>شناسه توکن ادمین</span>
                                            <b>
                                                {data.data.admin_token_id ? (
                                                    <Link className="link-c1" to={`/panel/tokens/admin/${data.data.admin_id}/${data.data.admin_token_id}`}>
                                                        {data.data.admin_token_id}
                                                    </Link>
                                                ) : "---"}
                                            </b>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    data.data.order_oid?.length > 0 && (
                                        <div className="py-4">
                                            <ul className="order-detail">
                                                <li className="inline-flex items-center">
                                                    <span>شناسه گروه سفارش</span>

                                                    <b>
                                                        <Link className="link-c1" to={`/panel/order/${data.data.order_oid}`}>
                                                            {data.data.order_oid}
                                                        </Link>
                                                    </b>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }
                            </>
                        ) : <p className="global-error">خطا در دریافت اطلاعات !</p>
                    ) : (
                        <div className="fit-center">
                            <Loading className="loading-c5" />
                        </div>
                    )
                }
            </div>

            <RejectModal
                id={data.data?.id}
                show={!isLoading && !error && showRejectModal}
                closeHandler={closeModal}
                mutate={mutate}
            />
        </>
    )
}

export default memo(View);