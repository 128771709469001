import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import MasterAPI from 'service/master'

const initialState = {
    darkMode: false,
    menuTheme: 0, // 1=light, 2=dark, 3=primary
    headerTheme: 0, // like up
    menuFadeLg: false,
    menuFadeSm: false,
    themeSettingModal: { show: false, panelSettings: true },
    activePanelRoute: null,
    cropSizes: {
        pending: true
    }
}

export const getCropSizes = createAsyncThunk(
    'getCropSizes',
    async () => {
        const res = await MasterAPI({
            url: "/api/setting/upload/image/crop_size/all",
            method: "GET"
        });

        return res.data.map(size => `${size.width}x${size.height}`);
    }
)

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        toggleDarkMode: state => {
            state.darkMode = !state.darkMode;
        },
        setMenuTheme: (state, action) => {
            state.menuTheme = action.payload;
        },
        setHeaderTheme: (state, action) => {
            state.headerTheme = action.payload;
        },
        setThemeSettingModal: (state, action) => {
            state.themeSettingModal = action.payload;
        },
        toggleMenuFadeLg: state => {
            state.menuFadeLg = !state.menuFadeLg;
        },
        toggleMenuFadeSm: state => {
            state.menuFadeSm = !state.menuFadeSm;
        },
        resetTheme: state => {
            return {
                ...state,
                darkMode: false,
                menuTheme: 0,
                headerTheme: 0
            }
        },
        setActivePanelRoute: (state, action) => {
            state.activePanelRoute = action.payload;
        },
        setMenuFadeSm: (state, action) => {
            state.menuFadeSm = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCropSizes.pending, state => {
            state.cropSizes = {
                data: [],
                pending: true,
                error: false
            }
        })

        builder.addCase(getCropSizes.fulfilled, (state, action) => {
            state.cropSizes = {
                data: action.payload,
                pending: false,
                error: false
            }
        })

        builder.addCase(getCropSizes.rejected, state => {
            state.cropSizes = {
                data: [],
                pending: false,
                error: true
            }
        })
    }
})

export const {
    setDarkMode,
    toggleDarkMode,
    setMenuTheme,
    setHeaderTheme,
    setThemeSettingModal,
    toggleMenuFadeLg,
    toggleMenuFadeSm,
    resetTheme,
    setActivePanelRoute,
    setMenuFadeSm
} = themeSlice.actions

export default themeSlice.reducer;