import { memo, useState } from "react"
import TextareaAutosize from 'react-textarea-autosize';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { useParams } from "react-router-dom";

const Send = props => {
    const { mutate, mode } = props;

    const [submitLoading, setSubmitLoading] = useState(false);
    const { id } = useParams();

    const {
        register,
        handleSubmit,
        setValue
    } = useForm({
        mode: "onChange"
    });

    const submitHandler = async data => {
        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: `/api/ticket/${mode}/reply`,
                payloadType: 3,
                method: "PUT",
                callback: () => setSubmitLoading(false),
                data: {
                    id,
                    message: data.message,
                    type: "text"
                }
            });

            setValue("message", "");
            toast.success(res.message);
            mutate();
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="mb-3">
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className="send">
                    <TextareaAutosize
                        placeholder="پیام خود را بنویسید ..."
                        {...register("message", { required: true })}
                    />

                    <button type="submit">
                        {
                            submitLoading ? (
                                <i className="fa-duotone fa-spinner-third spin"></i>
                            ) : (
                                <i className="fa-solid fa-paper-plane-top fa-rotate-180"></i>
                            )
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default memo(Send);