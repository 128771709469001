import TextareaAutosize from 'react-textarea-autosize';

const Textarea = props => {
    const {
        value,
        label,
        changeHandler,
        id
    } = props;

    return (
        <label className={`textarea-c1${value?.length ? " has-val" : ""}`}>
            <label htmlFor={id} className="title">{label}</label>

            <TextareaAutosize
                minRows={1}
                id={id}
                value={value || ""}
                onChange={e => changeHandler(e.target.value)}
            />
        </label>
    )
}

export default Textarea;