import Breadcrumb from "components/shared/breadcrumb";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import templateFetcherHook from "service/template-fetcher-hook";
import FormGenerator from "components/form-generator/main/main";

const OnlyForm = props => {
    const { template } = props;
    const { data: pageData } = templateFetcherHook(template);
    const [goSubmit, setGoSubmit] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);

    const setParentLoading = useCallback(val => {
        setSubmitLoading(val);
        // eslint-disable-next-line
    }, [])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={pageData.breadcrumb} />
                </div>

                <div className="flex">
                    <Link to={pageData.breadcrumb[1].link} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-7 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        ارسال
                        <i className="fa-regular fa-paper-plane-top mr-2 rotate-180"></i>

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                <FormGenerator
                    submitUrl={pageData.submit_url}
                    fields={pageData.edit_setting}
                    goSubmit={goSubmit}
                    setParentLoading={setParentLoading}
                />
            </div>
        </div>
    )
}

export default OnlyForm;