import { memo } from "react";
import ChangePassword from "./change-password";
import TwoVerification from "./two-verification/two-verification";
import { useSelector } from "react-redux";
import Loading from "components/shared/loading";

const PasswordSetting = () => {
    const profile = useSelector(state => state.main.info);

    return (
        <div className="m-container">
            {
                profile.fetched ? (
                    !profile.error ? (
                        <>
                            <ChangePassword />
                            <TwoVerification />
                        </>
                    ) : <div className="wrap-box tp-22 page-transition"><p className="text-red-500 dark:text-red-300">خطا در دریافت اطلاعات کاربر !</p></div>
                ) : <div className="wrap-box tp-22 page-transition fit-center"><Loading className="loading-c5" /></div>
            }
        </div>
    )
}

export default memo(PasswordSetting);