import Skeleton from "components/shared/skeleton";
import { memo } from "react"

const SkeletonComponent = () => (
    <>
        <div className="grid grid-cols-1 gap-5 mb-10">
            <Skeleton className="h-11 rounded-full" />

            {
                Object.keys([...Array(6)]).map(i => (
                    <div className="flex items-center" key={`permissions-sb1-${i}`}>
                        <Skeleton className="w-5 h-5 rounded-sm" />
                        <Skeleton className="w-36 h-5 rounded-sm mr-3" />
                    </div>
                ))
            }
        </div>

        {
            Object.keys([...Array(2)]).map(i => (
                <div className="permissions" key={`permissions-sb2-${i}`}>
                    <div className="head">
                        <div className="row">
                            <div className="title-col">
                                <Skeleton className="w-28 h-5 rounded-sm" />
                            </div>

                            <div className="checked-col">
                                <Skeleton className="h-5 rounded-sm" />
                            </div>

                            <div className="checked-col">
                                <Skeleton className="h-5 rounded-sm" />
                            </div>

                            <div className="checked-col">
                                <Skeleton className="h-5 rounded-sm" />
                            </div>
                        </div>
                    </div>

                    <div className="body">
                        {
                            Object.keys([...Array(5)]).map(j => (
                                <div className="item" key={`permissions-sb3-${i}-${j}`}>
                                    <div className="row">
                                        <div className="title-col">
                                            <span className="hide-btn"></span>
                                            <Skeleton className="w-5 h-5 rounded-sm ml-4" />
                                            <Skeleton className="w-24 h-3 rounded-sm" />
                                        </div>

                                        {
                                            Object.keys([...Array(3)]).map(n => (
                                                <div className="checked-col" key={`permissions-sb3-${i}-${j}-${n}`}>
                                                    <Skeleton className="w-5 h-5 rounded-sm mx-auto" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))
        }
    </>
)

export default memo(SkeletonComponent);