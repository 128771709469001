const Skeleton = () => (
    <div className="flex justify-between items-center">
        <div className="skeleton-box rounded-sm w-28 h-5">
            <span />
        </div>

        <div className="skeleton-box rounded-lg w-60 h-10">
            <span />
        </div>
    </div>
)

export default Skeleton;