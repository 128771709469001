import { memo, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import List from "./list/list";
import Add from "./add/add";
import Edit from "./edit/edit";
import EnumsContext from "context/initialize-context";
import { useMemo } from "react";
import { arrayToRecursive } from "utils/recursive";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { SERVICE_1 } from "service/routes";
import templateFetcherHook from "service/template-fetcher-hook";
import FormCard from "./formCard";

const Mapper = (props) => {
    const {
        formEl,
        onHoverHandler,
        setHoveredForm,
        countDetail
    } = props;

    if (formEl.childs.length) {
        return (
            <div className="col-span-full border border-gray-300 dark:border-slate-600 p-4 rounded-lg mb-4 last:mb-0" key={`parent-form-${formEl.id}`}>
                <FormCard
                    form={formEl}
                    styleType={1}
                    onHoverHandler={onHoverHandler}
                    setHoveredForm={setHoveredForm}
                    countDetail={countDetail}
                />

                <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4 px-2">
                    {
                        formEl.childs.map(childEl => (
                            <Mapper
                                formEl={childEl}
                                onHoverHandler={onHoverHandler}
                                setHoveredForm={setHoveredForm}
                                countDetail={countDetail}
                            />
                        ))
                    }
                </div>
            </div>
        )
    } else {
        return (
            <FormCard
                form={formEl}
                styleType={2}
                onHoverHandler={onHoverHandler}
                setHoveredForm={setHoveredForm}
                countDetail={countDetail}
            />
        )
    }
}

const sorter = data => {
    data.forEach(el => {
        if (el.childs.length) {
            el.childs = sorter(el.childs);
        }
    })

    return data
}

const Content = props => {
    const {
        template
    } = props;

    const { data: pageData } = templateFetcherHook(template);
    const enumsData = useContext(EnumsContext);
    const [searchParams] = useSearchParams();

    const accessToken = useSelector(state => state.main.accessToken);
    const [hoveredForm, setHoveredForm] = useState(null);
    const [timeoutState, setTimeoutState] = useState(null);
    const [signalState, setSignalState] = useState(null);
    const [countDetail, setCountDetail] = useState(null);

    useEffect(() => {
        setCountDetail(null);

        if (hoveredForm)
            fetcher(hoveredForm);

        // eslint-disable-next-line
    }, [hoveredForm])

    const fetcher = async formId => {
        setCountDetail(null);

        if (signalState)
            signalState.abort();

        const newSignal = new AbortController();
        setSignalState(newSignal);

        const routesObj = {
            waiting: `/api/content/list?form_id=${formId}&status_id=0`,
            accepted: `/api/content/list?form_id=${formId}&status_id=1`,
            rejected: `/api/content/list?form_id=${formId}&status_id=2`,
            deleted: `/api/content/list?form_id=${formId}&status_id=3`,
            draft: `/api/content/list?form_id=${formId}&status_id=4`
        }

        const batchRoutes = Object.keys(routesObj).map(routeKey => ({
            identifier: routeKey,
            url: routesObj[routeKey],
            method: "GET"
        }))

        try {
            const res = await axios({
                url: `${SERVICE_1}/api/batch`,
                signal: newSignal.signal,
                data: JSON.stringify({
                    requests: batchRoutes
                }),
                method: "POST",
                headers: {
                    Authorization: accessToken
                }
            });

            setCountDetail(res?.data?.data || null);
        } catch (e) {

        }
    }

    const forms = useMemo(() => {
        if (!searchParams.get("form_id")) {
            const enumForms = enumsData.data.forms.filter(formEl => formEl.status_id !== 3);
            let recursiveData = arrayToRecursive(enumForms)

            recursiveData.sort(function (a, b) {
                const keyA = new Date(a.priority || 1000),
                    keyB = new Date(b.priority || 1000);

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;

                return 0;
            });

            recursiveData = sorter(recursiveData);
            return recursiveData;
        }

        return [];
        // eslint-disable-next-line
    }, [searchParams])

    const onHoverHandler = formId => {
        if (timeoutState)
            clearTimeout(timeoutState);

        const newTimeout = setTimeout(() => {
            setHoveredForm(formId);
        }, 1000);

        setTimeoutState(newTimeout);
    }

    if (searchParams.get("form_id"))
        return <Inner1 pageData={pageData} />

    return (
        <div className="m-container">
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 relative transition-all gap-x-4 gap-y-10">
                {
                    forms.map(formEl => (
                        <Mapper
                            formEl={formEl}
                            onHoverHandler={onHoverHandler}
                            setHoveredForm={setHoveredForm}
                            countDetail={countDetail}
                        />
                    ))
                }
            </div>
        </div>
    )
}

const Inner1 = props => {
    const {
        pageData
    } = props;

    const params = useParams();
    const { addId, id } = params;

    return (
        <div className="m-container">
            <div className="relative transition-all">
                {
                    addId ? (
                        <Add
                            pageData={pageData}
                            formId={addId}
                        />
                    ) : (
                        id
                            ? <Edit pageData={pageData} id={id} />
                            : <List pageData={pageData} />
                    )
                }
            </div>
        </div>
    )
}

export default memo(Content);