import ErrorMessage from "components/form-generator/error-msg";
import { memo, useRef, useId, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import { faNumToEng } from "utils";

const Step1 = props => {
    const { phone, setPhone, setMode } = props;

    const inputRef = useRef();
    const formId = useId();
    const [error, setError] = useState();
    const [submitLoading, setSubmitLoading] = useState(false);

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        if (!validate(phone)) {
            inputRef.current.focus();
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/forgot_password",
                payloadType: 3,
                sendAuthorizationHeader: false,
                data: {
                    username: phone
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
            setMode("step2");
        } catch (e) {
            toast.error(e.message);
        }
    }

    const validate = value => {
        try {
            const phoneRegex = /^09\d{9}$/;

            if (!value?.length)
                throw Object.assign(new Error("required"), { code: 1 });
            else if (!phoneRegex.test(value))
                throw Object.assign(new Error("format"), { code: 2 });
            else
                setError(null);

            return true;
        } catch (e) {
            setError(e.message);
            return false;
        }
    }

    const phoneChangeHandler = useCallback(e => {
        const value = faNumToEng(e.target.value);
        const regex = /^[0-9\b]+$/;

        if (value === "" || (value.length <= 11 && regex.test(value))) {
            setPhone(value);
            validate(value);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="flex justify-between items-center mb-2">
                <h1 className="tp-27 font-bold">فراموشی رمز عبور</h1>
                <Link to="/" className="text-blue-500 text-sm">بازگشت</Link>
            </div>

            <p className="tp-28 text-sm mb-8">
                شماره همراه خود را وارد کنید تا لینک تغییر گذر واژه را برای شما ارسال کنیم
            </p>

            <form onSubmit={submitHandler}>
                <div className={`text-field${phone?.length ? " has-val" : ""}${!!error ? " fi-error" : ""}`}>
                    <label htmlFor={`${formId}-phone`}>شماره همراه</label>
                    <input
                        type="text"
                        value={phone || ""}
                        onChange={phoneChangeHandler}
                        id={`${formId}-phone`}
                        ref={inputRef} />

                    {!!error && <span className="f-error">{ErrorMessage(error)}</span>}
                </div>

                <button type="submit" className="go-btn">
                    ارسال

                    <CSSTransition in={submitLoading} timeout={300} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c9">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </form>
        </>
    )
}

export default memo(Step1);