import { memo, useState } from "react";
import MasterAPI from "service/master";
import { nowTimestamp, resolveAndDownloadBlob, unixToShamsi } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import { createSearchParams, useLocation } from "react-router-dom";

const DownloadExcel = ({url,title}) => {
    const location = useLocation();
    const [downloadLoading, setDownloadLoading] = useState(false);

    const downloadHandler = async () => {
        if (downloadLoading)
            return;

        setDownloadLoading(true);
        try {
            const newSearchParams = createSearchParams(location.search);
            newSearchParams.delete("offset");
            newSearchParams.delete("limit");
            console.log("url: ",url);
            
            const res = await MasterAPI({
                url: `${url}?${newSearchParams.toString()}`,
                method: "GET",
                responseType: "blob",
                returnData: false,
                callback: () => setDownloadLoading(false)
            });

            const filename = `لیست_${title}_${unixToShamsi(nowTimestamp(), false).replaceAll(" ", "_")}`;
            resolveAndDownloadBlob(res, filename);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <button
            className="bg-primary-100 mr-3 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden"
            onClick={downloadHandler}
        >
            <i className="fa-light fa-file-excel ml-2"></i>
            دانلود اکسل

            <CSSTransition in={downloadLoading} timeout={200} unmountOnExit={true}>
                <span className="pos-cover bg-primary-200 fit-center anim-c4">
                    <i className="fa-solid fa-spinner spin"></i>
                </span>
            </CSSTransition>
        </button>
    )
}

export default memo(DownloadExcel);