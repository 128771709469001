import { memo, useId, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import { overwriteState } from "redux/slices/main";
import MasterAPI from "service/master";

const Step2 = props => {
    const { phone, setMode } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formId = useId();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [code, setCode] = useState("");

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        if (code.length === 0) {
            toast.error("کد اعتبارسنجی را وارد نمایید");
            return;
        }

        if (password.length < 8) {
            toast.error("رمز عبور باید حداقل 8 کاراکتر باشد");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("رمز عبور با تکرار رمزعبور یکسان نمی باشد");
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/forgot_password_confirm",
                payloadType: 3,
                callback: () => setSubmitLoading(false),
                sendAuthorizationHeader: false,
                data: {
                    username: phone,
                    new_password: password,
                    sms_code: parseInt(code)
                }
            });

            const {
                access_token,
                access_token_expires_in,
                refresh_token,
                refresh_token_expires_in
            } = res;

            localStorage.setItem("_at", access_token);
            localStorage.setItem("_atet", access_token_expires_in);

            localStorage.setItem("_rt", refresh_token);
            localStorage.setItem("_rtet", refresh_token_expires_in);

            dispatch(overwriteState({
                authorizationProcess: true,
                authorization: true,
                accessToken: access_token,
                accessTokenExpireTime: refresh_token_expires_in,
                info: { fetched: false }
            }));

            navigate("/panel");
            toast.success("رمز عبور با موفقیت تغییر کرد");
        } catch {
            toast.error(e.message);
        }
    }

    return (
        <>
            <h1 className="tp-27 font-bold mb-2">کد اعتبارسنجی</h1>
            <p className="tp-28 text-sm">
                لطفا کد ارسال شده را وارد نمایید
            </p>

            <button type="button" className="text-blue-500 text-sm mt-2 mb-8" onClick={() => setMode("step1")}>تغییر شماره همراه</button>

            <form onSubmit={submitHandler}>
                <div className="grid grid-cols-2 gap-4">
                    <div className={`col-span-2 text-field${code?.length ? " has-val" : ""}`}>
                        <label htmlFor={`${formId}-vCode`}>کد اعتبارسنجی</label>
                        <input
                            type="text"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            id={`${formId}-vCode`}
                        />
                    </div>

                    <div className={`text-field${password?.length ? " has-val" : ""}`}>
                        <label htmlFor={`${formId}-pass`}>گذرواژه جدید</label>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            id={`${formId}-pass`}
                        />
                    </div>

                    <div className={`text-field${confirmPassword?.length ? " has-val" : ""}`}>
                        <label htmlFor={`${formId}-confirm-pass`}>تکرار گذرواژه جدید</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            id={`${formId}-confirm-pass`}
                        />
                    </div>
                </div>

                <button type="submit" className="go-btn">
                    ارسال

                    <CSSTransition in={submitLoading} timeout={300} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c9">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </form>
        </>
    )
}

export default memo(Step2);