import { createElement } from "react";

const InfoBox = (props) => {
    const { text } = props;

    if (!text)
        return <></>

    return (
        <div className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
            <i className="fa-solid fa-triangle-exclamation text-lg ml-3"></i>
            {createElement("div", { dangerouslySetInnerHTML: { __html: text } })}
        </div>
    )
}

export default InfoBox;