const ServiceTypes = {
    1: {
        title: "وب سایت"
    },
    2: {
        title: "پنل تامین کننده"
    },
    3: {
        title: "پنل ادمین"
    },
    4: {
        title: "سیستم"
    }
}

export default ServiceTypes;