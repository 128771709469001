import { useEffect } from "react";

const useFreeSlider = ref => {
    useEffect(() => {

        if (ref.current) {
            const widthSize = window.innerWidth;

            if (widthSize >= 768) {
                const slider = ref.current;
                let mouseDown = false;
                let moved = false;
                let startX, startY, scrollLeft;

                const linkClickHandler = e => {
                    if (moved) {
                        e.preventDefault();
                        moved = false;
                    }
                }

                const startDragging = (e, touchEvent) => {
                    mouseDown = true;
                    const pageX = touchEvent ? e.touches[0].pageX : e.pageX;
                    const pageY = touchEvent ? e.touches[0].pageY : e.pageY;

                    startX = pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;

                    startY = pageY;
                };

                const stopDragging = () => {
                    mouseDown = false;
                }

                const moveHandler = (e, touchEvent) => {
                    if (!mouseDown) {
                        moved = false;
                        return;
                    }

                    moved = true;
                    const pageX = touchEvent ? e.touches[0].pageX : e.pageX;
                    const pageY = touchEvent ? e.touches[0].pageY : e.pageY;

                    const x = pageX - slider.offsetLeft;
                    const scroll = x - startX;
                    slider.scrollLeft = scrollLeft - scroll;

                    let Yswings = startY - pageY;
                    Yswings = Yswings < 0 ? Yswings * -1 : Yswings;

                    if (Yswings > 50)
                        stopDragging();
                }

                const aTags = slider.querySelectorAll("a");
                aTags?.forEach(aTag => {
                    aTag.addEventListener('click', linkClickHandler)
                });

                slider.addEventListener('mousemove', e => moveHandler(e, false));
                slider.addEventListener('mousedown', e => startDragging(e, false), false);
                slider.addEventListener('mouseup', stopDragging, false);
                slider.addEventListener('mouseleave', stopDragging, false);

                slider.addEventListener('touchmove', e => moveHandler(e, true));
                slider.addEventListener('touchstart', e => startDragging(e, true), false);
                slider.addEventListener('touchend', stopDragging, false);

                return () => {
                    slider.removeEventListener('mousemove', e => moveHandler(e, false));
                    slider.removeEventListener('mousedown', e => startDragging(e, false), false);
                    slider.removeEventListener('mouseup', stopDragging, false);
                    slider.removeEventListener('mouseleave', stopDragging, false);

                    slider.removeEventListener('touchmove', e => moveHandler(e, true));
                    slider.removeEventListener('touchstart', e => startDragging(e, true), false);
                    slider.removeEventListener('touchend', stopDragging, false);

                    aTags?.forEach(aTag => {
                        aTag.removeEventListener('click', linkClickHandler)
                    });
                }
            } else
                ref.current.classList.add("mobileSlider");
        }
    }, [ref])
}

export default useFreeSlider;