import { CKEditor } from "ckeditor4-react";

function App(props) {
    const {
        changeHandler,
        value,
        label
    } = props;

    return (
        <>
            <label className='title-c2 tp-27 mb-1 block'>{label}</label>

            <CKEditor
                initData={value || ""}
                editorUrl="/assets/ckeditor/ckeditor.js"
                onChange={e => changeHandler(e.editor.getData())}
                config={{
                    toolbar: [
                        { name: 'document', "items": ['NewPage',] },
                        { name: 'clipboard', "items": ['Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] }, { name: 'editing', "items": ['Find', 'Replace', '-', 'SelectAll', '-', 'Maximize'] },
                        { name: 'insert', "items": ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar'] },
                        { name: 'paragraph', "items": ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
                        { name: 'links', "items": ['Link', 'Unlink', 'Anchor'] },
                        { name: 'basicstyles', "items": ['Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor', '-', 'RemoveFormat'] }, { name: 'styles', "items": ['Font', 'FontSize', 'Styles', 'Format'] },
                        { name: 'other', items: ['simplebutton', '-', 'gallery', '-', 'CodeSnippet', '-', 'newsinnews'] }
                    ],
                }}
            />
        </>
    );
}

export default App;