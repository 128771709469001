import MasterAPI from "service/master";

const searchAddressController = async (state, dispatch, keyword) => {
    const centerPoint = state.centerPoint;

    dispatch({
        type: "SET_SEARCH_ADDRESS",
        payload: {
            showReslultBox: false,
            pending: true,
            data: [],
            error: false
        }
    })

    try {
        const res = await MasterAPI({
            url: `/api/location_service/search?lat=${centerPoint[0]}&lng=${centerPoint[1]}&query=${keyword}`,
            method: "GET"
        });

        dispatch({
            type: "SET_SEARCH_ADDRESS",
            payload: {
                showReslultBox: true,
                pending: false,
                data: res.data || [],
                error: false
            }
        })
    } catch (e) {
        dispatch({
            type: "SET_SEARCH_ADDRESS",
            payload: {
                showReslultBox: true,
                pending: false,
                data: [],
                error: true
            }
        })
    }
}

export default searchAddressController;