import { memo, useId } from "react";

const Checkbox = props => {
    const {
        changeHandler,
        value,
        className = null
    } = props;

    const elId = useId();
    return (
        <label htmlFor={elId} className={`p-checkbox${value ? " checked" : ""} ${className}`}>
            <input type="checkbox" defaultChecked={!!value} id={elId} onChange={changeHandler} />
            <span></span>
        </label>
    )
}

export default memo(Checkbox);