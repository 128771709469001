import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { memo } from "react";
import { addZeroToNum } from "utils";
import { useSelector } from "react-redux";

const CustomComponent = props => {
    const {
        value,
        openCalendar,
        label,
        changeHandler,
        id
    } = props;

    return (
        <div className={`text-field relative${value?.length ? " has-val" : ""}`}>
            <label htmlFor={id}>{label}</label>

            <input
                id={id}
                type="text"
                className="block w-full"
                onClick={openCalendar}
                value={value}
                onFocus={openCalendar}
                onChange={() => { }}
            />

            {
                !!value?.length && (
                    <button type="button" className="unselect-c1" onClick={() => changeHandler(null)} />
                )
            }
        </div>
    )
}

const TimePickerComponent = props => {
    const darkMode = useSelector(state => state.theme.darkMode);

    const {
        value,
        setValue
    } = props;

    const attrs = {
        calendar: persian,
        value,
        plugins: [<TimePicker position="bottom" />],
        locale: persian_fa,
        weekDays: ["ش", "ی", "د", "س", "چ", "پ", "ج"],
        maxDate: new DateObject({ calendar: persian }),
        containerClassName: "datepicker-c",
        format: "YYYY/MM/DD HH:mm:ss",
        onChange: e => {
            let newVal = `${e.year}/${addZeroToNum(e.month)}/${addZeroToNum(e.day)}`;
            newVal += ` ${addZeroToNum(e.hour)}:${addZeroToNum(e.minute)}:${addZeroToNum(e.second)}`

            setValue(newVal);
        },
        render: (value, openCalendar) => (
            <CustomComponent
                openCalendar={openCalendar}
                value={value}
                label="تاریخ تسویه"
                changeHandler={setValue}
            />
        ),
    }

    if (darkMode)
        attrs.className = "bg-dark";

    return (
        <DatePicker {...attrs} />
    )
}

export default memo(TimePickerComponent);