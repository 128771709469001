import { memo } from "react"

const Skeleton = props => {
    const {
        className = ""
    } = props;

    return (
        <div className={`skeleton-box ${className}`}>
            <span />
        </div>
    )
}

export default memo(Skeleton);