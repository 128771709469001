import React, { memo, useMemo } from "react";
import Cancel from "./cancel";
import Delivered from "./delivered";
import Sending from "./sending";
import { isSingleProvirderMode } from "constants/config";
import Accept from "./accept";
import ReadyToSend from "./readyToSend";

const Actions = props => {
    const { data, mutate } = props;

    const buttonsList = useMemo(() => {
        return {
            "cancel": <Cancel data={data} mutate={mutate} />,
            "delivered": <Delivered data={data} mutate={mutate} />,
            "sending": <Sending data={data} mutate={mutate} />,
            "accept": <Accept data={data} mutate={mutate} />,
            "readyToSend": <ReadyToSend data={data} mutate={mutate} />
        }

        // eslint-disable-next-line
    }, [data])

    const actionsList = useMemo(() => {
        const output = [];

        if (data.status_id === 4)
            output.push("sending");

        if (data.status_id === 5)
            output.push("delivered");

        if ([1, 2, 3, 4, 5].indexOf(data.status_id) !== -1)
            output.push("cancel");

        if (isSingleProvirderMode) {
            if (data.status_id === 2)
                output.push("accept");
            else if (data.status_id === 3)
                output.push("readyToSend");
        }

        return output;
    }, [data])

    return (
        actionsList.map((action, i) => (
            <React.Fragment key={`order-action-${i}`}>
                {buttonsList[action]}
            </React.Fragment>
        ))
    )
}

export default memo(Actions);