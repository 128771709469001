import { memo, useState } from "react";
import { faNumToEng, toMoneyFormat } from "utils";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import UseFetch from "hooks/useFetch";
import { useParams, useSearchParams } from "react-router-dom";

const ConvertScore = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const [value, setValue] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { isLoading, data, error } = UseFetch("/api/score/get_setting");

    const changeHandler = e => {
        let newVal = e.target.value;
        newVal = faNumToEng(newVal);
        newVal = newVal.replaceAll(",", "");

        if (newVal === "")
            setValue(0);
        else if (/^[0-9\b]+$/.test(newVal))
            setValue(newVal);
    }

    const submitHandler = async e => {
        e.preventDefault();

        if (submitLoading)
            return;

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/score/score_to_money",
                payloadType: 3,
                data: {
                    user_id: parseInt(id),
                    user_kind_id: parseInt(searchParams.get("user_kind_id")),
                    score_amount: parseInt(value)
                },
                callback: () => setSubmitLoading(false)
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        !isLoading ? (
            !error ? (
                <div className="wrap-box tp-22 page-transition">
                    <h2 className="text-sm tp-34 font-medium mb-5">تبدیل امتیاز به پول</h2>

                    <form onSubmit={submitHandler}>
                        <div className="flex">
                            <div className="grow text-field has-val">
                                <label htmlFor="new-score">امتیاز</label>

                                <input
                                    type="text"
                                    id="new-score"
                                    value={value}
                                    onChange={changeHandler}
                                />
                            </div>

                            <button type="submit" className="bg-primary-100 text-white rounded-full text-sm px-10 mr-3 hover:bg-primary-200 transition-colors relative overflow-hidden">
                                ثبت

                                <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                                    <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                        <i className="fa-solid fa-spinner spin"></i>
                                    </span>
                                </CSSTransition>
                            </button>
                        </div>
                    </form>

                    <div className="bg-yellow-100 rounded-md mt-3 px-4 py-3 text-sm text-gray-800 font-medium dark:bg-slate-800 dark:text-gray-200">
                        تومان به ازای هر امتیاز: <b>{toMoneyFormat(parseInt(value) * data.data.reward_toman)}</b> تومان
                    </div>
                </div>
            ) : (
                <div className="wrap-box tp-22 page-transition">
                    <span className="text-sm text-red-500">خطای سیستم !</span>
                </div>
            )
        ) : (
            <div className="skeleton-box rounded-sm w-full h-24 mb-4">
                <span />
            </div>
        )

    )
}

export default memo(ConvertScore);