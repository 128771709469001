import { memo, useContext, useEffect, useState } from "react";
import EnumsContext from "context/initialize-context";
import FormGenerator from "components/form-generator/main/main";
import { arrayToRecursiveInTree } from "utils/recursive";

const Form = props => {
    const {
        id,
        formId,
        setParentLoading,
        goSubmit,
        defaultValues,
        draftLabel,
        defaultCats = [],
        targetCategory,
        activeIds,
        selectedGroupPolicy
    } = props;

    const enumsData = useContext(EnumsContext);
    const [categories, setCategories] = useState([]);
    const [formElements, setFormElements] = useState([]);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        if (!enumsData.loading && !enumsData.error) {
            // set fields
            let newFields = [];

            enumsData.data.formElements.forEach(field => {
                if (field.form_id === parseInt(formId)) {
                    newFields.push({
                        ...field,
                        name: `e${field.id}_${field.name}`
                    });
                }
            });

            const intendedForm = enumsData.data.forms.find(form => form.id === parseInt(formId));
            if (intendedForm?.parent_id) {
                const parentForm = enumsData.data.forms.find(form => form.id === intendedForm.parent_id);
                if (parentForm?.is_provider) {
                    newFields = [
                        {
                            caption: "تامین کننده",
                            name: "provider_id",
                            data_type: 1,
                            input_type: 17,
                            setting: {
                                "get_url": `/api/content/list?id=${defaultValues?.provider_id}&form_id=${intendedForm.parent_id}`,
                                "search_url": `/api/content/list?form_id=${intendedForm.parent_id}&key=:key`,
                                "value_key": "id",
                                "label_key": "virtual_title"
                            },
                            status_id: 1,
                            required_field: true
                        },
                        {
                            input_type: 21
                        },
                        ...newFields
                    ];
                }
            }

            setFields(newFields);

            const recursive = arrayToRecursiveInTree(enumsData.data.categories);
            setCategories(recursive);

            setFormElements(enumsData.data.formElements);
        }

        // eslint-disable-next-line
    }, [enumsData])

    const preData = {
        form_id: parseInt(formId),
        group_type: selectedGroupPolicy - 1
    }

    if (id)
        preData.group_id = id;

    return (
        <FormGenerator
            fields={fields}
            submitUrl="/api/content/create"
            goSubmit={goSubmit}
            setParentLoading={setParentLoading}
            payloadType={3}
            defaultValues={defaultValues}
            draftLabel={draftLabel}
            submitMethod="POST"
            preData={preData}
            categoryModule={true}
            categories={categories}
            categoriesFields={formElements}
            defaultSelectedCategories={defaultCats}
            targetCategory={targetCategory}
            activeIds={activeIds}
        />
    )
}

export default memo(Form);