import { useId, memo, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faNumToEng } from "utils";
import { CSSTransition } from "react-transition-group";
import ErrorMessage from "components/form-generator/error-msg";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import { overwriteState } from "redux/slices/main";
import { useDispatch } from "react-redux";

const Step1 = props => {
    const {
        phone,
        setPhone,
        changeStep,
        setSignupProcess
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputId = useId();
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [passwordMode, setPasswordMode] = useState(false);
    const [password, setPassword] = useState("");

    const validate = value => {
        try {
            const phoneRegex = /^09\d{9}$/;

            if (!value?.length)
                throw Object.assign(new Error("required"), { code: 1 });
            else if (!phoneRegex.test(value))
                throw Object.assign(new Error("format"), { code: 2 });
            else
                setError(null);

            return true;
        } catch (e) {
            setError(e.message);
            return false;
        }
    }

    const changeHandler = e => {
        const value = faNumToEng(e.target.value);
        const regex = /^[0-9\b]+$/;

        if (value === "" || (value.length <= 11 && regex.test(value))) {
            setPhone(value);
            validate(value);
        }
    }

    const submitHandler = async e => {
        e.preventDefault();

        if (loading)
            return;

        if (!validate(phone)) {
            inputRef.current.focus();
            return;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("username", phone);

            if (passwordMode)
                formData.append("password", password);

            const res = await MasterAPI({
                url: "/api/user/signin",
                data: formData,
                sendAuthorizationHeader: false,
                callback: () => setLoading(false),
                payloadType: 2
            });

            if (res.two_auth_method || !passwordMode) {
                toast.success(res.message);
                setSignupProcess(!!res.signup);
                changeStep(2);
            } else {
                const {
                    access_token,
                    access_token_expires_in,
                    refresh_token,
                    refresh_token_expires_in
                } = res.token;

                localStorage.setItem("_at", access_token);
                localStorage.setItem("_atet", access_token_expires_in);

                localStorage.setItem("_rt", refresh_token);
                localStorage.setItem("_rtet", refresh_token_expires_in);

                dispatch(overwriteState({
                    authorizationProcess: true,
                    authorization: true,
                    accessToken: access_token,
                    accessTokenExpireTime: refresh_token_expires_in
                }));

                navigate("/panel");
            }

        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <h1 className="tp-27 font-bold mb-2">ورود به اکانت</h1>
            <p className="tp-28 text-sm mb-8">
                جهت مدیریت تمام سیستم و تامین کنندگان می توانید با شماره همراه مدیر اکانت، وارد شوید
            </p>

            <form onSubmit={submitHandler}>
                <div className={`text-field${phone?.length ? " has-val" : ""}${!!error ? " fi-error" : ""}`}>
                    <label htmlFor={inputId}>شماره همراه</label>
                    <input
                        type="text"
                        value={phone || ""}
                        onChange={changeHandler}
                        id={inputId}
                        ref={inputRef} />

                    {!!error && <span className="f-error">{ErrorMessage(error)}</span>}
                </div>

                {
                    passwordMode && (
                        <div className={`text-field mt-4 fade-in${password.length ? " has-val" : ""}`}>
                            <label htmlFor={`${inputId}_pass`}>رمز عبور</label>
                            <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                id={`${inputId}_pass`}
                            />
                        </div>
                    )
                }

                <div className="mt-4 flex justify-between items-center">
                    <button type="submit" className="go-btn">
                        ورود

                        <CSSTransition in={loading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </form>

            <div className="mt-4 flex justify-between items-center">
                <button
                    className="text-[#6259ca] hover:text-[#4c4597] text-sm font-medium transition-colors"
                    onClick={() => setPasswordMode(prev => !prev)}
                >
                    {
                        passwordMode ? "ورود با کد تایید" : "ورود با کلمه عبور"
                    }
                </button>

                {
                    passwordMode && (
                        <Link
                            className="text-[#6259ca] hover:text-[#4c4597] text-sm font-medium transition-colors fade-in"
                            to="/forget"
                        >
                            فراموشی رمز عبور
                        </Link>
                    )
                }
            </div>
        </>
    )
}

export default memo(Step1);