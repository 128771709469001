import { memo, useEffect, useState } from "react"
import DotLoading from "components/shared/loading";
import { CSSTransition } from "react-transition-group";
import InitializeContext from "context/initialize-context";
import MasterAPI from "service/master";
import { appVersion } from "constants/config";
import { useSelector } from "react-redux";
import Error from "./error";

const Initialize = ({ children }) => {
    const authorizationProcess = useSelector(state => state.main.authorizationProcess);
    const accessToken = useSelector(state => state.main.accessToken);

    const [data, setData] = useState({
        loading: true,
        error: false,
        data: {},
        permissions: {}
    });

    useEffect(() => {
        if (authorizationProcess)
            if (accessToken)
                fetcher(accessToken);
            else
                setData({
                    loading: false,
                    error: false,
                    data: {},
                    permissions: {}
                });
        // eslint-disable-next-line
    }, [accessToken, authorizationProcess])

    useEffect(() => {
        if (!data.loading && authorizationProcess)
            document.body.classList.remove('overflow-hidden');
    }, [data, authorizationProcess])

    const fetcher = async accessToken => {
        try {
            const appData = await appDataFetcher();
            if (appData !== "reloadState") {
                const permissions = await permissionsFetcher(accessToken);

                setData({
                    loading: false,
                    error: false,
                    data: appData,
                    permissions: permissions
                });
            }
        } catch (e) {
            setData({ loading: false, error: true, data: {}, permissions: {} });
        }
    }

    const appDataFetcher = async () => {
        const lastUpdateTimeInStorage = localStorage.getItem("_lui");
        let lastUpdateTime = 0;

        const appDataInStorage = localStorage.getItem("_app");
        const appData = JSON.parse(appDataInStorage) || {};

        if (
            lastUpdateTimeInStorage &&
            !isNaN(lastUpdateTimeInStorage) &&
            appDataInStorage &&
            typeof appData === "object" &&
            Object.keys(appData)?.length
        )
            lastUpdateTime = parseInt(lastUpdateTimeInStorage);

        const res = await MasterAPI({
            url: `/api/resource/initailize_data${lastUpdateTime ? `?stamp=${lastUpdateTime}` : ""}`,
            method: "GET",
            sendAuthorizationHeader: false
        });

        localStorage.setItem("_lui", res.stamp || lastUpdateTime);
        const newData = {
            ...appData,
            ...res
        }

        localStorage.setItem("_app", JSON.stringify(newData));

        if (res.control_panel_ui) {
            caches.keys().then(keyList =>
                Promise.all(
                    keyList.map((key) => {
                        return caches.delete(key);
                    }),
                ),
            )
            window.location.reload(true);
            return "reloadState";
        }

        return newData;
    }

    const permissionsFetcher = async accessToken => {
        const permissionRes = await MasterAPI({
            url: "/api/user/permissions",
            method: "GET",
            sendAuthorizationHeader: false,
            headers: { Authorization: accessToken }
        });

        return permissionRes.permissions;
    }

    return (
        <InitializeContext.Provider value={data}>
            <CSSTransition in={!authorizationProcess || data.loading || data.error} unmountOnExit={true} timeout={500}>
                <div className="splash-screen">
                    {
                        data.error ? <Error /> : <DotLoading />
                    }

                    <div className="version">
                        <span className="text-white">version {appVersion}</span>
                    </div>
                </div>
            </CSSTransition>

            {
                authorizationProcess &&
                    !data.loading &&
                    !data.error
                    ? children : <></>
            }
        </InitializeContext.Provider>
    )
}

export default memo(Initialize);