const TemplateFetcher = filename => {
    const data = require(`constants/templates/${filename}`);

    return {
        data,
        loading: false,
        error: null
    }
}

export default TemplateFetcher;