import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const ToastifyProvider = () => {
    const darkMode = useSelector(state => state.theme.darkMode);

    return (
        <ToastContainer
            className="p-toast"
            position="top-center"
            pauseOnFocusLoss={false}
            theme={darkMode ? "dark" : "light"}
            rtl
        />
    )
}

export default ToastifyProvider;;