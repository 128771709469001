import { memo } from "react"
import { faNumToEng, toMoneyFormat } from "utils";

const InputText = props => {
    const localChangeHandler = e => {
        let value = e.target.value;

        if (props.moneyFormat) {
            value = value.replaceAll(",", "");
            value = faNumToEng(value);

            const regex = /^[0-9\b]+$/;
            if (regex.test(value))
                props.changeHandler(parseInt(value));
            else if(value.length === 0)
                props.changeHandler("");
        } else
            props.changeHandler(value);
    }

    const getValue = () => {
        if (props.moneyFormat)
            return toMoneyFormat(props.value);

        return props.value?.toString();
    }

    return (
        <div className={`text-field${props.value?.toString()?.length ? " has-val" : ""}${props.masterClass?.length ? ` ${props.masterClass}` : ""}`}>
            <label htmlFor={props.id}>{props.label}</label>

            <input
                type="text"
                id={props.id}
                value={getValue() || ""}
                onChange={localChangeHandler}
            />
        </div>
    )
}

export default memo(InputText);