import { memo } from "react"
import { Link } from "react-router-dom";

const GoToAll = ({ link, label = null }) => (
    <Link to={link} className="text-sm flex items-center text-blue-800 dark:text-blue-300">
        {label || "مشاهده همه"}
        <i className="fa-solid fa-angle-left mr-1"></i>
    </Link>
)

export default memo(GoToAll);