import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    beforeEditRoute: null
}

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setBeforeEditRoute: (state, action) => {
            state.beforeEditRoute = action.payload;
        }
    }
})

export const {
    setBeforeEditRoute
} = themeSlice.actions

export default themeSlice.reducer;