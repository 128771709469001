import { memo } from "react"

const InitializeError = () => {
    const clickHandler = () => {
        window.location.reload();
    }

    return (
        <div className="pos-cover bg-red-400 flex flex-col p-5 justify-center items-center">
            <i className="fa-solid fa-server text-7xl text-red-600"></i>
            <span className="text-sm text-white mt-4 mb-6">خطا در دریافت اطلاعات از سرور</span>

            <button
                className="bg-white rounded-lg text-slate-800 px-5 py-3 text-sm hover:bg-gray-200 transition-colors"
                onClick={clickHandler}
            >
                <i className="fa-regular fa-arrows-rotate ml-2"></i>
                تلاش مجدد
            </button>
        </div>
    )
}

export default memo(InitializeError);