import { memo } from "react"

const SectionTitle = ({ title }) => {
    return (
        <div className="section-title">
            <b>{title}</b>
        </div>
    )
}

export default memo(SectionTitle);