import { memo } from "react";
import Skeleton from "./skeleton";
import States from "./states";
import { getStartOfDayUnix, toMoneyFormat } from "utils";
import { Link } from "react-router-dom";

const Financial = props => {
    const { loading, data, userType } = props;
    const nowTimestamp = Math.floor(Date.now() / 1000);

    if (loading)
        return <Skeleton />

    return (
        !data.error ? (
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full lg:col-span-8">
                    <div className="wrap-box tp-22 page-transition">
                        <h3 className="font-bold text-sm tp-34">امروز</h3>
                        <span className="text-xs tp-35">لیست تراکنش های امروز</span>

                        <div className="flex items-center justify-between mt-5">
                            <Link
                                to={`/panel/transactions?${userType === "user" ? "user_id" : "provider_id"}=1&created_at=${getStartOfDayUnix(nowTimestamp)}-`}
                                className="w-11 h-11 flex items-center justify-center rounded-full bg-[#EFEEFA] text-[#6259CA] dark:bg-[#171634] hover:opacity-90"
                            >
                                <i className="fa-duotone fa-wallet"></i>
                            </Link>

                            <b className="tp-9 text-lg">
                                {toMoneyFormat(data.data.wallet_since["1"].balance)} {" "}
                                <span className="text-xs font-normal">تومان</span>
                            </b>
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-2 mt-4 gap-4">
                        <div className="wrap-box tp-22 page-transition">
                            <h3 className="font-bold text-sm tp-34">7 روز گذشته</h3>
                            <span className="text-xs tp-35">لیست تراکنش های 7 روز گذشته</span>

                            <div className="flex items-center justify-between mt-5">
                                <Link
                                    to={`/panel/transactions?${userType === "user" ? "user_id" : "provider_id"}=1&created_at=${getStartOfDayUnix(nowTimestamp - (86400 * 7))}-`}
                                    className="w-11 h-11 flex items-center justify-center rounded-full bg-[#EFEEFA] text-[#6259CA] dark:bg-[#171634] hover:opacity-90"
                                >
                                    <i className="fa-duotone fa-wallet"></i>
                                </Link>

                                <b className="tp-9 text-lg">
                                    {toMoneyFormat(data.data.wallet_since["7"].balance)} {" "}
                                    <span className="text-xs font-normal">تومان</span>
                                </b>
                            </div>
                        </div>

                        <div className="wrap-box tp-22 page-transition">
                            <h3 className="font-bold text-sm tp-34">30 روز گذشته</h3>
                            <span className="text-xs tp-35">لیست تراکنش های 30 روز گذشته</span>

                            <div className="flex items-center justify-between mt-5">
                                <Link
                                    to={`/panel/transactions?${userType === "user" ? "user_id" : "provider_id"}=1&created_at=${getStartOfDayUnix(nowTimestamp - (86400 * 30))}-`}
                                    className="w-11 h-11 flex items-center justify-center rounded-full bg-[#EFEEFA] text-[#6259CA] dark:bg-[#171634] hover:opacity-90"
                                >
                                    <i className="fa-duotone fa-wallet"></i>
                                </Link>

                                <b className="tp-9 text-lg">
                                    {toMoneyFormat(data.data.wallet_since["30"].balance)} {" "}
                                    <span className="text-xs font-normal">تومان</span>
                                </b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-full lg:col-span-4">
                    <States data={data.data} />
                </div>
            </div>
        ) : (
            <div className="wrap-box tp-22 page-transition">
                <p className="global-error">خطا در دریافت اطلاعات!</p >
            </div >
        )
    )
}

export default memo(Financial);