import { memo } from "react";
import { faNumToEng, toMoneyFormat } from "utils";

const InputNumber = props => {
    const {
        value,
        setValue,
        moneyFormat = false
    } = props;

    const changeHandler = e => {
        let newVal = faNumToEng(e.target.value);
        newVal = newVal.replaceAll(",", "");

        if (newVal === "") {
            setValue("");
            return;
        }

        const regex = /^[0-9\b]+$/;
        if (newVal === "" || regex.test(newVal))
            setValue(newVal);
    }

    const changeBtn = plus => {
        let currentVal;

        try {
            currentVal = parseInt(value.toString().replaceAll(",", ""));
            currentVal = (currentVal >= 0) ? currentVal : 0;
        } catch {
            currentVal = 0;
        }

        let newVal = plus ? currentVal + 1 : currentVal - 1;
        newVal = newVal >= 0 ? newVal : 0;

        setValue(newVal);
    }

    return (
        <div className="input-number">
            <span onClick={() => changeBtn(true)}>
                <i className="fa-solid fa-plus"></i>
            </span>

            <input
                type="text"
                value={(value || value === 0) ? moneyFormat ? toMoneyFormat(value) : value : ""}
                onChange={changeHandler}
            />

            <span onClick={() => changeBtn()}>
                <i className="fa-solid fa-minus"></i>
            </span>
        </div>
    )
}

export default memo(InputNumber);