import { memo, useContext, useEffect, useMemo, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Form from "./form";
import { useSearchParams } from "react-router-dom";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import templateFetcherHook from "service/template-fetcher-hook";
import InitializeContext from "context/initialize-context";
import GroupPoliciesStatus from "constants/group-policies-status";
import Selectbox from "components/shared/inputs/selectbox";
import { generateContentTitle } from "utils";

const Duplicate = props => {
    const { template } = props;
    const { id } = useParams();
    const { permissions, data: { forms } } = useContext(InitializeContext);

    const { data: pageData } = templateFetcherHook(template);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [defaultValues, setDefaultValues] = useState({});
    const [dataIsDraft, setDataIsDraft] = useState(false);
    const [defaultCats, setDefaultCats] = useState([]);
    const [targetCategory, setTargetCategory] = useState("");
    const [selectedGroupPolicy, setSelectedGroupPolicy] = useState(null);
    const [goSubmit, setGoSubmit] = useState(0);

    useEffect(() => {
        if (pageData.breadcrumb) {
            setBreadcrumb([
                ...pageData.breadcrumb,
                { title: "ایجاد" }
            ]);
        }
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        defaultValueFetcher();
        // eslint-disable-next-line
    }, [])

    const defaultValueFetcher = async () => {
        try {
            let url = pageData.get_duplicate_url;
            url = url.replace(":id", id);
            url = url.replace(":form_id", searchParams.get("form_id"));

            const res = await MasterAPI({
                url,
                method: "GET"
            });

            if (res.data) {
                const { data } = res;

                if (res.draft) {
                    setDataIsDraft(true);
                    setDefaultValues(res.draft.content.fields);
                } else if (data.fields)
                    setDefaultValues(res.data.fields);

                if (res.draft)
                    setDefaultCats(res.draft.content.categories);
                else if (data.categories.length)
                    setDefaultCats(data.categories);

                setTargetCategory(data.target_category);
            } else
                throw Object.assign(new Error("no fields"), { code: 402 });
        } catch (e) {
            toast.error(e.message);
        }
    }

    const selectOptions = useMemo(() => {
        if (permissions.all_access)
            return Object.keys(GroupPoliciesStatus);

        const intendedGroup = permissions.forms_group_policies[searchParams.get("form_id")];
        if (intendedGroup && intendedGroup.status_id)
            return intendedGroup.policies || [];

        return [];
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectOptions.length) {
            if (selectOptions.length === 1)
                setSelectedGroupPolicy(selectOptions[0]);
            else {
                if (selectOptions.indexOf(2) !== -1)
                    setSelectedGroupPolicy(2);
                else if (selectOptions.indexOf(3) !== -1)
                    setSelectedGroupPolicy(3);
            }
        }
    }, [selectOptions])

    const submitHandler = () => {
        if (submitLoading || selectOptions.length === 0)
            return;

        setGoSubmit(prev => prev + 1);
    }

    const contentTitle = useMemo(() => {
        if (selectedGroupPolicy === 3 && Object.keys(defaultValues).length)
            return generateContentTitle(parseInt(searchParams.get("form_id")), defaultValues, forms);

        return "";
        // eslint-disable-next-line
    }, [selectedGroupPolicy, defaultValues])

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={breadcrumb} />
                </div>

                <div className="flex">
                    {
                        selectOptions.length > 1 && (
                            <Selectbox
                                placeholder={selectedGroupPolicy ? GroupPoliciesStatus[selectedGroupPolicy] : "گروه بندی محتوا"}
                                options={selectOptions.map(option => ({ title: GroupPoliciesStatus[option], clickHandler: () => setSelectedGroupPolicy(option) }))}
                                className="c2 tp-24 ml-2 group-icon"
                            />
                        )
                    }

                    <button
                        className={`bg-primary-100 px-4 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden ${submitLoading ? "cursor-progress" : selectOptions.length && selectedGroupPolicy ? "cursor-pointer" : "cursor-default"} ${selectOptions.length === 0 || selectedGroupPolicy === null ? "opacity-70" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ایجاد

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    selectedGroupPolicy === 3 && (
                        <div className="grid gap-3 grid-cols-12">
                            <div className="col-span-full md:col-span-7 lg:col-span-8 text-center">
                                <h2 className="text-slate-900 dark:text-slate-200 font-bold text-lg mt-2 mb-5">{contentTitle}</h2>
                            </div>
                        </div>
                    )
                }

                <Form
                    goSubmit={goSubmit}
                    id={id}
                    formId={searchParams.get("form_id")}
                    defaultValues={defaultValues}
                    draftLabel={dataIsDraft}
                    defaultCats={defaultCats}
                    targetCategory={targetCategory}
                    activeIds={selectedGroupPolicy === 3 ? permissions.forms_group_policies[searchParams.get("form_id")].elemets_exclude_group_template : null}
                    setParentLoading={setSubmitLoading}
                    selectedGroupPolicy={selectedGroupPolicy}
                />
            </div>
        </div>
    )
}

export default memo(Duplicate);