import { useCallback, useContext, useEffect } from "react";
import ReducerProvider, { ReducerContext } from "./reducerProvider";
import Modal from "components/shared/modal";
import Map from "./map/map";
import Searchbar from "./map/searchbar";
import PointInfo from "./map/pointInfo";
import DetailForm from "./detailForm/detailForm";
import AddressCard from "./addressCard";
import InitializeContext from "context/initialize-context";

const SelectLocation = (props) => {
    return (
        <ReducerProvider>
            <Inner {...props} />
        </ReducerProvider>
    )
}

const Inner = (props) => {
    const {
        doneCallback,
        defaultValue = {},
        btnIcon = "fa-regular fa-plus",
        btnTitle = "افزودن لوکیشن",
        step1ModalTitle = "نقطه فعلی شما"
    } = props;

    const { state, dispatch } = useContext(ReducerContext);
    const enums = useContext(InitializeContext);

    useEffect(() => {
        if (Object.keys(defaultValue).length) {
            dispatch({ type: "SET_DEFAULT_VALUE", payload: defaultValue });
            dispatch({ type: "SET_CENTER_POINT", payload: defaultValue.coordinates });
        } else if (enums.data.general_keys)
            dispatch({ type: "SET_CENTER_POINT", payload: [parseFloat(enums.data.general_keys.default_location_latitude), parseFloat(enums.data.general_keys.default_location_longitude)] });
            
        // eslint-disable-next-line
    }, [defaultValue])

    const resetHandler = () => {
        doneCallback({});
        dispatch({ type: "SET_DEFAULT_VALUE", payload: {} });
    }

    const closeStep1Modal = useCallback(() => {
        dispatch({ type: "CLOSE_MODALS" });
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <button
                type="button"
                onClick={() => dispatch({ type: "SET_SHOW_STATUS", payload: true })}
                className="w-full border rounded-xl text p-4 text-sm tracking-tight font-bold transition-colors dark:border-slate-700 dark:text-slate-300"
            >
                <span className="relative top-px"><i className={btnIcon} /></span>
                <span className="mr-2">{btnTitle}</span>
            </button>

            {
                Object.keys(state.defaultValue).length > 0 && (
                    <div className="page-transition">
                        <div className="mt-4">
                            <AddressCard
                                address={state.defaultValue}
                                forView={true}
                                editHandler={() => dispatch({ type: "SET_SHOW_STATUS", payload: true })}
                                resetHandler={resetHandler}
                            />
                        </div>
                    </div>
                )
            }

            <Modal
                show={state.showModal || state.showDetailForm}
                title={step1ModalTitle}
                closeHandler={closeStep1Modal}
                className="addressModal"
            >
                {
                    !state.showDetailForm ? (
                        <div className="h-full flex flex-col">
                            {/* <CurrentList doneCallback={doneCallback} /> */}

                            <div className="grow min-h-0 relative">
                                <Searchbar />
                                <Map />
                            </div>

                            <div className="py-4 pt-2">
                                <div className="mb-2">
                                    <PointInfo />
                                </div>

                                <button
                                    className="grow text-center text-white bg-primary-100 w-full hover:bg-secondary-200 transition-all text-sm rounded-lg p-3 disabled:opacity-50 disabled:bg-secondary-100"
                                    onClick={() => dispatch({ type: "SET_SHOW_DETAIL_FORM", payload: true })}
                                    disabled={state.pointInfo.pending || state.pointInfo.error}
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    ) : (
                        <DetailForm doneCallback={doneCallback} />
                    )
                }
            </Modal>
        </>
    )
}

export default SelectLocation;