import { memo, useContext, useMemo, useState } from "react"
import ChartBoxSkeleton from "components/skeleton/chart-box";
import TinyListSkeleton from "components/skeleton/tiny-list";
import InitializeContext from "context/initialize-context";
import moment from "jalali-moment";
import ChartLine from "components/shared/charts/line";
import commentStatus from "constants/comment-statuses";
import GoToAll from "components/shared/go-to-all";
import { useParams } from "react-router-dom";

const Comments = props => {
    const enums = useContext(InitializeContext);

    const activeForm = useMemo(() => {
        const intendedForm = enums.data.forms.find(form => !!form.comment_feature);
        if (intendedForm)
            return intendedForm.id;

        return null;
        // eslint-disable-next-line
    }, [])

    if (activeForm)
        return <Inner {...props} initActiveForm={activeForm} />

    return <></>
}

const Inner = props => {
    const { loading, data, initActiveForm } = props;
    const { id } = useParams();
    const enums = useContext(InitializeContext);
    const [activeForm, setActiveForm] = useState(initActiveForm);

    const statuses = useMemo(() => {
        const output = [];

        enums.data.forms.forEach(form => {
            if (form.comment_feature)
                output.push({
                    title: form.title,
                    value: form.id
                });
        });

        return output;
        // eslint-disable-next-line
    }, [])

    const activeFormLabel = useMemo(() => {
        const intendedForm = enums.data.forms.find(form => form.id === parseInt(activeForm));
        return intendedForm ? intendedForm.title : "";
        // eslint-disable-next-line
    }, [activeForm])

    const chartData = useMemo(() => {
        const counts = [];
        const days = [];

        if (!loading && !data.error && data.data.comment.graph_last_month[activeForm])
            data.data.comment.graph_last_month[activeForm].forEach(el => {
                counts.push(el.count);
                days.push(moment.unix(el.day).format("jYYYY/jMM/jD"));
            })

        return {
            counts,
            days
        }
    }, [loading, data, activeForm])

    return (
        <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-full lg:col-span-8">
                {
                    !loading ? (
                        !data.error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <h2 className="tp-34 text-sm font-bold pb-4">نمودار ایجاد کامنت ها</h2>

                                <div className="pt-3">
                                    <label className="select-c1 mb-8">
                                        <span>فرم انتخاب شده</span>

                                        <select onChange={e => setActiveForm(e.target.value)} value={activeForm}>
                                            {
                                                statuses.map(status => (
                                                    <option key={`option-${status.value}`} value={status.value}>{status.title}</option>
                                                ))
                                            }
                                        </select>
                                    </label>

                                    <ChartLine series={[{ name: 'تعداد', data: chartData.counts }]} xaxis={chartData.days} />
                                </div>
                            </div>
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="global-error">خطا در دریافت اطلاعات !</p>
                            </div>
                        )
                    ) : <ChartBoxSkeleton />
                }
            </div>

            <div className="col-span-full lg:col-span-4">
                {
                    !loading ? (
                        !data.error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <div className="state-box h-full flex flex-col">
                                    <div className="flex items-center justify-between reset-i pb-4">
                                        <h2 className="tp-34 text-sm font-bold">وضعیت کامنت ها ({activeFormLabel})</h2>
                                        <GoToAll link={`/panel/comment?user_id=${id}&form_id=${activeForm}`} />
                                    </div>

                                    <div className="overflow-auto spec-sb-c2 pl-4">
                                        <ul>
                                            {
                                                commentStatus.map((status, i) => (
                                                    <li key={`content-status-${i}`}>
                                                        <div>
                                                            <span className="sec-title tp-34">{status.title}</span>
                                                        </div>

                                                        <b className="tp-34">
                                                            {
                                                                data.data.comment.status[activeForm]
                                                                    ? data.data.comment.status[activeForm][i] || 0
                                                                    : 0
                                                            }
                                                        </b>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="global-error">خطا در دریافت اطلاعات !</p>
                            </div>
                        )
                    ) : <TinyListSkeleton />
                }
            </div>
        </div>
    )
}

export default memo(Comments);