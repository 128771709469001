import { memo } from "react";
import DatePickerComponent from "./date-picker";
import { convertShamsiToUnix } from "utils";
import { toast } from "react-toastify";

const TimeRange = props => {
    const {
        value,
        pickTime = false,
        label,
        changeHandler
    } = props;

    const localValue = value ? value.split("-") : ["", ""];

    const localChangeHandler = (index, val) => {
        const currentValue = value ? value.split("-") : ["", ""];
        currentValue[index] = convertShamsiToUnix(val, pickTime);

        if (isNaN(currentValue[index]))
            currentValue[index] = "";

        if (currentValue[0] === "" && currentValue[1] === "")
            changeHandler(null);
        else {
            const from = currentValue[0] === "" ? 0 : currentValue[0];
            const to = currentValue[1] === "" ? 0 : currentValue[1];

            if (from === 0 || to === 0 || from < to)
                changeHandler(currentValue.join("-"));
            else {
                toast.error("تاریخ شروع نمی تواند از تاریخ پایان بزرگتر باشد");
                changeHandler(`${currentValue[0]}-`);
            }
        }
    }

    return (
        <div className="cat-holder">
            <span className="label tp-27">{label}</span>

            <div className="py-2">
                <div className="grid grid-cols-2 gap-4">
                    <DatePickerComponent
                        label="از"
                        value={new Date(parseInt(localValue[0]) * 1000)}
                        openInModal={true}
                        changeHandler={val => localChangeHandler(0, val)}
                        pickTime={pickTime}
                    />

                    <DatePickerComponent
                        label="تا"
                        value={new Date(parseInt(localValue[1]) * 1000)}
                        openInModal={true}
                        changeHandler={val => localChangeHandler(1, val)}
                        pickTime={pickTime}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(TimeRange);